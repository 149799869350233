import { useState, useMemo, useCallback, useRef, useEffect } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from "draft-js"
import {
  getContentById,
  getContentStats,
  getContentStatsById,
  scoreBias,
  scoreCognitiveBias,
  scoreEmotion,
  scoreFakeness,
  scoreHeadline,
  scorePoliticalBias,
  scoreEmpathy,
  scoreEngagement,
  scoreSentiment,
  scoreHateSpeech,
  scoreRacismSexism,
  scoreToxicity,
  scorePatronize,
  scoreSubjectiveBias,
  scoreGenderBias,
  reportBias,
  updateContentByIdAction,
  scoreInformativeness,
  scoreComplexity,
} from "store/actions"
import {
  averageScoreArray,
  averageScoreObject,
  getAverageBiasScore,
  getAverageCognitiveBiasScore,
  getAverageScore,
} from "utils"
import { useParams } from "react-router-dom"

const useComposer = () => {
  const dispatch = useDispatch()

  const { project_id, content_id } = useParams()

  const [activeCard, setActiveCard] = useState("")
  const [collapse, setCollapse] = useState("")
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [documentTitle, setDocumentTitle] = useState("")
  const [hasContent, setHasContent] = useState(false)

  const {
    loading,
    error,
    emotionScore,
    biasScore,
    cognitiveBiasScore,
    politicalBiasScore,
    fakenessScore,
    headlineScore,
    empathyScore,
    engagementScore,
    sentimentScore,
    hateSpeechScore,
    racismSexismScore,
    toxicityScore,
    patronizeScore,
    subjectiveBiasScore,
    genderBiasScore,
    reportBiasScore,
    informativenessScore,
    complexityScore
  } = useSelector(state => state.scoresReducer)

  const formContentDataRef = useRef(null)
  const formDataRef = useRef(null)
  const countWords = str => str.split(/\s+/).filter(Boolean).length

  useEffect(
    () => {
      if (formContentDataRef.current && hasContent) {
        const htmlContent = formContentDataRef.current.get("content")
        if (countWords(htmlContent) >= 100) {
          const debounceTimeout = setTimeout(() => {
            dispatch(scoreEmotion(formContentDataRef.current))
            dispatch(scoreBias(formContentDataRef.current))
            dispatch(scoreCognitiveBias(formContentDataRef.current))
            dispatch(scorePoliticalBias(formContentDataRef.current))
            dispatch(scoreFakeness(formContentDataRef.current))
            dispatch(scoreHeadline(formContentDataRef.current))
            dispatch(scoreEmpathy(formContentDataRef.current))
            dispatch(scoreEngagement(formContentDataRef.current))
            dispatch(scoreSentiment(formContentDataRef.current))
            dispatch(scoreHateSpeech(formContentDataRef.current))
            dispatch(scoreRacismSexism(formContentDataRef.current))
            dispatch(scoreToxicity(formContentDataRef.current))
            dispatch(scorePatronize(formContentDataRef.current))
            dispatch(scoreSubjectiveBias(formContentDataRef.current))
            dispatch(scoreGenderBias(formContentDataRef.current))
            dispatch(reportBias(formContentDataRef.current))
            dispatch(scoreInformativeness(formContentDataRef.current))
            dispatch(scoreComplexity(formContentDataRef.current))
          }, 2000)

          return () => clearTimeout(debounceTimeout)
        } else {
          console.log("Not enough words in the document to start analysis")
        }
      }
    },
    [
      /* dispatch, formContentDataRef.current, hasContent */
    ]
  )

  const onEditorStateChange = useCallback(
    editorState => {
      const rawContent = convertToRaw(editorState.getCurrentContent())
      let htmlContent = ""

      rawContent.blocks.forEach(block => {
        htmlContent += "<p>" + block.text + "</p>"
      })

      //htmlContent = htmlContent.substring(3, htmlContent.length - 4)

      const formData = new FormData()
      formData.append("content", htmlContent)
      formData.append("title", documentTitle)
      formDataRef.current = formData
      const formContentData = new FormData()

      formContentData.append("content", htmlContent)
      formContentDataRef.current = formContentData

      setEditorState(editorState)

      const hasText = editorState.getCurrentContent().hasText()
      setHasContent(hasText)
    },
    [dispatch, documentTitle, project_id, content_id]
  )

  const handleTitleChange = event => {
    setDocumentTitle(event.target.value)
  }

  const biasScoreAverage = useMemo(
    () => getAverageBiasScore(biasScore),
    [biasScore]
  )
  const cognitiveBiasScoreAverage = useMemo(
    () => getAverageScore(cognitiveBiasScore),
    [cognitiveBiasScore]
  )
  const politicalBiasScoreAverage = useMemo(
    () => getAverageScore(politicalBiasScore),
    [politicalBiasScore]
  )
  const fakenessScoreAverage = useMemo(
    () => averageScoreArray(fakenessScore),
    [fakenessScore]
  )

  let headlineOutput = headlineScore?.msgs.map(msg => ({
    msg,
    score: headlineScore.score,
  }))

  const headlineScoreAverage = useMemo(
    () => getAverageScore(headlineOutput),
    [headlineScore]
  )
  /* 
  const headlineUpdatedScore = useMemo(() => {
    const convertedObj = {}

    headlineScore?.msgs?.forEach(msg => {
      const key = msg.replace("\u2022", "").trim()
      convertedObj[key] = headlineScore.score
    })

    return convertedObj
  }, [headlineScore]) */

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen)
  }

  return {
    // Shared state variables
    formContentDataRef,
    formDataRef,
    documentTitle,
    editorState,
    hasContent,
    sidebarOpen,
    activeCard,
    collapse,
    loading,
    error,

    // Shared functions
    setDocumentTitle,
    toggleSidebar,
    onEditorStateChange,
    setHasContent,
    handleTitleChange,
    setSidebarOpen,
    setActiveCard,
    setCollapse,
    setEditorState,

    // Shared data for averages
    biasScoreAverage,
    cognitiveBiasScoreAverage,
    politicalBiasScoreAverage,
    fakenessScoreAverage,
    headlineScoreAverage,
    emotionScore,
    fakenessScore,
    biasScore,
    cognitiveBiasScore,
    politicalBiasScore,
    empathyScore,
    engagementScore,
    sentimentScore,
    hateSpeechScore,
    racismSexismScore,
    toxicityScore,
    patronizeScore,
    subjectiveBiasScore,
    genderBiasScore,
    reportBiasScore,
    informativenessScore,
    complexityScore,
  }
}

export default useComposer
