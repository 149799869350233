import {Card, CardBody, CardHeader} from "reactstrap";
import React from "react";

const ScoreDefinitions = () => {
    return (
        <Card style={{float: "left", width: "100%"}}>
            <CardHeader>
                <h3>Score Definitions</h3>
            </CardHeader>
            <CardBody>
                <p>
                    In the Flesch <b>Reading Ease</b> test, higher scores indicate
                    material that is easier to read; lower numbers mark passages that
                    are more difficult to read.
                    <span>
                {" "}
                        For more information
                <a
                    href="https://strainindex.wordpress.com/2009/04/30/mcalpine-eflaw-readability-score/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                  {" "}
                    click here
                </a>
              </span>
                </p>
                <p>
                    Mcalpine eflaw <b>Readability Score</b>. (Lower value is better)
                    <span>
                {" "}
                        For more information
                <a
                    href="https://en.wikipedia.org/wiki/Flesch%E2%80%93Kincaid_readability_tests"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                  {" "}
                    click here
                </a>
              </span>
                </p>
                <p>
                    <b>Readability score</b> is the grade level consensus identified
                    from the following scores - Flesch Kincaid Grade, SMOG Index,
                    Coleman Liau Index, Automated Readability Index, Dale Chall
                    Readability Score, Linsear Write Formula, Gunning Fog Index
                </p>
            </CardBody>
        </Card>
    )
}
export default ScoreDefinitions