import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// user Redux States
import { GET_USER_INFO } from "./actionTypes"
import { getUserInfoSuccess, getUserInfoError } from "./actions"

import { getUserInfo } from "../../helpers/backend_helper"
import { getUserSusbcriptionInfo, signupRequest } from "store/actions"

function* fetchUserInfo() {
  try {
    const response = yield call(getUserInfo)
    yield put(getUserInfoSuccess(response))
    localStorage.setItem("userInfo", JSON.stringify(response))
    yield put(getUserSusbcriptionInfo())
    /*  if (typeof response.subscription !== "undefined") {
      props.history.push("home")
    } */
  } catch (error) {
    const formData = new FormData()

    if (error?.response?.status === 404) {
      const email = localStorage.getItem("emailUser")
        ? JSON.parse(localStorage.getItem("emailUser"))
        : null

      formData.append("email", email)
      yield put(signupRequest(formData))
    } else {
      yield put(getUserInfoError(error))
    }
  }
}
function* userSaga() {
  yield takeEvery(GET_USER_INFO, fetchUserInfo)
}

export default userSaga
