import React from "react"
import PropTypes from "prop-types"
import StackedProgressBar from "components/progress-bar/StackedProgressBar"
import BiasScore from "./BiasScore"

const OffenseProfile = ({
  racismSexismData,
  hateSpeechData,
  toxicityData,
  error,
}) => {

  console.log("toxicityData", toxicityData)
  return (
    <div className="offense-profile mt-4">
      <div className="offense-profile__section mb-4">
        <h5>Racism/Sexism</h5>
        {!racismSexismData ||
        Object.keys(racismSexismData).length === 0 ||
        racismSexismData.error ? (
          <p className="error-message">Score is not available currently.</p>
        ) : (
          racismSexismData && (
            <BiasScore biasType="racismSexism" data={racismSexismData} />
          )
        )}
      </div>
      <hr />
      <div className="offense-profile__section mb-4">
        <h5>Hate Speech</h5>
        {!hateSpeechData ||
        Object.keys(hateSpeechData).length === 0 ||
        hateSpeechData.error ? (
          <p className="error-message">Score is not available currently.</p>
        ) : (
          hateSpeechData && (
            <BiasScore biasType="hateSpeech" data={hateSpeechData} />
          )
        )}
      </div>
      <hr />
      <div className="offense-profile__section mb-4">
        <h5>Toxicity</h5>
        {!toxicityData ||
        Object.keys(toxicityData).length === 0 ||
        toxicityData.error ? (
          <p className="error-message">Score is not available currently.</p>
        ) : (
          toxicityData && <BiasScore biasType="toxicity" data={toxicityData} />
        )}
      </div>
    </div>
  )
}

OffenseProfile.propTypes = {
  racismSexismData: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  hateSpeechData: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  toxicityData: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  error: PropTypes.any.isRequired,
}
export default OffenseProfile
