import {
  ADD_SCREENPLAY,
  ADD_SCREENPLAY_SUCCESS,
  ADD_SCREENPLAY_FAIL,
  UPDATE_SCREENPLAY,
  UPDATE_SCREENPLAY_SUCCESS,
  UPDATE_SCREENPLAY_FAIL,
  GET_SCREENPLAY,
  GET_SCREENPLAY_SUCCESS,
  GET_SCREENPLAY_FAIL,
  LIST_SCREENPLAYS,
  LIST_SCREENPLAYS_SUCCESS,
  LIST_SCREENPLAYS_FAIL,
  DELETE_SCREENPLAY,
  DELETE_SCREENPLAY_SUCCESS,
  DELETE_SCREENPLAY_FAIL,
} from "./actionTypes"

export const addScreenplay = (screenplay, history) => ({
  type: ADD_SCREENPLAY,
  payload: { screenplay, history },
})

export const addScreenplaySuccess = screenplay => ({
  type: ADD_SCREENPLAY_SUCCESS,
  payload: { screenplay },
})

export const addScreenplayFail = error => ({
  type: ADD_SCREENPLAY_FAIL,
  payload: { error },
})

export const updateScreenplay = (screenplay, history) => ({
  type: UPDATE_SCREENPLAY,
  payload: { screenplay, history },
})

export const updateScreenplaySuccess = screenplay => ({
  type: UPDATE_SCREENPLAY_SUCCESS,
  payload: { screenplay },
})

export const updateScreenplayFail = error => ({
  type: UPDATE_SCREENPLAY_FAIL,
  payload: { error },
})

export const getScreenplay = id => ({
  type: GET_SCREENPLAY,
  payload: { id },
})

export const getScreenplaySuccess = screenplay => ({
  type: GET_SCREENPLAY_SUCCESS,
  payload: { screenplay },
})

export const getScreenplayFail = error => ({
  type: GET_SCREENPLAY_FAIL,
  payload: { error },
})

export const listScreenplays = () => ({
  type: LIST_SCREENPLAYS,
})

export const listScreenplaysSuccess = screenplays => ({
  type: LIST_SCREENPLAYS_SUCCESS,
  payload: { screenplays },
})

export const listScreenplaysFail = error => ({
  type: LIST_SCREENPLAYS_FAIL,
  payload: { error },
})


export const deleteScreenplay = (screenplayId) => ({
  type: DELETE_SCREENPLAY,
  payload: { screenplayId }
});

export const deleteScreenplaySuccess = (screenplayId) => ({
  type: DELETE_SCREENPLAY_SUCCESS,
  payload: screenplayId
});

export const deleteScreenplayFail = (error) => ({
  type: DELETE_SCREENPLAY_FAIL,
  payload: error
});