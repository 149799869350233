export const ADD_SCENE = 'ADD_SCENE';
export const ADD_SCENE_SUCCESS = 'ADD_SCENE_SUCCESS';
export const ADD_SCENE_FAIL = 'ADD_SCENE_FAIL';

export const REORDER_SCENES = 'REORDER_SCENES';
export const REORDER_SCENES_SUCCESS = 'REORDER_SCENES_SUCCESS';
export const REORDER_SCENES_FAIL = 'REORDER_SCENES_FAIL';

export const DELETE_SCENE = 'DELETE_SCENE';
export const DELETE_SCENE_SUCCESS = 'DELETE_SCENE_SUCCESS';
export const DELETE_SCENE_FAIL = 'DELETE_SCENE_FAIL';
