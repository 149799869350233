import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button, Progress, Row, Col
} from "reactstrap"
import { Dropdown } from "react-bootstrap"
import Breadcrumb from "components/Common/Breadcrumb2"
import {
  getContentByProject,
  deleteContentById,
} from "store/actions"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import {
  MdDeleteForever,
  MdEditNote,
  MdOutlineNoteAdd,
  MdMoreHoriz,
} from "react-icons/md"
import { getLoggedInUser } from "helpers/backend_helper"
import mixpanel from "mixpanel-browser"
import NoDataFound from "components/NoDataFound"
import { withSignup } from "hooks/withSignup"
import ScaleLoader from "react-spinners/ScaleLoader";
import {COLOR_THEME_PRIMARY} from "utils";

const ListDocuments = () => {
  const dispatch = useDispatch()
  const { project } = useParams()
  const { contents, loading } = useSelector(state => state.projects)

  //const deleteRef = useRef(false)
  const [deleteProject, setDeleteProject] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)

  const [showModal, setShowModal] = useState(false)
  const [contentInfo, setContentInfo] = useState()

  useEffect(() => {
    const loggedInUser = getLoggedInUser()
    if (loggedInUser !== null) {
      mixpanel.track("list_project_docs", {
        event_properties: {
          user_id: loggedInUser.uid,
        },
      })
    } else {
      mixpanel.track("list_project_docs")
    }
  }, [])

  const items = [
    { title: "Projects", link: "/projects" },
    {
      title: `Documents`,
      link: "#",
    },
  ]


  useEffect(() => {
    if (project || deleteProject) {
      dispatch(getContentByProject(project))
    }
    setDeleteProject(false)
  }, [project.length, deleteProject])

  useEffect(() => {
    if (deleteLoading) {
      setDeleteLoading(false)
    }
  }, [contents])

  const handleDeleteClick = () => {
    setDeleteLoading(true)
    try {
      dispatch(deleteContentById(contentInfo.projectId, contentInfo.contentId))
      setDeleteProject(true)
      setShowModal(false)
    } catch (error) {
      console.log(error)
      setDeleteLoading(false)
    }
  }

  const onShowModal = (projectId, contentId) => {
    setContentInfo({
      projectId: projectId,
      contentId: contentId,
    })
    setShowModal(true)
  }

  const onCloseModal = () => {
    setShowModal(false)
  }

  function getSummary(content) {
    return (
      <>
        {content?.summary?.length > 100
          ? content?.summary?.substr(0, 100) + "..."
          : content?.summary?.substr(0, content?.summary?.length)}
      </>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Documents | QuillNow
          </title>
        </MetaTags>
        <Container fluid>
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18">Documents</h4>
                <div className="page-title-right">
                  <Breadcrumb breadcrumbItems={items} />
                </div>
              </div>
            </div>
          </div>
           <div className="row">
            <div className="mb-2 row">
              <div className="col-sm-8"/>
              <div className="col-sm-8 float-right">
                <Link
                    to={`/project/composer/document/add/${project}`}
                    className="btn-rounded mb-2 me-2 btn btn-primary"
                >
                  <MdOutlineNoteAdd size={18} /> New Document
                </Link>
              </div>
            </div>
            <div className="projects-container mb-4">
              <>
                {contents &&
                contents?.contents &&
                contents?.contents?.length > 0 ? (
                  contents?.contents.map(content => {
                    return (
                      <div key={content?.id}>
                        <div className="col-sm-6 col-2">
                          <Card style={{ width: "18rem" }} className="card-container">
                            <CardBody>
                              <CardTitle>
                                <Link
                                    to={`/project/composer/document/edit/${project}/${content?.id}`}
                                >
                                  <h4>{content?.title}</h4>
                                </Link>
                              </CardTitle>
                              <div className="d-flex align-content-start">
                                {content?.content_percentage && (
                                    <Progress style={{height: '20px', width: '200px'}} min={0} max="100" value={content?.content_percentage}
                                              color={COLOR_THEME_PRIMARY}>
                                      <span className="text-black">{content?.content_percentage}%</span>
                                    </Progress>
                                )}
                              </div>
                            </CardBody>
                            <CardBody>
                                <CardSubtitle>{getSummary(content)}</CardSubtitle>
                            </CardBody>
                            <ListGroup className="list-group-flush">
                              <ListGroupItem>
                                <Row>
                                  <Col className="col-9 text-muted font-size-12">
                                    Characters
                                  </Col>
                                  <Col className="col-3">
                                    {content?.text_stats &&
                                    content?.text_stats?.char_count !==
                                    undefined ? (
                                        <span className="badge badge-soft-dark font-size-11 m-1">
                                      {content?.text_stats?.char_count}
                                    </span>
                                    ) : (
                                        <ScaleLoader height='5px' width='6px' color={COLOR_THEME_PRIMARY} />
                                    )}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="col-9 text-muted font-size-12">Words</Col>
                                  <Col className="col-3">{content?.text_stats &&
                                  content?.text_stats?.word_count !==
                                  undefined ? (
                                      <span className="badge badge-soft-dark font-size-11 m-1">
                                      {content?.text_stats?.word_count}
                                    </span>
                                  ) : (
                                      <ScaleLoader height='5px' width='6px' color={COLOR_THEME_PRIMARY} />
                                  )}</Col>
                                </Row>
                                <Row>
                                  <Col className="col-9 text-muted font-size-12">Characters without space</Col>
                                  <Col className="col-3">{content?.text_stats &&
                                  content?.text_stats
                                      ?.char_count_without_space !== undefined ? (
                                      <span className="badge badge-soft-dark font-size-11 m-1">
                                      {
                                        content?.text_stats
                                            ?.char_count_without_space
                                      }
                                    </span>
                                  ) : (
                                      <ScaleLoader height='5px' width='6px' color={COLOR_THEME_PRIMARY} /> // or your custom spinner component here
                                  )}</Col>
                                </Row>
                              </ListGroupItem>
                            </ListGroup>
                            {!contents?.info?.is_demo && (
                                <CardBody className="content-card-link">
                                  <Dropdown style={{ position: "absolute", bottom: 0, right: 0 }}>
                                    <Dropdown.Toggle
                                        //variant="success"
                                        id="dropdown-basic"
                                        className="bg-transparent border-0"
                                        style={{ color: "#2a3042" }}
                                    >
                                      <MdMoreHoriz size={28} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item>
                                        <Link
                                            to={`/project/composer/document/edit/${project}/${content?.id}`}
                                        >
                                          <MdEditNote size={28} />
                                          Edit
                                        </Link>
                                      </Dropdown.Item>
                                      <Dropdown.Item>
                                        <Link
                                            to={`#`}
                                            onClick={() =>
                                                onShowModal(project, content?.id)
                                            }
                                        >
                                          <MdDeleteForever size={28} />
                                          Delete
                                        </Link>
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </CardBody>
                            )}
                          </Card>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <NoDataFound message="Add a document to get started" />
                )}
              </>
            </div>
          </div>
        </Container>
      </div>
      <div>
        {showModal && (
          <Modal
            className="project-delete-confirmation"
            isOpen={showModal}
            toggle={onCloseModal}
          >
            <ModalHeader
              className="text-center"
              toggle={onCloseModal}
            ></ModalHeader>
            <ModalBody className="text-center">
              <div className="icon-box">
                <MdDeleteForever className="text-danger" size={55} />
              </div>
              <h2>Are you sure?</h2>
              <p>
                Do you really want to delete these contents? This action cannot
                be undone.
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={onCloseModal}>
                Cancel
              </Button>
              <Button color="danger" onClick={handleDeleteClick}>
                Delete
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    </React.Fragment>
  )
}

const LoadingComponent = () => (
  <div className="sidebar-loading__container">
    <ScaleLoader height="5px" width="6px" color={COLOR_THEME_PRIMARY} />
  </div>
)
export default withSignup(ListDocuments)

ListDocuments.propTypes = {
  row: PropTypes.any,
  value: PropTypes.any,
  searchProps: PropTypes.any,
  baseProps: PropTypes.any,
}
