import React, {useEffect} from "react"
import PricingCard from "components/PricingCard"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import Breadcrumb from "components/Common/Breadcrumb2"
import PaymentPricingCard from "components/PricingPayment"
import {getLoggedInUser} from "../../helpers/backend_helper";
import mixpanel from "mixpanel-browser";
const Payment = () => {
    useEffect(() => {
        const loggedInUser = getLoggedInUser();
        if (loggedInUser !== null) {
            mixpanel.track('payment_page_load', {
                event_properties: {
                    'user_id': loggedInUser.uid
                }});
        } else {
            mixpanel.track('payment_page_load');
        }
    }, [])
    const items = [
        { title: "Settings", link: "/settings" },
        { title: "Payment", link: "/settings/payment" },
      ]

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Payment</title>
        </MetaTags>
        <Container fluid>
        <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18">Payment</h4>
                <div className="page-title-right">
                  <Breadcrumb breadcrumbItems={items} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <PaymentPricingCard />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Payment
