import {
  GET_SCORES,
  GET_SCORE_FAIL,
  GET_SCORE_SUCCESS,
  GET_SCORES_BY_PARA,
  GET_SCORES_BY_PARA_SUCCESS,
  GET_SCORES_BY_PARA_FAIL,
} from "./actionTypes"

export const getScoreData = content => ({
  type: GET_SCORES,
  payload: content,
})
export const getScoreDataSuccess = content => ({
  type: GET_SCORE_SUCCESS,
  payload: content,
})

export const getScoreDataFail = error => ({
  type: GET_SCORE_FAIL,
  payload: error,
})

export const getScoreDataByPara = content => ({
  type: GET_SCORES_BY_PARA,
  payload: content,
})
export const getScoreDataByParaSuccess = content => ({
  type: GET_SCORES_BY_PARA_SUCCESS,
  payload: content,
})

export const getScoreDataByParaFail = error => ({
  type: GET_SCORES_BY_PARA_FAIL,
  payload: error,
})
