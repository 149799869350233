import {
  GET_IDEAS_FAIL,
  GET_IDEAS_SUCCESS,
  GET_IDEA_DETAIL_FAIL,
  GET_IDEA_DETAIL_SUCCESS,
  ADD_IDEA_SUCCESS,
  ADD_IDEA_FAIL,
  UPDATE_IDEA_SUCCESS,
  UPDATE_IDEA_FAIL,
  DELETE_IDEA_SUCCESS,
  DELETE_IDEA_FAIL,
  GET_IDEAS,
  ADD_NEW_IDEA,
  UPDATE_IDEA,
  DELETE_IDEA,
  GET_IDEA_DETAIL,
} from "./actionTypes"

const INIT_STATE = {
  ideas: [],
  ideaDetail: {},
  loading: false,
  error: null,
  addIdeaStatus: null,
}

const ideas = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_IDEAS:
    case ADD_NEW_IDEA:
    case UPDATE_IDEA:
    case DELETE_IDEA:
    case GET_IDEA_DETAIL:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case GET_IDEAS_SUCCESS:
      return {
        ...state,
        ideas: action.payload.idea,
        loading: false,
      }

    case ADD_IDEA_SUCCESS:
      return {
        ...state,
        //ideas: [...state.ideas, action.payload],
        loading: false,
        addIdeaStatus: 'success',
      }

    case GET_IDEA_DETAIL_SUCCESS:
      return {
        ...state,
        ideaDetail: action.payload,
        loading: false,
      }

    case UPDATE_IDEA_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }

    case DELETE_IDEA_SUCCESS:
      console.log(action.payload) // Check the payload
      return {
        ...state,
        //ideas: [...state.ideas.filter(idea => idea.id !== action.payload.id)],
        loading: false,
      }

    case GET_IDEAS_FAIL:
    case ADD_IDEA_FAIL:
    case UPDATE_IDEA_FAIL:
    case DELETE_IDEA_FAIL:
    case GET_IDEA_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    default:
      return state
  }
}

export default ideas
