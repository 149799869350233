import React from "react"
import PropTypes from "prop-types"
import { Editor } from "react-draft-wysiwyg"

export default function CEditor(props) {
  const { hasContent, editorState, onEditorStateChange} = props
  return (
    <div className="ceditor">
      <div className="editor-container">
        {/* {!hasContent && (
          <div className="overlay">
            Type or paste (Ctrl + V) your text here{" "}
          </div>
        )} */}
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={onEditorStateChange}
          toolbar={{
            options: ["inline", "blockType", "list", "link", "remove"],
            inline: {
              options: ["bold", "italic", "underline"],
            },
            blockType: {
              options: ["Normal", "H1", "H2"],
            },
            list: {
              options: ["unordered", "ordered"],
            },
            link: {
              inDropdown: true,
              showOpenOptionOnHover: true,
              defaultTargetOption: "_blank",
            },
            remove: {},
          }}
          placeholder="Type or paste (Ctrl + V) your text here..."
        />
      </div>
    </div>
  )
}

CEditor.propTypes = {
  onEditorStateChange: PropTypes.func,
  editorState: PropTypes.any,
  hasContent: PropTypes.bool,
}
