import React from "react"
import MetaTags from "react-meta-tags"
import { Card, Container } from "reactstrap"
import { withSignup } from "hooks/withSignup"
const Feed = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Feed | QuillNow</title>
        </MetaTags>
        <Container fluid>
          <h4>Feed</h4>
          <Card  className="card-container p-4">

          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withSignup(Feed)
