import {
  CREATE_MEDIUM_POST,
  CREATE_MEDIUM_POST_SUCCESS,
  CREATE_MEDIUM_POST_FAILD,
  GET_ACCESS_MEDIUM,
  GET_ACCESS_MEDIUM_SUCCESS,
  GET_MEDIUM_API_ERROR,
} from "./actionTypes"

export const getAccessMedium = mediumApikey => {
  return {
    type: GET_ACCESS_MEDIUM,
    payload: mediumApikey
  }
}

export const getAccessMediumSuccess = msg => {
  return {
    type: GET_ACCESS_MEDIUM_SUCCESS,
    payload: msg,
  }
}

export const getApiMediumError = error => {
  return {
    type: GET_MEDIUM_API_ERROR,
    payload: error,
  }
}


export const createMediumPost = content => {
  return {
    type: CREATE_MEDIUM_POST,
    payload: content
  }
}

export const createMediumPostSuccess = msg => {
  return {
    type: CREATE_MEDIUM_POST_SUCCESS,
    payload: msg,
  }
}

export const createMediumPostError = error => {
  return {
    type: CREATE_MEDIUM_POST_FAILD,
    payload: error,
  }
}