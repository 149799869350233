import {
  GET_PORTFOLIOS,
  GET_PORTFOLIOS_FAIL,
  GET_PORTFOLIOS_SUCCESS,
  GET_PORTFOLIO_DETAIL,
  ADD_NEW_PORTFOLIO,
  ADD_PORTFOLIO_SUCCESS,
  ADD_PORTFOLIO_FAIL,
  UPDATE_PORTFOLIO,
  UPDATE_PORTFOLIO_SUCCESS,
  UPDATE_PORTFOLIO_FAIL,
  DELETE_PORTFOLIO,
  DELETE_PORTFOLIO_SUCCESS,
  DELETE_PORTFOLIO_FAIL,
  GET_PORTFOLIO_DETAIL_FAIL,
  GET_PORTFOLIO_DETAIL_SUCCESS,
  GET_PORTFOLIO_IMAGE,
  GET_PORTFOLIO_IMAGE_FAIL,
  GET_PORTFOLIO_IMAGE_SUCCESS,
} from "./actionTypes"

export const getPortfolios = () => ({
  type: GET_PORTFOLIOS,
})

export const getPortfoliosSuccess = portfolios => ({
  type: GET_PORTFOLIOS_SUCCESS,
  payload: portfolios,
})

export const addNewPortfolio = (portfolio, history) => ({
  type: ADD_NEW_PORTFOLIO,
  payload: { portfolio, history },
})

export const addPortfolioSuccess = portfolio => ({
  type: ADD_PORTFOLIO_SUCCESS,
  payload: portfolio,
})

export const addPortfolioFail = error => ({
  type: ADD_PORTFOLIO_FAIL,
  payload: error,
})

export const updatePortfolio = (id, portfolio) => ({
  type: UPDATE_PORTFOLIO,
  payload: { id, portfolio },
})

export const updatePortfolioSuccess = portfolio => ({
  type: UPDATE_PORTFOLIO_SUCCESS,
  payload: portfolio,
})

export const updatePortfolioFail = error => ({
  type: UPDATE_PORTFOLIO_FAIL,
  payload: error,
})

export const deletePortfolio = portfolio => ({
  type: DELETE_PORTFOLIO,
  payload: portfolio,
})

export const deletePortfolioSuccess = portfolio => ({
  type: DELETE_PORTFOLIO_SUCCESS,
  payload: portfolio,
})

export const deletePortfolioFail = error => ({
  type: DELETE_PORTFOLIO_FAIL,
  payload: error,
})

export const getPortfoliosFail = error => ({
  type: GET_PORTFOLIOS_FAIL,
  payload: error,
})

export const getPortfolioDetail = portfolioId => ({
  type: GET_PORTFOLIO_DETAIL,
  portfolioId,
})

export const getPortfolioDetailSuccess = portfolioDetails => ({
  type: GET_PORTFOLIO_DETAIL_SUCCESS,
  payload: portfolioDetails,
})

export const getPortfolioDetailFail = error => ({
  type: GET_PORTFOLIO_DETAIL_FAIL,
  payload: error,
})

export const getPortfolioImage = id => ({
  type: GET_PORTFOLIO_IMAGE,
  payload: { id },
})

export const getPortfolioImageSuccess = portfolioDetails => ({
  type: GET_PORTFOLIO_IMAGE_SUCCESS,
  payload: portfolioDetails,
})

export const getPortfolioImageFail = error => ({
  type: GET_PORTFOLIO_IMAGE_FAIL,
  payload: error,
})
