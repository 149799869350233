import React from "react"
import nodata from "assets/images/nodata.svg"
import { PropTypes } from "prop-types"

const NoDataFound = ({ message }) => {
  return (
    <div className="noData-page" style={{ margin:"0 auto"}}>
      <img src={nodata} alt="" className="noData-image" />
      <h1 className="text-center">{message}</h1>
    </div>
  )
}

NoDataFound.propTypes = {
  message: PropTypes.string,
}

export default NoDataFound
