import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import PortfolioCard from "components/PortfolioCard"
import { getPortfolios, deletePortfolio } from "../../store/actions"
import { Link } from "react-router-dom"
import { getLoggedInUser } from "../../helpers/backend_helper"
import mixpanel from "mixpanel-browser"
import NoDataFound from "components/NoDataFound"
import { withSignup } from "hooks/withSignup"
import {
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Row,
  Col
} from "reactstrap"
import { MdDeleteForever } from "react-icons/md"
import { getUserInfo } from "store/user/actions"

const Portfolio = () => {
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.user)
  const { portfolios } = useSelector(state => state.portfolios)
  const [deletePort, setDeletePort] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [portfolioInfo, setportfolioInfo] = useState()

  useEffect(() => {
    dispatch(getPortfolios())
    dispatch(getUserInfo())
    const loggedInUser = getLoggedInUser()
    if (loggedInUser !== null) {
      mixpanel.track("portfolio_entry_list", {
        event_properties: {
          user_id: loggedInUser.uid,
        },
      })
    } else {
      mixpanel.track("portfolio_entry_list")
    }
  }, [])

  const handleDeleteClick = () => {
    const formData = new FormData()
    formData.append("portfolio_id", portfolioInfo.id)

    try {
      dispatch(deletePortfolio(formData))
      setDeletePort(true)
      setShowModal(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (portfolios || deletePort) {
      dispatch(getPortfolios())
    }
    setDeletePort(false)
  }, [portfolios.length, deletePort])

  const onShowModal = id => {
    setportfolioInfo({ id: id })
    setShowModal(true)
  }

  const onCloseModal = () => {
    setShowModal(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Portfolio | QuillNow
          </title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col></Col>
          </Row>
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18">Portfolio</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="mb-2 row">
              <div className="col-sm-12">
                <div className="text-sm-end">
                  <div className="d-flex justify-content-between">
                    <Link to={`/profile/${user?.firebase_id}`}>
                      <label htmlFor="profile" className="btn-rounded mb-2 me-2 btn btn-primary">
                        Preview Profile
                      </label>
                    </Link>
                     <Link className="btn-rounded mb-2 me-2 btn btn-primary" to="/portfolio/create-new-portfolio-entry">
                        <i className="bx bxs-book-open"></i>
                        <span>Add to Portfolio</span>
                     </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="projects-container mb-4">
              {portfolios &&
              portfolios.items &&
              portfolios.items?.length > 0 ? (
                portfolios.items.map(portfolio => (
                  <PortfolioCard
                    key={portfolio?.id}
                    id={portfolio?.id}
                    title={portfolio?.title}
                    publicationUrl={portfolio?.publication_url}
                    date={portfolio.published_on}
                    publication={portfolio?.publication}
                    emotionScore={portfolio?.emotion_score}
                    readability={portfolio?.readability}
                    textStats={portfolio?.text_stats}
                    handleDelete={() => onShowModal(portfolio?.id)}
                  />
                ))
              ) : (
                <NoDataFound message="Add articles, blogs and publications to your portfolio" />
              )}
            </div>
          </div>
        </Container>

        {showModal && (
          <Modal
            className="project-delete-confirmation"
            isOpen={showModal}
            toggle={onCloseModal}
          >
            <ModalHeader
              className="text-center"
              toggle={onCloseModal}
            ></ModalHeader>
            <ModalBody className="text-center">
              <div className="icon-box">
                <MdDeleteForever className="text-danger" size={55} />
              </div>
              <h2>Are you sure?</h2>
              <p>
                Do you really want to delete this protfolio? This action cannot
                be undone.
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={onCloseModal}>
                Cancel
              </Button>
              <Button color="danger" onClick={handleDeleteClick}>
                Delete
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    </React.Fragment>
  )
}

export default withSignup(Portfolio)
