import React from "react";
import PropTypes from "prop-types";
import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts";
import { EMOTION_COLORS } from "utils";

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

function transform(data) {
  if (!data) {
    return [
      { name: "Happy", value: 0 },
      { name: "Sad", value: 0 },
      { name: "Fear", value: 0 },
      { name: "Surprise", value: 0 },
      { name: "Angry", value: 0 },
    ];
  }

  return Object.keys(data).map((key) => ({
    name: key.charAt(0).toUpperCase() + key.slice(1),
    value: data[key],
  }));
}

export function OverallEmotionScoresChart({ overallEmotionScores }) {
  if (!overallEmotionScores) return null;

  const data = transform(overallEmotionScores);

  return (
    <PieChart width={400} height={400}>
      <Pie
        dataKey="value"
        cx={200}
        cy={200}
        innerRadius={60}
        outerRadius={80}
        data={data}
        labelLine={false}
        label={renderCustomizedLabel}  // Passing the custom label function here
      >
        {data.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={EMOTION_COLORS[index % EMOTION_COLORS.length]}
          />
        ))}
      </Pie>
      <Legend verticalAlign="bottom" height={36} />
      <Tooltip/>
    </PieChart>
  );
}

OverallEmotionScoresChart.propTypes = {
  overallEmotionScores: PropTypes.object,
};

export default OverallEmotionScoresChart;
