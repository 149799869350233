import {
  GET_SCORES,
  GET_SCORE_FAIL,
  GET_SCORE_SUCCESS,
  GET_SCORES_BY_PARA,
  GET_SCORES_BY_PARA_SUCCESS,
  GET_SCORES_BY_PARA_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  scores: {},
  scoreByPara: {},
  error: {},
  loading: false
}

const scores = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SCORES:
      return {
        ...state,
        scores: action.payload,
        loading: true,
      }
    case GET_SCORE_SUCCESS:
      return {
        ...state,
        scores: action.payload,
        loading: false,
      }
    case GET_SCORE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
      case GET_SCORES_BY_PARA:
        return {
          ...state,
          scoreByPara: action.payload,
          loading: true,
        }
        case GET_SCORES_BY_PARA_SUCCESS:
        return {
          ...state,
          scoreByPara: action.payload,
          loading: false,
        }
      case GET_SCORES_BY_PARA_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false,
        }

    default:
      return state
  }
}

export default scores
