import React from "react"
import { useParams } from "react-router-dom"
import AddModeComposer from "./ComposerMode/AddModeComposer"
import EditModeComposer from "./ComposerMode/EditModeComposer"
import DefaultModeComposer from "./ComposerMode/DefaultModeComposer"
import { MetaTags } from "react-meta-tags"
import { Card, CardBody, Container } from "reactstrap"
import { withSignup } from "hooks/withSignup"

const Composer = () => {
  const { mode, project_id, content_id } = useParams()

  const getComposerModeComponent = () => {
    if (mode === "add") {
      return <DefaultModeComposer project={project_id} content={content_id} />
    } else if (mode === "edit") {
      return <EditModeComposer project={project_id} content={content_id} />
    } else {
      return <AddModeComposer project={project_id} content={content_id} />
    }
  }

  const ComposerMode = getComposerModeComponent()

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Composer</title>
        </MetaTags>
        <Container fluid>
          <Card>
            <CardBody style={{ display: "flex", flexDirection: "row" }}>
              {ComposerMode}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withSignup(Composer)
