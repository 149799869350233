import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  ListGroup,
  ListGroupItem,
  Tooltip,
} from "reactstrap"
import ScaleLoader from "react-spinners/ScaleLoader";

import { Link } from "react-router-dom"
import { Dropdown } from "react-bootstrap"
import {
  MdDeleteForever,
  MdEditNote,
  MdMoreHoriz,
} from "react-icons/md"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { getPortfolioImage } from "store/actions"
import ImageBlob from "components/ImageBlob"
import {COLOR_THEME_PRIMARY} from "utils";
import LoadingBar from "components/Common/loading-bar";

const PortfolioCard = props => {

  const dispatch = useDispatch()
  const { portfolioImage } = useSelector(state => state.portfolios)

  useEffect(() => {
    if (props.id) {
      dispatch(getPortfolioImage(props.id))
    }
  }, [props.id])

  const [tooltipOpen, setTooltipOpen] = useState({
    happy: false,
    angry: false,
    surprise: false,
    sad: false,
    fear: false,
  })

  const toggleTooltip = name => {
    setTooltipOpen({ ...tooltipOpen, [name]: !tooltipOpen[name] })
  }

  return (
    <div key={props.id}>
      <div className="col-sm-6 col-xl-2">
        <Card style={{ width: "18rem" }} className="card-container">
          <CardBody>
            <ImageBlob
              src={portfolioImage}
              alt=""
              style={{
                width: "100%",
                height: "200p",
                objectFit: "cover",
              }}
            />
            <CardTitle className="pb-3 pt-3">
              <h4>{props.title}</h4>
            </CardTitle>
          </CardBody>
          <ListGroup className="list-group-flush">
            <ListGroupItem>
              <p className="card-text">
                Readability &nbsp;{" "}
                {props.readability &&
                props.readability.readability_score !== undefined ? (
                  <em>{props.readability.readability_score}</em>
                ) : (
                    <LoadingBar className="progress-loading-bar"/>
                )}
                <br></br>
                Reading time &nbsp;{" "}
                {props.readability &&
                props.readability.reading_time !== undefined ? (
                  <em>{props.readability.reading_time}</em>
                ) : (
                    <ScaleLoader height='5px' width='6px' color={COLOR_THEME_PRIMARY} />
                )}
                <br></br>
                Characters &nbsp;{" "}
                {props.textStats && props.textStats.char_count !== undefined ? (
                  <em>{props.textStats.char_count}</em>
                ) : (
                    <ScaleLoader height='5px' width='6px' color={COLOR_THEME_PRIMARY} />
                )}
              </p>
            </ListGroupItem>

            <ListGroupItem>
              <p className="card-text">
                <span id="happy">😀 {props.emotionScore?.happy}</span>&nbsp;
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen.happy}
                  target="happy"
                  toggle={() => toggleTooltip("happy")}
                >
                  Happy
                </Tooltip>
                <span id="angry">😠 {props.emotionScore?.angry}</span>&nbsp;
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen.angry}
                  target="angry"
                  toggle={() => toggleTooltip("angry")}
                >
                  Angry
                </Tooltip>
                <span id="surprise">😲 {props.emotionScore?.surprise}</span>&nbsp;
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen.surprise}
                  target="surprise"
                  toggle={() => toggleTooltip("surprise")}
                >
                  Surprise
                </Tooltip>
                <span id="sad">😥 {props.emotionScore?.sad}</span>&nbsp;
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen.sad}
                  target="sad"
                  toggle={() => toggleTooltip("sad")}
                >
                  Sad
                </Tooltip>
                <span id="fear">😨 {props.emotionScore?.fear}</span>
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen.fear}
                  target="fear"
                  toggle={() => toggleTooltip("fear")}
                >
                  Fear
                </Tooltip>
              </p>
            </ListGroupItem>

            <ListGroupItem>
              <CardSubtitle>Published by {props.publication}</CardSubtitle>
              <small className="text-muted"> {props.date}</small>
            </ListGroupItem>
          </ListGroup>
          <CardBody className="content-card-link">
            <Link to={{ pathname: props.publicationUrl }} target="_blank">
              {/* <MdPreview size={28} /> */}
              View
            </Link>
            <Dropdown>
              <Dropdown.Toggle
                //variant="success"
                id="dropdown-basic"
                className="bg-transparent border-0"
                style={{ color: "#000000" }}
              >
                <MdMoreHoriz size={28} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>
                  <Link to={`/portfolio/edit/${props.id}`}>
                    <MdEditNote size={28} />
                    Edit
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link to={`#`} onClick={props.handleDelete}>
                    <MdDeleteForever size={28} />
                    Delete
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}
PortfolioCard.propTypes = {
  id: PropTypes.string,
  src: PropTypes.string,
  title: PropTypes.string,
  publicationUrl: PropTypes.string,
  date: PropTypes.string,
  publication: PropTypes.string,
  emotionScore: PropTypes.object,
  readability: PropTypes.object,
  textStats: PropTypes.object,
  handleDelete: PropTypes.func,
}
export default PortfolioCard
