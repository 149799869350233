import {
    GET_HOME_DATA,
    GET_HOME_DATA_SUCCESS,
    GET_HOME_DATA_FAIL
} from "./actionTypes";

const INIT_STATE = {
    homeData: {},
    loading: false,
};

const home = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_HOME_DATA:
            return {
                ...state,
                homeData: action.payload,
                loading: true,
            };
        case GET_HOME_DATA_SUCCESS:
            return {
                ...state,
                homeData: action.payload,
                loading: false,

            };
        case GET_HOME_DATA_FAIL:
            return {
                ...state,
                homeDataError: action.payload.error,
                loading: false,
            };
        default:
            return state;
    }
}

export default home;