import {

  GET_USER_INFO,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_ERROR,
} from "./actionTypes"

export const getUserInfo = () => {
  return {
    type: GET_USER_INFO,
  }
}

export const getUserInfoSuccess = user => {
  return {
    type: GET_USER_INFO_SUCCESS,
    payload: user,
  }
}

export const getUserInfoError = error => {
  return {
    type: GET_USER_INFO_ERROR,
    payload: error,
  }
}
