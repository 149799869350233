import React from "react"
import PropTypes from "prop-types"
import TextStatsCard from "components/Card/chart-cards/text-stats-card"
import {
  CHAR_COUNT_COLOR,
  CHAR_COUNT_WITHOUT_SPACE_COLOR,
  WORD_COUNT_COLOR,
  SYLLABLE_COUNT_COLOR,
  SENTENCE_COUNT_COLOR,
} from "utils"
import { Row, Col } from "reactstrap"

export function OverallTextStatsChart({ overallTextStats = {} }) {
  if (!overallTextStats) return null

  const getColor = key => {
    if (key === "char_count") {
      return CHAR_COUNT_COLOR
    } else if (key === "char_count_without_space") {
      return CHAR_COUNT_WITHOUT_SPACE_COLOR
    } else if (key === "syllable_count") {
      return SYLLABLE_COUNT_COLOR
    } else if (key === "word_count") {
      return WORD_COUNT_COLOR
    } else {
      return SENTENCE_COUNT_COLOR
    }
  }
  const getFormattedKey = key => {
    if (key === "char_count") {
      return "Characters"
    } else if (key === "char_count_without_space") {
      return "Characters without space"
    } else if (key === "syllable_count") {
      return "Syllables"
    } else if (key === "word_count") {
      return "Words"
    } else {
      return "Sentences"
    }
  }
  return (
    <div className="container" style={{ width: "100%", height: "400px" }}>
      <Row>
        {overallTextStats?.text_stats &&
          Object.entries(overallTextStats?.text_stats).map(
            ([key, value], index) => {
              if (typeof value === "object") {
                return null
              }
              return (
                <Col
                  md={4}
                  xs={12}
                  key={index}
                  style={{ marginBottom: index % 2 === 1 ? "30px" : "0px" }}
                >
                  <TextStatsCard
                    title={getFormattedKey(key)}
                    value={value}
                    color={getColor(key)}
                  />
                </Col>
              )
            }
          )}
      </Row>
    </div>
  )
}

OverallTextStatsChart.propTypes = {
  overallTextStats: PropTypes.any,
}
