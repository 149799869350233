import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {  GET_ENTITIES } from "./actionTypes"
import {
  getEntitiesSuccess,
  getEntitiesFail,
} from "./actions"

//Include Both Helper File with needed methods
import { fetchEntities } from "helpers/backend_helper"

function* getEntities({ payload: content}) {
  try {
    const response = yield call(fetchEntities, content)
    yield put(getEntitiesSuccess(response))
  } catch (error) {
    yield put(getEntitiesFail(error))
  }
}

function* entitiesSaga() {
  yield takeEvery(GET_ENTITIES, getEntities)
}

export default entitiesSaga
