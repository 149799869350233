import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  GET_USER_INFO,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_ERROR,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_ERROR,
  GET_USER_PHOTO,
  GET_USER_PHOTO_SUCCESS,
  GET_USER_PHOTO_ERROR,
  GET_USER_SHOWCASE,
  GET_USER_SHOWCASE_SUCCESS,
  GET_USER_SHOWCASE_ERROR,
  UPLOAD_USER_PHOTO,
  UPLOAD_USER_PHOTO_SUCCESS,
  UPLOAD_USER_PHOTO_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  user: {},
  loading: false,
  success: "",
  photo: null,
  showcase: [],
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state }
      break
    case PROFILE_SUCCESS:
      state = { ...state, success: action.payload }
      break
    case PROFILE_ERROR:
      state = { ...state, error: action.payload }
      break
    case RESET_PROFILE_FLAG:
      state = { ...state, success: null }
      break
    case GET_USER_INFO:
      state = { ...state, loading: true }
      break
    case GET_USER_INFO_SUCCESS:
      state = { ...state, user: action.payload, loading: false }
      break
    case GET_USER_INFO_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break

    case UPDATE_USER_PROFILE:
      return {
        ...state,
      }
      break
    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
      }
      break
    case UPDATE_USER_PROFILE_ERROR:
      state = { ...state, error: action.payload }
      break

    case GET_USER_PHOTO:
      state = { ...state, photo: action.payload, loading: true }
      break
    case GET_USER_PHOTO_SUCCESS:
      state = { ...state, photo: action.payload, loading: false }
      break
    case GET_USER_PHOTO_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    /* Showcase */
    case GET_USER_SHOWCASE:
      state = { ...state, showcase: action.payload, loading: true }
      break
    case GET_USER_SHOWCASE_SUCCESS:
      state = { ...state, showcase: action.payload, loading: false }
      break
    case GET_USER_SHOWCASE_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    /* Upload photo */
    case UPLOAD_USER_PHOTO:
      state = state = {
        ...state,
        loading: true,
      }
      break
    case UPLOAD_USER_PHOTO_SUCCESS:
      state = state = {
        ...state,
        loading: false,
      }
      break
    case UPLOAD_USER_PHOTO_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default profile
