import React, { useCallback, useEffect, useRef, useState } from "react"
import Breadcrumb from "components/Common/Breadcrumb2"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import ScreenplayList from "../ScreenplayList"
import { deleteScreenplay, listScreenplays } from "store/actions"
import { FaEdit, FaTrashAlt } from "react-icons/fa"
import ScriptCard from "components/Screenplay/ScreenplayCard"
import { Link } from "react-router-dom"

const ScreenPlayTable = () => {
  const [modal, setModal] = useState(false)
  const [selectedId, setSelectedId] = useState(null)

  const dispatch = useDispatch()

  const { screenplays, removedScreenplay } = useSelector(
    state => state.screenplayReducer
  )

  const handleRemove = id => {
    setSelectedId(id)
    setModal(true)
  }


  const removeScreenplay = () => {
    // Dispatch an action to add the act to the screenplay
    try {
      dispatch(deleteScreenplay(selectedId))
      setModal(false)
    } catch (error) {
      console.log(error)
    }
  } 

  console.log('screenplaysList', screenplays)

  /* const removeScreenplay = useCallback(() => {
    try {
        dispatch(deleteScreenplay(selectedId))
        setModal(false)
      } catch (error) {
        console.log(error)
      }
  }, [selectedId]); */

  useEffect(() => {
    dispatch(listScreenplays())
  }, [])

  useEffect(() => {
    if(removedScreenplay) {
        dispatch(listScreenplays())
    }
  },[removedScreenplay, dispatch])


  const items = [
    { title: "Home", link: "/home" },
    { title: "Screenplay List", link: "/screenplays" },
  ]

  
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Screenplay List | QuillNow
          </title>
        </MetaTags>
        <Container fluid>
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18">Screenplay List</h4>
                <div className="page-title-right">
                  <Breadcrumb breadcrumbItems={items} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="mb-2 row">
              <div className="col-sm-4">
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <label htmlFor="search-bar-0" className="search-label">
                      <span id="search-bar-0-label" className="sr-only">
                        Search in this List
                      </span>
                      <input
                        id="search-bar-0"
                        type="text"
                        aria-labelledby="search-bar-0-label"
                        className="form-control "
                        placeholder="Search"
                        defaultValue=""
                      />
                    </label>
                    <i className="bx bx-search-alt search-icon" />
                  </div>
                </div>
              </div>
              <div className="col-sm-8">
                <div className="text-sm-end">
                <Link to="/screenplay">
                  <button
                    type="button"
                    className="btn-rounded mb-2 me-2 btn btn-success"
                  >
                    Add new Screenplay
                  </button>
                  </Link>
                </div>
              </div>
            </div>
            <Row className="mb-4">
              {screenplays?.items &&
                screenplays?.items.map(item => (
                  <Col key={item.id} xs="12" sm="6" md="4" className="mb-4">
                    <ScriptCard
                      title={item.title}
                      dateCreated={item.created_at}
                      id={item.id}
                      //removeScreenplay={()=> removeScreenplay(item.id)}
                      //emoveScreenplay={() => toggle(item.id)}
                      removeScreenplay={() => handleRemove(item.id)}
                      //updateScreenplay={() => updateScreenplay(item.id)}
                    />
                  </Col>
                ))}{" "}
            </Row>
            {/* <ScreenplayList columns={columns} data={screenplays?.items} /> */}
          </div>
        </Container>
        <Modal isOpen={modal} toggle={() => setModal(false)}>
          <ModalHeader toggle={() => setModal(false)}>
            Confirm Remove
          </ModalHeader>
          <ModalBody>Are you sure you want to remove this script?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={removeScreenplay}>
              Yes
            </Button>{" "}
            <Button color="secondary" onClick={() => setModal(false)}>
              No
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </React.Fragment>
  )
}


export default ScreenPlayTable
