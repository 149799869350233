import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { getUserInfo } from "store/actions"
const AlertBar = ({ text }) => {
  const dispatch = useDispatch()
  const { user, loading } = useSelector(state => state.user)
  const [close, setClose] = useState(false)
  const location = useLocation()

  useEffect(() => {
    setClose(false)
    if (location.pathname === "/subscription-plan") {
      setClose(true)
    }
  }, [location.pathname])

  useEffect(() => {
    dispatch(getUserInfo())
  }, [])
  return (
    <>
      {loading && (
        <div id="alert-bar" style={{ display: close ? "none" : "block" }}>
          <div id="hellobar-bar" className="regular closable">
            <div className="hb-content-wrapper">
              <div className="hb-text-wrapper">
                <div className="hb-headline-text">
                  <p>
                    <span>{`Your trial will expire in ${user?.subscription?.pending_trial_days} days`}</span>
                  </p>
                </div>
              </div>
              <Link
                to="/subscription-plan"
                className="hb-cta hb-cta-button"
                onClick={() => setClose(true)}
              >
                <div className="hb-text-holder">
                  <p>Subscribe Now</p>
                </div>
              </Link>
            </div>
            <div className="hb-close-wrapper">
              <a className="icon-close" onClick={() => setClose(true)}>
                ✖
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

AlertBar.propTypes = {
  text: PropTypes.string,
}

export default AlertBar
