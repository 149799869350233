import * as actionTypes from "./actionTypes"

export const addScene = (sceneData, actId, screenplayId) => ({
  type: actionTypes.ADD_SCENE,
  payload: { sceneData, actId, screenplayId },
})

export const addSceneSuccess = scene => {
  return {
    type: actionTypes.ADD_SCENE_SUCCESS,
    scene,
  }
}

export const addSceneFail = error => {
  return {
    type: actionTypes.ADD_SCENE_FAIL,
    error,
  }
}

export const reorderScenes = (scenes, actId, screenplayId) => {
  return {
    type: actionTypes.REORDER_SCENES,
    scenes,
    actId,
    screenplayId,
  }
}

export const reorderScenesSuccess = scenes => {
  return {
    type: actionTypes.REORDER_SCENES_SUCCESS,
    scenes,
  }
}

export const reorderScenesFail = error => {
  return {
    type: actionTypes.REORDER_SCENES_FAIL,
    error,
  }
}

export const deleteScene = (sceneId, actId, screenplayId) => {
  return {
    type: actionTypes.DELETE_SCENE,
    payload: { sceneId, actId, screenplayId },
  }
}

export const deleteSceneSuccess = sceneId => {
  return {
    type: actionTypes.DELETE_SCENE_SUCCESS,
    sceneId,
  }
}

export const deleteSceneFail = error => {
  return {
    type: actionTypes.DELETE_SCENE_FAIL,
    payload: error,
  }
}
