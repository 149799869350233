import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_TOPICS } from "./actionTypes"
import {
  getTopicsSuccess,
  getTopicsFail,
} from "./actions"

//Include Both Helper File with needed methods
import { fetchTopics } from "helpers/backend_helper"

function* getTopics({payload: content}) {
  try {
    const response = yield call(fetchTopics, content)
    yield put(getTopicsSuccess(response))
  } catch (error) {
    yield put(getTopicsFail(error))
  }
}

function* topicsSaga() {
  yield takeEvery(GET_TOPICS, getTopics)
}

export default topicsSaga
