import axios from "axios"
import firebase from "firebase/compat/app"
import { useHistory } from "react-router-dom"
import history from "utils/history"

//apply base url for axiosApi
export const API_URL = process.env.REACT_APP_API_URL;

export const getToken = () =>
  localStorage.getItem("authUser")
    ? JSON.parse(localStorage.getItem("authUser"))
    : null

export const getAuthorizationHeader = () => `Bearer ${getToken()}`

export const axiosApi = axios.create({
  baseURL: API_URL,
  headers: { Authorization: getAuthorizationHeader() },
})

/* axiosApi.interceptors.response.use(
  response => response,
  error => {
    if (
      (error.response && error.response.status === 401) ||
      (error.response && error.response.status === 403)
    ) {
      firebase.auth().onAuthStateChanged(user => {
        localStorage.setItem("authUser", JSON.stringify(user.refreshToken))
      }) 

      const history = useHistory()
      localStorage.removeItem("authUser")
      history.push("/login")
    }
    return Promise.reject(error)
  }
) */

axiosApi.interceptors.response.use(
  response => response,
  error => {
    console.log(error) // log the error to the console

    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      localStorage.removeItem("authUser")
      history.push("/login") // Redirect to the login page
    }

    return Promise.reject(error)
  }
)

export async function get(url) {
  return await axiosApi
    .get(url, {
      headers: { Authorization: getAuthorizationHeader() },
    })
    .then(response => response.data)
}

export async function getImageProtfolio(url) {
  const response = await axiosApi.get(url, {
    headers: { Authorization: getAuthorizationHeader() },
    responseType: 'arraybuffer' // Add this line to get a binary response
  });

  if (response.headers['content-type'].startsWith('image/')) {
    // The response is an image. Convert it to a data URL.
    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    const imageUrl = URL.createObjectURL(blob);
    return imageUrl;
  } else {
    // The response is JSON. Parse it.
    return JSON.parse(Buffer.from(response.data, 'binary').toString());
  }
}

export async function getImage(url) {
  return await axiosApi
    .get(url, {
      responseType: "blob",
      headers: { Authorization: getAuthorizationHeader() },
    })
    .then(response => response.data)
}

export async function post(url, data) {
  return axiosApi
    .post(url, data, {
      headers: { Authorization: getAuthorizationHeader() },
    })
    .then(response => response.data)
}

export async function getApi(url, data) {
  return await axiosApi
    .get(url, data, {
      headers: { Authorization: getAuthorizationHeader() },
    })
    .then(response => response.data)
}

export async function put(url, data) {
  return axiosApi
    .put(url, data, {
      headers: { Authorization: getAuthorizationHeader() },
    })
    .then(response => response.data)
}

/* export async function put(url, data) {
  return axiosApi
    .put(url, data, { headers: { Authorization: getAuthorizationHeader() },
      })
    .then(response => response.data)
} */

export async function del(url, data) {
  return await axiosApi
    .delete(url, {
      headers: { Authorization: getAuthorizationHeader() },
      data: data,
    })
    .then(response => response.data)
}
