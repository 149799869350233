import React from "react"
import PropTypes from "prop-types"
// Bootstrap
import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Button,
  ModalFooter,
} from "reactstrap"

const CustomModal = ({ isShowing, hide, className, children }) => {
  return (
    <React.Fragment>
      {isShowing && (
        <React.Fragment>
          <Modal isOpen={isShowing} toggle={hide} className={className}>
            {children}
          </Modal>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

CustomModal.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  hide: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default CustomModal
