import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  GET_USER_INFO,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_ERROR,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_ERROR,
  /*  UPLOAD_USER_PIC,
  UPLOAD_USER_PIC_SUCCESS,
  UPLOAD_USER_PIC_ERROR, */
  GET_USER_PHOTO,
  GET_USER_PHOTO_SUCCESS,
  GET_USER_PHOTO_ERROR,
  GET_USER_SHOWCASE,
  GET_USER_SHOWCASE_SUCCESS,
  GET_USER_SHOWCASE_ERROR,
  /* upload photo*/
  UPLOAD_USER_PHOTO,
  UPLOAD_USER_PHOTO_SUCCESS,
  UPLOAD_USER_PHOTO_ERROR,
} from "./actionTypes"

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}

export const getUserInfo = () => {
  return {
    type: GET_USER_INFO,
  }
}

export const getUserInfoSuccess = user => {
  return {
    type: GET_USER_INFO_SUCCESS,
    payload: user,
  }
}

export const getUserInfoError = error => {
  return {
    type: GET_USER_INFO_ERROR,
    payload: error,
  }
}

export const updateUserProfile = user => {
  return {
    type: UPDATE_USER_PROFILE,
    payload: { user },
  }
}

export const updateUserProfileSuccess = user => {
  return {
    type: UPDATE_USER_PROFILE_SUCCESS,
    payload: user,
  }
}

export const updateUserProfileError = error => {
  return {
    type: UPDATE_USER_PROFILE_ERROR,
    payload: error,
  }
}

export const getUserPhoto = () => {
  return {
    type: GET_USER_PHOTO,
  }
}

export const getUserPhotoSuccess = user => {
  return {
    type: GET_USER_PHOTO_SUCCESS,
    payload: user,
  }
}

export const getUserPhotoError = error => {
  return {
    type: GET_USER_PHOTO_ERROR,
    payload: error,
  }
}

/*  */

export const getUserShowcase = user => {
  return {
    type: GET_USER_SHOWCASE,
    payload: user,
  }
}

export const getUserShowcaseSuccess = user => {
  return {
    type: GET_USER_SHOWCASE_SUCCESS,
    payload: user,
  }
}

export const getUserShowcaseError = error => {
  return {
    type: GET_USER_SHOWCASE_ERROR,
    payload: error,
  }
}

/* Upload Photo  */

export const uploadUserPhoto = photo => {
  return {
    type: UPLOAD_USER_PHOTO,
    payload: photo,
  }
}

export const uploadUserPhotoSuccess = user => {
  return {
    type: UPLOAD_USER_PHOTO_SUCCESS,
    payload: user,
  }
}

export const uploadUserPhotoError = error => {
  return {
    type: UPLOAD_USER_PHOTO_ERROR,
    payload: error,
  }
}
