import React from "react"
import PropTypes from "prop-types"
import {
  Area,
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts"
import {
  READABILITY_COLOR,
  READING_EASE_COLOR,
  FOREIGN_READER_READING_EASE_COLOR,
} from "utils"

export function LineReadabilityScoresChart({ lineReadabilityStats = {} }) {
  if (!lineReadabilityStats || !lineReadabilityStats.text_stats_scores) return null

  const data = lineReadabilityStats?.readability_scores?.map((score, i) => ({
    name: `Paragraph ${lineReadabilityStats.paragraph_index[i]}`,
    "Readability Score": score.readability_score,
    "Reading Ease": score.reading_ease,
    "Foreign Reader Ease Score": score.foreign_reader_ease_score,
  }))

  if (!data || data.length === 0) return null

  return (
      <ResponsiveContainer width="100%" height={400}>
        <AreaChart
            width={800}
            height={400}
            data={data}
            margin={{ top: 15, right: 15, left: 15, bottom: 15 }}
        >
          <defs>
            <linearGradient id="colorReadability" x1="0" y1="0" x2="0" y2="1">
              <stop
                  offset="5%"
                  stopColor={READABILITY_COLOR}
                  stopOpacity={0.8}
              />
              <stop
                  offset="95%"
                  stopColor={READABILITY_COLOR}
                  stopOpacity={0}
              />
            </linearGradient>
            <linearGradient id="colorReadingEase" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={READING_EASE_COLOR} stopOpacity={0.8}/>
              <stop offset="95%" stopColor={READING_EASE_COLOR} stopOpacity={0}/>
            </linearGradient>
            <linearGradient id="colorForeignReaderScore" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={FOREIGN_READER_READING_EASE_COLOR} stopOpacity={0.8}/>
              <stop offset="95%" stopColor={FOREIGN_READER_READING_EASE_COLOR} stopOpacity={0}/>
            </linearGradient>
          </defs>
          <XAxis dataKey="name" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Legend verticalAlign="top" height={36}/>

          <Area
              type="monotone"
              name="Readability Score"
              dataKey="Readability Score"
              stroke={READABILITY_COLOR}
              fillOpacity={1}
              fill="url(#colorReadability)"
              isAnimationActive={true}
          />
          <Area
              type="monotone"
              name="Reading Ease"
              dataKey="Reading Ease"
              stroke={READING_EASE_COLOR}
              fillOpacity={1}
              fill="url(#colorReadingEase)"
              isAnimationActive={true}
          />
          <Area
              type="monotone"
              name="Foreign Reader Ease Score"
              dataKey="Foreign Reader Ease Score"
              stroke={FOREIGN_READER_READING_EASE_COLOR}
              fillOpacity={1}
              fill="url(#colorForeignReaderScore)"
              isAnimationActive={true}
          />
        </AreaChart>
      </ResponsiveContainer>
  )
}

LineReadabilityScoresChart.propTypes = {
  lineReadabilityStats: PropTypes.any,
}
