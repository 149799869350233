// ToneTab.js
import React from "react"
import PropTypes from "prop-types"
import TabItemComponent from "./tab-items"
import { OverallReadabilityScoresChart } from "components/Charts/overall/OverallReadabilityScoresChart"
import { ParagraphReadabilityScoresChart } from "components/Charts/paragraph/ParagraphReadabilityScoresChart"
import { LineReadabilityScoresChart } from "components/Charts/line/LineReadabilityScoresChart"
import {Col, Row, TabContent, TabPane} from "reactstrap";
import NoteReadability from "components/Card/info-cards/readability-info";
import ScoreDefinitions from "components/Card/info-cards/score-definitions";
import LoadingBar from "components/Common/loading-bar";

const ReadabilityTabComponent = ({
  active,
  setActive,
  dataReadability = {},
  isLoading = false,
  tabItems,
}) => {


  console.log("dataReadability", dataReadability)
  const {
    line_stats: lineReadabilityAnalytics,
    overall_stats: overallReadabilityAnalytics,
    paragraph_stats: paragraphReadabilityAnalytics,
  } = dataReadability

  return (
    <>
      <div className="tabs">
        {tabItems.map(({ id, title }) => (
          <TabItemComponent
            key={id}
            title={title}
            onItemClicked={() => setActive(id)}
            isActive={active === id}
          />
        ))}
      </div>
      {isLoading ? (
          <LoadingBar/>
      ) : (
        <div className="mt-4">
          {tabItems.map(({ id }) => {
            if (active === id && id === 1) {
              return (<div key={id}>
                    <Row key="overall-readability-score-chart-row-1">
                      <Col className="col-9">
                        <OverallReadabilityScoresChart key='overall-readability-score-chart'
                            overallReadabilityStats={overallReadabilityAnalytics}
                        />
                      </Col>
                      <Col>
                        <ScoreDefinitions/>
                      </Col>
                    </Row>
                    <Row key="overall-readability-score-chart-row-2">
                      <Col className="col-9">
                        <NoteReadability key="overall-readability-score-chart-row-2-note"/>
                      </Col>
                    </Row>
              </div>
              )
            } else if (active === id && id === 2) {
              return (
                  <div key={id}>
                    <Row key="para-readability-score-chart-row-1">
                      <Col className="col-9">
                        <ParagraphReadabilityScoresChart key='para-readability-score-chart'
                            paragraphReadabilityStats={paragraphReadabilityAnalytics}
                        />
                      </Col>
                      <Col>
                        <ScoreDefinitions/>
                      </Col>
                    </Row>
                    <Row key="para-readability-score-chart-row-2">
                      <Col className="col-12">
                        <NoteReadability/>
                      </Col>
                    </Row>
                  </div>
              )
            } else if (active === id && id === 3) {
              return (
                  <div key={id}>
                    <Row key="line-readability-score-chart-row-1">
                      <Col className="col-9">
                        <LineReadabilityScoresChart key='line-readability-score-chart'
                            lineReadabilityStats={lineReadabilityAnalytics}
                        />
                      </Col>
                      <Col>
                        <ScoreDefinitions/>
                      </Col>
                    </Row>
                    <Row key="line-readability-score-chart-row-2">
                      <Col className="col-12">
                        <NoteReadability/>
                      </Col>
                    </Row>
                  </div>
              )
            } else {
              return null
            }
          })}
        </div>
      )}
    </>
  )
}

ReadabilityTabComponent.propTypes = {
  active: PropTypes.number,
  setActive: PropTypes.func,
  dataReadability: PropTypes.any,
  isLoading: PropTypes.any,
  tabItems: PropTypes.array,
}
export default ReadabilityTabComponent
