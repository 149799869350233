import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import calendarSaga from "./calendar/saga"
import projectsSaga from "./projects/saga"
import contactsSaga from "./contacts/saga"
import ideasSaga from "./ideas/saga"
import feedbacksSaga from "./feedback/saga"
import homeSaga from "./home/saga"
import scoresSaga from "./score/saga"
import subscriptionSaga from "./subscription/saga"
import correctionsSaga from "./corrections/saga"
import entitiesSaga from "./entities/saga"
import topicsSaga from "./topics/saga"
import portfoliosSaga from "./portfolio/saga"
import authTwitterSaga from "./auth_twitter/saga"
import authGoogleSaga from "./auth_google/saga"
import authCheckoutSaga from "./checkout/saga"
import paraphraseSaga from "./paraphrase/saga"
import generateTextSaga from "./generate/saga"
import userSaga from "./user/saga"
import authMediumSaga from "./auth_medium/saga"
import screenplaySaga from "./screenplay/saga"
import actSaga from "./act/saga"
import sceneSaga from "./scene/saga"
import userSubscriptionInfoSaga from "./user-subscription/saga"
import scoreSaga from "./scores/saga"
import signupSaga from "./signup/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(calendarSaga),
    fork(projectsSaga),
    fork(contactsSaga),
    fork(homeSaga),
    fork(ideasSaga),
    fork(feedbacksSaga),
    fork(scoresSaga),
    fork(subscriptionSaga),
    fork(correctionsSaga),
    fork(entitiesSaga),
    fork(topicsSaga),
    fork(portfoliosSaga),
    fork(authTwitterSaga),
    fork(authGoogleSaga),
    fork(authCheckoutSaga),
    fork(paraphraseSaga),
    fork(generateTextSaga),
    fork(userSaga),
    fork(authMediumSaga),
    fork(screenplaySaga),
    fork(actSaga),
    fork(sceneSaga),
    fork(userSubscriptionInfoSaga),
    fork(scoreSaga),
    fork(signupSaga)
  ])
}
