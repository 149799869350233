import {
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_FAIL,
  GET_SUBSCRIPTION_PLAN,
  GET_SUBSCRIPTION_PLAN_SUCCESS,
  GET_SUBSCRIPTION_PLAN_FAIL,
  GET_SETUP_INTENT,
  GET_SETUP_INTENT_SUCCESS,
  GET_SETUP_INTENT_FAIL,
  POST_SUBSCRIPTION_PORTAL,
  POST_SUBSCRIPTION_PORTAL_SUCCESS,
  POST_SUBSCRIPTION_PORTAL_FAIL,
} from "./actionTypes"

export const updateSubscription = plan => ({
  type: UPDATE_SUBSCRIPTION,
  payload: plan,
})

export const updateSubscriptionSuccess = message => ({
  type: UPDATE_SUBSCRIPTION_SUCCESS,
  payload: message,
})

export const updateSubscriptionFail = error => ({
  type: UPDATE_SUBSCRIPTION_FAIL,
  payload: error,
})

// GET Subscription Plans
export const getSubscriptionPlan = plans => ({
  type: GET_SUBSCRIPTION_PLAN,
  payload: plans,
})

export const getSubscriptionPlanSuccess = plans => ({
  type: GET_SUBSCRIPTION_PLAN_SUCCESS,
  payload: plans,
})

export const getSubscriptionPlanFail = error => ({
  type: GET_SUBSCRIPTION_PLAN_FAIL,
  payload: error,
})

// Old verion
export const getSetupIntent = () => ({
  type: GET_SETUP_INTENT,
})
export const getSetupIntentSuccess = intentId => ({
  type: GET_SETUP_INTENT_SUCCESS,
  payload: intentId,
})
export const getSetupIntentFail = error => ({
  type: GET_SETUP_INTENT_FAIL,
  payload: error,
})

// New verion
export const postSubscriptionPortal = (portal) => ({
  type: POST_SUBSCRIPTION_PORTAL,
  payload: portal
})
export const postSubscriptionPortalSuccess = portal => ({
  type: POST_SUBSCRIPTION_PORTAL_SUCCESS,
  payload: portal,
})
export const postSubscriptionPortalFail = error => ({
  type: POST_SUBSCRIPTION_PORTAL_FAIL,
  payload: error,
})
