import React from "react"
import PropTypes from "prop-types"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from "recharts"
import {
  ANGRY_COLOR,
  SURPRISE_COLOR,
  SAD_COLOR,
  FEAR_COLOR,
  HAPPY_COLOR,
} from "utils"

export function ParagraphEmotionScoresChart({ contentStatsProject }) {
  if (!contentStatsProject) return null

  const { paragraph_stats } = contentStatsProject
  const data = paragraph_stats?.emotion_scores?.map((score, i) => ({
    name: `Paragraph ${paragraph_stats.paragraph_index[i]}`,
    ...score,
  }))

  const toPercent = decimal => `${(decimal * 100).toFixed(2)}%`
  const getPercent = (value, total) => {
    const ratio = total > 0 ? value / total : 0

    return toPercent(ratio, 2)
  }
  const renderTooltipContent = o => {
    const { payload, label } = o
    const total = payload.reduce((result, entry) => result + entry.value, 0)

    return (
      <div className="customized-tooltip-content">
        <p className="total">{`${label} (Total: ${total})`}</p>
        <ul className="list">
          {payload.map((entry, index) => (
            <li key={`item-${index}`} style={{ color: entry.color }}>
              {`${entry.name}: ${entry.value}(${getPercent(
                entry.value,
                total
              )})`}
            </li>
          ))}
        </ul>
      </div>
    )
  }

  return (
    <div className="container" style={{ width: "100%", height: "400px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          style={{ width: "100%", height: "100%", minHeight: "300px" }}
          data={data}
          margin={{
            top: 12,
            right: 10,
            left: 20,
            bottom: 12,
          }}
          barGap={0}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis tickFormatter={toPercent} />
          <Tooltip content={renderTooltipContent} />
          <Legend verticalAlign="top" height={36} />
          <Bar
            dataKey="happy"
            stroke={HAPPY_COLOR}
            fill={HAPPY_COLOR}
            isAnimationActive={true}
            stackId="emotion"
            barSize={40}
          />
          <Bar
            dataKey="angry"
            stroke={ANGRY_COLOR}
            fill={ANGRY_COLOR}
            isAnimationActive={true}
            stackId="emotion"
            barSize={40}
          />
          <Bar
            dataKey="surprise"
            stroke={SURPRISE_COLOR}
            fill={SURPRISE_COLOR}
            isAnimationActive={true}
            stackId="emotion"
            barSize={40}
          />
          <Bar
            dataKey="sad"
            stroke={SAD_COLOR}
            fill={SAD_COLOR}
            isAnimationActive={true}
            stackId="emotion"
            barSize={40}
          />
          <Bar
            dataKey="fear"
            stroke={FEAR_COLOR}
            fill={FEAR_COLOR}
            isAnimationActive={true}
            stackId="emotion"
            barSize={40}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

ParagraphEmotionScoresChart.propTypes = {
  contentStatsProject: PropTypes.any,
}
