import React from "react"
import PropTypes from "prop-types"
import {
  AreaChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Area,
  ResponsiveContainer,
} from "recharts"
import {
  ANGRY_COLOR,
  SURPRISE_COLOR,
  SAD_COLOR,
  FEAR_COLOR,
  HAPPY_COLOR,
} from "utils"

export function LineEmotionScoresChart({ contentStatsProject }) {
  if (!contentStatsProject) return null

  const { line_stats } = contentStatsProject
  const data = line_stats?.emotion_scores?.map((score, i) => ({
    name: `Paragraph ${line_stats.paragraph_index[i]}`,
    ...score,
  }))
  const toPercent = (decimal) => `${(decimal * 100).toFixed(2)}%`
  const getPercent = (value, total) => {
    const ratio = total > 0 ? value / total : 0

    return toPercent(ratio, 2)
  }
  const renderTooltipContent = o => {
    const { payload, label } = o
    const total = payload.reduce((result, entry) => result + entry.value, 0)

    return (
      <div className="customized-tooltip-content">
        <p className="total">{`${label} (Total: ${total})`}</p>
        <ul className="list">
          {payload.map((entry, index) => (
            <li key={`item-${index}`} style={{ color: entry.color }}>
              {`${entry.name}: ${entry.value}(${getPercent(
                entry.value,
                total
              )})`}
            </li>
          ))}
        </ul>
      </div>
    )
  }

  return (
    <div className="container" style={{ width: "100%", height: "400px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={800}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <defs>
            <linearGradient id="angry" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={ANGRY_COLOR} stopOpacity={0.95} />
              <stop offset="95%" stopColor={ANGRY_COLOR} stopOpacity={0.6} />
            </linearGradient>
            <linearGradient id="fear" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={FEAR_COLOR} stopOpacity={0.95} />
              <stop offset="95%" stopColor={FEAR_COLOR} stopOpacity={0.6} />
            </linearGradient>
            <linearGradient id="happy" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={HAPPY_COLOR} stopOpacity={0.95} />
              <stop offset="95%" stopColor={HAPPY_COLOR} stopOpacity={0.6} />
            </linearGradient>
            <linearGradient id="sad" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={SAD_COLOR} stopOpacity={0.95} />
              <stop offset="95%" stopColor={SAD_COLOR} stopOpacity={0.6} />
            </linearGradient>
            <linearGradient id="surprise" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={SURPRISE_COLOR} stopOpacity={0.95} />
              <stop offset="95%" stopColor={SURPRISE_COLOR} stopOpacity={0.6} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" padding={{ left: 20, right: 20 }} />
          <YAxis padding={{ top: 20, bottom: 20 }} tickFormatter={toPercent} />
          <Tooltip content={renderTooltipContent} />
          <Legend verticalAlign="top" height={36} />
          <Area
            type="monotone"
            name="Happy"
            dataKey="happy"
            stroke="url(#happy)"
            fill="url(#happy)"
            isAnimationActive={true}
          />
          <Area
            type="monotone"
            name="Angry"
            dataKey="angry"
            stroke="url(#angry)"
            fill="url(#angry)"
            isAnimationActive={true}
          />
          <Area
            type="monotone"
            name="Surprise"
            dataKey="Surprise"
            stroke="url(#surprise)"
            fill="url(#surprise)"
            isAnimationActive={true}
          />
          <Area
            type="monotone"
            name="Sad"
            dataKey="sad"
            stroke="url(#sad)"
            fill="url(#sad)"
            isAnimationActive={true}
          />
          <Area
            type="monotone"
            name="Fear"
            dataKey="fear"
            stroke="url(#fear)"
            fill="url(#fear)"
            isAnimationActive={true}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}

LineEmotionScoresChart.propTypes = {
  contentStatsProject: PropTypes.any,
}
