// Function helpers

import _ from "lodash"

export const formatText = text => {
  const replacedText = text.replace(/_/g, " ")
  const capitalizedText =
    replacedText.charAt(0).toUpperCase() + replacedText.slice(1)
  return capitalizedText
}

export const COLOR_THEME_PRIMARY = "#FBB508"

export const COLORS = [
  "#184D47",
  "#FF4C29",
  "#03C988",
  "#B85C38",
  "#2F8886",
  "#5F264A",
  "#845EC2",
  "#D89216",
  "#FF79CD",
]

export const TEN_COLORS = [
  "#184D47",
  "#FF4C29",
  "#03C988",
  "#B85C38",
  "#2F8886",
  "#5F264A",
  "#845EC2",
  "#D89216",
  "#FF79CD",
  "#962712",
]

export const LOGO_COLOR_PALETTE = [
  "#FFFFFF",
  "#080808",
  "#BDB8B3",
  "#FBB508",
  "#686362",
  "#962712",
]
export const ALT_LOGO_COLOR_PALETTE = [
  "#F2E205",
  "#F29F05",
  "#8C1A0F",
  "#8C3030",
  "#0D0D0D",
]

export const PASTEL_COLORS = [
  "#beb9db",
  "#fd7f6f",
  "#b2e061",
  "#ffb55a",
  "#8bd3c7",
  "#7eb0d5",
  "#bd7ebe",
  "#ffee65",
  "#fdcce5",
]

export const EMOTION_COLORS = [
  "#fd7f6f",
  "#bd7ebe",
  "#b2e061",
  "#ffb55a",
  "#7eb0d5",
]

export const CHAR_COUNT_COLOR = "#FF4C29"
export const CHAR_COUNT_WITHOUT_SPACE_COLOR = "#03C988"
export const WORD_COUNT_COLOR = "#845EC2"
export const SYLLABLE_COUNT_COLOR = "#5F264A"
export const SENTENCE_COUNT_COLOR = "#B85C38"
export const ANGRY_COLOR = "#f46a6a"
export const HAPPY_COLOR = "#F29F05"
export const SAD_COLOR = "#FF79CD"
export const SURPRISE_COLOR = "#8884d8"
export const FEAR_COLOR = "#545a6d"
export const READING_TIME_COLOR = "#7eb0d5"
export const READABILITY_COLOR = "#b2e061"
export const READING_EASE_COLOR = "#bd7ebe"
export const FOREIGN_READER_READING_EASE_COLOR = "#f46a6a"
export const HAPAX_COLOR = "#03C988"
export const TTR_COLOR = "#8884d8"
export const MSTTR_COLOR = "#FF4C29"
export const MATTR_COLOR = "#FF79CD"
export const MTLD_COLOR = "#962712"
export const HDD_COLOR = "#D89216"
export const VOCD_COLOR = "#5F264A"

export const averageScoreObject = obj => {
  let total = 0
  let count = 0

  for (let key in obj) {
    if (obj[key] && typeof obj[key] === "number") {
      total += obj[key]
      count++
    }
  }
  return total / count
}

//
export const averageScoreArray = arr => {
  if (arr == null) {
    console.error("Input is null")
    return 0
  }
  if (!Array.isArray(arr)) {
    console.error("Input is not an array." + arr)
    return 0
  }

  const sum = arr.reduce((total, item) => {
    if (item === null) {
      console.warn("Encountered null item in array")
      return total
    }

    return total + item?.score
  }, 0)

  return (sum / arr.length).toFixed(2)
}

// Average Bias Score
export function getAverageBiasScore(output) {
  let sum = 0
  let count = 0

  if (output && output.bias_classifier) {
    for (let key in output.bias_classifier) {
      if (typeof output.bias_classifier[key] === "number") {
        sum += output.bias_classifier[key]
        count++
      }
    }
  }

  return count === 0 ? 0 : (sum / count) * 100
}

// Average Con Bias Score political
export function getAverageScore(output) {
  if (!Array.isArray(output)) {
    return 0
  }

  let sum = 0
  let count = 0

  for (let item of output) {
    if (typeof item.score === "number") {
      sum += item.score
      count++
    }
  }

  return count === 0 ? 0 : (sum / count) * 100
}

export function getAverageReadabilityScore(output) {
  let sum = 0
  let count = 0

  if (
    output &&
    output.overall_stats &&
    output.overall_stats.readability_score
  ) {
    for (let key in output.overall_stats.readability_score) {
      if (typeof output.overall_stats.readability_score[key] === "number") {
        sum += output.overall_stats.readability_score[key]
        count++
      }
    }
  }

  return count === 0 ? 0 : sum / count
}

export function getAverageLexicalDiversityScore(output) {
  let sum = 0
  let count = 0

  if (
    output &&
    output.overall_stats &&
    output.overall_stats.lexical_diversity_score
  ) {
    for (let key in output.overall_stats.lexical_diversity_score) {
      if (
        typeof output.overall_stats.lexical_diversity_score[key] === "number"
      ) {
        sum += output.overall_stats.lexical_diversity_score[key]
        count++
      }
    }
  }

  return count === 0 ? 0 : sum / count
}
