import {
  GET_ENTITIES,
  GET_ENTITIE_FAIL,
  GET_ENTITIE_SUCCESS,
} from "./actionTypes"

export const getEntities = content => ({
  type: GET_ENTITIES,
  payload: content
})

export const getEntitiesSuccess = content => ({
  type: GET_ENTITIE_SUCCESS,
  payload: content,
})

export const getEntitiesFail = error => ({
  type: GET_ENTITIE_FAIL,
  payload: error,
})



