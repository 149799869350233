import React from "react"
import PropTypes from "prop-types"
import StackedProgressBar from "components/progress-bar/StackedProgressBar"
import BiasScore from "./BiasScore"

const PsychologicalProfile = ({
  emotionData,
  sentimentData,
  empathyData,
  engagementData,
  error,
}) => {
  return (
    <div className="psychological-profile">
      {/* Emotion Score */}
      <div className="psychological-profile__section mb-4">
        <h5>Emotion</h5>
        {!emotionData ||
        Object.keys(emotionData).length === 0 ||
        emotionData.error ? (
          <p className="error-message">Score is not available currently.</p>
        ) : (
          emotionData && <BiasScore biasType="emotion" data={emotionData} />
        )}
      </div>

      {/* Sentiment Score */}
      <div className="psychological-profile__section mb-4">
        <h5>Sentiment</h5>
        {!sentimentData ||
        Object.keys(sentimentData).length === 0 ||
        sentimentData.error ? (
          <p className="error-message">Score is not available currently.</p>
        ) : (
          sentimentData && (
            <BiasScore biasType="sentiment" data={sentimentData} />
          )
        )}
      </div>

      {/* Empathy Score */}
      <div className="psychological-profile__section mb-4">
        <h5>Empathy</h5>
        {!empathyData ||
        Object.keys(empathyData).length === 0 ||
        empathyData.error ? (
          <p className="error-message">Score is not available currently.</p>
        ) : (
          empathyData && <BiasScore biasType="empathy" data={empathyData} />
        )}
      </div>

      {/* Engagement Score */}
      <div className="psychological-profile__section mb-4">
        <h5>Engagement</h5>
        {!engagementData ||
        Object.keys(engagementData).length === 0 ||
        engagementData.error ? (
          <p className="error-message">Score is not available currently.</p>
        ) : (
          engagementData && (
            <BiasScore biasType="engagement" data={engagementData} />
          )
        )}
      </div>
    </div>
  )
}

PsychologicalProfile.propTypes = {
  emotionData: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  sentimentData: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  empathyData: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  engagementData: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  error: PropTypes.any.isRequired,
}

export default PsychologicalProfile
