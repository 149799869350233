import { call, put, takeLatest } from "redux-saga/effects"
import {
  ADD_ACT,
  ADD_ACT_SUCCESS,
  ADD_ACT_FAIL,
  REORDER_ACTS,
  REORDER_ACTS_SUCCESS,
  REORDER_ACTS_FAIL,
  DELETE_ACT,
  DELETE_ACT_SUCCESS,
  DELETE_ACT_FAIL,
} from "./actionTypes"

import {
  addActApi,
  reorderActsApi,
  deleteActApi,
} from "helpers/backend_helper"

function* addActSaga({ payload: { actData, screenplayId } }) {
  try {
    const dataForm = new FormData()

    dataForm.append("act_title", actData.act_title)
    dataForm.append("act_tags", actData.act_tags)
    dataForm.append("act_description", actData.act_description)
    dataForm.append("screenplay_id", screenplayId)

    const response = yield call(addActApi, dataForm)
    yield put({ type: ADD_ACT_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: ADD_ACT_FAIL, payload: error })
  }
}

function* reorderActsSaga({ payload: { actIds, screenplayId } }) {
  try {
    // const { screenplayId, acts } = action.payload
    const commaSeparatedIds = actIds.map(obj => obj.id).join(",")
    const formData = new FormData()
    formData.append("act_list", commaSeparatedIds)
    formData.append("screenplay_id", screenplayId)
    const response = yield call(reorderActsApi, formData)
    yield put({ type: REORDER_ACTS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: REORDER_ACTS_FAIL, payload: error })
  }
}

function* deleteActSaga({ payload: { actId, screenplayId } }) {
  try {
    console.log("actId, screenplayId", actId, screenplayId)
    //const { screenplayId, actId } = action.payload
    const dataForm = new FormData()
    dataForm.append("screenplay_id", screenplayId)
    dataForm.append("act_id", actId)
    const response = yield call(deleteActApi, dataForm)
    yield put({ type: DELETE_ACT_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: DELETE_ACT_FAIL, payload: error })
  }
}

function* actSaga() {
  yield takeLatest(ADD_ACT, addActSaga)
  yield takeLatest(REORDER_ACTS, reorderActsSaga)
  yield takeLatest(DELETE_ACT, deleteActSaga)
}

export default actSaga
