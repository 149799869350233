/* _PORTFOLIOS */
export const GET_PORTFOLIOS = "GET_PORTFOLIOS"
export const GET_PORTFOLIOS_SUCCESS = "GET_PORTFOLIOS_SUCCESS"
export const GET_PORTFOLIOS_FAIL = "GET_PORTFOLIOS_FAIL"

/* _PORTFOLIOS DETAIL*/
export const GET_PORTFOLIO_DETAIL = "GET_PORTFOLIO_DETAIL"
export const GET_PORTFOLIO_DETAIL_SUCCESS = "GET_PORTFOLIO_DETAIL_SUCCESS"
export const GET_PORTFOLIO_DETAIL_FAIL = "GET_PORTFOLIO_DETAIL_FAIL"

/**
 * add portfolio
 */
export const ADD_NEW_PORTFOLIO = "ADD_NEW_PORTFOLIO"
export const ADD_PORTFOLIO_SUCCESS = "ADD_PORTFOLIO_SUCCESS"
export const ADD_PORTFOLIO_FAIL = "ADD_PORTFOLIO_FAIL"

/**
 * Edit portfolio
 */
export const UPDATE_PORTFOLIO = "UPDATE_PORTFOLIO"
export const UPDATE_PORTFOLIO_SUCCESS = "UPDATE_PORTFOLIO_SUCCESS"
export const UPDATE_PORTFOLIO_FAIL = "UPDATE_PORTFOLIO_FAIL"

/**
 * Delete portfolio
 */
export const DELETE_PORTFOLIO = "DELETE_PORTFOLIO"
export const DELETE_PORTFOLIO_SUCCESS = "DELETE_PORTFOLIO_SUCCESS"
export const DELETE_PORTFOLIO_FAIL = "DELETE_PORTFOLIO_FAIL"

/**
 * portfolio image
 */
export const GET_PORTFOLIO_IMAGE = "GET_PORTFOLIO_IMAGE"
export const GET_PORTFOLIO_IMAGE_FAIL = "GET_PORTFOLIO_IMAGE_FAIL"
export const GET_PORTFOLIO_IMAGE_SUCCESS = "GET_PORTFOLIO_IMAGE_SUCCESS"
