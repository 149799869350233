import {
  GET_PORTFOLIOS,
  GET_PORTFOLIOS_FAIL,
  GET_PORTFOLIOS_SUCCESS,
  GET_PORTFOLIO_DETAIL_FAIL,
  GET_PORTFOLIO_DETAIL_SUCCESS,
  ADD_NEW_PORTFOLIO,
  ADD_PORTFOLIO_SUCCESS,
  ADD_PORTFOLIO_FAIL,
  UPDATE_PORTFOLIO_SUCCESS,
  UPDATE_PORTFOLIO_FAIL,
  DELETE_PORTFOLIO_SUCCESS,
  DELETE_PORTFOLIO_FAIL,
  GET_PORTFOLIO_IMAGE,
  GET_PORTFOLIO_IMAGE_SUCCESS,
  GET_PORTFOLIO_IMAGE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  portfolios: [],
  portfolioDetail: {},
  portfolioImage: "",
  loading: false,
  error: "",
  message: "",
}

const portfolios = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PORTFOLIOS:
      return {
        ...state,
        loading: true,
      }

    case GET_PORTFOLIOS_SUCCESS:
      return {
        ...state,
        portfolios: action.payload,
        loading: false,
      }

    case GET_PORTFOLIOS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case ADD_NEW_PORTFOLIO:
      return {
        ...state,
        portfolios: action.payload,
        loading: true,
      }
    case ADD_PORTFOLIO_SUCCESS:
      return {
        ...state,
        portfolios: action.payload,
        loading: false,
      }

    case ADD_PORTFOLIO_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_PORTFOLIO_DETAIL_SUCCESS:
      return {
        ...state,
        portfolioDetail: action.payload,
        loading: false,
      }

    case UPDATE_PORTFOLIO_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case UPDATE_PORTFOLIO_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_PORTFOLIO_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case DELETE_PORTFOLIO_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PORTFOLIO_IMAGE:
      return {
        ...state,
        loading: true,
      }
    case GET_PORTFOLIO_IMAGE_SUCCESS:
      return {
        ...state,
        portfolioImage: action.payload,
        loading: false,
      }
    case GET_PORTFOLIO_IMAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default portfolios
