import {
  GET_USER_SUBSCRIPTION_INFO,
  GET_USER_SUBSCRIPTION_INFO_SUCCESS,
  GET_USER_SUBSCRIPTION_INFO_ERROR,
} from "./actionTypes"

export const getUserSusbcriptionInfo = () => {
  return {
    type: GET_USER_SUBSCRIPTION_INFO,
  }
}

export const getUserSusbcriptionInfoSuccess = user => {
  return {
    type: GET_USER_SUBSCRIPTION_INFO_SUCCESS,
    payload: user,
  }
}

export const getUserSusbcriptionInfoError = error => {
  return {
    type: GET_USER_SUBSCRIPTION_INFO_ERROR,
    payload: error,
  }
}
