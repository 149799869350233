import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  ListGroup,
  ListGroupItem,
  Progress,
  Row,
  Col
} from "reactstrap"
import Breadcrumb from "components/Common/Breadcrumb2"
import { getContentByProject } from "store/actions"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { MdOutlineNoteAdd, MdAnalytics, MdFolderOpen } from "react-icons/md"
import { getLoggedInUser } from "../../../helpers/backend_helper"
import mixpanel from "mixpanel-browser"
import NoDataFound from "components/NoDataFound"
import ScaleLoader from "react-spinners/ScaleLoader";
import {COLOR_THEME_PRIMARY} from "utils";

const DocumentList = () => {
  const dispatch = useDispatch()
  const { type, project } = useParams()
  const { contents, loading } = useSelector(state => state.projects)

  //const deleteRef = useRef(false)
  const [deleteProject, setDeleteProject] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)

  const [showModal, setShowModal] = useState(false)
  const [contentInfo, setContentInfo] = useState()

  useEffect(() => {
    const loggedInUser = getLoggedInUser()
    if (loggedInUser !== null) {
      mixpanel.track("analyze_project_doc_list", {
        event_properties: {
          user_id: loggedInUser.uid,
        },
      })
    } else {
      mixpanel.track("analyze_project_doc_list")
    }
  }, [])

  const items = [
    { title: "Analyze", link: "/analyze" },
    {
      title: `Documents`,
      link: "#",
    },
  ]

  /*  const handleDeleteClick = () => {
    try {
      dispatch(deleteContentById(contentInfo.projectId, contentInfo.contentId))
      setDeleteProject(true)
      setShowModal(false)
    } catch (error) {
      console.log(error)
    }
  } */

  useEffect(() => {
    if (project || deleteProject) {
      dispatch(getContentByProject(project))
    }
    setDeleteProject(false)
  }, [project.length, deleteProject])

  useEffect(() => {
    if (deleteLoading) {
      setDeleteLoading(false)
    }
  }, [contents])

  const handleDeleteClick = () => {
    setDeleteLoading(true)
    try {
      dispatch(deleteDocumentById(contentInfo.projectId, contentInfo.contentId))
      setDeleteProject(true)
      setShowModal(false)
    } catch (error) {
      console.log(error)
      setDeleteLoading(false)
    }
  }

  const onShowModal = (projectId, contentId) => {
    setContentInfo({
      projectId: projectId,
      contentId: contentId,
    })
    setShowModal(true)
  }

  const onCloseModal = () => {
    setShowModal(false)
  }

  function getSummary(content) {
    return (
      <>
        {content?.summary?.length > 100
          ? content?.summary?.substr(0, 100) + "..."
          : content?.summary?.substr(0, content?.summary?.length)}
      </>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Documents | QuillNow
          </title>
        </MetaTags>
        <Container fluid>
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18">Documents</h4>
                <div className="page-title-right">
                  <Breadcrumb breadcrumbItems={items} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="projects-container mb-4">
              <>
                {contents &&
                contents?.contents &&
                contents?.contents?.length > 0 ? (
                  contents?.contents.map(content => {
                    return (
                      <div key={content?.id}>
                        <div className="col-sm-6 col-xl-2">
                          <Card style={{ width: "18rem" }} className="card-container">
                            <CardBody>
                              <Link
                                  to={`/analyze/project/document/chart/${project}/${content?.id}`}
                              >
                                <CardTitle>
                                  <h4>{content?.title}</h4>
                                </CardTitle>
                              </Link>
                              <div className="d-flex align-content-start">
                                {content?.content_percentage && (
                                    <Progress style={{height: '20px', width: '200px'}} min={0} max="100"
                                              value={content?.content_percentage} color={COLOR_THEME_PRIMARY}>
                                      <span className="text-black">{content?.content_percentage}%</span>
                                    </Progress>
                                )}
                              </div>
                            </CardBody>
                            <CardBody>
                              <CardSubtitle>{getSummary(content)}</CardSubtitle>
                            </CardBody>
                            <ListGroup className="list-group-flush">
                              <ListGroupItem>
                                <Row>
                                  <Col className="col-9 text-muted font-size-12">Characters</Col>
                                  <Col className="col-3">{content?.text_stats &&
                                  content?.text_stats?.char_count !==
                                  undefined ? (
                                      <span className="badge badge-soft-dark font-size-11 m-1">
                                      {content?.text_stats?.char_count}
                                    </span>
                                  ) : (
                                      <span><ScaleLoader height='5px' width='6px' color={COLOR_THEME_PRIMARY} /></span>
                                  )}</Col>
                                </Row>
                                <Row>
                                  <Col className="col-9 text-muted font-size-12">Words</Col>
                                  <Col>{content?.text_stats &&
                                  content?.text_stats?.word_count !==
                                  undefined ? (
                                      <span className="badge badge-soft-dark font-size-11 m-1">
                                      {content?.text_stats?.word_count}
                                    </span>
                                  ) : (
                                      <span><ScaleLoader height='5px' width='6px' color={COLOR_THEME_PRIMARY} /></span>
                                  )}</Col>
                                </Row>
                                <Row>
                                  <Col className="col-9 text-muted font-size-12">Characters without space</Col>
                                  <Col>{content?.text_stats &&
                                  content?.text_stats
                                      ?.char_count_without_space !== undefined ? (
                                      <span className="badge badge-soft-dark font-size-11 m-1">
                                      {content?.text_stats?.char_count_without_space}
                                    </span>
                                  ) : (
                                      <span><ScaleLoader height='5px' width='6px' color={COLOR_THEME_PRIMARY} /></span>
                                  )}</Col>
                                </Row>
                              </ListGroupItem>
                            </ListGroup>
                          </Card>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <NoDataFound message="Add a project to get started" />
                )}
              </>
            </div>
          </div>
        </Container>
      </div>
      <div></div>
    </React.Fragment>
  )
}

export default DocumentList

DocumentList.propTypes = {
  row: PropTypes.any,
  value: PropTypes.any,
  searchProps: PropTypes.any,
  baseProps: PropTypes.any,
}
