import GaugeChart from "react-gauge-chart"
import React from "react"
import PropTypes from "prop-types"
import {Container, Card, CardBody, CardLink, Col, Row} from "reactstrap";

const LexDiversityCard = ({ hapaxScore, ttrScore }) => (
    <Container style={{width: "100%", height: "400px"}}>
  <div className="card">
    <div className="card-header" style={{ textAlign: 'center' }}>
      <h3>Lexical Richness</h3>
    </div>
    <div className="card-body lex-diversity__card">
      <div className="gauge-chart center">
        <GaugeChart
          id="gauge-chart-lex-overall-hapax"
          nrOfLevels={420}
          arcsLength={[0.3, 0.5, 0.2]}
          colors={["#fd7f6f", "#ffb55a", "#b2e061"]}
          percent={hapaxScore}
          arcPadding={0.02}
          textColor={"black"}
        />
        <h4>Hapax Score</h4>
      </div>
      <div className="gauge-chart center">
        <GaugeChart
          id="gauge-chart-lex-overall-ttr"
          nrOfLevels={420}
          arcsLength={[0.3, 0.5, 0.2]}
          colors={["#fd7f6f", "#ffb55a", "#b2e061"]}
          percent={ttrScore}
          arcPadding={0.02}
          textColor={"black"}
        />
        <h4>TTR Score</h4>
      </div>
    </div>
  </div>
        <Row id="reading-ease-exp-row">
            <Col className="col-6" id="reading-ease-exp-card">
                <Card style={{float: "left", width: "100%"}}>
                    <CardBody>
                        <p>Hapax is a word or an expression that occurs only once within a context.</p>
                        <CardLink href="https://en.wikipedia.org/wiki/Hapax_legomenon">
                            More info
                        </CardLink>
                    </CardBody>
                </Card>
            </Col>
            <Col className="col-6" id="foreign-reader-reading-ease-exp-card">
                <Card style={{float: "right", width: "100%"}}>
                    <CardBody>
                        <p>Type-token ratio (TTR) computed as the ratio of number of unique terms to the total number of
                            words.
                            The type-token ratio (TTR) is a measure of vocabulary variation within a written text or a
                            person’s speech.</p>
                        <p>The type-token ratio is shown to be a helpful measure of lexical variety within a text.
                            The more types there are in comparison to the number of tokens, then the more varied is the
                            vocabulary, i.e. it there is greater lexical variety.</p>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </Container>
)

LexDiversityCard.propTypes = {
  hapaxScore: PropTypes.number,
  ttrScore: PropTypes.number,
}

export default LexDiversityCard
