import { takeEvery, fork, put, all, call, delay } from "redux-saga/effects"
import { useHistory } from "react-router-dom"

//Account Redux states
import { REGISTER_USER, SIGNUP_USER } from "./actionTypes"
import {
  registerUserSuccessful,
  registerUserFailed,
  signUpUserSuccessful,
  signUpUserFailed,
} from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import { postSignup } from "helpers/backend_helper"

// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend()

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user, history } }) {
  // Form Data
  const userForm = new FormData()
  userForm.append("email", user.email)
  try {
    // Firebase Register
    // correct, effects will get executed in parallel
    const response = yield call(
      fireBaseBackend.registerUser,
      user.email,
      user.password
    )
    yield put(registerUserSuccessful(response))
    history.push("/login")
  } catch (error) {
    yield put(registerUserFailed(error))
  }
}

function* signUpUser({ payload: { user, history } }) {
  try {
    const response = yield call(postSignup, user)
    yield put(signUpUserSuccessful(response))
    history.push("/plans")
    //window.location.href = response
  } catch (error) {
    yield put(signUpUserFailed(error))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
  yield takeEvery(SIGNUP_USER, signUpUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
