import {Col, Row} from "reactstrap";
import ScaleLoader from "react-spinners/ScaleLoader";
import {COLOR_THEME_PRIMARY} from "../../utils";
import React from "react";

const LoadingBar = () => (
    <Row>
        <Col className="col-12">
            <div className="progress-loading-bar">
                <ScaleLoader height='5px' width='6px' color={COLOR_THEME_PRIMARY} />
            </div>
        </Col>
    </Row>
)
export default LoadingBar