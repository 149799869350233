import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESSFUL,
  SIGNUP_USER_FAILED,
} from "./actionTypes"

export const registerUser = (user, history) => {
  return {
    type: REGISTER_USER,
    payload: { user, history },
  }
}

export const registerUserSuccessful = user => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: user,
  }
}

export const registerUserFailed = user => {
  return {
    type: REGISTER_USER_FAILED,
    payload: user,
  }
}

// Sign up a user
export const signUpUser = (user, history) => {
  return {
    type: SIGNUP_USER,
    payload: {user, history},
  }
}

export const signUpUserSuccessful = (user) => {
  return {
    type: SIGNUP_USER_SUCCESSFUL,
    payload: user,
  }
}

export const signUpUserFailed = user => {
  return {
    type: SIGNUP_USER_FAILED,
    payload: user,
  }
}
