import React from "react"
import PropTypes from "prop-types"

const DashboardCard = props => {
  return (
    <div className="col-xl-3 col-sm-6 py-2">
      <div
        className="card text-white h-100 dashboard-card"
        style={{ border: "2px solid " + props.color }}
      >
        <div className="card-body dashboard-body-card">
          <div className="rotate">
            <h1 className="display-4 text-center" style={{ color: props.color }}>
              {props.count}
            </h1>
          </div>
            <h6 className="text-uppercase text-center" style={{ color: props.color }}>
              {props.description}
            </h6>
        </div>
      </div>
    </div>
  )
}
DashboardCard.propTypes = {
  color: PropTypes.string,
  count: PropTypes.number,
  description: PropTypes.string,
}
export default DashboardCard
