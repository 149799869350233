import React from 'react'
import { Link } from 'react-router-dom'
import verificationImg from "assets/images/verification-img.png"

const Success = () => {
  return (
    <div className="account-pages my-5 pt-5">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="text-center mb-5">
          <h4 className="text-uppercase">Thank You For Your Payment</h4>
          <div className="mt-5 text-center">
            <Link className="btn btn-primary" to="/home">
              Back to Home
            </Link>
          </div>
        </div>
      </div>
    </div>
    <div className="justify-content-center row">
      <div className="col-md-8 col-xl-6">
        <div>
          <img
            src={verificationImg}
            alt=""
            className="img-fluid"
          />
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Success