import React from "react"
import PropTypes from "prop-types"
import {
  Area,
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts"
import {
  CHAR_COUNT_COLOR,
  CHAR_COUNT_WITHOUT_SPACE_COLOR,
  WORD_COUNT_COLOR,
  SYLLABLE_COUNT_COLOR,
  SENTENCE_COUNT_COLOR,
} from "utils"

export function LineTextStatsChart({ lineTextStats = {} }) {

  if (!lineTextStats || !lineTextStats.text_stats_scores) return null

  const data = lineTextStats.text_stats_scores.map((score, i) => ({
    name: `Paragraph ${lineTextStats.paragraph_index[i]}`,
    Characters: score.char_count,
    "Characters Without Space": score.char_count_without_space,
    Syllables: score.syllable_count,
    Words: score.word_count,
    Sentences: score.sentence_count,
  }))

  if (!data || data.length === 0) return null

  console.log("data", data)
  return (
    <div className="container" style={{ width: "100%", height: "400px" }}>
      <ResponsiveContainer width="100%" height={400}>
        <AreaChart
          width={800}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <defs>
            <linearGradient id="colorCharCount" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={CHAR_COUNT_COLOR}
                stopOpacity={0.8}
              />
              <stop offset="95%" stopColor={CHAR_COUNT_COLOR} stopOpacity={0} />
            </linearGradient>
            <linearGradient
              id="colorCharCountWithoutSpace"
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop
                offset="5%"
                stopColor={CHAR_COUNT_WITHOUT_SPACE_COLOR}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={CHAR_COUNT_WITHOUT_SPACE_COLOR}
                stopOpacity={0}
              />
            </linearGradient>
            <linearGradient id="colorSyllableCount" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={SYLLABLE_COUNT_COLOR}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={SYLLABLE_COUNT_COLOR}
                stopOpacity={0}
              />
            </linearGradient>
            <linearGradient id="colorWordCount" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={WORD_COUNT_COLOR}
                stopOpacity={0.8}
              />
              <stop offset="95%" stopColor={WORD_COUNT_COLOR} stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorSentenceCount" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={SENTENCE_COUNT_COLOR}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={SENTENCE_COUNT_COLOR}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Legend verticalAlign="top" height={36} />
          <Area
            type="monotone"
            name="Characters"
            dataKey="Characters"
            stroke={CHAR_COUNT_COLOR}
            fillOpacity={1}
            fill="url(#colorCharCount)"
            isAnimationActive={true}
          />
          <Area
            type="monotone"
            name="Characters Without Space"
            dataKey="Characters Without Space"
            stroke={CHAR_COUNT_WITHOUT_SPACE_COLOR}
            fillOpacity={1}
            fill="url(#colorCharCountWithoutSpace)"
            isAnimationActive={true}
          />
          <Area
            type="monotone"
            name="Syllables"
            dataKey="Syllables"
            stroke={SYLLABLE_COUNT_COLOR}
            fillOpacity={1}
            fill="url(#colorSyllableCount)"
            isAnimationActive={true}
          />
          <Area
            type="monotone"
            name="Words"
            dataKey="Words"
            stroke={WORD_COUNT_COLOR}
            fillOpacity={1}
            fill="url(#colorWordCount)"
            isAnimationActive={true}
          />
          <Area
            type="monotone"
            name="Sentences"
            dataKey="Sentences"
            stroke={SENTENCE_COUNT_COLOR}
            fillOpacity={1}
            fill="url(#colorSentenceCount)"
            isAnimationActive={true}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}

LineTextStatsChart.propTypes = {
  lineTextStats: PropTypes.any,
}
