import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
//import "@fullcalendar/core/main.css"
import "@fullcalendar/daygrid/main.css"
import "@fullcalendar/timegrid/main.css"

import { eventList } from "../../common/data/events"
import { getLoggedInUser } from "../../helpers/backend_helper"
import mixpanel from "mixpanel-browser"
import { withSignup } from "hooks/withSignup"

const Calendar = () => {
  const [events, setEvents] = useState([])
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [pickDate, setPickDate] = useState(new Date())

  useEffect(() => {
    setEvents()
    const loggedInUser = getLoggedInUser()
    if (loggedInUser !== null) {
      mixpanel.track("calendar_page_load", {
        event_properties: {
          user_id: loggedInUser.uid,
        },
      })
    } else {
      mixpanel.track("calendar_page_load")
    }
  }, [])

  const handleClickReminder = e => {
    e.preventDefault()
    setEvents([
      ...events,
      {
        title: title,
        description: description,
        date: pickDate,
      },
    ])
  }

  const handleTitleChange = e => {
    setTitle(e.target.value)
  }
  const handleChangeDescription = e => {
    setDescription(e.target.value)
  }

  const handleDate = e => {
    setPickDate(e.target.value)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Calendar | QuillNow
          </title>
        </MetaTags>
        <Container fluid>
          {events?.length > 0 ? (
            <>
              <h4>Calendar</h4>
              <form>
                <div className="form-group mb-3">
                  <label htmlFor="titleControlText">Title</label>
                  <input
                    className="form-control"
                    type="text"
                    id="titleControlText"
                    placeholder="Title"
                    onChange={handleTitleChange}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="descriptionControlTextarea">
                    Description
                  </label>
                  <textarea
                    className="form-control"
                    id="descriptionControlTextarea"
                    rows={3}
                    onChange={handleChangeDescription}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="datepickLabel">Due On</label>
                  <input
                    className="form-control"
                    type="date"
                    onChange={handleDate}
                    id="datepickLabel"
                    placeholder="Due on"
                  />
                </div>
                <div className="form-group mb-5 buttons-reminder">
                  <button
                    className="btn btn-reminder"
                    color="primary"
                    onClick={handleClickReminder}
                  >
                    Add reminder
                  </button>
                </div>
              </form>

              <FullCalendar
                defaultView="dayGridMonth"
                header={{
                  left: "prev,next",
                  center: "title",
                  right: "dayGridMonth,timeGridWeek,timeGridDay",
                }}
                plugins={[dayGridPlugin, timeGridPlugin]}
                events={events}
              />
            </>
          ) : (
            <div className="row mb-3 rounded py-5 border border-dark d-flex justify-content-center align-items-center">
              <div className="h5 text-center">Calendar Not connected!</div>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withSignup(Calendar)
