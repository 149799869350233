import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import MetaTags from "react-meta-tags"
import { Container, Spinner } from "reactstrap"
import avatarPlaceholder from "../../assets/images/users/user.png"
import { getUserShowcase } from "store/actions"
import { useParams } from "react-router-dom"
import ProfileNotFound from "components/ProfileNotFound"
import ImageBlob from "components/ImageBlob"

const PublicProfile = () => {
  const dispatch = useDispatch()
  const { showcaseId } = useParams()
  const { showcase, photo } = useSelector(state => state.Profile)

  console.log("showcaseId", showcaseId)
  useEffect(() => {
    if (showcaseId) {
      dispatch(getUserShowcase({ user: showcaseId }))
    }
    //setShowUser(false)
  }, [showcaseId])

  if (showcase?.user_info?.firebase_id !== String(showcaseId)) {
    return <ProfileNotFound />
  }

  return (
    <div style={{ padding: "10px" }}>
      <MetaTags>
        <title>
          Profile | QuillNow
        </title>
      </MetaTags>
      <Container fluid>
        <div className="row">
          <div className="col-12">
            <div className="email-leftbar card">
              <div className="text-center">
                <div className="me-3 mb-4">
                  {photo ? (
                    <ImageBlob
                      src={photo}
                      alt=""
                      className="avatar-lg rounded-circle img-thumbnail"
                    />
                  ) : (
                    <img
                      src={avatarPlaceholder}
                      alt=""
                      className="avatar-lg rounded-circle img-thumbnail"
                    />
                  )}
                </div>
                <div className="mb-5">
                  <h5 className="">
                    {showcase?.user_info?.profile?.first_name}{" "}
                    {showcase?.user_info?.profile?.last_name}
                  </h5>
                  <p className="text-muted mb-1">
                    {showcase?.user_info?.profile?.display_name}
                  </p>
                  <p className="text-muted mb-0">
                    {showcase?.user_info?.profile?.email}
                  </p>
                </div>
              </div>
              <div className="mb-5 text-center">
                <h5 className="mb-2">BIOGRAPHY</h5>
                <p className="text-muted mb-1">
                  {showcase?.user_info?.profile?.bio}
                </p>
              </div>
              <div className="mb-5 text-center">
                <h5 className="mb-2">SHORT BIOGRAPHY</h5>
                <p className="text-muted mb-1">
                  {showcase?.user_info?.profile?.short_bio}
                </p>
              </div>
              <div className="mb-5 text-center">
                <h5 className="mb-2">WRITING CATEGORIES</h5>
                <div className="mail-list mt-1">
                  <div className="writing-categories">
                    {/* {showcase &&
                      showcase?.user_info?.profile?.writing_categories.length >
                        0 &&
                      showcase?.user_info?.profile?.writing_categories
                        ?.split(",")
                        .map((category, index) => (
                          <span key={index} className="bg-info badge bg-primary">
                            {category}
                          </span>
                        ))} */}
                  </div>
                </div>
              </div>
              <div className="mb-5 text-center">
                <h5 className="mb-2 text-center">PUBLICATIONS</h5>
                <ul className="list-unstyled product-list">
                  {showcase &&
                    showcase?.user_info?.profile?.publications &&
                    showcase?.user_info?.profile?.publications.map(
                      (publication, index) => (
                        <li key={index}>
                          <a href={publication.url}>
                            <i className="mdi mdi-chevron-right me-1" />{" "}
                            {publication.name}
                          </a>
                        </li>
                      )
                    )}
                </ul>
              </div>
            </div>
            <div className="email-rightbar mb-3">
              <div className="card">
                <div className="card-body">
                  <div className="porfolio-container">
                    <div className="row">
                      {showcase &&
                        showcase?.portfolio_items &&
                        showcase?.portfolio_items.map(portfolio => (
                          <div key={portfolio.id} className="col-6 col-md-4">
                            <div className="shadow-sm p-3 mb-5 bg-white rounded card">
                              <img
                                src={`https://materializecss.com/images/sample-1.jpg`}
                                alt="Skote"
                                className="img-fluid card-img-top"
                              />
                              <div className="card-body">
                                <div className="h4 mt-0 card-title">
                                  {portfolio.title}
                                </div>
                                <p className="card-text mb-3">
                                  {portfolio?.publication}
                                </p>
                                <p className="card-text mb-2">
                                  <small className="text-muted">
                                    Published on: {portfolio?.published_on}
                                  </small>
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default PublicProfile
