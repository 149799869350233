import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardTitle, Collapse, Button } from 'reactstrap';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { ScaleLoader } from 'react-spinners';
import { COLOR_THEME_PRIMARY } from 'utils';

const CardSection = ({ title, isLoading, children, isOpen, toggle, id }) => (
  <Card className='sidebar-card__section'>
    <Button className='d-flex justify-content-between bg-white' onClick={() => toggle(id)} style={{ marginBottom: '1rem' }}>
      {title}
      {isOpen ? <FaChevronUp className="float-right" /> : <FaChevronDown className="float-right" />}
    </Button>
    <Collapse isOpen={isOpen} style={{ overflow: 'auto' }}>
      {isLoading ? <LoadingComponent /> : <div>{children}</div>}
    </Collapse>
  </Card>
);

CardSection.propTypes = {
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};


const LoadingComponent = () => (
  <div className="sidebar-loading__container">
    <ScaleLoader height="5px" width="6px" color={COLOR_THEME_PRIMARY} />
  </div>
)
export default CardSection
