import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_PARAPHRASES } from "./actionTypes"
import {
  getParaphraseSuccess,
  getParaphraseFail,
} from "./actions"

//Include Both Helper File with needed methods
import { fetchParaphrase } from "helpers/backend_helper"

function* getParaphrase({ payload: content }) {
  try {
    const response = yield call(fetchParaphrase, content)
    yield put(getParaphraseSuccess(response))
  } catch (error) {
    yield put(getParaphraseFail(error))
  }
}
function* paraphraseSaga() {
  yield takeEvery(GET_PARAPHRASES, getParaphrase)
}

export default paraphraseSaga
