import { call, put, takeLatest } from "redux-saga/effects"

import {
  ADD_SCREENPLAY,
  ADD_SCREENPLAY_SUCCESS,
  ADD_SCREENPLAY_FAIL,
  UPDATE_SCREENPLAY,
  UPDATE_SCREENPLAY_SUCCESS,
  UPDATE_SCREENPLAY_FAIL,
  GET_SCREENPLAY,
  GET_SCREENPLAY_SUCCESS,
  GET_SCREENPLAY_FAIL,
  LIST_SCREENPLAYS,
  LIST_SCREENPLAYS_SUCCESS,
  LIST_SCREENPLAYS_FAIL,
  DELETE_SCREENPLAY,
  DELETE_SCREENPLAY_SUCCESS,
  DELETE_SCREENPLAY_FAIL,
} from "./actionTypes"
import {
  addScreenplayApi,
  updateScreenplayApi,
  getScreenplayApi,
  listScreenplaysApi,
  deleteScreenplaysApi,
} from "helpers/backend_helper"

function* addScreenplaySaga({ payload: { screenplay, history } }) {
  try {
    const response = yield call(addScreenplayApi, screenplay)

    yield put({
      type: ADD_SCREENPLAY_SUCCESS,
      payload: { screenplay: response },
    })
    history.push(`/screenplay/${response.screenplay_id}`)
  } catch (error) {
    yield put({ type: ADD_SCREENPLAY_FAIL, payload: { error } })
  }
}

function* updateScreenplaySaga({ payload: { screenplay, history } }) {
  try {
    const response = yield call(updateScreenplayApi, screenplay)
    yield put({
      type: UPDATE_SCREENPLAY_SUCCESS,
      payload: { screenplay: response },
    })
    

    history.push("/screenplays")

  } catch (error) {
    yield put({ type: UPDATE_SCREENPLAY_FAIL, payload: { error } })
  }
}

function* getScreenplaySaga({ payload: { id } }) {
  try {
    const response = yield call(getScreenplayApi, id)
    yield put({
      type: GET_SCREENPLAY_SUCCESS,
      payload: { screenplay: response },
    })
  } catch (error) {
    yield put({ type: GET_SCREENPLAY_FAIL, payload: { error } })
  }
}

function* listScreenplaysSaga() {
  try {
    const response = yield call(listScreenplaysApi)
    yield put({
      type: LIST_SCREENPLAYS_SUCCESS,
      payload: { screenplays: response },
    })
  } catch (error) {
    yield put({ type: LIST_SCREENPLAYS_FAIL, payload: { error } })
  }
}

function* deleteScreenplaySaga({ payload: { screenplayId } }) {
  try {
    const dataForm = new FormData()
    dataForm.append("screenplay_id", screenplayId)
    const response = yield call(deleteScreenplaysApi, dataForm)
    yield put({ type: DELETE_SCREENPLAY_SUCCESS, payload: screenplayId })
    yield call(listScreenplaysSaga)
  } catch (error) {
    yield put({ type: DELETE_SCREENPLAY_FAIL, payload: error })
  }
}

function* screenplaySaga() {
  yield takeLatest(ADD_SCREENPLAY, addScreenplaySaga)
  yield takeLatest(UPDATE_SCREENPLAY, updateScreenplaySaga)
  yield takeLatest(GET_SCREENPLAY, getScreenplaySaga)
  yield takeLatest([LIST_SCREENPLAYS, DELETE_SCREENPLAY], listScreenplaysSaga)
  yield takeLatest(DELETE_SCREENPLAY, deleteScreenplaySaga)
}

export default screenplaySaga
