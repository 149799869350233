import React, { useState, useEffect } from "react"
import Editor from "components/Editor"
import PropTypes from "prop-types"
import { Container, FormFeedback } from "reactstrap"
import MetaTags from "react-meta-tags"
import Breadcrumb from "components/Common/Breadcrumb2"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { addNewIdea, getIdeaDetail, updateIdea } from "store/actions"
import { getLoggedInUser } from "helpers/backend_helper"
import mixpanel from "mixpanel-browser"
import { Link, useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { withSignup } from "hooks/withSignup"

const AddIdea = props => {
  const [editorLoaded, setEditorLoaded] = useState(false)
  const { ideaDetail } = useSelector(state => state.ideas)

  const dispatch = useDispatch()

  const { edit, id } = useParams()
  useEffect(() => {
    setEditorLoaded(true)
    const loggedInUser = getLoggedInUser()
    if (loggedInUser !== null) {
      mixpanel.track("add_idea", {
        event_properties: {
          user_id: loggedInUser.uid,
        },
      })
    } else {
      mixpanel.track("add_idea")
    }
  }, [])

  useEffect(() => {
    if (id) {
      dispatch(getIdeaDetail(id))
    }
  }, [edit, id])

  const [dataEditor, setDataEditor] = useState({
    editorHtml: "",
    theme: "snow",
  })

  const items = [
    { title: "Ideas", link: "/idea" },
    {
      title: "Idea Entry",
      link: "/idea/create-new-idea",
    },
  ]

  const validationSchema = Yup.object({
    title: Yup.string().required("Please Enter The Title"),
    editorHtml: Yup.string()
      .notOneOf(["<p></br><p>", ""], "Please Enter The Content")
      .required("Please Enter The Content"),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      title: "",
      editorHtml: "",
    },
  })

  const onSubmit = data => {
    const ideaFormData = new FormData()
    ideaFormData.append("idea_title", data.title)
    ideaFormData.append(
      "idea_content",
      dataEditor.editorHtml.replace(/(<([^>]+)>)/gi, "")
    )
    if (edit && id) {
      dispatch(updateIdea(ideaFormData, id, props.history))
    } else {
      dispatch(addNewIdea(ideaFormData, props.history))
    }
  }

  const handleChangeEditor = content => {
    const contentText = content.replace(/<p><br><\/p>/g, "")
    setDataEditor({ editorHtml: contentText })
    setValue("editorHtml", contentText)
    trigger("editorHtml")
  }

  useEffect(() => {
    if (edit && id) {
      if (ideaDetail) {
        setValue("title", ideaDetail?.idea?.title)
        setDataEditor({ editorHtml: ideaDetail?.idea?.content })
      }
    }
  }, [ideaDetail, edit, id])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Ideas </title>
        </MetaTags>
        <Container fluid>
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18">Idea Entry</h4>
                <div className="page-title-right">
                  <Breadcrumb breadcrumbItems={items} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3 row">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Title
                      </label>
                      <div className="col-md-10">
                        <input
                          className={`form-control ${
                            errors.title && "is-invalid"
                          }`}
                          type="text"
                          placeholder="Title"
                          {...register("title")}
                        />
                        {errors.title && (
                          <FormFeedback type="invalid">
                            {errors.title.message}
                          </FormFeedback>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label
                        htmlFor="example-url-input"
                        className="col-md-2 col-form-label"
                      >
                        Content
                      </label>
                      <div className="col-md-10">
                        <Editor
                          onChange={handleChangeEditor}
                          value={dataEditor.editorHtml || ""}
                        />
                        {errors.editorHtml && (
                          <FormFeedback
                            type="invalid"
                            style={{
                              display: `${
                                dataEditor.editorHtml ? "none" : "block"
                              }`,
                            }}
                          >
                            {errors.editorHtml.message}
                          </FormFeedback>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <div className="portfolio-button">
                        <Link to="/idea" style={{ marginRight: "12px" }}>
                          <button
                            className="btn btn-composer"
                            style={{ backgroundColor: "#eee" }}
                          >
                            <i className="bx bx-arrow-back bx-sm"></i>
                            <span>Back</span>
                          </button>
                        </Link>
                        <button type="submit" className="btn btn-portfolio">
                          <i className="bx bxs-book-open"></i>
                          <span>Save</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

AddIdea.propTypes = {
  editorLoaded: PropTypes.bool,
  onSubmit: PropTypes.func,
  rest: PropTypes.any,
  handleChangeEditor: PropTypes.func,
  valueEditor: PropTypes.string,
  validation: PropTypes.bool,
  errorMessage: PropTypes.string,
  history: PropTypes.object,
}
export default withSignup(AddIdea)
