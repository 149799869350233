import PropTypes from "prop-types"
import React from "react"
import ReactQuill from "react-quill"

const Editor = ({
  onChange,
  value,
  onChangeSelection,
  theme,
  name
}) => {

  return (
    <>
      <ReactQuill
        theme={theme}
        value={value}
        name={name}
        onChange={onChange}
        placeholder={"Write something awesome..."}
        onChangeSelection={onChangeSelection}
        modules={Editor.modules}
        formats={Editor.formats}
      />
    </>
  )
}

/* 
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
Editor.modules = {
  toolbar: [
    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, 
     {'indent': '-1'}, {'indent': '+1'}],
    ['link'],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  }
}
/* 
 * Quill editor formats
 */
Editor.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
]

/* 
 * PropType validation
 */

Editor.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  theme: PropTypes.string,
  onChangeSelection: PropTypes.func,
  name: PropTypes.string,
}

export default Editor
