import React from "react"
import PropTypes from "prop-types"
import StackedProgressBar from "components/progress-bar/StackedProgressBar"
import BiasScore from "./BiasScore"

const AttitudeProfile = ({ patronizeData, error }) => {
  return (
    <div className="attitude-profile mt-4">
      <h6>Patronizing</h6>
      {!patronizeData ||
      Object.keys(patronizeData).length === 0 ||
      patronizeData.error ? (
        <p className="error-message">Score is not available currently.</p>
      ) : (
        patronizeData && (
          <BiasScore biasType="patronizing" data={patronizeData} />
        )
      )}
    </div>
  )
}

AttitudeProfile.propTypes = {
  patronizeData: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  error: PropTypes.any.isRequired,
}

export default AttitudeProfile
