import React from "react";
import PropTypes from "prop-types";
import { TEN_COLORS } from "utils";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Surface,
} from "recharts";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const SummaryCard = ({ summary, topFrequencyWords, topFrequencyWordsStop }) => {
  return (
    <Card
      className="card-container"
      style={{ borderRadius: "5%", width: "100%", height: 500 }}
    >
      <CardBody>
        <h5 className="text-primary">Summary</h5>
        <br />
        <span className="text-muted font-size-16">{summary}</span>
      </CardBody>
      <CardBody>
        <h5 className="text-primary">Word Frequencies</h5>
        <br />
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              dataKey="value"
              isAnimationActive={true}
              data={topFrequencyWords}
              cx="30%"
              cy="50%"
              outerRadius={80}
              fill="#8884d8"
              labelLine={false}
              label={renderCustomizedLabel}
            >
              {topFrequencyWords.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={TEN_COLORS[index % TEN_COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <text x="30%" y="80%" dy={15} textAnchor="middle" fill="#333">
              Top Frequency Words
            </text>

            <Pie
              dataKey="value"
              isAnimationActive={true}
              data={topFrequencyWordsStop}
              cx="60%"
              cy="50%"
              outerRadius={80}
              fill="#82ca9d"
              labelLine={false}
              label={renderCustomizedLabel}
            >
              {topFrequencyWordsStop.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={TEN_COLORS[index % TEN_COLORS.length]}
                />
              ))}
            </Pie>
            <text x="60%" y="80%" dy={15} textAnchor="middle" fill="#333">
              Top Frequency Words Stop
            </text>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </CardBody>
    </Card>
  );
};

SummaryCard.propTypes = {
  summary: PropTypes.string,
  topFrequencyWords: PropTypes.array,
  topFrequencyWordsStop: PropTypes.array,
};

export default SummaryCard;
