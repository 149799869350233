import {
  GET_CORRECTIONS,
  GET_CORRECTION_FAIL,
  GET_CORRECTION_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  corrections: [],
  error: {},
  loading: false,
}

const corrections = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CORRECTIONS:
      return {
        ...state,
        corrections: action.payload,
        loading: true,
      }

    case GET_CORRECTION_SUCCESS:
      return {
        ...state,
        corrections: action.payload,
        loading: false,
      }

    case GET_CORRECTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default corrections
