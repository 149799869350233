import React from "react"
import PropTypes from "prop-types"
import SummaryCard from "components/Card/analytics-cards/summary-card"
import LoadingBar from "components/Common/loading-bar"

const SummaryTabComponent = ({ documentStatsAttributes = {}, isLoading }) => {
  const {
    summary,
    top_frequency_words: topFrequencyWords,
    top_frequency_stop_words: topFrequencyStopWords,
  } = documentStatsAttributes

  if (!summary) return null

  const formatData = wordFrequencies => {
    return wordFrequencies.map(wordCountArray => ({
      name: wordCountArray[0],
      value: wordCountArray[1],
    }))
  }

  return (
    <div className="col-lg mt-4">
      <div className="card shadow p-3 mb-5 bg-white rounded">
        <div className="card-body">
          {isLoading ? (
            <LoadingBar className="progress-loading-bar" />
          ) : (
            <SummaryCard
              summary={summary}
              topFrequencyWords={formatData(topFrequencyWords)}
              topFrequencyWordsStop={formatData(topFrequencyStopWords)}
            />
          )}
        </div>
      </div>
    </div>
  )
}

SummaryTabComponent.propTypes = {
  documentStatsAttributes: PropTypes.object,
  isLoading: PropTypes.bool,
}

export default SummaryTabComponent
