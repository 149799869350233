import {
  CREATE_CHECKOUT_SESSION,
  CREATE_CHECKOUT_SESSION_SUCCESS,
  CREATE_CHECKOUT_ERROR,
} from "./actionTypes"

export const createCheckoutSession = checkout => {
  return {
    type: CREATE_CHECKOUT_SESSION,
    payload: checkout
  }
}

export const checkoutSessionSuccess = checkout => {
  return {
    type: CREATE_CHECKOUT_SESSION_SUCCESS,
    payload: checkout,
  }
}

export const checkoutSessionFail = error => {
  return {
    type: CREATE_CHECKOUT_ERROR,
    payload: error,
  }
}
