import React from "react"
import PropTypes from "prop-types"
import StackedProgressBar from "components/progress-bar/StackedProgressBar"
import BiasScore from "./BiasScore"

const InformationProfile = ({
  complexityData,
  synthesisData,
  informativenessData,
}) => {
  return (
    <div className="information-profile mt-4">
      <div className="information-profile__section mb-4">
        <h5>Complexity</h5>
        {!complexityData ||
        Object.keys(complexityData).length === 0 ||
        complexityData.error ? (
          <p className="error-message">Score is not available currently.</p>
        ) : (
          complexityData && (
            <BiasScore biasType="complexity" data={complexityData} />
          )
        )}
      </div>

      <div className="information-profile__section mb-4">
        <h5>Synthetic Content</h5>
        {!synthesisData ||
        Object.keys(synthesisData).length === 0 ||
        synthesisData.error ? (
          <p className="error-message">Score is not available currently.</p>
        ) : (
          synthesisData && (
            <BiasScore biasType="synthesis" data={synthesisData} />
          )
        )}
      </div>
      <div className="information-profile__section mb-4">
        <h5>Informativeness</h5>
        {!informativenessData ||
        Object.keys(informativenessData).length === 0 ||
        informativenessData.error ? (
          <p className="error-message">Score is not available currently.</p>
        ) : (
          informativenessData && (
            <BiasScore biasType="informativeness" data={informativenessData} />
          )
        )}
      </div>
    </div>
  )
}

InformationProfile.propTypes = {
  complexityData: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  synthesisData: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  informativenessData: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
}
export default InformationProfile
