import React from "react"
import PropTypes from "prop-types"
import LexDiversityCard from "components/Card/chart-cards/lex-diversity-card"

function OverallLexDiversityScoresChart({ overallLexDiversity = {} }) {
  if (!overallLexDiversity) return null

  return (
    <>
      <div className="container" style={{width:"100%", height:"400px"}}>
        <LexDiversityCard
          hapaxScore={overallLexDiversity?.lexical_diversity_score?.hapax}
          ttrScore={overallLexDiversity?.lexical_diversity_score?.ttr} />
      </div>
    </>
  )
}

OverallLexDiversityScoresChart.propTypes = {
  overallLexDiversity: PropTypes.any,
}

export default OverallLexDiversityScoresChart
