import {
  REQUEST_FEEDBACK,
  REQUEST_FEEDBACK_SUCCESS,
  REQUEST_FEEDBACK_FAIL,
  GET_FEEDBACK_REQUEST,
  GET_FEEDBACK_REQUEST_SUCCESS,
  GET_FEEDBACK_REQUEST_FAIL,
  GET_FEEDBACK_REQUEST_LIST,
  GET_FEEDBACK_REQUEST_LIST_SUCCESS,
  GET_FEEDBACK_REQUEST_LIST_FAIL,
  GET_MY_FEEDBACK_REQUEST_LIST,
  GET_MY_FEEDBACK_REQUEST_LIST_SUCCESS,
  GET_MY_FEEDBACK_REQUEST_LIST_FAIL,
  GET_MY_FEEDBACK_COMMENT_LIST,
  GET_MY_FEEDBACK_COMMENT_LIST_SUCCESS,
  GET_MY_FEEDBACK_COMMENT_LIST_FAIL,
  CLOSE_FEEDBACK_REQUEST,
  CLOSE_FEEDBACK_REQUEST_SUCCESS,
  CLOSE_FEEDBACK_REQUEST_FAIL,
  SEND_FEEDBACK,
  SEND_FEEDBACK_SUCCESS,
  SEND_FEEDBACK_FAIL
} from "./actionTypes"

export const requestNewFeedback = feedbackRequest => ({
  type: REQUEST_FEEDBACK,
  payload: feedbackRequest
})

export const requestNewFeedbackSuccess = feedbackRequest => ({
  type: REQUEST_FEEDBACK_SUCCESS,
  payload: feedbackRequest,
})

export const requestNewFeedbackFail = error => ({
  type: REQUEST_FEEDBACK_FAIL,
  payload: error,
})

export const getFeedbackRequestById = feedbackReqId => ({
  type: GET_FEEDBACK_REQUEST,
  feedbackReqId,
})

export const getFeedbackRequestByIdSuccess = feedbackRequest => ({
  type: GET_FEEDBACK_REQUEST_SUCCESS,
  payload: feedbackRequest,
})

export const getFeedbackRequestByIdFail = error => ({
  type: GET_FEEDBACK_REQUEST_FAIL,
  payload: error,
})

export const getFeedbackRequestList = (page, size) => ({
  type: GET_FEEDBACK_REQUEST_LIST,
  page,
  size
})

export const getFeedbackRequestListSuccess = feedbackRequestList => ({
  type: GET_FEEDBACK_REQUEST_LIST_SUCCESS,
  payload: feedbackRequestList,
})

export const getFeedbackRequestListFail = error => ({
  type: GET_FEEDBACK_REQUEST_LIST_FAIL,
  payload: error,
})

export const getMyFeedbackRequestList = (page, size) => ({
  type: GET_MY_FEEDBACK_REQUEST_LIST,
  page,
  size
})

export const getMyFeedbackRequestListSuccess = myFeedbackRequestList => ({
  type: GET_MY_FEEDBACK_REQUEST_LIST_SUCCESS,
  payload: myFeedbackRequestList,
})

export const getMyFeedbackRequestListFail = error => ({
  type: GET_MY_FEEDBACK_REQUEST_LIST_FAIL,
  payload: error,
})

export const getMyFeedbackCommentList = (page, size) => ({
  type: GET_MY_FEEDBACK_COMMENT_LIST,
  page,
  size
})

export const getMyFeedbackCommentListSuccess = myFeedbackCommentList => ({
  type: GET_MY_FEEDBACK_COMMENT_LIST_SUCCESS,
  payload: myFeedbackCommentList,
})

export const getMyFeedbackCommentListFail = error => ({
  type: GET_MY_FEEDBACK_COMMENT_LIST_FAIL,
  payload: error,
})

export const closeFeedbackRequestById = feedbackReqId => ({
  type: CLOSE_FEEDBACK_REQUEST,
  feedbackReqId,
})

export const closeFeedbackRequestByIdSuccess = feedbackRequest => ({
  type: CLOSE_FEEDBACK_REQUEST_SUCCESS,
  payload: feedbackRequest,
})

export const closeFeedbackRequestByIdFail = error => ({
  type: CLOSE_FEEDBACK_REQUEST_FAIL,
  payload: error,
})

export const sendFeedback = (feedbackReqId, feedbackComment) => ({
  type: SEND_FEEDBACK,
  feedbackReqId,
  payload: feedbackComment
})

export const sendFeedbackSuccess = feedback => ({
  type: SEND_FEEDBACK_SUCCESS,
  payload: feedback,
})

export const sendFeedbackFail = error => ({
  type: SEND_FEEDBACK_FAIL,
  payload: error,
})