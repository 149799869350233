import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "./actionTypes";
import * as actions from "./actions";
import { postSignup } from "helpers/backend_helper";
import { getUserInfo } from "store/user/actions";

/* function* signupSaga(action) {
  try {
    const response = yield call(signupUser, action.payload)
    yield put(actions.signupSuccess(response.data))
  } catch (error) {
    yield put(actions.signupFailure(error))
  }
} */
function* signupSaga(action) {
  try {
    const response = yield call(postSignup, action.payload);
    yield put(actions.signupSuccess(response.data));
    yield put(getUserInfo());
  } catch (error) {
    yield put(actions.signupFailure(error));
  }
}

export default function* watchSignup() {
  yield takeLatest(actionTypes.SIGNUP_REQUEST, signupSaga);
}
