import React from "react"
import PropTypes from "prop-types"
import ReadabilityCard from "components/Card/chart-cards/readability-card"

import {
  READABILITY_COLOR,
  READING_TIME_COLOR,
  READING_EASE_COLOR,
  FOREIGN_READER_READING_EASE_COLOR,
} from "utils"
import { Row, Col } from "reactstrap"

export function OverallReadabilityScoresChart({
  overallReadabilityStats = {},
}) {
  if (!overallReadabilityStats) return null

  const readabilityMetrics = [
    {
      title: "Reading Time",
      color: READING_TIME_COLOR,
      value: overallReadabilityStats?.readability_score?.reading_time,
    },
    {
      title: "Readability Score",
      color: READABILITY_COLOR,
      value: overallReadabilityStats?.readability_score?.readability_score,
    },
    {
      title: "Reading ease score",
      color: READING_EASE_COLOR,
      value: overallReadabilityStats?.readability_score?.reading_ease,
    },
    {
      title: "Foreign reader reading ease score",
      color: FOREIGN_READER_READING_EASE_COLOR,
      value:
        overallReadabilityStats?.readability_score?.foreign_reader_ease_score,
    },
  ]

  return (
    <div className="container" style={{ width: "100%", height: "400px" }}>
      <Row>
        {readabilityMetrics.map((metric, index) => (
          <Col
            md={4}
            xs={12}
            key={index}
            style={{ marginBottom: index % 2 === 1 ? "30px" : "0px" }}
          >
            <ReadabilityCard
              title={metric.title}
              color={metric.color}
              value={metric.value}
            />
          </Col>
        ))}
      </Row>
    </div>
  )
}

OverallReadabilityScoresChart.propTypes = {
  overallReadabilityStats: PropTypes.any,
}
