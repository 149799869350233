import ActEditor from "components/Act/ActEditor"
import PropTypes from "prop-types"
import Input from "components/Input"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { Form, FormGroup, Label, Button, Container, Row, Col } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { addScreenplay, getScreenplay, updateScreenplay } from "store/actions"
import { useParams, withRouter } from "react-router-dom"
import { useEffect } from "react"
import { FaPlus, FaMinus } from "react-icons/fa"

const ScreenplayEditor = props => {
  const { edit, id } = useParams()
  const dispatch = useDispatch()
  const { selectedScreenplay } = useSelector(state => state.screenplayReducer)

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      screenplayTitle: "",
      screenplayContent: "",
      screenplayTitleInfo: "",
    },
  })

  const [acts, setActs] = useState([])

  useEffect(() => {
    if (edit && id) {
      dispatch(getScreenplay(id))
    }
  }, [edit, id])

  useEffect(() => {
    if (edit && id) {
      dispatch(getScreenplay(id))
    }
  }, [])

  useEffect(() => {
    if (edit && id) {
      reset({
        screenplayTitle: selectedScreenplay?.title,
        screenplayContent: selectedScreenplay?.content,
        screenplayTitleInfo: "",
      })
    }
  }, [reset, edit && id])

  const handleAddAct = () => {
    setActs([
      ...acts,
      { id: acts.length + 1, title: "", tags: [], description: "", scenes: [] },
    ])
  }

  const onAddSubmit = data => {
    const formData = new FormData()
    formData.append("screenplay_title", data.screenplayTitle)
    formData.append("screenplay_content", data.screenplayContent)
    formData.append("screenplay_title_info", data.screenplayTitleInfo)
    dispatch(addScreenplay(formData, props.history))
  }

  const onUpdateSubmit = data => {
    const formData = new FormData()
    formData.append("screenplay_title", data.screenplayTitle)
    formData.append("screenplay_content", data.screenplayContent)
    formData.append("screenplay_title_info", data.screenplayTitleInfo)
    formData.append("screenplay_id", id)
    dispatch(updateScreenplay(formData, props.history))
  }

  const [keyValuePairs, setKeyValuePairs] = useState([{ index: 1, key: "", value: "" }])
  const [keyValuePairsIndx, setKeyValuePairsIdx] = useState(0)

  const handleKeyValueChange = (e, index) => {
    const { name, value } = e.target
    const newKeyValuePairs = [...keyValuePairs]
    newKeyValuePairs[index][name] = value
    setKeyValuePairs(newKeyValuePairs)
  }

  const handleAddKeyValuePair = () => {
    setKeyValuePairs([...keyValuePairs, { index: keyValuePairsIndx, key: "", value: "" }])
    setKeyValuePairsIdx(keyValuePairsIndx + 1)
  }
  const handleRemoveKeyValuePair = (idx) => {
    setKeyValuePairs(keyValuePairs.filter((keyValue) => keyValue.index !== idx))
  }

  console.log("keyValuePairs", keyValuePairs)

  return (
    <Container>
      <Form>
        <FormGroup>
          <Label for="screenplayTitle">Screenplay Title</Label>
          <Input
            type="text"
            id="screenplayTitle"
            placeholder="Enter the screenplay title"
            name="screenplayTitle"
            //{...register("screenplayTitle", { required: true, minLength: 5 })}
            {...register("screenplayTitle", { required: true })}
          />
          {errors.screenplayTitle && (
            <p>This field is required and must be at least 5 characters long</p>
          )}
        </FormGroup>
        <FormGroup>
          <Label for="screenplayContent">Screenplay Content</Label>
          <Input
            type="textarea"
            id="screenplayContent"
            placeholder="Enter the screenplay content"
            name="screenplayContent"
            {...register("screenplayContent", { required: true })}
          />
          {errors.screenplayContent && <p>This field is required</p>}
        </FormGroup>
        <FormGroup>
          <Label for="screenplayTitleInfo">Screenplay Title Info</Label>
          <Input
            type="text"
            id="screenplayTitleInfo"
            placeholder="Enter the screenplay title info"
            name="screenplayTitleInfo"
            {...register("screenplayTitleInfo")}
          />

          {keyValuePairs.map((keyValuePair, index) => (
            <div className="screenplay-info" key={index}>
              <Input
                type="text"
                id="screenplayTitleInfoKey"
                placeholder="Enter the screenplay title key info"
                name="screenplayTitleInfoKey"
                {...register("screenplayTitleInfoKey")}
              />

              <Input
                type="text"
                id="screenplayTitleInfoValue"
                placeholder="Enter the screenplay title Value info"
                name="screenplayTitleInfoValue"
                {...register("screenplayTitleInfoValue")}
              />
              <div onClick={() => handleRemoveKeyValuePair(index)}>
              <FaMinus />
              <span>-</span>
            </div>
            </div>
          ))}
          <div>
            <div onClick={handleAddKeyValuePair}>
              <FaPlus />
              <span>Add Key-Value</span>
            </div>
          </div>
        </FormGroup>
        {edit && id ? (
          <Button onClick={handleSubmit(onUpdateSubmit)} color="success">
            Update Screenplay
          </Button>
        ) : (
          <Button onClick={handleSubmit(onAddSubmit)} color="success">
            Create New Screenplay
          </Button>
        )}
      </Form>
      <Row>
        {acts.map(act => (
          <Col sm="6" key={act.id}>
            <ActEditor act={act} />
          </Col>
        ))}
      </Row>
    </Container>
  )
}

ScreenplayEditor.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}
export default withRouter(ScreenplayEditor)
