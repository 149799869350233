import axios from "axios"
import {
  del,
  get,
  post,
  put,
  getImage,
  getApi,
  getImageProtfolio,
} from "./api_helper"
import { axiosApi } from "./api_helper"
import * as url from "./url_helper"
import {
  API_BASE_IDEA, API_BASE_PORTFOLIO, API_BASE_PROJECT,
  CLOSE_FEEDBACK_REQUEST,
  COMMENT_ON_FEEDBACK_REQUEST,
  HOME, LIST_IDEA,
} from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}
export const isUserSubscribed = async () => {
  let userInfo
  await axiosApi.get(url.GET_USER_INFO).then(user => {
    userInfo = user
  })

  return userInfo
}
//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postFakeLogin = data => post(url.POST_FAKE_LOGIN, data)

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data)

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data)

// Register Method
/* const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
} */

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data)

// get Events
export const getEvents = () => get(url.GET_EVENTS)

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event)

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event)

// delete Event
export const deleteEvent = event =>
  del(url.DELETE_EVENT, { headers: { event } })

// get Categories
export const getCategories = () => get(url.GET_CATEGORIES)

export const getUserProfile = () => get(url.GET_USER_PROFILE)

const getTopics = url => get(url)

const getToken = () => post(url.GET_TOKEN)

const getTokens = data => post(url.GET_TOKEN, data)

let config = {
  withCredentials: true,
}
// Login Method
const postJwtTokenLogin = data => post(url.GET_TOKEN, data)

// Login Method
//const postJwtLogin = data => post(url.GET_TOKEN, data)
// Register Method
const postJwtLogin = async data => {
  return await axiosToken
    .post(url.GET_TOKEN, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

/* const postJwtTokenLogin = async (data) => {
  return await axios({
    method: "post",
    data: data,
    headers: { "Content-Type": "multipart/form-data" },
  })
} */
// Register Method
const postJwtRegister = async data => {
  return await axiosApi
    .post(url.SIGNUP, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}
export const postSignup = data => post(url.SIGNUP, data)

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data)

/** IDEAS */

// get idea
export const fetchIdeas = () => get(url.LIST_IDEA)

// get idea details
export const getIdeasDetails = id => get(`${url.API_BASE_IDEA}/${id}`)

// add idea
export const addNewIdea = idea => post(url.API_BASE_IDEA, idea)

// update idea
export const updateIdea = (id, idea) => put(`${url.API_BASE_IDEA}/${id}`, idea)

// delete idea
export const deleteIdea = data => post(url.DELETE_IDEA, data)

/** HOME */
export const homeData = () => get(url.HOME)

/** FEEDBACKS */
export const feedbackRequestById = () =>
  get(`${url.GET_FEEDBACK_REQUEST_BY_ID}/${id}`, { params: { id } })
export const feedbackRequestList = () => get(url.GET_FEEDBACK_REQUEST_LIST)
export const myFeedbackRequestList = () => get(url.GET_MY_FEEDBACK_REQUEST_LIST)
export const myFeedbackList = () => get(url.GET_MY_FEEDBACK_LIST)
export const requestFeedback = (data) => post(url.REQUEST_FEEDBACK, data)
export const closeFeedbackRequest = id =>
  put(`${url.CLOSE_FEEDBACK_REQUEST}/${id}`, { params: { id } })
export const commentOnFeedbackRequest = comments =>
  post(`${url.COMMENT_ON_FEEDBACK_REQUEST}/${id}`, { params: { id } }, comments)

/** PROJECT */

// get project
export const getProjects = () => get(url.GET_PROJECTS)

// add user
export const addNewProject = project => post(url.API_BASE_PROJECT, project)

// add user
export const createProject = (id, project) =>
  post(`${url.API_BASE_PROJECT}/${id}`, project)

// update user
export const updateProject = (id, project) =>
  put(`${url.API_BASE_PROJECT}/${id}`, project)

// delete user
export const deleteProject = id => del(`${url.API_BASE_PROJECT}/${id}`)

// get project details
export const getProjectsDetails = id => get(`${url.API_BASE_PROJECT}/${id}`)

// get project summary
export const getProjectSummary = content =>
  post(url.GET_PROJECT_SUMMARY, content)
//export const getProjectSummary = project => postSocialLogin(url.GET_PROJECT_SUMMARY, project)

// get project types
export const getProjectTypes = () => get(url.GET_PROJECT_TYPES)

//get project categories
export const getProjectCategories = () => get(url.GET_PROJECT_CATEGORIES)

getProjectTypes
getProjectCategories

/** SCORE */
// get Score

//export const fetchScores = score => get(url.GET_SCORE, score)
export const fetchScores = content => post(url.GET_SCORE, content)
export const fetchScoresByPara = content => post(url.GET_SCORE_BY_PARA, content)

// update Subscription
export const updateSubscription = plan => post(url.UPDATE_SUBSCRIPTION, plan)
//Get subscription Plans
export const getSubscriptionPlan = () => get(url.GET_SUBSCRIPTION_PLANS)

/** USERS FEED */
// get contacts
export const getUsers = () => get(url.GET_USERS_FEED)

// add user
export const addNewUser = user => post(url.ADD_NEW_USER, user)

// update user
export const updateUser = user => put(url.UPDATE_USER, user)

// delete user
export const deleteUser = user => del(url.DELETE_USER, { headers: { user } })

// get user photo
export const getUserPhoto = () => getImage(url.GET_USER_PHOTO)

/** PORTFOLIO */

// get portfolios
export const getPortfolios = () => get(url.PORTFOLIO_LIST)

// add porfolio entry
export const addNewPortfolio = portfolio =>
  post(url.API_BASE_PORTFOLIO, portfolio)

// update porfolio entry
export const updatePortfolio = (id, portfolio) =>
  put(`${url.API_BASE_PORTFOLIO}/${id}`, portfolio)

// delete porfolio entry
export const deletePortfolio = data => post(url.DELETE_PORTFOLIO, data)

// get porfolio entry details
export const getPortfoliosDetails = id => get(`${url.API_BASE_PORTFOLIO}/${id}`)

export const portfolioImage = id =>
  getImage(`${url.GET_PROTFOLIO_MAGE_API}/${id}`)

/** CORRECTIONS */
export const fetchCorrections = content => post(url.GET_CORRECTIONS, content)

/** ENTITIES */
export const fetchEntities = content => post(url.GET_ENTITIES, content)

/** TOPICS */

export const fetchTopics = content => post(url.GET_TOPICS, content)

/** GET ACCESS TWITTER */
export const postAccessTwitter = () => get(url.GET_TWITTER_AUTHORIZATION_URL)
export const getTwitterGetMe = () => get(url.GET_TWITTER_GET_ME)
export const getTwitterFollowersCount = () => get(url.GET_FOLLOWER_COUNT)

/** GET ACCESS GOOGLE */
export const postAccessGoogle = () => get(url.GET_GOOGLE_AUTHORIZATION_URL)

/** CHECKOUT SESSIONS */
export const postCheckoutSession = checkout =>
  post(url.CREATE_CHECKOUT_SESSION, checkout)

/** PARAPHRASE */
export const fetchParaphrase = content => post(url.GET_PARAPHRASES, content)

/** GENERATE TEXT */
export const fetchGenerateText = content => post(url.GET_GENERATE_TEXT, content)

/** GET USER INFO */
export const getUserInfo = () => get(url.GET_USER_INFO)
export const updateUserProfile = data => put(url.UPDATE_USER_PROFILE, data)

/** CONNECT MEDIUM */
export const postAccessMedium = data => post(url.CONNECT_MEDIUM, data)

/** CREATE POST MEDIUM */
export const createMediumPost = data => post(url.CREATE_POST_MEDIUM, data)

/** ADD CONTENT TO PROJECT */
export const addContentToProject = (projectId, data) =>
    post(`${url.API_BASE_PROJECT}/${projectId}`, data)

/** UPDATE CONTENT BY ID */
export const updateContentById = (projectId, contentId, data) =>
    put(`${url.API_BASE_PROJECT}/${projectId}/${contentId}`, data)

/** GET PROJECT */
export const getContentByProjectId = projectId =>
    get(`${url.API_BASE_PROJECT}/${projectId}`)

/** GET CONTENT STATS */
export const getContentStats = (projectId, contentId) =>
    get(`${url.GET_DOCUMENT_STATS}${projectId}/${contentId}`)

/* GET USER SHOWCASE */
export const getUserShowcase = userId =>
  get(`${url.GET_USER_SHOWCASE}${userId}`)

/** GET CONTENT BY ID */
export const getContentById = (projectId, contentId) =>
    get(`${url.API_BASE_PROJECT}/${projectId}/${contentId}`)

/** DELETE CONTENT BY ID */
export const deleteContentById = (projectId, contentId) =>
    del(`${url.API_BASE_PROJECT}/${projectId}/${contentId}`)

/** GET SETUP INTENT */
export const getSetupIntent = () => get(url.GET_SETUP_INTENT)

/** POST SUBSCRIPTION PLAN */
export const postSubscriptionPortal = () => post(url.POST_SUBSCRIPTION_PORTAL)

/** UPLOAD PHOTO */
export const postUserPhoto = data => post(url.POST_USER_PHOTO, data)

/** GET RESPONSE LOCATION HEADER PORTAL */
//export const getPortal = (url) => get(url)

export const addScreenplayApi = data => post(url.POST_SCREENPLAY, data)
export const updateScreenplayApi = data => put(url.UPDATE_SCREENPLAY, data)
export const getScreenplayApi = id =>
  get(`${url.GET_SCREENPLAY}?screenplay_id=${id}`)
export const listScreenplaysApi = () => get(url.LSIT_SCREENPLAYS)
export const deleteScreenplaysApi = data => del(url.DELETE_SCREENPLAY, data)

export const addActApi = data => post(url.ADD_ACT_SCREENPLAY, data)
export const reorderActsApi = data => post(url.REORDER_ACT_SCREENPLAY, data)
export const deleteActApi = data => del(url.DELETE_ACT_SCREENPLAY, data)

export const addSceneApi = data => post(url.ADD_SCENE_ACT, data)
export const reorderScenesApi = data => post(url.REORDER_SCENE_ACT, data)
export const deleteSceneApi = data => del(url.DELETE_SCENE_ACT, data)

export const getUserSubscription = () => get(url.GET_USER_SUBSCRIPTION_INFO)

export const getEmotionScore = data => post(url.GET_EMOTION_SCORE, data)
export const getBiasScore = data => post(url.GET_BIAS_SCORE, data)
export const getCognitiveBiasScroe = data =>
  post(url.GET_COGNITIVE_BIAS_SCORE, data)
export const getPoliticalBiasScore = data =>
  post(url.GET_POLITICAL_BIAS_SCORE, data)
export const getFakenessScore = data => post(url.GET_FAKENESS_SCORE, data)
export const getHeadLineScore = data => post(url.GET_HEADLINE_SCORE, data)


export const getEmpathyScore = data => post(url.GET_EMPATHY_SCORE, data)
export const getEngagementScore = data => post(url.GET_ENGAGEMENT_SCORE, data)
export const getSentimentScore = data => post(url.GET_SENTIMENT_SCORE, data)
export const getHateSpeechScore = data => post(url.GET_HATE_SPEECH_SCORE, data)
export const getRacismSexismScore = data => post(url.GET_RACISM_SEXISM_SCORE, data)
export const getToxicityScore = data => post(url.GET_TOXICITY_SCORE, data)
export const getPatronizeScore = data => post(url.GET_PATRONIZE_SCORE, data)
export const getSubjectiveBiasScore = data => post(url.GET_SUBJECTIVE_BIAS_SCORE, data)
export const getGenderBiasScore = data => post(url.GET_GENDER_BIAS_SCORE, data)
export const getReportBiasScore = data => post(url.GET_REPORT_BIAS_SCORE, data)

export const getComplexityScore = data => post(url.GET_COMPLEXITY_SCORE, data)
export const getInformativenessScore = data => post(url.GET_INFORMATIVENESS_SCORE, data)

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
  getTopics,
  getToken,
  getTokens,
  postJwtTokenLogin,
  //postJwtTokenRegister,
}
