import { useEffect, useState } from "react"

const useBlobUrl = imageUrl => {
  const [blobUrl, setBlobUrl] = useState("")

  useEffect(() => {
    let url = sessionStorage.getItem(imageUrl)

    async function fetchData() {
      sessionStorage.removeItem(imageUrl)
      try {
        if (!url) {
          url = URL.createObjectURL(imageUrl)
          sessionStorage.setItem(imageUrl, url)
        }
        setBlobUrl(url)
      } catch (error) {
        console.log(error)
      }
    }

    fetchData()
  }, [imageUrl])

  return blobUrl
}

export default useBlobUrl
