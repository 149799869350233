import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//Calendar
import calendar from "./calendar/reducer"

//projects
import projects from "./projects/reducer"

//contacts
import users from "./contacts/reducer"

//Home
import home from "./home/reducer"

// Ideas
import ideas from "./ideas/reducer"

// Ideas
import feedbackHub from "./feedback/reducer"

// Ideas
import scores from "./score/reducer"

// Subscription
import subscription from "./subscription/reducer"

// Corrections
import corrections from "./corrections/reducer"

// Entities
import entities from "./entities/reducer"

// Topics
import topics from "./topics/reducer"

// Portfolio
import portfolios from "./portfolio/reducer"

// Twitter
import twitter from "./auth_twitter/reducer"

// Google
import google from "./auth_google/reducer"

// checkout
import checkout from "./checkout/reducer"

// Paraphrase
import paraphrase from "./paraphrase/reducer"

// Generate Text
import generate from "./generate/reducer"

// user
import user from "./user/reducer"

// medium
import medium from "./auth_medium/reducer"

// Screenplay
import screenplayReducer from "./screenplay/reducer"

// act
import actReducer from "./act/reducer"

// scene
import sceneReducer from "./scene/reducer"

// verify subscription
import userSubscription from "./user-subscription/reducer"

// Scores
import scoresReducer from "./scores/reducer"

// sign up
import signUpReducer from "./signup/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  calendar,
  projects,
  users,
  home,
  feedbackHub,
  ideas,
  scores,
  subscription,
  corrections,
  entities,
  topics,
  portfolios,
  twitter,
  google,
  checkout,
  paraphrase,
  generate,
  user,
  medium,
  screenplayReducer,
  actReducer,
  sceneReducer,
  userSubscription,
  scoresReducer,
  signUpReducer
})

export default rootReducer
