import React from "react"
import PropTypes from "prop-types"
import TabItemComponent from "./tab-items"
import { Col, Row, TabContent, TabPane } from "reactstrap"
import { OverallTextStatsChart } from "components/Charts/overall/OverallTextStatsChart"
import { ParagraphTextStatsChart } from "components/Charts/paragraph/ParagraphStatsChart"
import { LineTextStatsChart } from "components/Charts/line/LineTextStatsChart"
import LoadingBar from "components/Common/loading-bar"

const TextStatsTabComponent = ({
  active,
  setActive,
  dataTextStats = {},
  isLoading = false,
  tabItems, // assume you pass tabItems as props
}) => {
  const {
    line_stats: lineLevelAnalytics,
    overall_stats: overallAnalytics,
    paragraph_stats: paragraphLevelAnalytics,
  } = dataTextStats

  const formatTabName = baseName => {
    if (baseName === "Overall") return "Overall analytics"
    if (baseName === "Paragraph") return "Paragraph level analytics"
    if (baseName === "Line") return "Line level analytics"
    return baseName
  }

  return (
    <>
      <div className="tabs">
        {tabItems.map(({ id, title }) => (
          <TabItemComponent
            key={id}
            title={formatTabName(title)} 
            onItemClicked={() => setActive(id)}
            isActive={active === id}
          />
        ))}
      </div>
      {isLoading ? (
        <LoadingBar />
      ) : (
        <div className="mt-4">
          {tabItems.map(({ id }) => {
            if (active === id && id === 1) {
              return (
                <div key={id}>
                  <Row>
                    <Col className="col-10">
                      <OverallTextStatsChart
                        overallTextStats={overallAnalytics}
                      />
                    </Col>
                  </Row>
                </div>
              )
            } else if (active === id && id === 2) {
              return (
                <div key={id}>
                  <Row>
                    <Col className="col-9">
                      <ParagraphTextStatsChart
                        paragraphTextStats={paragraphLevelAnalytics}
                      />
                    </Col>
                  </Row>
                </div>
              )
            } else if (active === id && id === 3) {
              return (
                <div key={id}>
                  <Row>
                    <Col className="col-9">
                      <LineTextStatsChart lineTextStats={lineLevelAnalytics} />
                    </Col>
                  </Row>
                </div>
              )
            } else {
              return null
            }
          })}
        </div>
      )}
    </>
  )
}

TextStatsTabComponent.propTypes = {
  active: PropTypes.any,
  setActive: PropTypes.func,
  dataTextStats: PropTypes.any,
  isLoading: PropTypes.any,
  tabItems: PropTypes.array, // add this propType
}

export default TextStatsTabComponent
