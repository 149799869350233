import React, { useState, useEffect, useMemo, useRef, useCallback } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import useComposer from "hooks/useComposer"
import { addConentProject } from "store/actions"
import { convertToRaw } from "draft-js"
import { CardTitle } from "reactstrap"
import CEditor from "pages/Composer/MyEditor/Editor"

const DefaultModeComposer = ({ project }) => {
  const dispatch = useDispatch()

  const composer = useComposer()
  const {
    documentTitle,
    editorState,
    hasContent,
    onEditorStateChange,
    handleTitleChange,
  } = composer

  const handleSave = useCallback(() => {
    const rawContent = convertToRaw(editorState.getCurrentContent())
    let htmlContent = ""

    rawContent.blocks.forEach(block => {
      htmlContent += "<p>" + block.text + "</p>"
    })

    htmlContent = htmlContent.substring(3, htmlContent.length - 4)

    const formData = new FormData()
    formData.append("content", htmlContent)
    formData.append("title", documentTitle)
    dispatch(addConentProject(project, formData, props.history))
  }, [dispatch, editorState, documentTitle, project])

  return (
    <React.Fragment>
      <div style={{ flex: 1 }}>
        <CardTitle style={{ position: "relative", textAlign: "start" }}>
          <input
            className="document-title"
            type="text"
            value={documentTitle}
            onChange={handleTitleChange}
            onFocus={e => (e.target.style.backgroundColor = "#f0f0f0")}
            onBlur={e => (e.target.style.backgroundColor = "#ffffff")}
            placeholder="Untitled document"
          />
        </CardTitle>
        {editorState && (
          <CEditor
            onEditorStateChange={onEditorStateChange}
            editorState={editorState}
            hasContent={hasContent}
          />
        )}

        <div className="buttons-composer">
          <div>
            <Link
              to={`/project/documents/list/${project}`}
              style={{ marginRight: "12px" }}
            >
              <button
                className="btn btn-composer composer__project"
                style={{ backgroundColor: "#eee" }}
              >
                <span style={{ fontSize: "20px" }}>Back</span>
              </button>
            </Link>
            <button
              className="btn btn-composer composer__project"
              onClick={handleSave}
              //disabled={!hasContent ? false : true}
            >
              <span style={{ fontSize: "20px" }}> Save</span>
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

DefaultModeComposer.propTypes = {
  project: PropTypes.string,
  content: PropTypes.string,
  history: PropTypes.object,
}

export default DefaultModeComposer
