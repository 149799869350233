import {
  ADD_SCREENPLAY,
  ADD_SCREENPLAY_SUCCESS,
  ADD_SCREENPLAY_FAIL,
  UPDATE_SCREENPLAY,
  UPDATE_SCREENPLAY_SUCCESS,
  UPDATE_SCREENPLAY_FAIL,
  GET_SCREENPLAY,
  GET_SCREENPLAY_SUCCESS,
  GET_SCREENPLAY_FAIL,
  LIST_SCREENPLAYS,
  LIST_SCREENPLAYS_SUCCESS,
  LIST_SCREENPLAYS_FAIL,
  DELETE_SCREENPLAY,
  DELETE_SCREENPLAY_SUCCESS,
  DELETE_SCREENPLAY_FAIL,
} from "./actionTypes"

const initialState = {
  screenplays: [],
  selectedScreenplay: {},
  loading: false,
  error: null,
}

const screenplayReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SCREENPLAY:
    case UPDATE_SCREENPLAY:
    case GET_SCREENPLAY:
    case LIST_SCREENPLAYS:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case ADD_SCREENPLAY_SUCCESS:
      return {
        ...state,
        screenplays: [...state.screenplays, action.payload],
        loading: false,
      }
    case UPDATE_SCREENPLAY_SUCCESS:
      return {
        ...state,
        screenplays: [...state.screenplays.items.map(screenplay => {
          if (screenplay.id === action.payload.screenplay.id) {
            return action.payload.screenplay
          }
          return screenplay
        })],
        loading: false,
      }
    case GET_SCREENPLAY_SUCCESS:
      return {
        ...state,
        selectedScreenplay: action.payload.screenplay,
        loading: false,
      }
    case LIST_SCREENPLAYS_SUCCESS:
      return {
        ...state,
        screenplays: action.payload.screenplays,
        loading: false,
      }
    case ADD_SCREENPLAY_FAIL:
    case UPDATE_SCREENPLAY_FAIL:
    case GET_SCREENPLAY_FAIL:
    case LIST_SCREENPLAYS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    case DELETE_SCREENPLAY:
      return {
        ...state,
        isLoading: true,
      }
    case DELETE_SCREENPLAY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        screenplays: [
          ...state.screenplays.items.filter(script => script.id !== action.payload),
        ],
      }
    case DELETE_SCREENPLAY_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default screenplayReducer
