import {
  GET_TOPIC_FAIL,
  GET_TOPIC_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  topics: [],
  error: {},
  loading: false,
}

const topics = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TOPIC_SUCCESS:
      return {
        ...state,
        topics: action.payload,
        loading: true,
      }

    case GET_TOPIC_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default topics
