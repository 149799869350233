import React, { useState } from "react"
import PropTypes from "prop-types"
import { Collapse } from "reactstrap"
import MainTabComponent from "components/Tabs/main-tabs"
import SummaryTabComponent from "components/Tabs/summary-tab"
import TextStatsTabComponent from "components/Tabs/tab-text-stats"
import ReadabilityTabComponent from "components/Tabs/tab-readability"
import LexDiversityTabComponent from "components/Tabs/tab-lex-diversity"
import EmotionTabComponent from "components/Tabs/tab-emotion"

const TabContentComponent = ({
  attributesRender,
  statsContentRender,
  isLoading,
  emotionData,
  collapse,
}) => {
  const [activeMain, setActiveMain] = useState(1)
  const [activeLex, setActiveLex] = useState(2)
  const [active, setActive] = useState(3)

  const mainTabItems = [
    {
      id: 1,
      title: "Overview",
    },
    {
      id: 2,
      title: "Text Stats",
    },
    {
      id: 3,
      title: "Readability",
    },
    {
      id: 4,
      title: "Signature",
    },
    {
      id: 5,
      title: "Emotions",
    },
  ]

  const tabItems = [
    {
      id: 1,
      title: "Overall",
    },
    {
      id: 2,
      title: "Paragraph",
    },
    {
      id: 3,
      title: "Line",
    },
  ]

  return (
    <Collapse isOpen={collapse}>
      <div className="mb-4 row mt-3">
        <div className="mb-4 row mt-4">
          <div className="col-lg-10 mb-2">
            <div className="tabs-main">
              {mainTabItems.map(({ id, title }) => (
                <MainTabComponent
                  key={title}
                  title={title}
                  onMainItemClicked={() => setActiveMain(id)}
                  isActive={activeMain === id}
                />
              ))}
            </div>
            {activeMain === 1 && (
              <SummaryTabComponent
                contentProject={attributesRender}
                isLoading={isLoading}
              />
            )}
            {activeMain === 2 && (
              <TextStatsTabComponent
                active={active}
                setActive={setActive}
                dataTextStats={statsContentRender}
                isLoading={isLoading}
              />
            )}
            {activeMain === 3 && (
              <ReadabilityTabComponent
                active={active}
                setActive={setActive}
                tabItems={tabItems}
                dataReadability={statsContentRender}
                isLoading={isLoading}
              />
            )}
            {activeMain === 4 && (
              <LexDiversityTabComponent
                active={activeLex}
                setActive={setActiveLex}
                tabItems={tabItems}
                dataLexDiversity={statsContentRender}
                isLoading={isLoading}
              />
            )}
            {activeMain === 5 && (
              <EmotionTabComponent
                active={active}
                setActive={setActive}
                tabItems={tabItems}
                dataEmotion={emotionData}
                isLoading={isLoading}
              />
            )}
          </div>
        </div>
      </div>
    </Collapse>
  )
}

TabContentComponent.propTypes = {
  attributesRender: PropTypes.any,
  statsContentRender: PropTypes.any,
  isLoading: PropTypes.any,
  emotionData: PropTypes.any,
  collapse: PropTypes.bool
}

export default TabContentComponent
