import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { getPortfolios, getUserSusbcriptionInfo } from "../../store/actions"
import { getLoggedInUser } from "../../helpers/backend_helper"
import mixpanel from "mixpanel-browser"
import Plans from "components/Plans"

const Plan = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPortfolios())
    const loggedInUser = getLoggedInUser()
    if (loggedInUser !== null) {
      mixpanel.track("portfolio_dashboard", {
        event_properties: {
          user_id: loggedInUser.uid,
        },
      })
    } else {
      mixpanel.track("portfolio_dashboard")
    }
  }, [])

  const items = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Portfolio", link: "/portfolio" },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Plans | QuillNow
          </title>
        </MetaTags>
        <Container fluid>
          <div className="row">
            <Plans />
          </div>                    
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Plan
