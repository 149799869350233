import { takeEvery, put, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  GET_ACCESS_TWITTER,
  GET_TWITTER_GET_ME,
  GET_TWITTER_FOLLOWERS_COUNT,
} from "./actionTypes"

import {
  getAccessTwitterSuccess,
  getApiTwitterError,
  getTwitterGetMeSuccess,
  getTwitterGetMeError,
  getTwitterFollowersCountSuccess,
  getTwitterFollowersCountError,
} from "./actions"

//Include Both Helper File with needed methods
import {
  postAccessTwitter,
  getTwitterGetMe,
  getTwitterFollowersCount,
} from "../../helpers/backend_helper"

function* getAccessTwitter() {
  try {
    const response = yield call(postAccessTwitter)
    yield put(getAccessTwitterSuccess(response))
    window.location.href = response
  } catch (error) {
    yield put(getApiTwitterError(error))
  }
}

function* fetchTwitterGetMe() {
  try {
    const response = yield call(getTwitterGetMe)
      yield put(getTwitterGetMeSuccess(response))
  } catch (error) {
    yield put(getTwitterGetMeError(error))
  }
}

function* fetchTwitterFollowersCount() {
  try {
    const response = yield call(getTwitterFollowersCount)
      yield put(getTwitterFollowersCountSuccess(response))
  } catch (error) {
    yield put(getTwitterFollowersCountError(error))
  }
}

function* authTwitterSaga() {
  yield takeEvery(GET_ACCESS_TWITTER, getAccessTwitter)
  yield takeEvery(GET_TWITTER_GET_ME, fetchTwitterGetMe)
  yield takeEvery(GET_TWITTER_FOLLOWERS_COUNT, fetchTwitterFollowersCount)
}

export default authTwitterSaga
