import PropTypes from "prop-types"
import CustomChart from "components/Charts/CustomChart"
import { Timeline, TimelineEvent } from "react-event-timeline"
import React, { Component, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import {
  getHomeData,
  getUserInfo,
  getUserPhoto,
  signUpUser,
  getUserSusbcriptionInfo,
} from "../../store/actions"
import { CardContent } from "@mui/material"
//Include Both Helper File with needed methods
import mixpanel from "mixpanel-browser"
import { getLoggedInUser } from "helpers/backend_helper"
import { loadStripe } from "@stripe/stripe-js"
import { withRouter } from "react-router-dom"
import { withSignup } from "hooks/withSignup"
const Home = props => {
  const dispatch = useDispatch()
  const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh")
  const { homeData } = useSelector(state => state.home)
  const { user, loading } = useSelector(state => state.user)
  //const { subscribe } = useSelector(state => state.userSubscription)

  //console.log("subscribe", subscribe)

  const homeRef = React.useRef(true)
  React.useEffect(() => {
    const loggedInUser = getLoggedInUser()
    if (loggedInUser !== null) {
      mixpanel.track("home_page", {
        event_properties: {
          user_id: loggedInUser.uid,
        },
      })
    } else {
      mixpanel.track("home_page")
    }
    //if (!user.subscription) return dispatch(getUserInfo())
    dispatch(getUserInfo())
    dispatch(getUserPhoto())
    //dispatch(getUserSusbcriptionInfo())
    localStorage.getItem("userInfo")
  }, [])

  useEffect(() => {
    if (homeRef) {
      dispatch(getHomeData())
    }
  }, [homeRef])

  /* const handleSignup = () => {
    const signUpUserForm = new FormData()
    const email = JSON.parse(localStorage.getItem("emailUser"))
    signUpUserForm.append("email", email)
    dispatch(signUpUser(signUpUserForm, props.history))
    dispatch(getUserInfo())
  } */

  //console.log("userSubscription", userSubscription)
  //console.log("userSubscription", subscribe)
  // VERIFY IS SUBSCRIBE
  /*   useEffect(() => {
    if (!subscribe?.subscribed) {
      handleSignup()
    }
  }, [subscribe?.subscribed]) */

  /*  useEffect(() => {
    const categories = []
    const series = []
    homeData?.writing_stats?.series.map(data => {
      categories.push(data.label)
      series.push(data.value)
    })
    setBarSeries(JSON.stringify(series))
  }, [homeData]) */

  //const dataSeries = getApexData(barSeries?.series, barSeries?.meta?.legends)

  const forCastRef = React.useRef(true)
  const [series, setSeries] = useState()

  /* if (
    JSON.parse(localStorage.getItem("userInfo"))?.subscription === undefined
  ) {
    return <StarterPage />
  } */
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Home | QuillNow
          </title>
        </MetaTags>
        <Container fluid>
          <h4>Home</h4>
          {/*<div className="row mb-3 rounded border border-dark py-5 d-flex justify-content-center align-items-center">*/}
          {/*  {homeData && <CustomChart data={homeData} type="line" />}*/}
          {/*</div>*/}
          <>
            {homeData ? (
              <div className="timeline-event row mb-3 rounded border border-dark py-5 d-flex justify-content-center align-items-center">
                <Timeline orientation="left">
                  {homeData?.feed_items && homeData?.feed_items?.length > 0 ? (
                    homeData?.feed_items?.map(feed => (
                      <TimelineEvent
                        key={feed.id}
                        title={feed.title}
                        createdAt={feed.created_at}
                        iconStyle={{ marginLeft: 1, marginTop: 0 }}
                        icon={<EventIcon type={feed.resource_type} />}
                        style={{
                          backgroundColor: "#fff",
                          border: "1px solid #000000",
                          padding: 6,
                        }}
                        titleStyle={{ fontSize: "medium", color: "#545a6d" }}
                        contentStyle={{
                          color: "#c47b66",
                          fontStyle: "italic",
                          fontSize: "12px",
                        }}
                      >
                        <CardContent variant="outlined">
                          {feed.details}
                        </CardContent>
                      </TimelineEvent>
                    ))
                  ) : (
                    <TimelineEvent>
                      <CardContent variant="outlined">
                        Oops! No Data Found ...
                      </CardContent>
                    </TimelineEvent>
                  )}
                </Timeline>
              </div>
            ) : (
              <div className="row mb-3 rounded py-5 border border-dark d-flex justify-content-center align-items-center">
                <div className="h5 text-center">Oops! No Data Found ...</div>
              </div>
            )}
          </>
        </Container>
      </div>
      <div></div>
    </React.Fragment>
  )
}

const EventIcon = ({ type }) => {
  let icon = null

  switch (type) {
    case "User":
      icon = <i className="bx bx-user"></i>
      break
    case "Subscription":
      icon = <i className="bx bx-dollar"></i>
      break
    case "Post":
      icon = <i className="bx bx-repost"></i>
      break
    case "Project":
      icon = <i className="bx bx-book-content"></i>
      break
    case "Content":
      icon = <i className="bx bxs-book-content"></i>
      break
    case "Feedback Request":
      icon = <i className="bx bxs-comment-add"></i>
      break
    case "Feedback":
      icon = icon = <i className="bx bxs-comment-detail"></i>
      break
    case "Portfolio":
      icon = icon = <i className="bx bx-book"></i>
      break
    case "Idea":
      icon = icon = <i className="bx bxs-info-circle"></i>
      break
    case "Google":
      icon = icon = <i className="bx bxl-google"></i>
      break
    case "Twitter":
      icon = icon = <i className="bx bxl-twitter"></i>
      break
    case "Medium":
      icon = icon = <i className="bx bxl-medium"></i>
      break
    case "Screenplay":
      icon = icon = <i className="bx bxs-movie"></i>
      break
    default:
      icon = null
  }

  return icon
}

EventIcon.propTypes = {
  type: PropTypes.string,
}

Home.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}
export default withSignup(Home)
