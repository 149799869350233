import { takeEvery, put, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  GET_ACCESS_GOOGLE,
} from "./actionTypes"

import {
  getAccessGoogleSuccess,
  getApiGoogleError,
} from "./actions"

//Include Both Helper File with needed methods
import {
  postAccessGoogle,
} from "../../helpers/backend_helper"

function* fetchAccessGoogle() {
  try {
    const response = yield call(postAccessGoogle)
    yield put(getAccessGoogleSuccess(response))
    window.location.href = response
  } catch (error) {
    yield put(getApiGoogleError(error))
  }
}


function* authGoogleSaga() {
  yield takeEvery(GET_ACCESS_GOOGLE, fetchAccessGoogle)
}

export default authGoogleSaga
