import * as actionTypes from "./actionTypes"

const initialState = {
  loading: false,
  data: null,
  error: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SIGNUP_REQUEST:
      return { ...state, loading: true, error: null }
    case actionTypes.SIGNUP_SUCCESS:
      return { ...state, loading: false, data: action.payload }
    case actionTypes.SIGNUP_FAILURE:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export default reducer