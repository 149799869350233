import {
  GET_GENERATE_TEXT,
  GET_GENERATE_TEXT_FAIL,
  GET_GENERATE_TEXT_SUCCESS,
} from "./actionTypes"

export const getGenerateText = content => ({
  type: GET_GENERATE_TEXT,
  payload: content,
})
export const getGenerateTexteSuccess = content => ({
  type: GET_GENERATE_TEXT_SUCCESS,
  payload: content,
})

export const getGenerateTextFail = error => ({
  type: GET_GENERATE_TEXT_FAIL,
  payload: error,
})

