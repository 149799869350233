import React from "react"
import PropTypes from "prop-types"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts"
import {
  READABILITY_COLOR,
  READING_EASE_COLOR,
  FOREIGN_READER_READING_EASE_COLOR,
} from "utils"

export function ParagraphReadabilityScoresChart({ paragraphReadabilityStats = {} }) {

  if (!paragraphReadabilityStats || !paragraphReadabilityStats.text_stats_scores) return null

  const data = paragraphReadabilityStats?.readability_scores?.map((score, i) => ({
    name: `Paragraph ${paragraphReadabilityStats.paragraph_index[i]}`,
    "Readability Score": score.readability_score,
    "Reading Ease": score.reading_ease,
    "Foreign Reader Ease Score": score.foreign_reader_ease_score,
  }))
 if (!data || data.length === 0) return null
  return (
    <div className="container" style={{ width: "100%", height: "400px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          width={800}
          height={300}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend verticalAlign="top" height={36}/>
          <Bar
            dataKey="Readability Score"
            stroke={READABILITY_COLOR}
            fill={READABILITY_COLOR}
            isAnimationActive={true}
          />
          <Bar
            dataKey="Reading Ease"
            stroke={READING_EASE_COLOR}
            fill={READING_EASE_COLOR}
            isAnimationActive={true}
          />
          <Bar
            dataKey="Foreign Reader Ease Score"
            stroke={FOREIGN_READER_READING_EASE_COLOR}
            fill={FOREIGN_READER_READING_EASE_COLOR}
            isAnimationActive={true}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

ParagraphReadabilityScoresChart.propTypes = {
  paragraphReadabilityStats: PropTypes.any,
}
