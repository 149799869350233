import React, {useEffect, useMemo} from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import {
    Container,
    Card,
    CardBody,
    Progress,
    Row,
    Col, CardSubtitle, CardTitle
} from "reactstrap"
import {getProjects} from "../../store/actions"
import {useDispatch, useSelector} from "react-redux"
import {Link} from "react-router-dom"
import mixpanel from "mixpanel-browser"
import {getLoggedInUser} from "helpers/backend_helper"
import NoDataFound from "components/NoDataFound"
import ScaleLoader from "react-spinners/ScaleLoader";
import {withSignup} from "hooks/withSignup"
import {COLOR_THEME_PRIMARY} from "utils";
import {CardActionArea} from "@mui/material";

const Analyze = () => {
    const dispatch = useDispatch()
    const {projects, loading} = useSelector(state => state.projects)

    useEffect(() => {
        dispatch(getProjects())
    }, [dispatch])
    useEffect(() => {
        const loggedInUser = getLoggedInUser()
        if (loggedInUser !== null) {
            mixpanel.track("analyze_project_list", {
                event_properties: {
                    user_id: loggedInUser.uid,
                },
            })
        } else {
            mixpanel.track("analyze_project_list")
        }
    }, [])

    const projectItems = useMemo(
        () =>
            projects &&
            projects.items &&
            projects.items.map(item => {
                return {
                    ...item,
                }
            }),
        [projects]
    )

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>
                        Analytics | QuillNow
                    </title>
                </MetaTags>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-0 font-size-18">Analytics</h4>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                            {loading ? (
                                <Col className="col-12">
                                    <ScaleLoader height='5px' width='6px' color={COLOR_THEME_PRIMARY}/>
                                </Col>
                            ) : (
                                <>
                                    {projectItems && projectItems?.length > 0 ? (
                                        <div className="projects-container mb-4">
                                            <>
                                                {projectItems?.map(content => {
                                                    return (
                                                        <div key={content.id}>
                                                            <Col className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                                                <Card style={{width: "100%", minWidth: "20rem"}}  className="card-container">
                                                                    <CardBody>
                                                                        <Link
                                                                            to={`/analyze/project/documents/list/${content?.id}`}
                                                                        >
                                                                            <CardTitle>
                                                                                <h4>{content.title}</h4>
                                                                            </CardTitle>
                                                                        </Link>
                                                                        <div className="d-flex align-content-start" style={{
                                                                            paddingBottom: "0.8em",
                                                                            paddingTop: "0.8em"
                                                                        }}>
                                                                            {content.progress && (
                                                                                <Progress style={{height: '20px', width: '200px'}}
                                                                                          min={0} max="100"
                                                                                          value={content.progress}
                                                                                          color={COLOR_THEME_PRIMARY} >
                                                                                    <span className="text-black">{content.progress}%</span>
                                                                                </Progress>
                                                                            )}
                                                                        </div>
                                                                        {content.is_demo && (
                                                                            <span className="badge badge-soft-dark font-size-12" style={{
                                                                                paddingBottom: "0.5em",
                                                                                paddingTop: "0.5em"
                                                                            }}>
                                                                                Demo
                                                                            </span>
                                                                        )}
                                                                    </CardBody>
                                                                    <CardBody>
                                                                        {content.created_at}
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </div>
                                                    )
                                                })}
                                            </>
                                        </div>
                                    ) : (
                                        <Col className="col-lg">
                                            <Card style={{width: "100%", minWidth: "20rem"}} className="card-container">
                                                <CardBody>
                                                    <NoDataFound message="Add a project to get started"/>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    )}
                                </>
                            )}
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withSignup(Analyze)

Analyze.propTypes = {
    row: PropTypes.any,
    value: PropTypes.any,
    searchProps: PropTypes.any,
    baseProps: PropTypes.any,
}
