import React, { useEffect, useState } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { deleteIdea, getIdeas } from "store/actions"
import { withSignup } from "hooks/withSignup"

const RemoveIdea = ({ modal, setModal, idea }) => {

  const { ideas } = useSelector(state => state.ideas)
  const [deleteIdeas, setDeleteIdeas] = useState(false)

  const dispatch = useDispatch()

  const handleRemoveIdea = () => {
    // Dispatch an action to add the act to the idea
    const dataForm = new FormData()
    dataForm.append('idea_id', idea.id)

    try {
      dispatch(deleteIdea(dataForm))
      setDeleteIdeas(true)
      setModal(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (ideas || deleteIdeas) {
      //dispatch(getIdeas().then(u => setAllIdeas(u)))
      dispatch(getIdeas())
    }
    setDeleteIdeas(false)
  }, [ideas.length, deleteIdeas]);

  return (
    <>
      <Modal isOpen={modal} toggle={() => setModal(false)}>
        <ModalHeader toggle={() => setModal(false)}>Confirm Delete</ModalHeader>
        <ModalBody>
          {`Are you sure you want to delete the idea ${idea?.title} ?`}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setModal(false)}>
            Cancel
          </Button>
          <Button color="danger" onClick={handleRemoveIdea}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

RemoveIdea.propTypes = {
  modal: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  idea: PropTypes.object.isRequired,
}

export default withSignup(RemoveIdea)
