import React, { useEffect } from "react"
import { DataGrid, GridRenderCellParams, GridToolbar } from "@mui/x-data-grid"
import { useState } from "react"
import { myFeedbackRequests } from "../../common/data"
import { getUsers } from "../../store/actions"
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
//redux
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types";

const List = () => {
  const [data, setData] = useState(myFeedbackRequests)

  const dispatch = useDispatch()
  const { users } = useSelector(state => state.users)

  useEffect(() => {
    dispatch(getUsers())
  }, [])

  console.log("contacts", users);
  const handleDelete = id => {
    setData(data.filter(item => item.id !== id))
  }
  const ExpandableCell = ({ value }) => {
    const [expanded, setExpanded] = useState(false);
  
    return (
      <Box>
        {expanded ? value : value.slice(0, 200)}&nbsp;
        {value.length > 200 && (
          <Link
            type="button"
            component="button"
            sx={{ fontSize: 'inherit' }}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? 'view less' : 'view more'}
          </Link>
        )}
      </Box>
    );
  };

  const userColumns = [
    {
      field: "title",
      headerName: "Title",
      width: 400,
      renderCell: params => {
        if (params) {
          return (
            <div className="cellWithImg">
             {/*  <img className="cellImg" src={params.row.image} alt="avatar" /> */}
              <div className="row-activity">
                <span className="cell-activity">{params.row.title}</span>
                <span className="cell-timeline">Updated 1 day ago</span>
              </div>
            </div>
          )
        }
      },
    },
    {
      field: "details",
      headerName: "Details",
      width: 400,
      renderCell:(params) => { if(params) { return <ExpandableCell {...params} /> }},
      
    },
    {
      field: "category",
      headerName: "Category",
      width: 100,
      renderCell:(params) => { if(params) { return <ExpandableCell {...params} /> }},
    },
    {
      field: "content_type",
      headerName: "Content Type",
      width: 100,
      renderCell:(params) => { if(params) { return <ExpandableCell {...params} /> }},
    },
    {
      field: "content",
      headerName: "Content",
      width: 200,
      renderCell:(params) => { if(params) { return <ExpandableCell {...params} /> }},
    },
    {
      field: "created_at",
      headerName: "Date",
      width: 150,
      renderCell: params => {
        if (params) {
          return (
            <div className="row-date">
              <span className="cell-date__publication">{params.row.created_at}</span>
              {/* <span className="cell-time__publication">at 6:30 PM</span> */}
            </div>
          )
        }
      },
    },
  
  ]
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 100,
      headerClassName: "lastcolumnSeparatordiv",
      renderCell: params => {
        if (params) {
          return (
            <div className="cellAction">
              <div
                className="deleteButton"
                onClick={() => handleDelete(params.row.id)}
              >
                <i className="bx bx-dots-vertical-rounded"></i>
              </div>
            </div>
          )
        }
      },
    },
  ]
  return (
    <div className="datatable">
      <div className="datatableTitle">
      </div>
      <DataGrid
        className="datagrid"
        rows={users.items}
        columns={userColumns.concat(actionColumn)}
        pageSize={8}
        getEstimatedRowHeight={() => 100}
        components={{ Toolbar: GridToolbar }}
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
            py: '8px',
          },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
            py: '15px',
          },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
            py: '22px',
          },
        }}
        rowsPerPageOptions={[8]}
      />
    </div>
  )
}

export default List

List.propTypes = {
  value: PropTypes.string,
  length: PropTypes.number,
};