import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// user Redux States
import { GET_USER_SUBSCRIPTION_INFO } from "./actionTypes"
import {
  getUserSusbcriptionInfoSuccess,
  getUserSusbcriptionInfoError,
} from "./actions"

import { getUserSubscription } from "../../helpers/backend_helper"

function* getUserSubscriptionInfo() {
  try {
    const response = yield call(getUserSubscription)
      yield put(getUserSusbcriptionInfoSuccess(response))
  } catch (error) {
    yield put(getUserSusbcriptionInfoError(error))
  }
}
function* userSubscriptionInfoSaga() {
  yield takeEvery(GET_USER_SUBSCRIPTION_INFO, getUserSubscriptionInfo)
}

export default userSubscriptionInfoSaga
