import React from "react";
import {
    Card,
    CardBody,
    CardSubtitle,
    CardTitle,
    Progress
} from "reactstrap";
import {Link} from "react-router-dom";
import {Col, Dropdown} from "react-bootstrap";
import {MdDeleteForever, MdEditNote, MdMoreHoriz} from "react-icons/md";
import PropTypes from "prop-types";

const ProjectCard = ({content, onShowModal}) => (
        <Col className="col-sm-6 col-2">
            <Card style={{ width: "18rem" }} className="card-container" >
                <CardBody>
                    <Link to={`/project/documents/list/${content?.id}`}>
                        <CardTitle>
                            <h4>{content.title}</h4>
                        </CardTitle>
                    </Link>
                    {content.progress && (
                        <Progress style={{height: '20px', width: '200px'}} min={0} max="100" value={content.progress}>
                            <span className="text-black">{content.progress}%</span>
                        </Progress>
                    )}
                    {content.is_demo && (
                        <span className="badge badge-soft-dark font-size-12" style={{
                            paddingBottom: "0.5em", marginTop: "1.0em",
                            paddingTop: "0.5em", marginBottom: "1.0em",
                            height: '20px'
                        }}>Demo</span>
                    )}
                </CardBody>
                <CardBody>
                    <CardSubtitle>
                        {content.created_at}
                    </CardSubtitle>
                </CardBody>
                {!content.is_demo && (
                    <CardBody className="content-card-link">
                        <Dropdown style={{ position: "absolute", bottom: 0, right: 0 }}>
                            <Dropdown.Toggle
                                id="dropdown-basic"
                                className="bg-transparent border-0"
                                style={{ color: "#2a3042" }}
                            >
                                <MdMoreHoriz size={28} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item>
                                    <Link to={`/project-entry/${content?.id}`}>
                                        <MdEditNote size={28} />
                                        Edit
                                    </Link>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <Link to={`#`} onClick={onShowModal}>
                                        <MdDeleteForever size={28} />
                                        Delete
                                    </Link>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </CardBody>
                )}
            </Card>
        </Col>

)

ProjectCard.propTypes = {
    content: PropTypes.any,
    onShowModal: PropTypes.func
}

export default ProjectCard
