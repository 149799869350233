import {
  GET_PARAPHRASE_FAIL,
  GET_PARAPHRASE_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  paraphrase: [],
  error: {},
  loading: false
}

const paraphrase = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PARAPHRASE_SUCCESS:
      return {
        ...state,
        paraphrase: action.payload,
        loading: true,
      }
    case GET_PARAPHRASE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default paraphrase
