import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_SCORES, GET_SCORES_BY_PARA } from "./actionTypes"
import {
  getScoreDataSuccess,
  getScoreDataFail,
  getScoreDataByParaSuccess,
  getScoreDataByParaFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  fetchScores,
  fetchScoresByPara,
  getContentStats,
} from "helpers/backend_helper"

function* getScoreData({ payload: content }) {
  try {
    const response = yield call(fetchScores, content)
    yield put(getScoreDataSuccess(response))
  } catch (error) {
    yield put(getScoreDataFail(error))
  }
}
function* getScoreDataByPara({ payload: content }) {
  try {
    const response = yield call(fetchScoresByPara, content)
    yield put(getScoreDataByParaSuccess(response))
  } catch (error) {
    yield put(getScoreDataByParaFail(error))
  }
}

// get content stats
function* onGetContentStat({ payload: projectId, contentId }) {
  try {
    const response = yield call(getContentStats, projectId, contentId)
    yield put(getContentStatSuccess(response))
  } catch (error) {
    yield put(getScoreDataByParaFail(error))
  }
}
function* scoresSaga() {
  yield takeEvery(GET_SCORES, getScoreData)
  yield takeEvery(GET_SCORES_BY_PARA, getScoreDataByPara)
}

export default scoresSaga
