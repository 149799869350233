import React from "react"
import PropTypes from "prop-types"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts"
import {
  CHAR_COUNT_COLOR,
  CHAR_COUNT_WITHOUT_SPACE_COLOR,
  WORD_COUNT_COLOR,
  SYLLABLE_COUNT_COLOR,
  SENTENCE_COUNT_COLOR,
} from "utils"

export function ParagraphTextStatsChart({ paragraphTextStats = {} }) {

  console.log("paragraphLevelAnalytics", paragraphTextStats)
  if (!paragraphTextStats) return null

  if (!paragraphTextStats || !paragraphTextStats.text_stats_scores) return null

  const data = paragraphTextStats?.text_stats_scores?.map((score, i) => ({
    name: `Paragraph ${paragraphTextStats.paragraph_index[i]}`,
    Characters: score.char_count,
    "Characters Without Space": score.char_count_without_space,
    Syllables: score.syllable_count,
    Words: score.word_count,
    Sentences: score.sentence_count,
  }))

  if (!data || data.length === 0) return null

  return (
    <div className="container" style={{ width: "100%", height: "400px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          width={800}
          height={300}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend verticalAlign="top" height={36} />
          <Bar
            dataKey="Characters"
            stroke={CHAR_COUNT_COLOR}
            fill={CHAR_COUNT_COLOR}
            isAnimationActive={true}
          />
          <Bar
            dataKey="Characters Without Space"
            stroke={CHAR_COUNT_WITHOUT_SPACE_COLOR}
            fill={CHAR_COUNT_WITHOUT_SPACE_COLOR}
            isAnimationActive={true}
          />
          <Bar
            dataKey="Syllables"
            stroke={SYLLABLE_COUNT_COLOR}
            fill={SYLLABLE_COUNT_COLOR}
            isAnimationActive={true}
          />
          <Bar
            dataKey="Words"
            stroke={WORD_COUNT_COLOR}
            fill={WORD_COUNT_COLOR}
            isAnimationActive={true}
          />
          <Bar
            dataKey="Sentences"
            stroke={SENTENCE_COUNT_COLOR}
            fill={SENTENCE_COUNT_COLOR}
            isAnimationActive={true}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

ParagraphTextStatsChart.propTypes = {
  paragraphTextStats: PropTypes.any,
}
