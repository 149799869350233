import {
  GET_TOPICS,
  GET_TOPIC_FAIL,
  GET_TOPIC_SUCCESS,
} from "./actionTypes"

export const getTopics = content => ({
  type: GET_TOPICS,
  payload: content
})

export const getTopicsSuccess = projects => ({
  type: GET_TOPIC_SUCCESS,
  payload: projects,
})

export const getTopicsFail = error => ({
  type: GET_TOPIC_FAIL,
  payload: error,
})



