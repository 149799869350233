import React from "react"
import PropTypes from "prop-types"

const TabItemComponent = ({ title, onItemClicked, isActive }) => {
  return (
    <div
      className={isActive ? "tabitem mt-4" : "tabitem mt-4 tabitem--inactive"}
      onClick={onItemClicked}
    >
      <p className="tabitem__title">{title}</p>
    </div>
  )
}

TabItemComponent.propTypes = {
  title: PropTypes.string,
  onItemClicked: PropTypes.func,
  isActive: PropTypes.bool,
}
export default TabItemComponent
