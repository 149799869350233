import {
  CREATE_MEDIUM_POST,
  CREATE_MEDIUM_POST_SUCCESS,
  CREATE_MEDIUM_POST_FAILD,
  GET_ACCESS_MEDIUM,
  GET_ACCESS_MEDIUM_SUCCESS,
  GET_MEDIUM_API_ERROR,
} from "./actionTypes"

const initialState = {
  medium: null,
  content: null,
  error: null,
  loading: false,
}

const medium = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCESS_MEDIUM:
      return {
        ...state,
        medium: action.payload,
        loading: true,
      }
    case CREATE_MEDIUM_POST_SUCCESS:
      return {
        ...state,
        medium: action.payload,
        loading: false,
      }
      break
    case GET_MEDIUM_API_ERROR:
      return { ...state, error: action.payload, loading: false }
      break

      case CREATE_MEDIUM_POST:
        return {
          ...state,
          content: action.payload,
          loading: true,
        }
      case GET_ACCESS_MEDIUM_SUCCESS:
        return {
          ...state,
          content: action.payload,
          loading: false,
        }
        break
      case CREATE_MEDIUM_POST_FAILD:
        return { ...state, error: action.payload, loading: false }
        break
    default:
      return state
  }
}

export default medium
