import React, { useState } from "react"
import {
  Progress,
  Modal,
  ListGroup,
  ListGroupItem,
  Button,
  Tooltip,
  Badge,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import PropTypes from "prop-types"
import Informativeness from "./Informativeness"

// Utility functions
const getProgressBarValue = confidence => {
  switch (confidence) {
    case "Low":
      return 25
    case "Medium":
      return 50
    case "High":
      return 75
    default:
      return 0
  }
}

const progressBarColorMap = {
  red: "danger",
  yellow: "warning",
  green: "success",
  blue: "info",
  primary: "primary",
  secondary: "secondary",
  dark: "dark",
  light: "light",
  purple: "purple",
}

const renderBias = (data, openModal, tooltipOpen, toggleTooltip) => {
  const {
    color,
    Confidence,
    Biased,
    "Sentences to check": sentencesToCheck,
    "Words to check": wordsToCheck,
  } = data.verdict

  const progressBarValue = getProgressBarValue(Confidence)
  const progressBarColor = progressBarColorMap[color] || "secondary"

  return (
    <div>
      <p>Biased: {Biased}</p>
      <p>Confidence: {Confidence}</p>
      <Progress
        value={progressBarValue}
        color={progressBarColor}
        id="bias-tooltip"
        style={{ marginBottom: "10px" }}
      >
        {progressBarValue}%
      </Progress>
      <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        target="bias-tooltip"
        toggle={toggleTooltip}
      >
        Confidence: {Confidence}
      </Tooltip>
      {sentencesToCheck && sentencesToCheck.length > 0 && (
        <a
          href="#!"
          style={{
            cursor: "pointer",
            color: "black",
          }}
          onClick={e => {
            e.preventDefault()
            openModal(
              [...sentencesToCheck, ...wordsToCheck],
              "Sentences and Words to Check"
            )
          }}
        >
          <span style={{ textDecoration: "underline" }}>See details</span>
        </a>
      )}
    </div>
  )
}

const renderSubjectBias = (data, openModal, tooltipOpen, toggleTooltip) => {
  const {
    color,
    Confidence,
    "Subjective bias": subjectiveBias,
    Suggestions,
  } = data.verdict

  const progressBarValue = getProgressBarValue(Confidence)
  const progressBarColor = progressBarColorMap[color] || "secondary"

  return (
    <div>
      <p>Subjective bias: {subjectiveBias}</p>
      <p>Confidence: {Confidence}</p>
      <Progress
        value={progressBarValue}
        color={progressBarColor}
        id="subjective-tooltip"
        style={{ marginBottom: "10px" }}
      >
        {progressBarValue}%
      </Progress>
      <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        target="subjective-tooltip"
        toggle={toggleTooltip}
      >
        Confidence: {Confidence}
      </Tooltip>

      {Suggestions && Suggestions.length > 0 && (
        <a
          href="#!"
          style={{
            cursor: "pointer",
            color: "black",
          }}
          onClick={e => {
            e.preventDefault()
            openModal(Suggestions, "Suggestions")
          }}
        >
          <span style={{ textDecoration: "underline" }}>See details</span>
        </a>
      )}
    </div>
  )
}

// Render function for gender bias
const renderGenderBias = (data, openModal, tooltipOpen, toggleTooltip) => {
  const { color, Confidence, "Is Gender biased": isGenderBiased } = data.verdict

  const progressBarValue = getProgressBarValue(Confidence)
  const progressBarColor = progressBarColorMap[color] || "secondary"

  return (
    <div>
      <p>Is Gender biased: {isGenderBiased}</p>
      <p>Confidence: {Confidence}</p>
      <Progress
        value={progressBarValue}
        color={progressBarColor}
        id="gender-tooltip"
      >
        {progressBarValue}%
      </Progress>
      <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        target="gender-tooltip"
        toggle={toggleTooltip}
      >
        Confidence: {Confidence}
      </Tooltip>
    </div>
  )
}

// Render function for cognitive bias
const renderCognitiveVerdict = (
  data,
  openModal,
  openTooltipId,
  toggleTooltips
) => {
  return Object.keys(data.verdict).map(biasType => {
    const { color, Confidence, tooltip } = data.verdict[biasType]
    const progressBarValue = getProgressBarValue(Confidence)
    const progressBarColor = progressBarColorMap[color] || "secondary"

    return (
      <div key={biasType} className="mb-4">
        <p style={{ fontWeight: "bold" }}>{biasType}</p>
        <p>Confidence: {Confidence}</p>
        <Progress
          value={progressBarValue}
          color={progressBarColor}
          id={`${biasType}-tooltip`}
        >
          {progressBarValue}%
        </Progress>
        <Tooltip
          placement="top"
          isOpen={openTooltipId === `${biasType}-tooltip`}
          target={`${biasType}-tooltip`}
          toggle={() => toggleTooltips(`${biasType}-tooltip`)}
        >
          {tooltip}
        </Tooltip>
      </div>
    )
  })
}

// Render function for political bias
const renderPoliticalBias = (data, openModal, tooltipOpen, toggleTooltip) => {
  const { color, Confidence, "Political bias": politicalBias } = data.verdict

  const progressBarValue = getProgressBarValue(Confidence)
  const progressBarColor = progressBarColorMap[color] || "secondary"

  return (
    <div>
      <p>Political Bias: {politicalBias}</p>
      <p>Confidence: {Confidence}</p>
      <Progress
        value={progressBarValue}
        color={progressBarColor}
        id="political-tooltip"
      >
        {progressBarValue}%
      </Progress>
      <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        target="political-tooltip"
        toggle={toggleTooltip}
      >
        Confidence: {Confidence}
      </Tooltip>
    </div>
  )
}

// hateSpeech score
const renderHateSpeechScore = (data, openModal, tooltipOpen, toggleTooltip) => {
  const { color, Confidence, Hate } = data.verdict

  const progressBarValue = getProgressBarValue(Confidence)
  const progressBarColor = progressBarColorMap[color] || "secondary"

  return (
    <div>
      <p>Hate Speech Detected: {Hate}</p>
      <p>Confidence: {Confidence}</p>
      <Progress
        value={progressBarValue}
        color={progressBarColor}
        id="hate-tooltip"
      >
        {progressBarValue}%
      </Progress>
      <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        target="hate-tooltip"
        toggle={toggleTooltip}
      >
        Confidence: {Confidence}
      </Tooltip>
    </div>
  )
}

const renderRacismSexismScore = (
  data,
  openModal,
  openTooltipId,
  toggleTooltips
) => {
  return Object.keys(data.verdict).map(type => {
    const { color, Confidence, Racist, Sexist } = data.verdict[type]
    const progressBarValue = getProgressBarValue(Confidence)
    const progressBarColor = progressBarColorMap[color] || "secondary"

    return (
      <div key={type} className="mb-4">
        <p style={{ fontWeight: "bold" }}>
          {type.charAt(0).toUpperCase() + type.slice(1)}
        </p>
        <p>{Racist ? `Racist: ${Racist}` : `Sexist: ${Sexist}`}</p>
        <p>Confidence: {Confidence}</p>
        <Progress
          value={progressBarValue}
          color={progressBarColor}
          id={`${type}-tooltip`}
        >
          {progressBarValue}%
        </Progress>
        <Tooltip
          placement="top"
          isOpen={openTooltipId === `${type}-tooltip`}
          target={`${type}-tooltip`}
          toggle={() => toggleTooltips(`${type}-tooltip`)}
        >
          Confidence: {Confidence}
        </Tooltip>
      </div>
    )
  })
}

const renderToxicityScore = (data, openModal, tooltipOpen, toggleTooltip) => {
  const { color, Confidence, Hate } = data.verdict
  const progressBarValue = getProgressBarValue(Confidence)
  const progressBarColor = progressBarColorMap[color] || "secondary"

  return (
    <div>
      <p>Hate: {Hate}</p>
      <p>Confidence: {Confidence}</p>
      <Progress
        value={progressBarValue}
        color={progressBarColor}
        id="toxicity-tooltip"
      >
        {progressBarValue}%
      </Progress>
      <Tooltip
        placement="top"
        isOpen={tooltipOpen === "toxicity-tooltip"}
        target="toxicity-tooltip"
        toggle={() => toggleTooltip("toxicity-tooltip")}
      >
        Confidence: {Confidence}
      </Tooltip>
    </div>
  )
}

const renderPatronizingScore = (
  data,
  openModal,
  tooltipOpen,
  toggleTooltip
) => {
  const { color, Confidence, Patronizing } = data.verdict
  const progressBarValue = getProgressBarValue(Confidence)
  const progressBarColor = progressBarColorMap[color] || "secondary"

  return (
    <div>
      <p>Patronizing: {Patronizing}</p>
      <p>Confidence: {Confidence}</p>
      <Progress
        value={progressBarValue}
        color={progressBarColor}
        id="patronizing-tooltip"
      >
        {progressBarValue}%
      </Progress>
      <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        target="patronizing-tooltip"
        toggle={toggleTooltip}
      >
        Confidence: {Confidence}
      </Tooltip>
    </div>
  )
}

// Tones scores

const renderEmotionScore = data => {
  const { "Primary emotion": primary, "Secondary emotion": secondary } =
    data.verdict

  const renderBadges = (value, color) =>
    value.split(",").map((emotion, index) => (
      <Badge color={color} key={index} className="m-1">
        {emotion}
      </Badge>
    ))

  return (
    <div>
      <p>Primary Emotion: {renderBadges(primary, "primary")}</p>
      <p>Secondary Emotion: {renderBadges(secondary, "secondary")}</p>
    </div>
  )
}

const renderEmpathyScore = data => {
  const { "Primary empathy": primary, "Secondary empathy": secondary } =
    data.verdict

  const renderBadges = (value, color) =>
    value.split(",").map((empathy, index) => (
      <Badge color={color} key={index} className="m-1">
        {empathy}
      </Badge>
    ))

  return (
    <div>
      <p>Primary Empathy: {renderBadges(primary, "warning")}</p>
      <p>Secondary Empathy: {renderBadges(secondary, "info")}</p>
    </div>
  )
}

const renderEngagementScore = (data, tooltipOpen, toggleTooltip) => {
  const { color, Engaging, Confidence } = data.verdict
  const progressBarValue = getProgressBarValue(Confidence)
  const progressBarColor = progressBarColorMap[color] || "secondary"

  return (
    <div>
      <p>Engaging: {Engaging}</p>
      <p>Confidence: {Confidence}</p>
      <Progress
        value={progressBarValue}
        color={progressBarColor}
        id="engagement-tooltip"
      >
        {progressBarValue}%
      </Progress>
      <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        target="engagement-tooltip"
        toggle={toggleTooltip}
      >
        Confidence: {Confidence}
      </Tooltip>
    </div>
  )
}

/* const renderSentimentScore = (data, tooltipOpen, toggleTooltip) => {
  const negativeValue = data["NEGATIVE"] * 100
  const positiveValue = data["POSITIVE"] * 100

  return (
    <div className="mb-4">
      <p>Negative: {negativeValue.toFixed(2)}%</p>
      <p>Positive: {positiveValue.toFixed(2)}%</p>
    </div>
  )
} */

// New sentiment function

const renderSentimentScore = (data, tooltipOpen, toggleTooltip) => {
  const { color, Sentiment, Confidence } = data.verdict

  const progressBarValue = getProgressBarValue(Confidence)
  const progressBarColor = progressBarColorMap[color] || "secondary"

  return (
    <div className="mb-4">
      <p>Sentiment: {Sentiment}</p>
      <p>Confidence: {Confidence}</p>
      <Progress
        value={progressBarValue}
        color={progressBarColor}
        id="sentiment-tooltip"
      >
        {progressBarValue}%
      </Progress>
      <Tooltip
        placement="top"
        isOpen={tooltipOpen === "sentiment-tooltip"}
        target="sentiment-tooltip"
        toggle={() => toggleTooltip("sentiment-tooltip")}
      >
        Confidence: {Confidence}
      </Tooltip>
    </div>
  )
}

// Complexity

const renderComplexityScore = (data, tooltipOpen, toggleTooltip) => {
  const { color, Complex, Confidence } = data.verdict
  const progressBarValue = getProgressBarValue(Confidence)
  const progressBarColor = progressBarColorMap[color] || "secondary"

  return (
    <div>
      <p>Complexity: {Complex}</p>
      <p>Confidence: {Confidence}</p>
      <Progress
        value={progressBarValue}
        color={progressBarColor}
        id="complexity-tooltip"
      >
        {progressBarValue}%
      </Progress>
      <Tooltip
        placement="top"
        isOpen={tooltipOpen === "complexity-tooltip"}
        target="complexity-tooltip"
        toggle={() => toggleTooltip("complexity-tooltip")}
      >
        Confidence: {Confidence}
      </Tooltip>
    </div>
  )
}
//Synthesis score
const renderSynthesisScore = (data, tooltipOpen, toggleTooltip) => {
  const { color, Confidence, "Is AI Generated": isAIGenerated } = data.verdict

  const progressBarValue = getProgressBarValue(Confidence)
  const progressBarColor = progressBarColorMap[color] || "secondary"

  return (
    <div>
      <p>Is AI Generated: {isAIGenerated}</p>
      <p>Confidence: {Confidence}</p>
      <Progress
        value={progressBarValue}
        color={progressBarColor}
        id="synthesis-tooltip"
      >
        {progressBarValue}%
      </Progress>
      <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        target="synthesis-tooltip"
        toggle={toggleTooltip}
      >
        Confidence: {Confidence}
      </Tooltip>
    </div>
  )
}

const renderInformativenessScore = data => <Informativeness data={data} />
const BiasScore = ({ data, biasType }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState([])
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [openTooltipId, setOpenTooltipId] = useState(null)
  const [modalTitle, setModalTitle] = useState("")

  const toggleTooltips = id => {
    if (openTooltipId === id) {
      setOpenTooltipId(null)
    } else {
      setOpenTooltipId(id)
    }
  }
  const toggleModal = () => setIsModalOpen(!isModalOpen)
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen)
  /*  const openModal = content => {
    setModalContent(content)
    toggleModal()
  } */

  const openModal = (content, title) => {
    const labeledContent = content.map(item => {
      if (
        data.verdict["Sentences to check"] &&
        data.verdict["Sentences to check"].includes(item)
      ) {
        return "Sentence: " + item
      }
      if (
        data.verdict["Words to check"] &&
        data.verdict["Words to check"].includes(item)
      ) {
        return "Word: " + item
      }
      return item
    })
    setModalContent(labeledContent)
    setModalTitle(title)
    toggleModal()
  }

  const renderBiasInfo = () => {
    switch (biasType) {
      case "bias":
        return renderBias(data, openModal, tooltipOpen, toggleTooltip)
      case "subjective":
        return renderSubjectBias(data, openModal, tooltipOpen, toggleTooltip)
      case "gender":
        return renderGenderBias(data, openModal, tooltipOpen, toggleTooltip)
      case "political":
        return renderPoliticalBias(data, openModal, tooltipOpen, toggleTooltip)
      case "hateSpeech":
        return renderHateSpeechScore(
          data,
          openModal,
          tooltipOpen,
          toggleTooltip
        )
      case "racismSexism":
        return renderRacismSexismScore(
          data,
          openModal,
          openTooltipId,
          toggleTooltips
        )
      case "cognitive":
        return renderCognitiveVerdict(
          data,
          openModal,
          openTooltipId,
          toggleTooltips
        )
      case "toxicity":
        return renderToxicityScore(data, openModal, tooltipOpen, toggleTooltip)
      case "patronizing":
        return renderPatronizingScore(
          data,
          openModal,
          tooltipOpen,
          toggleTooltip
        )
      case "emotion":
        return renderEmotionScore(data)
      case "empathy":
        return renderEmpathyScore(data)
      case "engagement":
        return renderEngagementScore(data, tooltipOpen, toggleTooltip)
      case "sentiment":
        return renderSentimentScore(data, tooltipOpen, toggleTooltip)
      case "complexity":
        return renderComplexityScore(data, tooltipOpen, toggleTooltip)
      case "synthesis":
        return renderSynthesisScore(data, tooltipOpen, toggleTooltip)
      case "informativeness":
        return renderInformativenessScore(data)
      default:
        return <p>Unsupported bias type</p>
    }
  }

  return (
    <div>
      {renderBiasInfo()}
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{modalTitle}</ModalHeader>
        <ModalBody>
          <ListGroup>
            {modalContent.map((item, idx) => (
              <ListGroupItem key={idx}>{item}</ListGroupItem>
            ))}
          </ListGroup>
        </ModalBody>
      </Modal>
    </div>
  )
}

BiasScore.propTypes = {
  data: PropTypes.object.isRequired,
  biasType: PropTypes.string.isRequired,
}

export default BiasScore
