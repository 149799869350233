import React, { useState, useEffect, useCallback, useRef } from "react"
//redux
import { useDispatch, useSelector } from "react-redux"

// actions
import { createCheckoutSession, getUserInfo } from "../../store/actions"
import { API_URL } from "../../helpers/api_helper"

const PaymentPricingCard = () => {
  const [subscriptionType, setSubscriptionType] = useState("mounthly")
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.user)

  useEffect(() => {
    dispatch(getUserInfo())
  }, [])

  const [isSending, setIsSending] = useState(false)
  const handlePayment = useCallback(async () => {
    // don't send again while we are sending
    if (isSending) return
    // update state
    setIsSending(true)
    // send the actual request
    const checkoutForm = new FormData()
    checkoutForm.append("plan", "business")
    checkoutForm.append("billing_frequency", "monthly")
    checkoutForm.append("success_redirect", API_URL.concat("?success=true"))
    checkoutForm.append("cancel_redirect", API_URL.concat("?canceled=true"))
    dispatch(createCheckoutSession(checkoutForm))
    // once the request is sent, update state again
    setIsSending(false)
  }, [isSending]) // update the callback if the state changes

  const styles = {
    switchPlan: {
      left: subscriptionType === "mounthly" ? "2px" : "163px",
    },
  }

  return (
    <div className="pricingTable">
      <div className="row">
        <div className="col-12">
          <div className="inner d-flex justify-content-center align-items-center tabsBtnHolder">
            <ul>
              <li>
                <p
                  id="monthly"
                  onClick={() => setSubscriptionType("mounthly")}
                  className={`${
                    subscriptionType === "mounthly" ? "active" : ""
                  }`}
                >
                  Monthly
                </p>
              </li>
              <li>
                <p
                  id="yearly"
                  onClick={() => setSubscriptionType("yearly")}
                  className={`${subscriptionType === "yearly" ? "active" : ""}`}
                >
                  Yearly
                </p>
              </li>
              <li className="indicator" style={styles.switchPlan}></li>
            </ul>
          </div>
        </div>
      </div>
      <div
        className={`row monthlyPriceList animated d-flex justify-content-center align-items-center mb-5 ${
          subscriptionType === "mounthly" ? "fadeIn" : "d-none"
        }`}
      >
        <div className="col-md-3">
          <div className="inner holder">
            <form>
              <div className="hdng">
                <p>Essential Plan</p>
              </div>
              <div className="price mt-1">
                <p>
                  <b>$49.99</b>
                  <span> / mo</span>
                </p>
              </div>
              <div className="info">
                <p>Adv 1</p>
                <p>Adv 2</p>
                <p>Adv 3</p>
                <p>Adv 4</p>
                <p>24/7 Support</p>
              </div>
              <div className="btn">
                {user?.subscription?.billing_cycle === "Monthly" &&
                user?.subscription?.plan === "Essential" ? (
                  <a
                    href="#"
                    className="readon"
                    style={{ pointerEvents: "none" }}
                  >
                    Current Plan
                  </a>
                ) : (
                  <a href="#" className="readon" onClick={handlePayment}>
                    Update Now
                  </a>
                )}
              </div>
            </form>
          </div>
        </div>

        <div className="col-md-3">
          <div className="inner holder active">
            <div className="hdng">
              <p>Standard Plan</p>
            </div>
            <div className="price mt-1">
              <p>
                <b>$69.99</b>
                <span> / mo</span>
              </p>
            </div>
            <div className="info">
              <p>Adv 1</p>
              <p>Adv 2</p>
              <p>Adv 3</p>
              <p>Adv 4</p>
              <p>24/7 Support</p>
            </div>
            <div className="btn">
              {user?.subscription?.billing_cycle === "Monthly" &&
              user?.subscription?.plan === "Standard" ? (
                <a
                  href="#"
                  className="readon"
                  style={{ pointerEvents: "none" }}
                >
                  Current Plan
                </a>
              ) : (
                <a href="#" className="readon" onClick={handlePayment}>
                  Update Now
                </a>
              )}
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <div className="inner holder">
            <div className="hdng">
              <p>Business Plan</p>
            </div>
            <div className="price mt-1">
              <p>
                <b>$99.99</b>
                <span> / mo</span>
              </p>
            </div>
            <div className="info">
              <p>Adv 1</p>
              <p>Adv 2</p>
              <p>Adv 3</p>
              <p>Adv 4</p>
              <p>24/7 Support</p>
            </div>
            <div className="btn">
              {user?.subscription?.billing_cycle === "Monthly" &&
              user?.subscription?.plan === "Business" ? (
                <a
                  href="#"
                  className="readon"
                  style={{ pointerEvents: "none" }}
                >
                  Current Plan
                </a>
              ) : (
                <a href="#" className="readon" onClick={handlePayment}>
                  Update Now
                </a>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className={`row yearlyPriceList animated d-flex justify-content-center align-items-center mb-5 ${
          subscriptionType === "yearly" ? "fadeIn" : "d-none"
        }`}
      >
        <div className="col-md-3">
          <div className="inner holder">
            <div className="hdng">
              <p>Essential Plan</p>
            </div>
            <div className="price mt-1">
              <p>
                <b>$499.99</b>
                <span> / mo</span>
              </p>
            </div>
            <div className="info">
              <p>Adv 1</p>
              <p>Adv 2</p>
              <p>Adv 3</p>
              <p>Adv 4</p>
              <p>24/7 Support</p>
            </div>
            <div className="btn">
              {user?.subscription?.billing_cycle === "Yearly" &&
              user?.subscription?.plan === "Essential" ? (
                <a
                  href="#"
                  className="readon"
                  style={{ pointerEvents: "none" }}
                >
                  Current Plan
                </a>
              ) : (
                <a href="#" className="readon" onClick={handlePayment}>
                  Update Now
                </a>
              )}
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <div className="inner holder active">
            <div className="hdng">
              <p>Standard Plan</p>
            </div>
            <div className="price mt-1">
              <p>
                <b>$699.99</b>
                <span> / mo</span>
              </p>
            </div>
            <div className="info">
              <p>Adv 1</p>
              <p>Adv 2</p>
              <p>Adv 3</p>
              <p>Adv 4</p>
              <p>24/7 Support</p>
            </div>
            <div className="btn">
              {user?.subscription?.billing_cycle === "Yearly" &&
              user?.subscription?.plan === "Standard" ? (
                <a
                  href="#"
                  className="readon"
                  style={{ pointerEvents: "none" }}
                >
                  Current Plan
                </a>
              ) : (
                <a href="#" className="readon" onClick={handlePayment}>
                  Update Now
                </a>
              )}
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <div className="inner holder">
            <div className="hdng">
              <p>Business Plan</p>
            </div>
            <div className="price mt-1">
              <p>
                <b>$999.99</b>
                <span> / mo</span>
              </p>
            </div>
            <div className="info">
              <p>Adv 1</p>
              <p>Adv 2</p>
              <p>Adv 3</p>
              <p>Adv 4</p>
              <p>24/7 Support</p>
            </div>
            <div className="btn">
              {user?.subscription?.billing_cycle === "Yearly" &&
              user?.subscription?.plan === "Business" ? (
                <a
                  href="#"
                  className="readon"
                  style={{ pointerEvents: "none" }}
                >
                  Current Plan
                </a>
              ) : (
                <a href="#" className="readon" onClick={handlePayment}>
                  Update Now
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentPricingCard
