import {
  GET_USER_INFO,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  user: {},
  success: "",
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_INFO:
      state = { ...state }
      break
    case GET_USER_INFO_SUCCESS:
      state = { ...state, user: action.payload }
      break
    case GET_USER_INFO_ERROR:
      state = { ...state, error: action.payload }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default user
