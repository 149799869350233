import {
  addSceneApi,
  deleteSceneApi,
  reorderScenesApi,
} from "helpers/backend_helper"
import { takeLatest, put, call } from "redux-saga/effects"
import {
  ADD_SCENE,
  ADD_SCENE_SUCCESS,
  ADD_SCENE_FAIL,
  REORDER_SCENES,
  REORDER_SCENES_SUCCESS,
  REORDER_SCENES_FAIL,
  DELETE_SCENE,
  DELETE_SCENE_SUCCESS,
  DELETE_SCENE_FAIL,
} from "./actionTypes"

export function* onAddScene({ payload: { sceneData, actId, screenplayId } }) {
  try {
    //console.log("sceneData", sceneData)
    const dataForm = new FormData()

    dataForm.append("scene_title", sceneData.scene_title)
    dataForm.append("scene_description", sceneData.scene_description)
    dataForm.append("scene_content", sceneData.scene_content)
    dataForm.append("scene_tags", sceneData.scene_tags)
    dataForm.append("act_id", actId)
    dataForm.append("screenplay_id", screenplayId)

    const response = yield call(addSceneApi, dataForm)
    yield put({ type: ADD_SCENE_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: ADD_SCENE_FAIL, payload: error })
  }
}

export function* onReorderScenes(action) {
  try {
    const { actId, scenes } = action.payload
    const response = yield call(reorderScenesApi, actId, scenes)
    yield put({ type: REORDER_SCENES_SUCCESS, payload: response.data })
  } catch (error) {
    yield put({ type: REORDER_SCENES_FAIL, payload: error })
  }
}

export function* onDeleteScene({ payload: { sceneId, actId, screenplayId } }) {
  try {
    const dataForm = new FormData()

    dataForm.append("screenplay_id", screenplayId)
    dataForm.append("act_id", actId)
    dataForm.append("scene_id", sceneId)

    const response = yield call(deleteSceneApi, dataForm)
    yield put({ type: DELETE_SCENE_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: DELETE_SCENE_FAIL, payload: error })
  }
}

export default function* sceneSaga() {
  yield takeLatest(ADD_SCENE, onAddScene)
  yield takeLatest(REORDER_SCENES, onReorderScenes)
  yield takeLatest(DELETE_SCENE, onDeleteScene)
}
