module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "83707173809-r6pedu9ml3fh5n4p8rgmpqng0kcbgvlc.apps.googleusercontent.com",
    SECRET: "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",
  },
  facebook: {
    APP_ID: "ffb4b7c2e403ac95734aa5d462c057c8",
  },
}
