import {
  GET_PARAPHRASES,
  GET_PARAPHRASE_FAIL,
  GET_PARAPHRASE_SUCCESS,
} from "./actionTypes"

export const getParaphrase = content => ({
  type: GET_PARAPHRASES,
  payload: content,
})
export const getParaphraseSuccess = content => ({
  type: GET_PARAPHRASE_SUCCESS,
  payload: content,
})

export const getParaphraseFail = error => ({
  type: GET_PARAPHRASE_FAIL,
  payload: error,
})

