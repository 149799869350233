import React from "react"
import PropTypes from "prop-types"
import { formatText } from "utils"
import { Card, CardBody, CardTitle, CardHeader } from "reactstrap"

const ReadabilityCard = ({ title, value, color }) => (
  <Card
    style={{
      backgroundColor: color,
      borderRadius: "15px",
      width: "18rem",
      boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
    }}
  >
    <CardHeader
      style={{
        backgroundColor: "white",
        color: color,
        fontWeight: "normal",
        fontSize: "1rem",
        textAlign: "center",
      }}
    >
      {formatText(title)}
    </CardHeader>
    <CardBody
      style={{
        backgroundColor: color,
        color: "white",
      }}
    >
      <CardTitle tag="h3" style={{ textAlign: "center" }}>
        {value}
      </CardTitle>
    </CardBody>
  </Card>
)

ReadabilityCard.propTypes = {
  title: PropTypes.any,
  value: PropTypes.any,
  color: PropTypes.any,
}

export default ReadabilityCard
