import { call, put, takeEvery } from "redux-saga/effects"
import firebase from "firebase/compat/app"
// Add the Firebase products that you want to use
import "firebase/compat/auth"
import "firebase/compat/firestore"
// Crypto Redux States
import {
  UPDATE_SUBSCRIPTION,
  GET_SUBSCRIPTION_PLAN,
  GET_SETUP_INTENT,
  POST_SUBSCRIPTION_PORTAL,
} from "./actionTypes"
import {
  updateSubscriptionSuccess,
  updateSubscriptionFail,
  getSubscriptionPlanSuccess,
  getSubscriptionPlanFail,
  getSetupIntentSuccess,
  getSetupIntentFail,
  postSubscriptionPortalSuccess,
  postSubscriptionPortalFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  updateSubscription,
  getSubscriptionPlan,
  getSetupIntent,
  postSubscriptionPortal,
  getPortal,
} from "helpers/backend_helper"
import { toast } from "react-toastify"

function* onUpdateSubscription({ payload: plan }) {
  try {
    const response = yield call(updateSubscription, plan)
    yield put(updateSubscriptionSuccess(response))
    toast.success("You Subscription has been updated", {
      position: toast.POSITION.TOP_RIGHT,
    })
  } catch (error) {
    yield put(updateSubscriptionFail(error))
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }
}

function* onGetSubscriptionPlan() {
  try {
    const response = yield call(getSubscriptionPlan)
    yield put(getSubscriptionPlanSuccess(response))
  } catch (error) {
    yield put(getSubscriptionPlanFail(error))
  }
}

function* goGetSetupIntent() {
  try {
    const response = yield call(getSetupIntent)
    yield put(getSetupIntentSuccess(response))
  } catch (error) {
    yield put(getSetupIntentFail(error))
  }
}

function* onPostSubscrptionPortal() {

  console.log("We are there")
  try {
    const response = yield call(postSubscriptionPortal)
    yield put(postSubscriptionPortalSuccess(response))
    
    window.location.href = response
  } catch (error) {
    yield put(postSubscriptionPortalFail(error))
  }
}

function* subscriptionSaga() {
  yield takeEvery(UPDATE_SUBSCRIPTION, onUpdateSubscription)
  yield takeEvery(GET_SUBSCRIPTION_PLAN, onGetSubscriptionPlan)
  yield takeEvery(GET_SETUP_INTENT, goGetSetupIntent)
  yield takeEvery(POST_SUBSCRIPTION_PORTAL, onPostSubscrptionPortal)
}

export default subscriptionSaga
