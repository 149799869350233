import React, { useState, useEffect, useRef, useCallback, useMemo } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import MetaTags from "react-meta-tags"
import {
  Container,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
} from "reactstrap"
import { Link } from "react-router-dom"
import Breadcrumb from "components/Common/Breadcrumb2"
import avatar from "../../assets/images/users/avatar.jpeg"
import Select from "react-select"
import CustomModal from "components/Modal"
import {
  getUserInfo,
  getProjectCategories,
  updateUserProfile,
  updateSubscription,
  getUserPhoto,
  getAccessMedium,
  getAccessTwitter,
  getAccessGoogle,
  createCheckoutSession,
  getSubscriptionPlan,
  postSubscriptionPortal,
  uploadUserPhoto,
} from "store/actions"
import Subscription from "components/PrincingPlans"
import ImageBlob from "components/ImageBlob"
import avatarPlaceholder from "../../assets/images/users/user.png"

const schema = yup
  .object()
  .shape({
    //apiKey: yup.string().required(),
  })
  .required()

const Profile = () => {
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.user)
  const { categories } = useSelector(state => state.projects)
  const { photo } = useSelector(state => state.Profile)

  console.log("avatarPlaceholder", avatarPlaceholder)
  const { subscriptionPlan } = useSelector(state => state.subscription)
  const { portal } = useSelector(state => state.subscription)
  const [userPhoto, setUserPhoto] = useState()
  /* const blobToBase64 = file => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      const base64String = reader.result
      console.log(base64String)
    }
  } */

  //blobToBase64(photo)

  console.log("subscriptionPlan", photo)

  const userRef = useRef(true)

  useEffect(() => {
    if (userRef.current) {
      dispatch(getUserInfo())
      dispatch(getProjectCategories())
      dispatch(getUserPhoto())
      dispatch(getSubscriptionPlan())
    }
  }, [])

  useEffect(() => {
    dispatch(getUserPhoto())
  }, [])

  const options = React.useMemo(() =>
    categories?.map(
      option => ({
        value: option,
        label: option,
      }),
      [categories]
    )
  )

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const [active, setActive] = useState(1)
  const [selectedOption, setSelectedOption] = useState("")
  const [publications, setPublications] = useState([])
  const [showModal, setShowModal] = useState(false)
  const tabItems = [
    {
      id: 1,
      title: "Profile",
      icon: "user",
    },
    {
      id: 2,
      title: "Subscription",
      icon: "exchange-alt",
    },
   /*  {
      id: 3,
      title: "Integration",
      icon: "network-wired",
    }, */
  ]
  const items = [
    { title: "Dashboard", link: "/dashboard" },
    {
      title: "Profile",
      link: "/profile",
    },
  ]

  const handleCreatePublication = data => {
    setPublications(prevState => [
      ...prevState,
      {
        name: data.pub_name,
        url: data.pub_url,
        publish_type: data.pub_type,
        engagement_type: data.pub_engagement,
      },
    ])
    setShowModal(false)
  }

  const submitUpdatedProfile = data => {
    const contentForm = new FormData()
    data.display_name && contentForm.append("display_name", data.display_name)
    data.first_name && contentForm.append("first_name", data.first_name)
    data.last_name && contentForm.append("last_name", data.last_name)
    data.email && contentForm.append("email", data.email)
    data.options && contentForm.append("categories", data.options.join())
    data.bio && contentForm.append("bio", data.bio)
    data.short_bio &&
      contentForm.append("short_bio", data.short_bio && data.short_bio)
    publications.length > 0 &&
      contentForm.append("publications", JSON.stringify(publications))
    dispatch(updateUserProfile(contentForm))
    dispatch(getUserInfo())
    reset({
      display_name: "",
      first_name: "",
      last_name: "",
      options: [],
      bio: "",
      short_bio: "",
      pub_name: "",
      pub_url: "",
      pub_type: "",
      pub_engagement: "",
    })
    setPublications([])
  }

  const resetUpdatedProfile = data => {
    reset({
      display_name: "",
      first_name: "",
      last_name: "",
      options: [],
      short_bio: "",
      pub_name: "",
      pub_url: "",
      pub_type: "",
      pub_engagement: "",
    })
  }

  const handleGetAtuthTwitter = useCallback(() => {
    dispatch(getAccessTwitter())
  }, [])

  const handleGetAtuthGoogle = useCallback(() => {
    dispatch(getAccessGoogle())
  }, [])

  const onFileChange = event => {
    // Update the state
    setUserPhoto({ selectedFile: event.target.files[0] })
  }

  const onFileUpload = () => {
    const formData = new FormData()
    formData.append("file", userPhoto?.selectedFile)
    dispatch(uploadUserPhoto(formData))
    dispatch(getUserPhoto())
    setUserPhoto()
    sessionStorage.getItem(photo)
  }

  const [mediumModal, setMediumModal] = useState(false)
  const submitMediumForm = data => {
    const KeyDataForm = new FormData()
    KeyDataForm.append("medium_api_key", data.apiKey)
    dispatch(getAccessMedium(KeyDataForm))
    reset({
      apiKey: "",
    })
    setMediumModal(false)
  }

  const handlePaymentEssential = useCallback(() => {
    const checkoutForm = new FormData()
    checkoutForm.append("plan", "business")
    checkoutForm.append("billing_frequency", "monthly")
    checkoutForm.append(
      "success_redirect",
      "https://content.quillnow.com/payment/success"
    )
    checkoutForm.append(
      "cancel_redirect",
      "https://content.quillnow.com/payment/success"
    )
    dispatch(createCheckoutSession(checkoutForm))
  }, [])

  const handlePaymentStandard = useCallback(() => {
    const checkoutForm = new FormData()
    checkoutForm.append("plan", "business")
    checkoutForm.append("billing_frequency", "monthly")
    checkoutForm.append(
      "success_redirect",
      "https://content.quillnow.com/payment/success"
    )
    checkoutForm.append(
      "cancel_redirect",
      "https://content.quillnow.com/payment/success"
    )
    dispatch(createCheckoutSession(checkoutForm))
  }, [])

  const handlePaymentBusiness = useCallback(() => {
    const checkoutForm = new FormData()
    checkoutForm.append("plan", "business")
    checkoutForm.append("billing_frequency", "monthly")
    checkoutForm.append(
      "success_redirect",
      "https://content.quillnow.com/payment/success"
    )
    checkoutForm.append(
      "cancel_redirect",
      "https://content.quillnow.com/payment/success"
    )
    dispatch(createCheckoutSession(checkoutForm))
  }, [])

  const [planSubscribe, setPlanSubscribe] = useState("monthly")
  const handleChangePlan = e => {
    const checked = e.target.checked

    if (checked) {
      setPlanSubscribe("yearly")
    } else {
      setPlanSubscribe("monthly")
    }
  }

  /* const handleChangeSubscriptionPlan = (prices, name, type) => {

      console.log("handleChangeSubscriptionPlan", prices, name, type)
    } */

  const handleChangeSubscriptionPlan = useCallback(
    (prices, name, type) => {
      console.log("handleChangeSubscriptionPlan", prices, name, type)
      /* const checkoutForm = new FormData()
      checkoutForm.append("plan", "business")
      checkoutForm.append("billing_frequency", "monthly")
      checkoutForm.append(
        "success_redirect",
        "https://content.quillnow.com//payment/success"
      )
      checkoutForm.append(
        "cancel_redirect",
        "https://content.quillnow.com//payment/success"
      )
      dispatch(createCheckoutSession(checkoutForm)) */
    },
    [subscriptionPlan]
  )

  const handlePortal = () => {
    dispatch(postSubscriptionPortal())
  }

  const pricingOptions = useMemo(() => {
    if (!subscriptionPlan) {
      return []
    }
    const planOrder = ["essential", "standard", "business"]

    return Object.keys(subscriptionPlan)
      .map(key => {
        let icon = ""
        switch (key) {
          case "business":
            icon = "cycling"
            break
          case "essential":
            icon = "walking"
            break
          case "standard":
            icon = "run"
            break
          default:
            icon = ""
        }

        return {
          name: key,
          icon: icon,
          ...subscriptionPlan[key],
        }
      })
      .sort((a, b) => planOrder.indexOf(a.name) - planOrder.indexOf(b.name))
  }, [subscriptionPlan])

  const { subscription } = user
  return (
    <div className="page-content">
      <MetaTags>
        <title>
          Profile | QuillNow
        </title>
      </MetaTags>
      <Container fluid>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-0 font-size-18">Profile</h4>
              <div className="page-title-right">
                <Breadcrumb breadcrumbItems={items} />
                <Link to={`/profile/${user?.firebase_id}`}>
                  <label
                    htmlFor="upload"
                    className="btn btn-info d-block w-100 mb-2"
                  >
                    {" "}
                    PREVIEW PROFILE
                  </label>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="email-leftbar card">
              <div className="text-center">
                <div className="me-3 mb-4">
                  {photo ? (
                    <ImageBlob
                      src={photo}
                      alt=""
                      className="avatar-lg rounded-circle img-thumbnail"
                    />
                  ) : (
                    <img
                      src={avatarPlaceholder}
                      alt=""
                      className="avatar-lg rounded-circle img-thumbnail"
                    />
                  )}
                </div>
                <div className="mb-5">
                  <h5 className="">
                    {user.profile?.first_name} {user.profile?.last_name}
                  </h5>
                  <p className="text-muted mb-1">
                    @{user.profile?.display_name}
                  </p>
                  <p className="text-muted mb-0">{user.profile?.email}</p>
                </div>
              </div>
              <input type="file" id="upload" hidden onChange={onFileChange} />

              {userPhoto?.selectedFile ? (
                <>
                  <button
                    type="button"
                    onClick={onFileUpload}
                    className="btn btn-outline-info d-block w-100 mb-5"
                  >
                    UPLOAD NEW AVATAR
                  </button>
                </>
              ) : (
                <>
                  <label
                    htmlFor="upload"
                    className="btn btn-info d-block w-100 mb-5"
                  >
                    {" "}
                    CHANGE AVATAR
                  </label>
                </>
              )}

              <div className="mb-5 text-center">
                <h5 className="mb-2 text-capitalize">Biography</h5>
                <p className="text-muted mb-1">
                  {user.profile?.bio
                    ? user.profile?.bio
                    : "Update Your Biography.."}
                </p>
              </div>
              <div className="mb-5 text-center">
                <h5 className="mb-2 text-capitalize">Short Biography</h5>
                <p className="text-muted mb-1">
                  {user.profile?.short_bio
                    ? user.profile?.short_bio
                    : "Update Your Short bio.."}
                </p>
              </div>
              <div className="mb-5 text-center">
                <h5 className="mb-2 text-capitalize">Writing Categories</h5>
                <div className="mail-list mt-1">
                  {user.profile?.writing_categories.length > 0 ? (
                    <div className="writing-categories">
                      {user &&
                        user.profile?.writing_categories &&
                        user.profile?.writing_categories?.map(
                          (category, index) => (
                            <span key={index} className="bg-info badge bg-primary">
                              {category}
                            </span>
                          )
                        )}
                    </div>
                  ) : (
                    <div className="writing-categories">
                      Update Your Writing Categories
                    </div>
                  )}
                </div>
              </div>
              <div className="mb-5 text-center">
                <h5 className="mb-2 text-center text-capitalize">
                  Publications
                </h5>
                {user.profile?.publications.length > 0 ? (
                  <ul className="list-unstyled product-list">
                    {user &&
                      user.profile?.publications &&
                      user.profile?.publications.map((publication, index) => (
                        <li key={index}>
                          <a href={publication.url}>
                            <i className="mdi mdi-chevron-right me-1" />{" "}
                            {publication.name}
                          </a>
                        </li>
                      ))}
                  </ul>
                ) : (
                  <ul className="list-unstyled product-list">
                    Update Your Publications
                  </ul>
                )}
              </div>
            </div>
            <div className="email-rightbar mb-3">
              <div className="card">
                <div className="card-body">
                  <ul className="nav-tabs-custom nav-justified nav nav-tabs">
                    {tabItems.map(({ id, title, icon }) => (
                      <TabItemComponent
                        key={title}
                        title={title}
                        icon={icon}
                        onItemClicked={() => setActive(id)}
                        isActive={active === id}
                      />
                    ))}
                  </ul>
                  {tabItems.map(({ id }) => {
                    return active === id && id === 1 ? (
                      <div className="tab-pane">
                        <div className="row mt-5">
                          <div className="col-sm-12">
                            <form
                              //{handleSubmit(submitUpdatedProfile)}
                              className="Form profile-form"
                            >
                              <div className="card-title">Basic Info</div>
                              <hr />
                              <div className="row mb-4">
                                <label
                                  htmlFor="horizontal-displayname-Input"
                                  className="col-sm-3 col-form-label form-label"
                                >
                                  Display Name
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    id="horizontal-displayname-Input"
                                    placeholder="Enter Your Display Name"
                                    type="text"
                                    className="form-control form-control"
                                    {...register("display_name")}
                                  />
                                </div>
                              </div>
                              <div className="row mb-4">
                                <label
                                  htmlFor="horizontal-firstname-Input"
                                  className="col-sm-3 col-form-label form-label"
                                >
                                  First name
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    id="horizontal-firstname-Input"
                                    placeholder="Enter Your First Name"
                                    type="text"
                                    className="form-control form-control"
                                    {...register("first_name")}
                                  />
                                </div>
                              </div>
                              <div className="row mb-4">
                                <label
                                  htmlFor="horizontal-lastname-Input"
                                  className="col-sm-3 col-form-label form-label"
                                >
                                  Last name
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    id="horizontal-lastname-Input"
                                    placeholder="Enter Your Last Name"
                                    type="text"
                                    className="form-control form-control"
                                    {...register("last_name")}
                                  />
                                </div>
                              </div>
                              <div className="row mb-4">
                                <label
                                  htmlFor="horizontal-email-Input"
                                  className="col-sm-3 col-form-label form-label"
                                >
                                  Email
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    id="horizontal-email-Input"
                                    placeholder="Enter Your Email ID"
                                    type="email"
                                    className="form-control form-control"
                                    {...register("email")}
                                  />
                                </div>
                              </div>
                              <div className="row mb-4">
                                <label
                                  htmlFor="horizontal-category-Input"
                                  className="col-sm-3 col-form-label form-label"
                                >
                                  Category
                                </label>
                                <div className="col-sm-9">
                                  <Controller
                                    control={control}
                                    name="options"
                                    render={({
                                      field: { onChange, value, ref },
                                    }) => (
                                      <Select
                                        inputRef={ref}
                                        value={(options || []).filter(c =>
                                          value?.includes(c.value)
                                        )}
                                        onChange={val =>
                                          onChange(val.map(c => c.value))
                                        }
                                        options={options}
                                        isMulti
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="card-title mt-5">About Me</div>
                              <hr />
                              <div className="row mb-4">
                                <label
                                  htmlFor="horizontal-bio-Input"
                                  className="col-sm-3 col-form-label form-label"
                                >
                                  BIO
                                </label>
                                <div className="col-sm-9">
                                  <textarea
                                    id="horizontal-bio-Input"
                                    placeholder="Enter Your BIO"
                                    type="text"
                                    className="form-control form-control"
                                    {...register("bio")}
                                  />
                                </div>
                              </div>
                              <div className="row mb-4">
                                <label
                                  htmlFor="horizontal-short-bio-Input"
                                  className="col-sm-3 col-form-label form-label"
                                >
                                  Short BIO
                                </label>
                                <div className="col-sm-9">
                                  <textarea
                                    id="horizontal-short-bio-Input"
                                    placeholder="Enter Your Shot BIO"
                                    type="text"
                                    className="form-control form-control"
                                    {...register("short_bio")}
                                  />
                                </div>
                              </div>
                              <div className="card-title mt-5">
                                Publications
                              </div>
                              <hr />

                              <div className="row mb-4">
                                <label
                                  htmlFor="horizontal-publication-Input"
                                  className="col-sm-3 col-form-label form-label"
                                >
                                  Create Publication
                                </label>
                                <div className="col-sm-9">
                                  <div className="mb-5">
                                    <button
                                      onClick={() => {
                                        setShowModal(true)
                                      }}
                                      type="button"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                      className="btn btn-light dropdown-toggle w-100 btn btn-#eff2f7"
                                    >
                                      <i className="mdi mdi-plus me-1" /> Add
                                    </button>
                                  </div>
                                  <div>
                                    {publications &&
                                      publications.map((publication, index) => (
                                        <div
                                          key={index}
                                          className="table-responsive mb-4"
                                        >
                                          <h5>Publication {index + 1}</h5>
                                          <table className="table-nowrap mb-0 table">
                                            <tbody>
                                              <tr>
                                                {publication.name && (
                                                  <>
                                                    <th scope="row">
                                                      {" "}
                                                      Publication Name
                                                    </th>
                                                    <td>{publication.name}</td>
                                                  </>
                                                )}
                                              </tr>
                                              <tr>
                                                {publication.url && (
                                                  <>
                                                    <th scope="row">
                                                      Publication URL
                                                    </th>
                                                    <td>{publication.url}</td>
                                                  </>
                                                )}
                                              </tr>
                                              <tr>
                                                {publication.publish_type && (
                                                  <>
                                                    <th scope="row">
                                                      Publication Type
                                                    </th>
                                                    <td>
                                                      {publication.publish_type}
                                                    </td>
                                                  </>
                                                )}
                                              </tr>
                                              <tr>
                                                {publication.engagement_type && (
                                                  <>
                                                    <th scope="row">
                                                      Engagement Name
                                                    </th>
                                                    <td>
                                                      {
                                                        publication.engagement_type
                                                      }
                                                    </td>
                                                  </>
                                                )}
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div className="row justify-content-end mt-4">
                                <div className="col-sm-9">
                                  <div className="button-profile">
                                    <button
                                      onClick={handleSubmit(
                                        submitUpdatedProfile
                                      )}
                                      className="w-md btn btn-info"
                                    >
                                      Edit
                                    </button>
                                    <button
                                      onClick={handleSubmit(
                                        resetUpdatedProfile
                                      )}
                                      className="w-md btn btn-outline-info"
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    ) : active === id && id === 2 ? (
                      <div className="tab-pane">
                        <div className="row mt-5">
                          <div className="col-sm-12">
                            <div className="justify-content-center row">
                              <div className="col-lg-6">
                                <div className="text-center mb-5">
                                  <h4>Update your Subscription plan</h4>
                                  <p className="text-muted">
                                    To achieve this, it would be necessary to
                                    have uniform grammar, pronunciation and more
                                    common words If several languages coalesce
                                  </p>
                                </div>
                                <div className="slideToggle">
                                  <label className="form-switch">
                                    <span className="beforeinput text-info">
                                      MONTHLY
                                    </span>
                                    <input
                                      className="bg-info"
                                      type="checkbox"
                                      id="js-contcheckbox"
                                      onChange={e => handleChangePlan(e)}
                                    />
                                    <i></i>
                                    <span className="afterinput">ANNUAL</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row justify-content-center">
                              {pricingOptions &&
                                pricingOptions.map((plan, index) => (
                                  <Subscription
                                    key={index}
                                    name={plan.name}
                                    tagline={plan.tag_line}
                                    features={plan.features}
                                    prices={plan.prices}
                                    icon={plan.icon}
                                    type={planSubscribe}
                                    currectSubscription={subscription}
                                  />
                                ))}

                              {/*  <Subscription
                                name="Standard"
                                tagline={subscriptionPlan?.standard?.tag_line}
                                features={subscriptionPlan?.standard?.features}
                                prices={subscriptionPlan?.standard?.prices}
                                type={planSubscribe}
                                //onClick={handleSubscriptionBusiness}
                                onClick={handlePaymentStandard}
                                icon={"run"}
                              />
                              <Subscription
                                name="Business"
                                tagline={subscriptionPlan?.business?.tag_line}
                                features={subscriptionPlan?.business?.features}
                                prices={subscriptionPlan?.business?.prices}
                                type={planSubscribe}
                                //onClick={handleSubscriptionBusiness}
                                onClick={handlePaymentBusiness}
                                icon={"cycling"}
                              /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    // ) : active === id && id === 3 ? (
                    //   (<div className="tab-pane">
                    //     <div className="row mt-5">
                    //       <div className="justify-content-center row">
                    //         <div className="col-lg-6">
                    //           <div className="text-center mb-5">
                    //             <h4>Integrations</h4>
                    //           </div>
                    //         </div>
                    //       </div>
                    //       <div className="col-sm-12">
                    //         <table className="table-nowrap align-middle mb-0 table">
                    //           <tbody>
                    //             {/* Medium */}
                    //             <tr>
                    //               <th scope="row">
                    //                 <div className="d-flex align-items-center">
                    //                   <div className="avatar-xs me-3">
                    //                     <span className="avatar-title rounded-circle bg-info bg-info text-white font-size-18">
                    //                       <i className="fab fa-medium-m" />
                    //                     </span>
                    //                   </div>
                    //                   <span>Meduim</span>
                    //                 </div>
                    //               </th>
                    //               <td style={{ width: 220 }}>
                    //                 <a
                    //                   className="btn btn-info btn-sm w-xs"
                    //                   onClick={() => setMediumModal(true)}
                    //                 >
                    //                   Connect Medium
                    //                 </a>
                    //               </td>
                    //             </tr>
                    //             {/* Google */}
                    //             <tr>
                    //               <th scope="row">
                    //                 <div className="d-flex align-items-center">
                    //                   <div className="avatar-xs me-3">
                    //                     <span className="avatar-title rounded-circle bg-info bg-info text-white font-size-18">
                    //                       <i className="fab fa-google" />
                    //                     </span>
                    //                   </div>
                    //                   <span>Google</span>
                    //                 </div>
                    //               </th>
                    //               <td style={{ width: 220 }}>
                    //                 <a
                    //                   className="btn btn-info btn-sm w-xs"
                    //                   onClick={handleGetAtuthGoogle}
                    //                 >
                    //                   Connect Google
                    //                 </a>
                    //               </td>
                    //             </tr>
                    //             {/* Twitter */}
                    //             <tr>
                    //               <th scope="row">
                    //                 <div className="d-flex align-items-center">
                    //                   <div className="avatar-xs me-3">
                    //                     <span className="avatar-title rounded-circle bg-info bg-info text-white font-size-18">
                    //                       <i className="fab fa-twitter" />
                    //                     </span>
                    //                   </div>
                    //                   <span>Twitter</span>
                    //                 </div>
                    //               </th>
                    //               <td style={{ width: 220 }}>
                    //                 <a
                    //                   className="btn btn-info btn-sm w-xs"
                    //                   onClick={handleGetAtuthTwitter}
                    //                 >
                    //                   Connect Twitter
                    //                 </a>
                    //               </td>
                    //             </tr>
                    //           </tbody>
                    //         </table>
                    //       </div>
                    //     </div>
                    //   </div> /* : active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */ /*: active === id && id === 4 ? (
                    //
                    // ) */)
                    ) : (
                      ""
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <CustomModal
        title="Create Publication"
        isShowing={showModal}
        hide={() => setShowModal(false)}
      >
        <ModalHeader toggle={() => setShowModal(false)}>
          Create Publication
        </ModalHeader>
        <ModalBody>
          <div className="row mb-4">
            <label
              htmlFor="horizontal-name-Input"
              className="col-sm-3 col-form-label form-label"
            >
              Name
            </label>
            <div className="col-sm-9">
              <input
                id="horizontal-name-Input"
                placeholder="Enter Name"
                type="text"
                className="form-control form-control"
                {...register("pub_name")}
              />
            </div>
          </div>
          <div className="row mb-4">
            <label
              htmlFor="horizontal-url-Input"
              className="col-sm-3 col-form-label form-label"
            >
              URL
            </label>
            <div className="col-sm-9">
              <input
                id="horizontal-url-Input"
                placeholder="Enter Your URL"
                type="text"
                className="form-control form-control"
                {...register("pub_url")}
              />
            </div>
          </div>
          <div className="row mb-4">
            <label
              htmlFor="horizontal-publishing_type-Input"
              className="col-sm-3 col-form-label form-label"
            >
              Publishing Type
            </label>
            <div className="col-sm-9">
              <input
                id="horizontal-publishing_type-Input"
                placeholder="Enter Your Publishing Type"
                type="text"
                className="form-control form-control"
                {...register("pub_type")}
              />
            </div>
          </div>
          <div className="row mb-4">
            <label
              htmlFor="horizontal-engagement_type-Input"
              className="col-sm-3 col-form-label form-label"
            >
              Engagement Type
            </label>
            <div className="col-sm-9">
              <input
                id="horizontal-engagement_type-Input"
                placeholder="Enter Your Engagement Type"
                type="text"
                className="form-control form-control"
                {...register("pub_engagement")}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={handleSubmit(handleCreatePublication)}
            color="primary"
          >
            Add
          </Button>{" "}
          <Button onClick={() => setShowModal(false)}>Cancel</Button>
        </ModalFooter>
      </CustomModal>

      {/* Medium Modal */}
      <CustomModal
        title="Connect Medium"
        isShowing={mediumModal}
        hide={() => setMediumModal(false)}
      >
        <ModalHeader toggle={() => setMediumModal(false)}>
          Connect Medium
        </ModalHeader>
        <Form onSubmit={handleSubmit(submitMediumForm)}>
          <ModalBody>
            <input
              placeholder="Add medium API Key"
              rows={3}
              type="textarea"
              name="apiKey"
              className={`form-control ${
                errors.apiKey?.message && "is-invalid"
              }`}
              {...register("apiKey")}
            />
            {errors.apiKey?.message && (
              <div className="invalid-feedback text-capitalize">
                {errors.apiKey?.message}
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="primary">
              Connect
            </Button>{" "}
            <Button onClick={() => setMediumModal(false)}>Cancel</Button>
          </ModalFooter>
        </Form>
      </CustomModal>
    </div>
  )
}

const TabItemComponent = ({
  title = "",
  icon = "",
  onItemClicked = () => console.error("You passed no action to the component"),
  isActive = false,
}) => {
  return (
    <li className="nav-item" onClick={onItemClicked}>
      <a
        className={isActive ? "active nav-link" : "nav-link"}
        style={{ cursor: "pointer" }}
      >
        <span className="d-block d-sm-none">
          <i className={`fas fa-${icon}`} />
        </span>
        <span className="d-none d-sm-block">{title}</span>
      </a>
    </li>
  )
}
TabItemComponent.propTypes = {
  isActive: PropTypes.bool,
  onItemClicked: PropTypes.func,
  onMainItemClicked: PropTypes.func,
  title: PropTypes.string,
  icon: PropTypes.string,
}

export default Profile
