import React, { useState } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import { useForm } from "react-hook-form"
import { Modal, Form, Button } from "react-bootstrap"
import ActTag from "./ActTag"
import ScreenplayEditor from "components/Screenplay/ScreenplayEditor"
import Breadcrumb from "components/Common/Breadcrumb2"
import { Link } from "react-router-dom"
import { withSignup } from "hooks/withSignup"

const Screenplay = () => {
  const [showModal, setShowModal] = useState(false)
  const screenplay_id = 1
  const handleAddAct = (actTitle, actTags, actDescription) => {
    // Add the act to the screenplay using the screenplay_id
  }
  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const items = [
    { title: "Home", link: "/home" },
    { title: "Screenplay", link: "/screenplay" },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Screenplay | QuillNow
          </title>
        </MetaTags>
        <Container fluid>
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18">New Screenplay</h4>
                <div className="page-title-right">
                  <Breadcrumb breadcrumbItems={items} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="mb-2 row">
              <div className="col-sm-4">
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <label htmlFor="search-bar-0" className="search-label">
                      <span id="search-bar-0-label" className="sr-only">
                        Search in this List
                      </span>
                      <input
                        id="search-bar-0"
                        type="text"
                        aria-labelledby="search-bar-0-label"
                        className="form-control "
                        placeholder="Search"
                        defaultValue=""
                      />
                    </label>
                    <i className="bx bx-search-alt search-icon" />
                  </div>
                </div>
              </div>
              <div className="col-sm-8">
                <div className="text-sm-end">
                <Link to="/screenplays">
                  <button
                    type="button"
                    className="btn-rounded mb-2 me-2 btn btn-success"
                  >
                    List Screenplays
                  </button>
                  </Link>
                </div>
              </div>
            </div>
          <ScreenplayEditor />
          </div>
          {/* <Timeline /> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

const AddAct = props => {
  //const { register, handleSubmit, errors } = useForm()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const onSubmit = async data => {
    const { actTitle, actTags, actDescription } = data
    props.onAddAct(actTitle, actTags, actDescription)
    props.toggleModal()
  }

  return (
    <Modal show={props.showModal} onHide={props.toggleModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add Act</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <Form.Label>Act Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter act title"
              {...register({ required: true }, { name: "actTitle" })}
            />
            {errors.actTitle && <p>This field is required</p>}
          </Form.Group>
          <Form.Group>
            <Form.Label>Act Tags (optional, comma separated)</Form.Label>
            <ActTag register={register} errors={errors} name="actTags" />
          </Form.Group>
          <Form.Group>
            <Form.Label>Act Description (optional)</Form.Label>
            <Form.Control
              as="textarea"
              rows="3"
              placeholder="Enter act description"
              {...register({ name: "actDescription" })}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.toggleModal}>
          Close
        </Button>
        <Button variant="primary" type="submit">
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

AddAct.propTypes = {
  onAddAct: PropTypes.any,
  toggleModal: PropTypes.any,
  showModal: PropTypes.any,
}

export default withSignup(Screenplay)
