import {Col, Row} from "reactstrap";
import React from "react";
import PropTypes from "prop-types";

const ProjectActionBar = ({onClick}) => (
    <Row>
        <Col className="col-sm-8 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
            <div className="page-title-box d-sm-flex align-items-start justify-content-between align-content-start">
                    <button type="button" className="btn-rounded mb-2 me-2 btn btn-primary"
                            onClick={onClick}>
                        New Project
                    </button>
            </div>
        </Col>
    </Row>
)
ProjectActionBar.propTypes = {
    onClick: PropTypes.func
}

export default ProjectActionBar
