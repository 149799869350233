import {
  GET_IDEAS,
  GET_IDEAS_FAIL,
  GET_IDEAS_SUCCESS,
  GET_IDEA_DETAIL,
  ADD_NEW_IDEA,
  ADD_IDEA_SUCCESS,
  ADD_IDEA_FAIL,
  UPDATE_IDEA,
  UPDATE_IDEA_SUCCESS,
  UPDATE_IDEA_FAIL,
  DELETE_IDEA,
  DELETE_IDEA_SUCCESS,
  DELETE_IDEA_FAIL,
  GET_IDEA_DETAIL_FAIL,
  GET_IDEA_DETAIL_SUCCESS,
  
} from "./actionTypes"

export const getIdeas = () => ({
  type: GET_IDEAS,
})

export const getIdeasSuccess = idea => ({
  type: GET_IDEAS_SUCCESS,
  payload: { idea },
})

export const getIdeasFail = error => ({
  type: GET_IDEAS_FAIL,
  payload: { error },
})

export const addNewIdea = (idea, history) => ({
  type: ADD_NEW_IDEA,
  payload: { idea, history },
})

export const addIdeaSuccess = idea => ({
  type: ADD_IDEA_SUCCESS,
  payload: idea,
})

export const addIdeaFail = error => ({
  type: ADD_IDEA_FAIL,
  payload: error,
})

export const updateIdea = (idea, id, history) => ({
  type: UPDATE_IDEA,
  payload: { idea, id, history },
})

export const updateIdeaSuccess = idea => ({
  type: UPDATE_IDEA_SUCCESS,
  payload: { idea },
})

export const updateIdeaFail = error => ({
  type: UPDATE_IDEA_FAIL,
  payload: { error },
})

export const deleteIdea = id => ({
  type: DELETE_IDEA,
  payload: { id },
})

export const deleteIdeaSuccess = id => ({
  type: DELETE_IDEA_SUCCESS,
  payload: { id },
})

export const deleteIdeaFail = error => ({
  type: DELETE_IDEA_FAIL,
  payload: { error },
})

export const getIdeaDetail = id => ({
  type: GET_IDEA_DETAIL,
  payload: { id },
})

export const getIdeaDetailSuccess = idea => ({
  type: GET_IDEA_DETAIL_SUCCESS,
  payload: { idea },
})

export const getIdeaDetailFail = error => ({
  type: GET_IDEA_DETAIL_FAIL,
  payload: { error },
})
