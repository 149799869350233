import { takeEvery, put, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import { CREATE_CHECKOUT_SESSION } from "./actionTypes"

import {
  checkoutSessionSuccess,
  checkoutSessionFail,
} from "./actions"

//Include Both Helper File with needed methods
import { postCheckoutSession } from "../../helpers/backend_helper"

function* onCreateCheckoutSession({ payload: checkout }) {
  try {
    const response = yield call(postCheckoutSession, checkout)
    window.location.href = response
    yield put(checkoutSessionSuccess(response))
  } catch (error) {
    yield put(checkoutSessionFail(error))
  }
}

function* authCheckoutSaga() {
  yield takeEvery(CREATE_CHECKOUT_SESSION, onCreateCheckoutSession)
}

export default authCheckoutSaga
