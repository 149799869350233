/**
  * get score
  */
 export const GET_SCORES = "GET_SCORES"
 export const GET_SCORES_BY_PARA = "GET_SCORE_BY_PARA"
 export const GET_SCORE_FAIL = "GET_SCORE_FAIL"
 export const GET_SCORE_SUCCESS = "GET_SCORE_SUCCESS"
 export const GET_SCORES_BY_PARA_SUCCESS = "GET_SCORES_BY_PARA_SUCCESS"
 export const GET_SCORES_BY_PARA_FAIL = "GET_SCORES_BY_PARA_FAIL"

