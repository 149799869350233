import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button, Tooltip } from "reactstrap"
import CardSection from "../common/card-section"
import PsychologicalProfile from "../assistant-scores/PsychologicalProfile"
import OffenseProfile from "../assistant-scores/OffenseProfile"
import BiasProfile from "../assistant-scores/BiasProfile"
import AttitudeProfile from "../assistant-scores/AttitudeProfile"
import InformationProfile from "../assistant-scores/InformationProfile"
import { IoReload } from "react-icons/io5"

const SidebarScore = ({
  activeCard,
  sidebarOpen,
  toggleSidebar,
  isLoading,
  loading,
  lastUpdated,
  onRefreshClick,
  isRefreshDisabled,
  ...props
}) => {
  const [activeSection, setActiveSection] = useState(null)
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen)

  const toggleSection = id => {
    setActiveSection(activeSection === id ? null : id)
  }

  const data = [
    { variant: "success", percentage: 30 },
    { variant: "info", percentage: 40 },
    { variant: "warning", percentage: 30 },
  ]

  return (
    <div
      className={`collapse-sidebar ${
        sidebarOpen ? "sidebar-collapse-open" : "sidebar-collapse-close"
      }`}
    >
      {sidebarOpen && (
        <div>
          <Button
            color="primary"
            onClick={toggleSidebar}
            className="toggle-button"
          >
            HIDE ASSISTANT
          </Button>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0 1em",
              marginTop: "1em",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <IoReload
                id="TooltipRefresh"
                onClick={onRefreshClick}
                size={30}
                style={{
                  cursor: isRefreshDisabled ? "not-allowed" : "pointer",
                  fontSize: "20px",
                }}
                className={isRefreshDisabled ? "disabled-icon" : ""}
              />
              <Tooltip
                placement="top"
                isOpen={tooltipOpen && !isRefreshDisabled}
                target="TooltipRefresh"
                toggle={toggleTooltip}
              >
                Click to Refresh
              </Tooltip>

              {/* {isRefreshDisabled && (
                <>
                  <IoReload
                    id="TooltipRefresh"
                    onClick={onRefreshClick}
                    size={30}
                    style={{ cursor: "pointer", fontSize: "20px" }}
                  />
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen}
                    target="TooltipRefresh"
                    toggle={toggleTooltip}
                  >
                    Click to Refresh
                  </Tooltip>
                </>
              )} */}
              <p style={{ margin: "5px 0 0 0", fontSize: "12px" }}>
                {lastUpdated
                  ? `Last Updated: ${new Date(
                      lastUpdated
                    ).toLocaleTimeString()}`
                  : ""}
              </p>
            </div>
          </div>
        </div>
      )}
      <div className="sidebar-card__container">
        <hr />
        <CardSection
          title="Psychometric Profile"
          isLoading={isLoading}
          id="psychological-profile"
          isOpen={activeSection === "psychological-profile"}
          toggle={toggleSection}
        >
          <PsychologicalProfile
            error={props.error}
            emotionData={props.emotionScore}
            sentimentData={props.sentimentScore}
            empathyData={props.empathyScore}
            engagementData={props.engagementScore}
          />
        </CardSection>
        <hr />
        <CardSection
          title="Information Profile"
          isLoading={isLoading}
          id="information-profile"
          isOpen={activeSection === "information-profile"}
          toggle={toggleSection}
        >
          <InformationProfile
            complexityData={props.complexityScore}
            synthesisData={props.fakenessScore}
            informativenessData={props.informativenessScore}
          />
        </CardSection>
        <hr />
        <CardSection
          title="Bias Profile"
          isLoading={isLoading}
          id="bias-profile"
          isOpen={activeSection === "bias-profile"}
          toggle={toggleSection}
        >
          <BiasProfile
            error={props.error}
            biasData={props.biasScore}
            cognitiveBiasData={props.cognitiveBiasScore}
            subjectiveBiasData={props.subjectiveBiasScore}
            politicalBiasData={props.politicalBiasScore}
            genderBiasData={props.genderBiasScore}
          />
        </CardSection>
        <hr />
        <CardSection
          title="Offense Profile"
          isLoading={isLoading}
          id="offense-profile"
          isOpen={activeSection === "offense-profile"}
          toggle={toggleSection}
        >
          <OffenseProfile
            error={props.error}
            racismSexismData={props.racismSexismScore}
            hateSpeechData={props.hateSpeechScore}
            toxicityData={props.toxicityScore}
          />
        </CardSection>
        <hr />
        <CardSection
          title="Attitude Profile"
          isLoading={isLoading}
          id="attitude-profile"
          isOpen={activeSection === "attitude-profile"}
          toggle={toggleSection}
        >
          <AttitudeProfile
            error={props.error}
            patronizeData={props.patronizeScore}
          />
        </CardSection>
      </div>
    </div>
  )
}

// Define propTypes
SidebarScore.propTypes = {
  activeCard: PropTypes.bool.isRequired,
  sidebarOpen: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  overAllReadability: PropTypes.number.isRequired,
  readabilityScore: PropTypes.object.isRequired,
  lexicalRichnessScoreAverage: PropTypes.number.isRequired,
  statsContentRender: PropTypes.array.isRequired,
  fakenessScoreAverage: PropTypes.number.isRequired,
  fakenessScore: PropTypes.object.isRequired,
  headlineScoreAverage: PropTypes.number.isRequired,
  headlineUpdatedScore: PropTypes.object.isRequired,
  biasScoreAverage: PropTypes.number.isRequired,
  biasScore: PropTypes.object.isRequired,
  toxicityScore: PropTypes.object.isRequired,
  cognitiveBiasScoreAverage: PropTypes.number.isRequired,
  cognitiveBiasScore: PropTypes.object.isRequired,
  politicalBiasScoreAverage: PropTypes.number.isRequired,
  politicalBiasScore: PropTypes.object.isRequired,
  complexityScore: PropTypes.object.isRequired,
  informativenessScore: PropTypes.object.isRequired,
  emotionScore: PropTypes.shape({
    happy: PropTypes.number.isRequired,
    surprise: PropTypes.number.isRequired,
    sad: PropTypes.number.isRequired,
    fear: PropTypes.number.isRequired,
    angry: PropTypes.number.isRequired,
  }).isRequired,
  /* emotionScore: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired, */
  empathyScore: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  engagementScore: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  sentimentScore: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  hateSpeechScore: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  racismSexismScore: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  /* toxicityScore: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired, */
  patronizeScore: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  subjectiveBiasScore: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  genderBiasScore: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  reportBiasScore: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  lastUpdated: PropTypes.any.isRequired,
  onRefreshClick: PropTypes.func.isRequired,
  error: PropTypes.any.isRequired,
  isRefreshDisabled: PropTypes.bool.isRequired,
}

export default SidebarScore
