import {
  GET_PROJECTS,
  GET_PROJECTS_FAIL,
  GET_PROJECTS_SUCCESS,
  GET_PROJECT_DETAIL_FAIL,
  GET_PROJECT_DETAIL_SUCCESS,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_FAIL,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAIL,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
  GET_PROJECT_SUMMARY_FAIL,
  GET_PROJECT_SUMMARY_SUCCESS,
  GET_PROJECT_TYPES_SUCCESS,
  GET_PROJECT_TYPES_FAIL,
  GET_PROJECT_CATEGORIES_SUCESS,
  GET_PROJECT_CATEGORIES_FAIL,
  ADD_CONTENT_PROJECT,
  ADD_CONTENT_SUCCESS,
  ADD_CONTENT_FAIL,
  GET_CONTENT_PROJECT,
  GET_CONTENT_PROJECT_SUCCESS,
  GET_CONTENT_PROJECT_FAIL,
  /* Add content to existing project */
  ADD_CONTENT_TO_PROJECT,
  ADD_CONTENT_TO_SUCCESS,
  ADD_CONTENT_TO_FAIL,
  /* get content by project id*/
  GET_CONTENT_STATS,
  GET_CONTENT_STATS_SUCCESS,
  GET_CONTENT_STATS_FAIL,
  /** Content by Id */
  GET_CONTENT_ID,
  GET_CONTENT_ID_SUCCESS,
  GET_CONTENT_ID_FAIL,
  /** update Content by Id */
  UPDATE_CONTENT_ID,
  UPDATE_CONTENT_ID_SUCCESS,
  UPDATE_CONTENT_ID_FAIL,
  /** search */
  SEARCH_CONTENT,
  SEARCH_CONTENT_SUCCESS,
  SEARCH_CONTENT_FAIL,
  /** update Content by Id */
  DELETE_CONTENT_ID,
  DELETE_CONTENT_ID_SUCCESS,
  DELETE_CONTENT_ID_FAIL,

  /** Start project */
  ADD_PROJECT_START_SUCCESS,
  ADD_PROJECT_START_FAIL,

  /** UPDATE */
  ADD_CONTENT_TO_PROJECTS,
  ADD_CONTENT_TO_SUCCESS_S,
  ADD_CONTENT_TO_FAIL_S,
  GET_CONTENT_STATS_BY_ID,
  GET_CONTENT_STATS_BY_ID_SUCCESS,
  GET_CONTENT_STATS_BY_ID_FAIL,

  /** Create Project */
  START_CREATE_PROJECT_SUCCESS,
  START_CREATE_PROJECT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  projects: [],
  stats: null,
  contentProject: null,
  contentsProject: null,
  contentStatsProject: null,
  types: [],
  categories: [],
  summary: {},
  projectDetail: {},
  isLoading: false,
  error: {},
  contents: null,
  msg: "",
  contentState: null,
}

const projects = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROJECTS:
      return {
        ...state,
        projects: action.payload,
        isLoading: true,
      }

    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.payload,
        isLoading: false,
      }

    case GET_PROJECTS_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    case ADD_PROJECT_SUCCESS:
      return {
        ...state,
        projects: [...state.projects, action.payload],
        isLoading: false,
      }

    case ADD_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    case ADD_PROJECT_START_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    //projects: [...state.projects, action.payload],

    case ADD_PROJECT_START_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }

    case GET_PROJECT_DETAIL_SUCCESS:
      return {
        ...state,
        projectDetail: action.payload,
        isLoading: false,
      }

    case UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        /* projects: state.projects.map(project =>
          project.id.toString() === action.payload.id.toString()
            ? { project, ...action.payload }
            : project
        ), */
        isLoading: false,
      }

    case UPDATE_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }

    // Create Project
    case START_CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }

    case START_CREATE_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }

    case DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }

    case DELETE_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROJECT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }

    case GET_PROJECT_SUMMARY_SUCCESS:
      return {
        ...state,
        summary: action.payload,
        isLoading: true,
      }

    case GET_PROJECT_SUMMARY_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    case GET_PROJECT_TYPES_SUCCESS:
      return {
        ...state,
        types: action.payload,
        isLoading: true,
      }

    case GET_PROJECT_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    case GET_PROJECT_CATEGORIES_SUCESS:
      return {
        ...state,
        categories: action.payload,
        isLoading: true,
      }

    case GET_PROJECT_CATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    case ADD_CONTENT_PROJECT:
      return {
        ...state,
        msg: action.payload,
        isLoading: true,
      }
    case ADD_CONTENT_SUCCESS:
      return {
        ...state,
        contents: [...state.contents, action.payload],
        isLoading: false,
      }

    case ADD_CONTENT_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    case ADD_CONTENT_TO_PROJECT:
      return {
        ...state,
        msg: action.payload,
        isLoading: true,
      }
    case ADD_CONTENT_TO_SUCCESS:
      return {
        ...state,
        msg: action.payload,
        isLoading: false,
      }

    case ADD_CONTENT_TO_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }

    case GET_CONTENT_PROJECT:
      return {
        ...state,
        contents: action.payload,
        isLoading: true,
      }
    case GET_CONTENT_PROJECT_SUCCESS:
      return { ...state, contents: action.payload }

    case GET_CONTENT_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    /* STATS */

    case GET_CONTENT_STATS:
      return {
        stats: action.payload,
        isLoading: true,
      }

    case GET_CONTENT_STATS_SUCCESS:
      return {
        stats: action.payload,
        isLoading: false,
      }

    case GET_CONTENT_STATS_FAIL:
      return {
        error: action.payload,
        isLoading: false,
      }

    /* CONTENT STATE BY ID */

    case GET_CONTENT_STATS_BY_ID:
      return {
        ...state,
        contentStatsProject: action.payload,
        isLoading: true,
      }

    case GET_CONTENT_STATS_BY_ID_SUCCESS:
      return {
        ...state,
        contentStatsProject: action.payload,
        isLoading: false,
      }

    case GET_CONTENT_STATS_BY_ID_FAIL:
      return {
        error: action.payload,
        isLoading: false,
      }

    /* CONTENT BY ID */

    case GET_CONTENT_ID:
      return {
        ...state,
        contentsProject: action.payload,
        isLoading: true,
      }

    case GET_CONTENT_ID_SUCCESS:
      return {
        ...state,
        contentsProject: action.payload,
        isLoading: false,
      }

    case GET_CONTENT_ID_FAIL:
      return {
        error: action.payload,
        isLoading: false,
      }

    /* UPDATE CONTENT BY ID */

    case UPDATE_CONTENT_ID:
      return {
        ...state,
        isLoading: true,
      }

    case UPDATE_CONTENT_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }

    case UPDATE_CONTENT_ID_FAIL:
      return {
        error: action.payload,
        isLoading: false,
      }

    /* SEARCH CONTENT */

    case SEARCH_CONTENT:
      return {
        ...state,
        isLoading: true,
      }

    case SEARCH_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }

    case SEARCH_CONTENT_FAIL:
      return {
        error: action.payload,
        isLoading: false,
      }

    /* DELETE CONTENT BY ID */

    case DELETE_CONTENT_ID:
      return {
        ...state,
        isLoading: true,
      }

    case DELETE_CONTENT_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }

    case DELETE_CONTENT_ID_FAIL:
      return {
        error: action.payload,
        isLoading: false,
      }

    /* update CONTENT BY ID */

    case ADD_CONTENT_TO_PROJECTS:
      return {
        ...state,
        isLoading: true,
      }

    case ADD_CONTENT_TO_SUCCESS_S:
      return {
        ...state,
        isLoading: false,
      }

    case ADD_CONTENT_TO_FAIL_S:
      return {
        error: action.payload,
        isLoading: false,
      }
    default:
      return state
  }
}

export default projects
