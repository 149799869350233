import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import {
  EDIT_PROFILE,
  GET_USER_INFO,
  UPDATE_USER_PROFILE,
  GET_USER_PHOTO,
  GET_USER_SHOWCASE,
  UPLOAD_USER_PHOTO,
} from "./actionTypes"
import {
  profileSuccess,
  profileError,
  updateUserProfileSuccess,
  updateUserProfileError,
  getUserPhotoSuccess,
  getUserPhotoError,
  getUserShowcaseSuccess,
  getUserShowcaseError,
  uploadUserPhotoSuccess,
  uploadUserPhotoError,
} from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  getUserInfo,
  updateUserProfile,
  getUserPhoto,
  getUserShowcase,
  postUserPhoto,
} from "../../../helpers/backend_helper"
import { toast } from "react-toastify"

const fireBaseBackend = getFirebaseBackend()

function* editProfile({ payload: { user } }) {
  try {
    const response = yield call(
      fireBaseBackend.editProfileAPI,
      user.username,
      user.idx
    )
    yield put(profileSuccess(response))
  } catch (error) {
    yield put(profileError(error))
  }
}

function* onGetUserInfo() {
  try {
    const response = yield call(getUserInfo)
    yield put(profileSuccess(response))
  } catch (error) {
    yield put(profileError(error))
  }
}

function* onUpdateUserInfo({ payload: { user } }) {
  try {
    const response = yield call(updateUserProfile, user)
    yield put(updateUserProfileSuccess(response))
    toast.success("Your has been updated", {
      position: toast.POSITION.TOP_RIGHT,
    })
  } catch (error) {
    toast.error("Your not updated, please try again", {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(updateUserProfileError(error))
  }
}

function* onGetUserPhoto() {
  try {
    const response = yield call(getUserPhoto)
    yield put(getUserPhotoSuccess(response))
  } catch (error) {
    yield put(getUserPhotoError(error))
  }
}

/* Showcase */
function* onGetUserShowcase({ payload: { user } }) {
  try {
    const response = yield call(getUserShowcase, user)
    yield put(getUserShowcaseSuccess(response))
  } catch (error) {
    yield put(getUserShowcaseError(error))
  }
}

/* Upload photo */
function* onPostUserPhoto({ payload: photo }) {
  try {
    const response = yield call(postUserPhoto, photo)
    yield put(uploadUserPhotoSuccess(response))
    toast.success("Your Photo has been updated", {
      position: toast.POSITION.TOP_RIGHT,
    })
  } catch (error) {
    yield put(uploadUserPhotoError(error))
  }
}

export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile)
  yield takeEvery(GET_USER_INFO, onGetUserInfo)
  yield takeEvery(UPDATE_USER_PROFILE, onUpdateUserInfo)
  yield takeEvery(GET_USER_PHOTO, onGetUserPhoto)
  yield takeEvery(GET_USER_SHOWCASE, onGetUserShowcase)
  yield takeEvery(UPLOAD_USER_PHOTO, onPostUserPhoto)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
