import {
  ADD_SCENE_SUCCESS,
  ADD_SCENE_FAIL,
  REORDER_SCENES_SUCCESS,
  REORDER_SCENES_FAIL,
  DELETE_SCENE_SUCCESS,
  DELETE_SCENE_FAIL
} from './actionTypes';

const initialState = {
  scenes: [],
  scene: null,
  isLoading: false,
  error: null
};

export default function sceneReducer(state = initialState, action) {
  switch (action.type) {
      case ADD_SCENE_SUCCESS:
          return {
              ...state,
              scenes: [...state.scenes, action.payload],
              scene: action.payload,
              isLoading: false
          };
      case ADD_SCENE_FAIL:
          return {
              ...state,
              error: action.payload,
              isLoading: false
          };
      case REORDER_SCENES_SUCCESS:
          return {
              ...state,
              scenes: action.payload,
              isLoading: false
          };
      case REORDER_SCENES_FAIL:
          return {
              ...state,
              error: action.payload,
              isLoading: false
          };
      case DELETE_SCENE_SUCCESS:
          return {
              ...state,
              scenes: state.scenes.filter(scene => scene.id !== action.payload),
              scene: action.payload,
              isLoading: false
          };
      case DELETE_SCENE_FAIL:
          return {
              ...state,
              error: action.payload,
              isLoading: false
          };
      default:
          return state;
  }
}
