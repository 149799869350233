import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col
} from "reactstrap"

const NoteReadability = () => {
  /*  90 - 100: 'very easy'
    80 - 89:  'easy'
    70 - 79:  'fairly easy'
    60 - 69:  'standard'
    50 - 59:  'fairly difficult'
    30 - 49:  'difficult'
    < 30:     'very confusing' */
  const scores = [
    {
      id: "1",
      score: "100 – 90",
      //school_level: "5th grade",
      notes: "Very easy",
    },
    {
      id: "2",
      score: "80 - 89",
      //school_level: "6th grade",
      notes: "Easy",
    },
    {
      id: "3",
      score: "70 - 79",
      //school_level: "7th grade",
      notes: "Fairly easy",
    },
    {
      id: "4",
      score: "60 - 69",
      school_level: "8th & 9th grade",
      notes: "Standard",
    },
    {
      id: "5",
      score: "50 - 59",
      //school_level: "10th to 12th grade",
      notes: "Fairly difficult",
    },
    {
      id: "6",
      score: "30 - 49",
      //school_level: "College",
      notes: "Difficult",
    },
    {
      id: "7",
      score: "< 30",
      //school_level: "College graduate",
      notes: "Very confusing",
    },
  ]

  const scoresForeign = [
    {
      id: "1",
      score: "1 - 20",
      //school_level: "College",
      notes: "Very easy",
    },
    {
      id: "2",
      score: "21 - 25",
      //school_level: "College",
      notes: "Quite easy",
    },
    {
      id: "3",
      score: "26 - 29",
      //school_level: "College",
      notes: "Little difficult",
    },
    {
      id: "4",
      score: "< 30",
      //school_level: "College graduate",
      notes: "Very confusing",
    },
  ]

  const headerSortingStyle = {
    backgroundColor: "#f8f9fa",
    borderColor: "#eff2f7",
    color: "#495057",
  }

  const columns = [
    {
      text: "Reading Ease(score)",
      dataField: "score",
      sort: true,
      headerSortingStyle,
      Cell: row => (
        <div>
          <span title={row.value}>{row.value}</span>
        </div>
      ),
    },
    {
      text: "Notes",
      dataField: "notes",
      sort: true,
      headerSortingStyle,
      Cell: row => (
        <div>
          <span title={row.value}>{row.value}</span>
        </div>
      ),
    },
  ]

  const columnsForeign = [
    {
      text: "Foreign Reading Ease(score)",
      dataField: "score",
      sort: true,
      headerSortingStyle,
      Cell: row => (
        <div>
          <span title={row.value}>{row.value}</span>
        </div>
      ),
    },
    {
      text: "Notes",
      dataField: "notes",
      sort: true,
      headerSortingStyle,
      Cell: row => (
        <div>
          <span title={row.value}>{row.value}</span>
        </div>
      ),
    },
  ]

  return (
      <Container style={{width: "100%", marginTop: "8px"}}>
        <Row id="reading-ease-exp-row">
          <Col className="col-6" id="reading-ease-exp-card">
            <Card style={{float: "left", width: "100%"}}>
              <CardHeader>
                <h3>Reading Ease</h3>
              </CardHeader>
              <CardBody>
                <BootstrapTable keyField="id" data={scores} columns={columns}/>
              </CardBody>
            </Card>
          </Col>
          <Col className="col-6" id="foreign-reader-reading-ease-exp-card">
            <Card style={{float: "right", width: "100%"}}>
              <CardHeader>
                <h3>Foreign Reader Reading Ease</h3>
              </CardHeader>
              <CardBody>
                <BootstrapTable
                    keyField="id"
                    data={scoresForeign}
                    columns={columnsForeign}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
  )
}

export default NoteReadability
