import React from "react"
import PropTypes from "prop-types"
import {
  Card,
  CardTitle,
  CardText,
  Button,
  Row,
  Col,
  Container,
} from "reactstrap"
import { FaTrash, FaPencilAlt } from "react-icons/fa"
import { Link } from "react-router-dom"

const formatDate = date => {
  return new Date(date).toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  })
}

const ScriptCard = ({
  title,
  dateCreated,
  removeScreenplay,
  updateScreenplay,
  id,
}) => {
  return (
    <Card body style={{ borderRadius: "10px", boxShadow: "0 0 10px #ccc" }}>
      <Container fluid>
        <Row>
          <Col xs="8">
            <Link style={{ color: "#000" }} to={`/screenplay/${id}`}>
              <CardTitle
                style={{
                  fontSize: "1.5em",
                  fontWeight: "bold",
                  marginBottom: "10px",
                }}
              >
                {title}
              </CardTitle>
            </Link>
            <CardText
              style={{
                fontSize: "0.9em",
                marginBottom: "10px",
                color: "gray",
              }}
            >
              Date Created: {formatDate(dateCreated)}
            </CardText>
          </Col>
          <Col xs="4" className="text-right">
            <Button
              onClick={removeScreenplay}
              color="danger"
              size="sm"
              style={{ marginRight: "10px" }}
            >
              <FaTrash />
            </Button>
            <Link to={`/screenplay/edit/${id}`}>
              <Button color="warning" size="sm">
                <FaPencilAlt />
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </Card>
  )
}

ScriptCard.propTypes = {
  title: PropTypes.string.isRequired,
  dateCreated: PropTypes.string.isRequired,
  removeScreenplay: PropTypes.func,
  updateScreenplay: PropTypes.func,
  id: PropTypes.string.isRequired,
}

export default ScriptCard
