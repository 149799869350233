import React, { useEffect, useState, useMemo } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Breadcrumb from "components/Common/Breadcrumb2"
import LexDiversityTabComponent from "components/Tabs/tab-lex-diversity"
import SummaryTabComponent from "components/Tabs/summary-tab"
import TextStatsTabComponent from "components/Tabs/tab-text-stats"
import ReadabilityTabComponent from "components/Tabs/tab-readability"
import EmotionTabComponent from "components/Tabs/tab-emotion"
import { getContentById, getContentStatsById } from "store/actions"

const mainTabItems = [
  {
    id: 1,
    title: "Overview",
  },
  {
    id: 2,
    title: "Text Stats",
  },
  {
    id: 3,
    title: "Readability",
  },
  {
    id: 4,
    title: "Lexical Richness",
  },
  {
    id: 5,
    title: "Emotions",
  },
]

const tabItems = [
  {
    id: 1,
    title: "Overall",
  },
  {
    id: 2,
    title: "Paragraph",
  },
  {
    id: 3,
    title: "Line",
  },
]

const getBreadcrumbItems = projectId => [
  {
    title: "Documents",
    link: location.pathname.includes("analyze")
      ? `/analyze/project/documents/list/${projectId}`
      : `/project/documents/list/${projectId}`,
  },
  { title: "Analytics", link: "#" },
]

const Composer = () => {
  const dispatch = useDispatch()
  const { project, content } = useParams()

  // Tabs States
  const [activeMain, setActiveMain] = useState("1")
  const [active, setActive] = useState(3)
  const [activeLex, setActiveLex] = useState(2)

  const {
    contentsProject: projectOverall,
    contentStatsProject: documentStats,
    isLoading,
  } = useSelector(state => state.projects)

  useEffect(() => {
    dispatch(getContentById(content, project))
    dispatch(getContentStatsById(content, project))
  }, [content, project])

  const statsContentRender = useMemo(
    () => documentStats?.document_stats,
    [documentStats]
  )
  const attributesRender = useMemo(
    () => documentStats?.attributes,
    [documentStats]
  )
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Content Analytics</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-0 font-size-18">Content Analytics</h4>
            <div className="page-title-right">
              <Breadcrumb breadcrumbItems={getBreadcrumbItems(project)} />
            </div>
          </div>
          <div>
            <Nav tabs>
              {mainTabItems.map(tabItem => (
                <NavItem key={tabItem.id}>
                  <NavLink
                    className={
                      activeMain === tabItem.id.toString() ? "active" : ""
                    }
                    onClick={() => {
                      setActiveMain(tabItem.id.toString())
                    }}
                  >
                    {tabItem.title}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={activeMain}>
              <TabPane tabId="1">
                <SummaryTabComponent
                  documentStatsAttributes={attributesRender}
                  isLoading={isLoading}
                />
              </TabPane>
              <TabPane tabId="2">
                <TextStatsTabComponent
                  active={active}
                  setActive={setActive}
                  tabItems={tabItems}
                  dataTextStats={statsContentRender}
                  isLoading={isLoading}
                />
              </TabPane>
              <TabPane tabId="3">
                <ReadabilityTabComponent
                  active={active}
                  setActive={setActive}
                  tabItems={tabItems}
                  dataReadability={statsContentRender}
                  isLoading={isLoading}
                />
              </TabPane>
              <TabPane tabId="4">
                <LexDiversityTabComponent
                  active={activeLex}
                  setActive={setActiveLex}
                  tabItems={tabItems}
                  dataLexDiversity={statsContentRender}
                  isLoading={isLoading}
                />
              </TabPane>
              <TabPane tabId="5">
                <EmotionTabComponent
                  active={active}
                  setActive={setActive}
                  tabItems={tabItems}
                  dataEmotion={statsContentRender}
                  isLoading={isLoading}
                />
              </TabPane>
            </TabContent>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Composer
