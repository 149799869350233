import { call, all, put, takeEvery, takeLatest } from "redux-saga/effects"

// Projects Redux States
import {
  GET_PROJECTS,
  GET_PROJECT_SUMMARY,
  GET_PROJECT_DETAIL,
  ADD_NEW_PROJECT,
  DELETE_PROJECT,
  UPDATE_PROJECT,
  GET_PROJECT_TYPES,
  GET_PROJECT_CATEGORIES,
  ADD_CONTENT_PROJECT,
  GET_CONTENT_PROJECT,
  GET_CONTENT_STATS,
  GET_CONTENT_ID,
  UPDATE_CONTENT_ID,
  DELETE_CONTENT_ID,
  ADD_START_PROJECT,
  ADD_CONTENT_TO_PROJECT,
  GET_CONTENT_STATS_BY_ID,
  GET_CONTENT_STATS_BY_ID_SUCCESS,
  GET_CONTENT_STATS_BY_ID_FAIL,
  ADD_CONTENTS_TO_PROJECT,
  UPDATE_CONTENT_BY_ID,
  START_CREATE_PROJECT,
} from "./actionTypes"
import {
  getProjectsSuccess,
  getProjectsFail,
  getProjectSummarySuccess,
  getProjectSummaryFail,
  getProjectDetailSuccess,
  getProjectDetailFail,
  addProjectFail,
  addProjectSuccess,
  updateProjectSuccess,
  updateProjectFail,
  deleteProjectSuccess,
  deleteProjectFail,
  getProjectTypesSuccess,
  getProjectCategoriesSuccess,
  getProjectTypesFail,
  getProjectCategoriesFail,
  addContentProjectSuccess,
  addContentProjectFail,
  getContentByProjectSuccess,
  getContentByProjectFail,
  getContentStatsSuccess,
  getContentStatsFail,
  getContentByIdSuccess,
  getContentByIdFail,
  updateContentByIdSuccess,
  updateContentByIdFail,
  deleteContentByIdSuccess,
  deleteContentByIdFail,
  addStartProjectSuccess,
  addStartProjectFail,
  getContentStatsById,
  getContentByStatIdSuccess,
  getContentStatByIdFail,
  createProjectSuccess,
  createProjectFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getProjects,
  getProjectsDetails,
  addNewProject,
  updateProject,
  deleteProject,
  getProjectSummary,
  getProjectTypes,
  getProjectCategories,
  addContentToProject,
  getContentByProjectId,
  getContentStats,
  getContentById,
  updateContentById,
  deleteContentById,
  createProject,
} from "helpers/backend_helper"
import { toast } from "react-toastify"

function* fetchProjects() {
  try {
    const response = yield call(getProjects)
    yield put(getProjectsSuccess(response))
  } catch (error) {
    yield put(getProjectsFail(error))
  }
}

function* fetchProjectDetail(payload) {
  const { projectId } = payload
  try {
    const response = yield call(getProjectsDetails, projectId)
    yield put(getProjectDetailSuccess(response))
  } catch (error) {
    yield put(getProjectDetailFail(error))
  }
}

function* fetchProjectSummary({ payload: content }) {
  try {
    const response = yield call(getProjectSummary, content)
    yield put(getProjectSummarySuccess(response))
  } catch (error) {
    yield put(getProjectSummaryFail(error))
  }
}

// GET state/project/content/
function* onGetContentStateProject({ payload: { projectId, contentId } }) {
  //console.log('payload', payload)
  //const { projectId, contentId } = payload
  try {
    const response = yield call(getContentStats, contentId, projectId)
    yield put(getContentByStatIdSuccess(response))
  } catch (error) {
    yield put(getContentStatByIdFail(error))
  }
}

function* onUpdateProject({ payload: { id, project } }) {
  try {
    const response = yield call(updateProject, id, project)
    yield put(updateProjectSuccess(response))
    toast.success("Project updated", {
      position: toast.POSITION.TOP_CENTER,
    })
  } catch (error) {
    yield put(updateProjectFail(error))
    toast.error("Project could not be updated", {
      position: toast.POSITION.TOP_CENTER,
    })
  }
}

function* onCreateProject({ payload: { id, project } }) {
  try {
    const response = yield call(createProject, id, project)
    yield put(createProjectSuccess(response))
    toast.success("Project created", {
      position: toast.POSITION.TOP_CENTER,
    })
  } catch (error) {
    yield put(createProjectFail(error))
    toast.error("Project could not be created", {
      position: toast.POSITION.TOP_CENTER,
    })
  }
}

function* onDeleteProject({ payload: payload }) {
  try {
    const response = yield call(deleteProject, payload)
    yield put(deleteProjectSuccess(response))
    toast.success("Project deleted", {
      position: toast.POSITION.TOP_CENTER,
    })
  } catch (error) {
    yield put(deleteProjectFail(error))
  }
}
// Choosing Project Starter
/* function* onStartProject({ payload: { projects, history } }) {
  //const type = projects.get("project_type")
  try {
    const response = yield call(addNewProject, projects)
    const { project_id } = response
    yield put(addStartProjectSuccess(response))
    // add project to get content_id
    const contentFormData = new FormData()

    contentFormData.append("title", projects.title)
    contentFormData.append("content", "")
    const project = yield call(addContentToProject, project_id, contentFormData)
    const { content_id } = project
    //history.push(`/composer/${type}/${response.project_id}`)
    history.push(`/composer/project/${project_id}/${content_id}`)
  } catch (error) {
    yield put(addStartProjectFail(error))
    toast.warning(
      "Just retry again after a short while as the content is probably being analyzed",
      {
        position: toast.POSITION.TOP_RIGHT,
      }
    )
  }
} */

function* onStartProject({ payload: { projects, history } }) {
  try {
    const response = yield call(addNewProject, projects)
    const { project_id } = response
    yield put(addStartProjectSuccess(response))

    // Prepare the form data for addContentToProject
    const contentFormData = new FormData()
    const title = projects.get("title")
    contentFormData.append("title", title)
    contentFormData.append("content", "")

    // Call addContentToProject with the project_id from the addNewProject response
    const project = yield call(addContentToProject, project_id, contentFormData)
    const { document_id } = project

    history.push(`/project/composer/project/${project_id}/${document_id}`)
  } catch (error) {
    yield put(addStartProjectFail(error))
    toast.warning(
      "Retry again later as the content is currently being analyzed",
      {
        position: toast.POSITION.TOP_RIGHT,
      }
    )
  }
}

function* onAddNewProject({ payload: project }) {
  const { projectId, projectInfo, history } = project

  const delay = time => new Promise(resolve => setTimeout(resolve, time))

  try {
    const project = yield call(addContentToProject, projectId, projectInfo)
    yield call(delay, 3000)
    const content = yield call(getContentStats, projectId, project.document_id)

    yield put(getContentStatsSuccess(content))
    toast.success("Project created", {
      position: toast.POSITION.TOP_CENTER,
    })

    history.push(`/project/documents/list/${projectId}`)
  } catch (error) {
    yield put(getContentStatsFail(error))
    /*  toast.warning(
      "You have reached the maximum number of content allowed for your account, Please upgrade your account to continue immediately",
      {
        position: toast.POSITION.TOP_RIGHT,
      }
    ) */
  }
}

function* fetchProjectTypes() {
  try {
    const response = yield call(getProjectTypes)
    yield put(getProjectTypesSuccess(response))
  } catch (error) {
    yield put(getProjectTypesFail(error))
  }
}

function* fetchProjectCategories() {
  try {
    const response = yield call(getProjectCategories)
    yield put(getProjectCategoriesSuccess(response))
  } catch (error) {
    yield put(getProjectCategoriesFail(error))
  }
}

/* function* onAddNewContent({ payload: { projects }}) {
  const { projectId, projectInfo } = projects;
  try {
    const response = yield call(addContentToProject, projectId, projectInfo)
    yield put(addContentProjectSuccess(response))
  } catch (error) {
    yield put(addContentProjectFail(error))
  }
} */

function* onAddContentProject({ payload: { projectId, projectInfo } }) {
  try {
    const response = yield call(addContentToProject, projectId, projectInfo)
    yield put(addContentProjectSuccess(response))
    if (response.document_id.includes("False")) {
      toast.warning("Reached maximum project limit for your subscribed plan")
    }
  } catch (error) {
    yield put(addContentProjectFail(error))
  }
}

function* onGetContentProject({ payload: { projectId } }) {
  try {
    const response = yield call(getContentByProjectId, projectId)
    yield put(getContentByProjectSuccess(response))
  } catch (error) {
    yield put(getContentByProjectFail(error))
  }
}

function* onGetContentById({ payload: { projectId, contentId } }) {
  try {
    const response = yield call(getContentById, contentId, projectId)
    yield put(getContentByIdSuccess(response))
  } catch (error) {
    yield put(getContentByIdFail(error))
  }
}

function* onUpdateContentById({
  payload: { projectId, contentId, contentInfo },
}) {
  //const { history, type } = additionalContentInfo
  try {
    const response = yield call(
      updateContentById,
      projectId,
      contentId,
      contentInfo
    )
    //history.push(`/project/contents/list/${type}/${projectId}`)
    yield put(updateContentByIdSuccess(response))
    toast.success("The Content update was successfully", {
      position: toast.POSITION.TOP_CENTER,
    })
  } catch (error) {
    yield put(updateContentByIdFail(error))
  }
}

function* onDeleteContentById({ payload: { projectId, contentId } }) {
  try {
    const response = yield call(deleteContentById, projectId, contentId)
    yield put(deleteContentByIdSuccess(response))
  } catch (error) {
    yield put(deleteContentByIdFail(error))
  }
}

// **** //
// Worker Saga to Add Content to Project
/* function* addContentToProjectSaga(action) {
  const { projectId, data } = action.payload

  try {
    const response = yield call(addContentToProject, projectId, data)

    // Here we suppose the response has a 'content_id' field
    const { content_id } = response

    // Call updateContentById API once we get content_id from addContentToProject
    yield call(updateContentByIdSaga, {
      payload: { projectId, contentId: content_id, data },
    })
  } catch (error) {
    // Handle your error here
    console.error(error)
  }
} */

// Worker Saga to Add Content to Project
function* addContentToProjectSaga(action) {
  const { projectId, content_id, data } = action.payload

  try {
    // Call updateContentById API once we get content_id from addContentToProject
    yield call(updateContentByIdSaga, {
      payload: { projectId, contentId: content_id, data },
    })
  } catch (error) {
    // Handle your error here
    console.error(error)
  }
}

// Worker Saga to Update Content by Id
function* updateContentByIdSaga(action) {
  const { projectId, contentId, data } = action.payload

  try {
    yield call(updateContentById, projectId, contentId, data)
  } catch (error) {
    // Handle your error here
    console.error(error)
  }
}

// Watcher saga
function* watchAddContentToProjectSaga() {
  yield takeLatest(ADD_CONTENTS_TO_PROJECT, addContentToProjectSaga)
}

// Another watcher saga for updateContentById
function* watchUpdateContentByIdSaga() {
  yield takeLatest(UPDATE_CONTENT_BY_ID, updateContentByIdSaga)
}

function* projectsSaga() {
  yield takeEvery(GET_PROJECTS, fetchProjects)
  yield takeEvery(GET_PROJECT_DETAIL, fetchProjectDetail)
  yield takeEvery(GET_CONTENT_STATS_BY_ID, onGetContentStateProject)
  yield takeEvery(ADD_CONTENT_PROJECT, onAddNewProject)
  yield takeEvery(ADD_START_PROJECT, onStartProject)
  yield takeEvery(UPDATE_PROJECT, onUpdateProject)
  yield takeEvery(DELETE_PROJECT, onDeleteProject)
  yield takeEvery(GET_PROJECT_SUMMARY, fetchProjectSummary)
  yield takeEvery(GET_PROJECT_TYPES, fetchProjectTypes)
  yield takeEvery(GET_PROJECT_CATEGORIES, fetchProjectCategories)
  yield takeEvery(ADD_CONTENT_TO_PROJECT, onAddContentProject)
  yield takeEvery(GET_CONTENT_PROJECT, onGetContentProject)
  yield takeEvery(GET_CONTENT_ID, onGetContentById)
  yield takeEvery(UPDATE_CONTENT_ID, onUpdateContentById)
  yield takeEvery(DELETE_CONTENT_ID, onDeleteContentById)
  yield takeEvery(START_CREATE_PROJECT, onCreateProject)
  yield all([watchAddContentToProjectSaga(), watchUpdateContentByIdSaga()])
}

export default projectsSaga
