import React from "react"
import errorImg from "../assets/images/error-img.png"

const ProfileNotFound = () => {
  return (
    <div className="account-pages my-5 pt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center mb-5">
              <h1 className="display-2 fw-medium">
                4<i className="bx bx-buoy bx-spin text-primary display-3" />4
              </h1>
              <h4 className="text-uppercase">Sorry, The Profile Not Found</h4>
            </div>
          </div>
        </div>
        <div className="justify-content-center row">
          <div className="col-md-8 col-xl-6">
            <div>
              <img src={errorImg} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileNotFound
