import {
  GET_ACCESS_GOOGLE_SUCCESS,
  GET_GOOGLE_API_ERROR,
} from "./actionTypes"

const initialState = {
  redirectUrl: "",
  error: "",
  loading: false,
}

const google = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCESS_GOOGLE_SUCCESS:
      return {
        ...state,
        redirectUrl: action.payload,
        loading: true,
      }
      break
    case GET_GOOGLE_API_ERROR:
      return { ...state, error: action.payload, loading: false }
      break
    default:
      return state
  }
}

export default google
