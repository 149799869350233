import {
  GET_USER_SUBSCRIPTION_INFO,
  GET_USER_SUBSCRIPTION_INFO_SUCCESS,
  GET_USER_SUBSCRIPTION_INFO_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  subscribe: {},
  success: "",
  loading: false,
}

const userSubscription = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_SUBSCRIPTION_INFO:
      return {
        ...state,
        subscribe: action.payload,
        loading: true,
      }
    case GET_USER_SUBSCRIPTION_INFO_SUCCESS:
      return {
        ...state,
        subscribe: action.payload,
        loading: false,
      }
    case GET_USER_SUBSCRIPTION_INFO_ERROR:
      return {
        ...state,
        subscribeError: action.payload,
        loading: false,
      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default userSubscription
