/* FEEDBACK REQUEST */
// POST /api/feedback/request
export const REQUEST_FEEDBACK = "REQUEST_FEEDBACK"
export const REQUEST_FEEDBACK_SUCCESS = "REQUEST_FEEDBACK_SUCCESS"
export const REQUEST_FEEDBACK_FAIL = "REQUEST_FEEDBACK_FAIL"

// GET /api/feedback/request/<feedback_req_id>
export const GET_FEEDBACK_REQUEST = "GET_FEEDBACK_REQUEST"
export const GET_FEEDBACK_REQUEST_SUCCESS = "GET_FEEDBACK_REQUEST_SUCCESS"
export const GET_FEEDBACK_REQUEST_FAIL = "GET_FEEDBACK_REQUEST_FAIL"

// GET /api/feedback/request/list
export const GET_FEEDBACK_REQUEST_LIST = "GET_FEEDBACK_REQUEST_LIST"
export const GET_FEEDBACK_REQUEST_LIST_SUCCESS = "GET_FEEDBACK_REQUEST_LIST_SUCCESS"
export const GET_FEEDBACK_REQUEST_LIST_FAIL = "GET_FEEDBACK_REQUEST_LIST_FAIL"

// GET /api/feedback/request/self/list
export const GET_MY_FEEDBACK_REQUEST_LIST = "GET_MY_FEEDBACK_REQUEST_LIST"
export const GET_MY_FEEDBACK_REQUEST_LIST_SUCCESS = "GET_MY_FEEDBACK_REQUEST_LIST_SUCCESS"
export const GET_MY_FEEDBACK_REQUEST_LIST_FAIL = "GET_MY_FEEDBACK_REQUEST_LIST_FAIL"

// GET /api/feedback/comment/self/list
export const GET_MY_FEEDBACK_COMMENT_LIST = "GET_MY_FEEDBACK_COMMENT_LIST"
export const GET_MY_FEEDBACK_COMMENT_LIST_SUCCESS = "GET_MY_FEEDBACK_COMMENT_LIST_SUCCESS"
export const GET_MY_FEEDBACK_COMMENT_LIST_FAIL = "GET_MY_FEEDBACK_COMMENT_LIST_FAIL"

// PUT /api/feedback/request/close/<feedback_req_id>
export const CLOSE_FEEDBACK_REQUEST = "CLOSE_FEEDBACK_REQUEST"
export const CLOSE_FEEDBACK_REQUEST_SUCCESS = "CLOSE_FEEDBACK_REQUEST_SUCCESS"
export const CLOSE_FEEDBACK_REQUEST_FAIL = "CLOSE_FEEDBACK_REQUEST_FAIL"

/* FEEDBACK */
// POST /api/feedback/comment/<feedback_req_id>
export const SEND_FEEDBACK = "SEND_FEEDBACK"
export const SEND_FEEDBACK_SUCCESS = "SEND_FEEDBACK_SUCCESS"
export const SEND_FEEDBACK_FAIL = "SEND_FEEDBACK_FAIL"
