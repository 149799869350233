import { call, put, takeEvery } from "redux-saga/effects"

import { GET_HOME_DATA } from "./actionTypes";
import { getHomeDataSuccess, getHomeDataFail } from "./actions";
import {
    homeData
} from "helpers/backend_helper"

function* fetchHomeData() {
    try {
        const response = yield call(homeData);
        yield put(getHomeDataSuccess(response));
    } catch (error) {
        yield put(getHomeDataFail(error));
    }
}

function* homeSaga() {
    yield takeEvery(GET_HOME_DATA, fetchHomeData)
}

export default homeSaga
