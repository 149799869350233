import {
  GET_ACCESS_GOOGLE,
  GET_ACCESS_GOOGLE_SUCCESS,
  GET_GOOGLE_API_ERROR,
} from "./actionTypes"

export const getAccessGoogle = () => {
  return {
    type: GET_ACCESS_GOOGLE,
  }
}

export const getAccessGoogleSuccess = redirectUrl => {
  return {
    type: GET_ACCESS_GOOGLE_SUCCESS,
    payload: redirectUrl,
  }
}

export const getApiGoogleError = error => {
  return {
    type: GET_GOOGLE_API_ERROR,
    payload: error,
  }
}
