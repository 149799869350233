import {
  ADD_ACT,
  ADD_ACT_SUCCESS,
  ADD_ACT_FAIL,
  REORDER_ACTS,
  REORDER_ACTS_SUCCESS,
  REORDER_ACTS_FAIL,
  DELETE_ACT,
  DELETE_ACT_SUCCESS,
  DELETE_ACT_FAIL,
} from "./actionTypes"

const initialState = {
  acts: [],
  isLoading: false,
  error: null,
}

function actReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_ACT:
      return {
        ...state,
        isLoading: true,
      }
    case ADD_ACT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        acts: [...state.acts, action.payload],
      }
    case ADD_ACT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }
    case REORDER_ACTS:
      return {
        ...state,
        isLoading: true,
      }
    case REORDER_ACTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        acts: action.payload,
      }
    case REORDER_ACTS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }
    case DELETE_ACT:
      return {
        ...state,
        isLoading: true,
      }
    case DELETE_ACT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        acts: state.acts.filter(act => act.id !== action.payload),
      }
    case DELETE_ACT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default actReducer
