import React, { useCallback, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"
import Breadcrumb from "components/Common/Breadcrumb2"
import DatePicker from "react-datepicker"
import { addStartProject, getProjectDetail, updateProject } from "store/actions"
import * as moment from "moment"
import { withSignup } from "hooks/withSignup"
import { Link, useParams } from "react-router-dom"
import Spinner from "components/ui/spinner"

let objects = [
  { id: 1, value: "Posts", slug: "post", icon: "bx bxl-medium-square bx-lg" },
  { id: 2, value: "Article", slug: "article", icon: "bx bxs-planet bx-lg" },
  { id: 3, value: "Books", slug: "book", icon: "bx bxs-book bx-lg" },
  { id: 4, value: "Script", slug: "script", icon: "bx bx-code-alt bx-lg" },
]

const items = [
  { title: "Projects", link: "/projects" },
  {
    title: "Project Entry",
    link: "/project-entry",
  },
]

const Project = props => {
  const [project, setProject] = useState()
  const { projectDetail } = useSelector(state => state.projects)
  const [isEditMode, setIsEditMode] = useState(false)

  const dispatch = useDispatch()
  const { id } = useParams()

  useEffect(() => {
    if (id) {
      dispatch(getProjectDetail(String(id)))
      setIsEditMode(true)
    }
  }, [dispatch, id])

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    register("deadlineDate", {
      value: new Date("2024-05-24T00:00:00"),
      required: "Deadline Date is Required",
    })

    register("title", {
      required: "Title is required",
    })

    register("charCount", {
      required: "Character goal is required",
      validate: value => {
        return value > 0 || "Character goal cannot be a negative number or zero"
      },
    })
  }, [register])

  const handleProjectChange = useCallback(
    item => {
      setProject(item)
    },
    [project]
  )
  useEffect(() => {
    if (projectDetail && isEditMode) {
      setValue("title", projectDetail?.info?.title)
    }
  }, [projectDetail, isEditMode, setValue])

  const onDeadlineStateChange = deadlineState => {
    setValue("deadlineDate", deadlineState)
  }

  const deadlineDate = watch("deadlineDate")
  const [isAnalyzing, setIsAnalyzing] = useState(false)
  const [progress, setProgress] = useState(0)
  const [timeRemaining, setTimeRemaining] = useState(0)


  const onSubmit = useCallback(
    data => {
      const formDataPost = new FormData()
      const dateDeadline = String(moment(data?.deadline).format("DD-MM-YYYY"))
      formDataPost.append("title", data?.title)
      formDataPost.append("project_type", "article")
      formDataPost.append("target_char_count", data?.charCount)
      formDataPost.append("deadline", dateDeadline)
      formDataPost.append("prompt", data?.prompt)
      if (isEditMode) {
        dispatch(updateProject(String(id), formDataPost))
      } else {
        dispatch(addStartProject(formDataPost, props.history))
        setIsAnalyzing(true)
      }
    },
    [project, isEditMode]
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Projects</title>
        </MetaTags>
        <Container fluid>
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18">Projects</h4>
                <div className="page-title-right">
                  <Breadcrumb breadcrumbItems={items} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="card">
              <div className="card-body">

                {isAnalyzing ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      padding: "10px",
                      height: "500px",
                      heightMax: "100%",
                    }}
                  >
                    <Spinner />
                  </div>
                ) : (
                  <form>
                    <div>
                      <div className="mb-4 row mb-2">
                        <label className="col-form-label col-lg-2 form-label">
                          Title
                        </label>
                        <div className="col-lg-10">
                          <div className="pe-md-0 md-pe-3 col-md-6">
                            <input
                              id="title"
                              name="title"
                              placeholder="Enter Title"
                              type="text"
                              className={`form-control ${
                                errors.title?.message && "is-invalid"
                              }`}
                              {...register("title")}
                            />
                            {errors.title && (
                              <div className="invalid-feedback text-capitalize">
                                {errors.title?.message}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="mb-4 row mb-2">
                        <label className="col-form-label col-lg-2 form-label">
                          Character goal
                        </label>
                        <div className="col-lg-10">
                          <div className="pe-md-0 md-pe-3 col-md-6">
                            <input
                              id="charCount"
                              name="charCount"
                              placeholder="Enter Character goal..."
                              type="number"
                              min="0"
                              max="1000000" // Update max attribute value
                              className={`form-control ${
                                errors.charCount?.message && "is-invalid"
                              }`}
                              {...register("charCount", {
                                value: 1000,
                                required: "Character goal is required",
                                validate: value =>
                                  value <= 1000000 ||
                                  "Value must be less than or equal to 1,000,000",
                              })}
                            />
                            {errors.charCount && (
                              <div className="invalid-feedback text-capitalize">
                                {errors.charCount?.message}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="mb-4 row mb-2">
                        <label className="col-form-label col-lg-2 form-label">
                          Deadline
                        </label>
                        <div className="col-lg-10">
                          <div className="pe-md-0 md-pe-3 col-md-6">
                            <DatePicker
                              dateFormat="dd MMM, yyyy"
                              selected={deadlineDate}
                              onChange={onDeadlineStateChange}
                              className="form-control react-datepicker-ignore-onclickoutside"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mb-4 row mb-2">
                        <label className="col-form-label col-lg-2 form-label">
                          Prompt for generating content
                        </label>
                        <div className="col-lg-10">
                          <div className="pe-md-0 md-pe-3 col-md-6">
                            <textarea
                              maxLength="225"
                              rows="3"
                              placeholder="Enter prompt for the AI to generate content"
                              id="prompt"
                              name="prompt"
                              className="form-control"
                              {...register("prompt")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <Link to={"/projects"} style={{ marginRight: "12px" }}>
                        <button
                          //onClick={handleSubmit(submitUpdateContentForm)}
                          className="btn btn-composer mt-3"
                          style={{ backgroundColor: "#eee" }}
                        >
                          <i className="bx bx-arrow-back bx-sm"></i>
                          <span>Back</span>
                        </button>
                      </Link>
                      <button
                        form="hook-form"
                        className="btn btn-composer mt-3"
                        type="submit"
                        onClick={handleSubmit(onSubmit)}
                      >
                        {/* <i className="mdi mdi-page-next mdi-lg"></i> */}
                        <i className="bx bx-edit-alt bx-sm"></i>
                        <span>Save</span>
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

const ProjectCard = ({ values, selectedValue }) => {
  const [current, setCurrent] = useState()

  const handleClick = value => {
    selectedValue(value)
    setCurrent(value.id)
  }
  return (
    <div className="radio-with-Icon">
      {values.map(item => (
        <p
          key={item.id}
          className="radioOption-Item"
          onClick={() => handleClick(item)}
        >
          <div className="ng-valid ng-dirty ng-touched ng-empty">
            <label className={current === item.id ? "active" : ""}>
              <i className={item.icon}></i>
              {item.value}
            </label>
          </div>
        </p>
      ))}
    </div>
  )
}

ProjectCard.propTypes = {
  selectedValue: PropTypes.fun,
  values: PropTypes.array,
}

Project.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}
export default withSignup(Project)
