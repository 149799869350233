import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { Container, Row, Col, Button } from "reactstrap"
import MetaTags from "react-meta-tags"
import { useDispatch } from "react-redux"
import { deleteAct, getScreenplay, reorderActs } from "store/actions"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import AddActModal from "components/Act/AddActModal"
import Breadcrumb from "components/Common/Breadcrumb2"
import Act from "components/Act"
import { FaMinusCircle } from "react-icons/fa"
import AddSceneModal from "components/Scene/AddSceneModal"
import styles from "./styles"
//import AddActForm from './AddActForm';
import { FaTimes, FaPlus } from "react-icons/fa"
import { propTypes } from "react-bootstrap/esm/Image"
import { FaRegTrashAlt, FaRegPlusSquare } from "react-icons/fa"
import RemoveActModal from "components/Act/RemoveActModal"
import RemoveSceneModal from "components/Scene/RemoveSceneModal"

const ScreenplayPage = () => {
  const [modal, setModal] = useState(false)
  const [sceneModal, setSceneModal] = useState(false)
  const [removeActModal, setRemoveActModal] = useState(false)

  const [removeSceneModal, setRemoveSceneModal] = useState(false)

  const [act, setAct] = useState()

  const [sceneId, setSceneId] = useState()

  const dispatch = useDispatch()

  const { selectedScreenplay, loading, error } = useSelector(
    state => state.screenplayReducer
  )

  const { screenplay_id } = useParams()

  useEffect(() => {
    if (screenplay_id || selectedScreenplay?.acts) {
      dispatch(getScreenplay(screenplay_id))
    }
  }, [screenplay_id, dispatch])

  const onDragEnd = result => {
    if (!result.destination) {
      return
    }

    const reorderedActs = Array.from(selectedScreenplay?.acts)
    const [removed] = reorderedActs.splice(result.source.index, 1)
    reorderedActs.splice(result.destination.index, 0, removed)

    dispatch(reorderActs(reorderedActs, screenplay_id))
    //setActs(reorderedActs)

    console.log("reorderedActs", reorderedActs)
  }

  const [scenes, setScenes] = useState([
    {
      id: 1,
      scene_title: "Scene Title 01",
      scene_description: "Scene Description",
    },
    {
      id: 2,
      scene_title: "Scene Title 02",
      scene_description: "Scene Description",
    },
    {
      id: 3,
      scene_title: "Scene Title 03",
      scene_description: "Scene Description",
    },
  ])

  const onDragEndScene = result => {
    const { destination, source } = result

    if (!destination) {
      return
    }

    if (destination.index === source.index) {
      return
    }

    const newScenes = Array.from(scenes)
    const [removedScene] = newScenes.splice(source.index, 1)
    newScenes.splice(destination.index, 0, removedScene)

    setScenes(newScenes)
  }

  console.log("SCENES", scenes)

  /* if (!loading) {
    return <div>Loading...</div>
  } */

  const [activeAct, setActiveAct] = useState(null)

  const handleActClick = act => {
    setActiveAct(act)
  }

  // Context Menu
  const [contextMenu, setContextMenu] = useState({
    isVisible: false,
    x: 0,
    y: 0,
    accordionId: 0,
  })
  const handleContextMenu = (event, accordionId) => {
    event.preventDefault()
    setContextMenu({
      isVisible: true,
      x: event.clientX,
      y: event.clientY,
      accordionId: accordionId,
    })
  }

  const handleCloseContextMenu = () => {
    setContextMenu({
      isVisible: false,
      x: 0,
      y: 0,
      accordionId: 0,
    })
  }

  useEffect(() => {
    window.addEventListener("click", handleCloseContextMenu)

    return () => {
      window.removeEventListener("click", handleCloseContextMenu)
    }
  }, [])

  const showSceneModal = actId => {
    if (actId) {
      setAct(actId)
    }
    setSceneModal(true)
  }

  const addScene = actId => {
    if (actId) {
      setAct(actId)
    }
    setSceneModal(true)
  }

  const handleRemoveAct = actId => {
    if (actId) {
      setAct(actId)
    }
    setRemoveActModal(true)
  }

  const handleRemoveScene = (actId, sceneId) => {
    console.log('actId, sceneId', actId, sceneId)
    if (sceneId && actId) {
      setSceneId(sceneId)
      setAct(actId)
    }
    setRemoveSceneModal(true)
  }

  const handleDelete = (event, actId) => {
    try {
      dispatch()
    } catch (error) {
      console.log(error)
    }
  }
  /* const handleRemoveAct = (actId) => {
    try {
      dispatch(deleteAct(actId, screenplay_id))
    } catch (error) {
      console.log(error)
    }
  } */
  console.log("selectedScreenplay ===>", selectedScreenplay?.acts)

  const actsItem = [
    {
      number: 1,
      title: "Act 1",
      scenes: [
        { number: 1, description: "Scene 1 description" },
        { number: 2, description: "Scene 2 description" },
        { number: 3, description: "Scene 3 description" },
      ],
    },
    {
      number: 2,
      title: "Act 2",
      scenes: [
        { number: 1, description: "Scene 1 description" },
        { number: 2, description: "Scene 2 description" },
        { number: 3, description: "Scene 3 description" },
      ],
    },
  ]

  const [actsList, setActsList] = useState([
    {
      name: "Act 1",
      scenes: [
        { name: "Scene 1", description: "Description 1" },
        { name: "Scene 2", description: "Description 2" },
      ],
    },
  ])

  const addAct = () => {
    setActsList([...acts, { name: `Act ${acts.length + 1}`, scenes: [] }])
  }

  const removeAct = actIndex => {
    setActsList(acts.filter((_, index) => index !== actIndex))
  }

  /*  const addScene = actIndex => {
    const newActs = [...acts]
    newActs[actIndex].scenes.push({
      name: `Scene ${newActs[actIndex].scenes.length + 1}`,
      description: "",
    })
    setActsList(newActs)
  } */

  const removeScene = (actIndex, sceneIndex) => {
    const newActs = [...acts]
    newActs[actIndex].scenes = newActs[actIndex].scenes.filter(
      (_, index) => index !== sceneIndex
    )
    setActsList(newActs)
  }

  const [actsItems, setActsItems] = useState([
    {
      id: 1,
      title: "Act 1",
      scenes: [
        { id: 1, title: "Scene 1", description: "Scene description" },
        { id: 2, title: "Scene 2", description: "Scene description" },
        { id: 3, title: "Scene 3", description: "Scene description" },
        { id: 3, title: "Scene 3", description: "Scene description" },
        { id: 3, title: "Scene 3", description: "Scene description" },
        { id: 3, title: "Scene 3", description: "Scene description" },
      ],
    },
  ])

  const items = [
    { title: "Home", link: "/home" },
    { title: `Screenplay details`, link: `/screenplay/${selectedScreenplay?.id}` },
  ]

  return (
    <div className="page-content">
      <MetaTags>
        <title>
          Screenplay | QuillNow
        </title>
      </MetaTags>
      <Container fluid>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-0 font-size-18">Screenplay details</h4>
              <div className="page-title-right">
                <Breadcrumb breadcrumbItems={items} />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
      <div className="mb-2 row">
        <div className="col-sm-4">
          <div className="search-box me-2 mb-2 d-inline-block">
            <div className="position-relative">
              <label htmlFor="search-bar-0" className="search-label">
                <span id="search-bar-0-label" className="sr-only">
                  Search in this List
                </span>
                <input
                  id="search-bar-0"
                  type="text"
                  aria-labelledby="search-bar-0-label"
                  className="form-control "
                  placeholder="Search"
                  defaultValue=""
                />
              </label>
              <i className="bx bx-search-alt search-icon" />
            </div>
          </div>
        </div>
        <div className="col-sm-8">
          <div className="text-sm-end">
          <Link to="/screenplay">
            <button
              type="button"
              className="btn-rounded mb-2 me-2 btn btn-success"
            >
              Add new Screenplay
            </button>
            </Link>
          </div>
        </div>
      </div>
        <Row>
          <Col>
            <div className="screenplay">
              <main>
                <div className="screenplay-act-container">
                  <div className="screenplay-header">
                    <h1>{selectedScreenplay.title}</h1>
                    <p>{selectedScreenplay.content}</p>
                  </div>
                  <Col>
                    <Button color="primary" onClick={() => setModal(true)}>
                      Add Act
                    </Button>
                  </Col>
                </div>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {provided => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        <div className="storyboard">
                          {selectedScreenplay?.acts &&
                            selectedScreenplay?.acts.map((act, index) => (
                              <StoryboardAct
                                key={act.id}
                                index={index}
                                act={act}
                                removeAct={handleRemoveAct}
                                removeScene={handleRemoveScene}
                                addScene={addScene}
                              />
                            ))}
                        </div>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </main>
            </div>
          </Col>
        </Row>
        <AddActModal
          modal={modal}
          setModal={setModal}
          screenplay={selectedScreenplay}
        />
        <AddSceneModal
          modal={sceneModal}
          setModal={setSceneModal}
          screenplay={selectedScreenplay}
          act={act}
        />

        <RemoveActModal
          modal={removeActModal}
          setModal={setRemoveActModal}
          screenplay={selectedScreenplay}
          act={act}
        />

        <RemoveSceneModal
          modal={removeSceneModal}
          setModal={setRemoveSceneModal}
          screenplay={selectedScreenplay}
          act={act}
          scene={sceneId}
        />
        </div>
      </Container>
    </div>
  )
}

/* const CustomAct = ({ act, scenes, index, handleDelete, showSceneModal }) => {
  return (
    <Draggable className="acts" key={act.id} draggableId={act.id} index={index}>
      {provided => (
        <>
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className="act-header">
              <div>
                <button className="delete-button" onClick={handleDelete}>
                  <FaMinusCircle className="delete-icon" />
                </button>
              </div>
              <div>
                <h2>{act.title}</h2>
              </div>
              {provided.placeholder}
            </div>
            <div className={scenes?.length > 0 ? "acts" : "act-empty"}>
              <>
                {scenes?.length > 0 ? (
                  scenes.map(scene => <Scene key={scene.id} scene={scene} />)
                ) : (
                  <p>Add the scene to Act ...</p>
                )}
                <Button
                  className="button-scene"
                  color="primary"
                  onClick={() => showSceneModal(act.id)}
                >
                  Add Scene
                </Button>
              </>
            </div>
          </div>
        </>
      )}
    </Draggable>
  )
} */

/* CustomAct.propTypes = {
  scenes: PropTypes.any,
  act: PropTypes.any,
  index: PropTypes.number,
  handleDelete: PropTypes.func,
  showSceneModal: PropTypes.func,
} */

ScreenplayPage.propTypes = {
  match: PropTypes.object,
}

// new concept

const StoryboardAct = ({ act, addScene, removeAct, removeScene, index }) => {
  return (
    <Draggable className="" key={act.id} draggableId={act.id} index={index}>
      {provided => (
        <>
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className="storyboard__act">
              <div className="storyboard__act-header">
                <FaRegTrashAlt
                  className="storyboard__act-remove"
                  onClick={() => removeAct(act)}
                  size={20}
                />
                <div className="storyboard__act-header-title">{act.title}</div>
                <FaRegPlusSquare
                  className="storyboard__add-scene"
                  onClick={() => addScene(act.id)}
                  size={20}
                />
              </div>
              {act?.scenes.length > 0 ? (
                <div className="storyboard__scenes">
                  {act?.scenes.map((scene, index) => (
                    <StoryboardScene
                      key={scene.id}
                      index={index}
                      scene={scene}
                      act={act}
                      removeScene={removeScene}
                    />
                  ))}
                </div>
              ) : (
                <div className="storyboard__scenes-empty">
                  <p className="storyboard__scenes-headline">
                    Add scene to act...
                  </p>
                </div>
              )}
            </div>
            {provided.placeholder}
          </div>
        </>
      )}
    </Draggable>
  )
}

StoryboardAct.propTypes = {
  act: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  addScene: PropTypes.func,
  removeAct: PropTypes.func,
  removeScene: PropTypes.func
}

const StoryboardScene = ({ scene, act, index, removeScene }) => {
  return (
    <div className="storyboard__scene">
      <div className="storyboard__scene-component">
        <div className="storyboard__scene-component-header">
          <FaRegTrashAlt
            className="storyboard__scene-remove"
            onClick={() => removeScene(act, scene)}
            size={15}
          />
          <div className="storyboard__scene-component-header-title">
            {scene.title}
          </div>
        </div>
        <div className="storyboard__scene-component-content">
          <div className="storyboard__scene-component-content-description">
            {scene.description}
          </div>
        </div>
      </div>
      {index < act.scenes.length - 1 && (
        <div className="storyboard__scene-connector" />
      )}
    </div>
  )
}

StoryboardScene.propTypes = {
  scene: PropTypes.object.isRequired,
  act: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  removeScene: PropTypes.func.isRequired,
}

export default ScreenplayPage
