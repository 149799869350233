import {
    GET_HOME_DATA,
    GET_HOME_DATA_SUCCESS,
    GET_HOME_DATA_FAIL
} from "./actionTypes";

export const getHomeData = () => ({
    type: GET_HOME_DATA,
})

export const getHomeDataSuccess = homeData => ({
    type: GET_HOME_DATA_SUCCESS,
    payload: homeData,
});

export const getHomeDataFail = error => ({
    type: GET_HOME_DATA_FAIL,
    payload: error,
});
