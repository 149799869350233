import {
  GET_CORRECTIONS,
  GET_CORRECTION_FAIL,
  GET_CORRECTION_SUCCESS,
} from "./actionTypes"

export const getCorrections = content => ({
  type: GET_CORRECTIONS,
  payload: content
})

export const getCorrectionSuccess = content => ({
  type: GET_CORRECTION_SUCCESS,
  payload: content,
})

export const getCorrectionFail = error => ({
  type: GET_CORRECTION_FAIL,
  payload: error,
})



