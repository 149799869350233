import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_CORRECTIONS } from "./actionTypes"
import {
  getCorrectionSuccess,
  getCorrectionFail,
} from "./actions"

//Include Both Helper File with needed methods
import { fetchCorrections } from "helpers/backend_helper"

function* getCorrections({payload: content}) {
  try {
    const response = yield call(fetchCorrections, content)
    yield put(getCorrectionSuccess(response))
  } catch (error) {
    yield put(getCorrectionFail(error))
  }
}

function* correctionsSaga() {
  yield takeEvery(GET_CORRECTIONS, getCorrections)
}

export default correctionsSaga
