export const ADD_SCREENPLAY = "ADD_SCREENPLAY"
export const ADD_SCREENPLAY_SUCCESS = "ADD_SCREENPLAY_SUCCESS"
export const ADD_SCREENPLAY_FAIL = "ADD_SCREENPLAY_FAIL"

export const UPDATE_SCREENPLAY = "UPDATE_SCREENPLAY"
export const UPDATE_SCREENPLAY_SUCCESS = "UPDATE_SCREENPLAY_SUCCESS"
export const UPDATE_SCREENPLAY_FAIL = "UPDATE_SCREENPLAY_FAIL"

export const GET_SCREENPLAY = "GET_SCREENPLAY"
export const GET_SCREENPLAY_SUCCESS = "GET_SCREENPLAY_SUCCESS"
export const GET_SCREENPLAY_FAIL = "GET_SCREENPLAY_FAIL"

export const LIST_SCREENPLAYS = "LIST_SCREENPLAYS"
export const LIST_SCREENPLAYS_SUCCESS = "LIST_SCREENPLAYS_SUCCESS"
export const LIST_SCREENPLAYS_FAIL = "LIST_SCREENPLAYS_FAIL"

export const DELETE_SCREENPLAY = 'DELETE_SCREENPLAY';
export const DELETE_SCREENPLAY_SUCCESS = 'DELETE_SCREENPLAY_SUCCESS';
export const DELETE_SCREENPLAY_FAIL = 'DELETE_SCREENPLAY_FAIL';