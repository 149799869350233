import React, { useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import PropTypes from "prop-types"
import { withSignup } from "hooks/withSignup"
import MetaTags from "react-meta-tags"
import {
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Row,
} from "reactstrap"
import { getProjects, deleteProject } from "store/actions"
import { useDispatch, useSelector } from "react-redux"
import { getLoggedInUser } from "helpers/backend_helper"
import mixpanel from "mixpanel-browser"
import { useRef } from "react"
import {
  MdDeleteForever,
} from "react-icons/md"
import ProjectCard from "components/Card/project-cards/project-card";
import ProjectHeaderCard from "components/Card/project-cards/project-header";
import LoadingBar from "components/Common/loading-bar";
import NoProjectCard from "components/Common/no-project-card";
import ProjectActionBar from "components/Project/project-action-bar";

const Projects = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  function handleClick(path) {
    history.push(path)
  }

  const [showModal, setShowModal] = useState(false)
  const { projects, loading } = useSelector(state => state.projects)

  useEffect(() => {
    dispatch(getProjects())
  }, [dispatch])

  useEffect(() => {
    const loggedInUser = getLoggedInUser()
    if (loggedInUser !== null) {
      mixpanel.track("list_projects", {
        event_properties: {
          user_id: loggedInUser.uid,
        },
      })
    } else {
      mixpanel.track("list_projects")
    }
  }, [])

  const deleteRef = useRef(false)

  useEffect(() => {
    if (deleteRef.current) {
      dispatch(getProjects())
      deleteRef.current = false
    }
  }, [deleteRef.current])

  const [projectInfo, setProjectInfo] = useState()

  const handleDeleteClick = () => {
    dispatch(deleteProject(projectInfo.projectId))
    deleteRef.current = true
    setShowModal(false)
  }

  const onShowModal = projectId => {
    setShowModal(true)
    setProjectInfo({ projectId: projectId })
  }

  const onCloseModal = () => {
    setShowModal(false)
  }

  const projectItems = useMemo(
    () =>
      projects &&
      projects.items &&
      projects.items.map(item => {
        return {
          ...item,
        }
      }),
    [projects]
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Projects | QuillNow
          </title>
        </MetaTags>
        <Container fluid>
          <ProjectHeaderCard/>
          <ProjectActionBar onClick={() => handleClick("project-entry")}/>
            {loading ? (
              <LoadingBar/>
            ) : (
                  <Container className="projects-container mb-4" fluid>
                  {projectItems && projectItems?.length > 0 ? (
                        <Row>
                          {projectItems?.map(content => {
                            return (
                                <ProjectCard key={content.id} content={content} onShowModal={() => onShowModal(content.id)}/>
                            )}
                          )}
                        </Row>
                  ) : (
                      <NoProjectCard/>
                  )}
                  </Container>
              )}
        </Container>
      </div>

      {showModal && (
        <Modal
          className="project-delete-confirmation"
          isOpen={showModal}
          toggle={onCloseModal}
        >
          <ModalHeader
            className="text-center"
            toggle={onCloseModal}
          ></ModalHeader>
          <ModalBody className="text-center">
            <div className="icon-box">
              <MdDeleteForever size={55} />
            </div>
            <h2>Are you sure?</h2>
            <p>
              Do you want to delete all the documents in this project? This action cannot
              be undone.
            </p>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={onCloseModal}>
              Cancel
            </Button>
            <Button color="danger" onClick={handleDeleteClick}>
              Delete
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </React.Fragment>
  )
}

export default withSignup(Projects)

Projects.propTypes = {
  row: PropTypes.any,
  value: PropTypes.any,
  searchProps: PropTypes.any,
  baseProps: PropTypes.any,
}
