import React from "react"
import PropTypes from "prop-types"
import TabItemComponent from "./tab-items"
import { Col, Row } from "reactstrap"
import ScaleLoader from "react-spinners/ScaleLoader"
import { ParagraphEmotionScoresChart } from "components/Charts/paragraph/ParagraphEmotionScoresChart"
import { LineEmotionScoresChart } from "components/Charts/line/LineEmotionScoresChart"
import { OverallEmotionScoresChart } from "components/Charts/overall/OverallEmotionScoresChart"
import { COLOR_THEME_PRIMARY } from "../../utils"

const EmotionTabComponent = ({
  active,
  dataEmotion = {},
  isLoading = false,
  tabItems,
  setActive,
}) => {
  return (
    <>
      {/* Tabs */}
      <div className="tabs">
        {tabItems.map(({ id, title }) => (
          <TabItemComponent
            key={title}
            title={title}
            onItemClicked={() => setActive(id)}
            isActive={active === id}
          />
        ))}
      </div>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <ScaleLoader height="5rem" width="6rem" color={COLOR_THEME_PRIMARY} />
        </div>
      ) : (
        <>
          {tabItems.map(({ id }) => {
            if (active === id && id === 1) {
              return (
                <div key={id}>
                  <Row>
                    <Col className="col-10 d-flex justify-content-center align-items-center">
                      <OverallEmotionScoresChart
                        overallEmotionScores={
                          dataEmotion?.overall_stats?.emotion_score
                        }
                      />
                    </Col>
                  </Row>
                </div>
              )
            } else if (active === id && id === 2) {
              return (
                <div key={id}>
                  <Row>
                    <Col className="col-9">
                      <ParagraphEmotionScoresChart
                        contentStatsProject={dataEmotion}
                      />
                    </Col>
                  </Row>
                </div>
              )
            } else if (active === id && id === 3) {
              return (
                <div key={id}>
                  <Row>
                    <Col className="col-9">
                      <LineEmotionScoresChart
                        contentStatsProject={dataEmotion}
                      />
                    </Col>
                  </Row>
                </div>
              )
            } else {
              return null
            }
          })}
        </>
      )}
    </>
  )
}

EmotionTabComponent.propTypes = {
  active: PropTypes.number,
  dataEmotion: PropTypes.any,
  isLoading: PropTypes.any,
  tabItems: PropTypes.arrayOf(PropTypes.object),
  setActive: PropTypes.func,
}

export default EmotionTabComponent
