import * as types from "./actionTypes"

export const scoreEmotion = content => ({
  type: types.SCORE_EMOTION_REQUEST,
  payload: { content },
})
export const scoreBias = content => ({
  type: types.SCORE_BIAS_REQUEST,
  payload: { content },
})
export const scoreCognitiveBias = content => ({
  type: types.SCORE_COGNITIVE_BIAS_REQUEST,
  payload: { content },
})
export const scorePoliticalBias = content => ({
  type: types.SCORE_POLITICAL_BIAS_REQUEST,
  payload: { content },
})
export const scoreFakeness = content => ({
  type: types.SCORE_FAKENESS_REQUEST,
  payload: { content },
})
export const scoreHeadline = content => ({
  type: types.SCORE_HEADLINE_REQUEST,
  payload: { content },
})


// tone/emotion
export const scoreEmpathy = content => ({
  type: types.FETCH_EMPATHY,
  payload: { content },
});

export const scoreEngagement = content => ({
  type: types.FETCH_ENGAGEMENT,
  payload: { content },
});

export const scoreSentiment = content => ({
  type: types.FETCH_SENTIMENT,
  payload: { content },
});

// offensive/abusive
export const scoreHateSpeech = content => ({
  type: types.FETCH_HATE_SPEECH,
  payload: { content },
});

export const scoreRacismSexism = content => ({
  type: types.FETCH_RACISM_SEXISM,
  payload: { content },
});

export const scoreToxicity = content => ({
  type: types.FETCH_TOXICITY,
  payload: { content },
});

export const scorePatronize = content => ({
  type: types.FETCH_PATRONIZE,
  payload: { content },
});

// bias
export const scoreSubjectiveBias = content => ({
  type: types.FETCH_SUBJECTIVE_BIAS,
  payload: { content },
});

export const scoreGenderBias = content => ({
  type: types.FETCH_GENDER_BIAS,
  payload: { content },
});

export const reportBias = content => ({
  type: types.FETCH_REPORT_BIAS,
  payload: { content },
});

export const scoreInformativeness = content => ({
  type: types.SCORE_INFORMATIVENESS_REQUEST,
  payload: { content },
});

export const scoreComplexity = content => ({
  type: types.SCORE_COMPLEXITY_REQUEST,
  payload: { content },
});