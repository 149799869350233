import {
  GET_PROJECTS,
  GET_PROJECTS_FAIL,
  GET_PROJECTS_SUCCESS,
  GET_PROJECT_DETAIL,
  ADD_NEW_PROJECT,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_FAIL,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAIL,
  DELETE_PROJECT,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
  GET_PROJECT_DETAIL_FAIL,
  GET_PROJECT_DETAIL_SUCCESS,
  GET_PROJECT_SUMMARY,
  GET_PROJECT_SUMMARY_SUCCESS,
  GET_PROJECT_SUMMARY_FAIL,
  GET_PROJECT_TYPES,
  GET_PROJECT_TYPES_SUCCESS,
  GET_PROJECT_TYPES_FAIL,
  GET_PROJECT_CATEGORIES,
  GET_PROJECT_CATEGORIES_SUCESS,
  GET_PROJECT_CATEGORIES_FAIL,
  ADD_CONTENT_PROJECT,
  ADD_CONTENT_SUCCESS,
  ADD_CONTENT_FAIL,
  /* Add content to existing project*/
  ADD_CONTENT_TO_PROJECT,
  ADD_CONTENT_TO_SUCCESS,
  ADD_CONTENT_TO_FAIL,
  /* get content */
  GET_CONTENT_PROJECT,
  GET_CONTENT_PROJECT_SUCCESS,
  GET_CONTENT_PROJECT_FAIL,
  /* Content stats */
  GET_CONTENT_STATS,
  GET_CONTENT_STATS_SUCCESS,
  GET_CONTENT_STATS_FAIL,
  /** Content by Id */
  GET_CONTENT_ID,
  GET_CONTENT_ID_SUCCESS,
  GET_CONTENT_ID_FAIL,

  /** update Content by Id */
  UPDATE_CONTENT_ID,
  UPDATE_CONTENT_ID_SUCCESS,
  UPDATE_CONTENT_ID_FAIL,

  /** search content */
  SEARCH_CONTENT,
  SEARCH_CONTENT_SUCCESS,
  SEARCH_CONTENT_FAIL,

  /** delete Content by Id */
  DELETE_CONTENT_ID,
  DELETE_CONTENT_ID_SUCCESS,
  DELETE_CONTENT_ID_FAIL,

  /** Start project */
  ADD_START_PROJECT,
  ADD_PROJECT_START_SUCCESS,
  ADD_PROJECT_START_FAIL,
  GET_CONTENT_STATS_BY_ID,
  GET_CONTENT_STATS_BY_ID_SUCCESS,
  GET_CONTENT_STATS_BY_ID_FAIL,
  ADD_CONTENTS_TO_PROJECT,
  UPDATE_CONTENT_BY_ID,

  // Create Project
  START_CREATE_PROJECT,
  START_CREATE_PROJECT_SUCCESS,
  START_CREATE_PROJECT_FAIL,
} from "./actionTypes"

export const getProjects = () => ({
  type: GET_PROJECTS,
})

export const getProjectsSuccess = projects => ({
  type: GET_PROJECTS_SUCCESS,
  payload: projects,
})

// Starter Project
export const addStartProject = (projects, history) => ({
  type: ADD_START_PROJECT,
  payload: { projects, history },
})

export const addStartProjectSuccess = project => ({
  type: ADD_PROJECT_START_SUCCESS,
  payload: project,
})

export const addStartProjectFail = error => ({
  type: ADD_PROJECT_START_FAIL,
  payload: error,
})

export const addNewProject = projects => ({
  type: ADD_NEW_PROJECT,
  payload: projects,
})

export const addProjectSuccess = project => ({
  type: ADD_PROJECT_SUCCESS,
  payload: project,
})

export const addProjectFail = error => ({
  type: ADD_PROJECT_FAIL,
  payload: error,
})

export const updateProject = (id, project) => ({
  type: UPDATE_PROJECT,
  payload: { id, project },
})

export const updateProjectSuccess = project => ({
  type: UPDATE_PROJECT_SUCCESS,
  payload: project,
})

export const updateProjectFail = error => ({
  type: UPDATE_PROJECT_FAIL,
  payload: error,
})

// Create project

export const startCreateProject = (id, project) => ({
  type: START_CREATE_PROJECT,
  payload: { id, project },
})

export const createProjectSuccess = project => ({
  type: START_CREATE_PROJECT_SUCCESS,
  payload: project,
})

export const createProjectFail = error => ({
  type: START_CREATE_PROJECT_FAIL,
  payload: error,
})

export const deleteProject = project => ({
  type: DELETE_PROJECT,
  payload: project,
})

export const deleteProjectSuccess = project => ({
  type: DELETE_PROJECT_SUCCESS,
  payload: project,
})

export const deleteProjectFail = error => ({
  type: DELETE_PROJECT_FAIL,
  payload: error,
})

export const getProjectsFail = error => ({
  type: GET_PROJECTS_FAIL,
  payload: error,
})

export const getProjectDetail = projectId => ({
  type: GET_PROJECT_DETAIL,
  projectId,
})

export const getProjectDetailSuccess = projectDetails => ({
  type: GET_PROJECT_DETAIL_SUCCESS,
  payload: projectDetails,
})

export const getProjectDetailFail = error => ({
  type: GET_PROJECT_DETAIL_FAIL,
  payload: error,
})

export const getProjectSummary = content => ({
  type: GET_PROJECT_SUMMARY,
  payload: content,
})

export const getProjectSummarySuccess = content => ({
  type: GET_PROJECT_SUMMARY_SUCCESS,
  payload: content,
})

export const getProjectSummaryFail = error => ({
  type: GET_PROJECT_SUMMARY_FAIL,
  payload: error,
})

export const getProjectTypes = () => ({
  type: GET_PROJECT_TYPES,
})

export const getProjectTypesSuccess = types => ({
  type: GET_PROJECT_TYPES_SUCCESS,
  payload: types,
})

export const getProjectTypesFail = error => ({
  type: GET_PROJECT_TYPES_FAIL,
  payload: error,
})

export const getProjectCategories = () => ({
  type: GET_PROJECT_CATEGORIES,
})

export const getProjectCategoriesSuccess = categories => ({
  type: GET_PROJECT_CATEGORIES_SUCESS,
  payload: categories,
})

export const getProjectCategoriesFail = error => ({
  type: GET_PROJECT_CATEGORIES_FAIL,
  payload: error,
})

export const addConentProject = (projectId, projectInfo, history) => ({
  type: ADD_CONTENT_PROJECT,
  payload: { projectId, projectInfo, history },
})

export const addContentProjectSuccess = project => ({
  type: ADD_CONTENT_SUCCESS,
  payload: project,
})

export const addContentProjectFail = error => ({
  type: ADD_CONTENT_FAIL,
  payload: error,
})

// add content to exiting project
export const addConentToProject = (projectId, projectInfo) => ({
  type: ADD_CONTENT_TO_PROJECT,
  payload: { projectId, projectInfo },
})

export const addContentToProjectSuccess = project => ({
  type: ADD_CONTENT_TO_SUCCESS,
  payload: project,
})

export const addContentToProjectFail = error => ({
  type: ADD_CONTENT_TO_FAIL,
  payload: error,
})

export const getContentByProject = projectId => ({
  type: GET_CONTENT_PROJECT,
  payload: { projectId },
})

export const getContentByProjectSuccess = contents => ({
  type: GET_CONTENT_PROJECT_SUCCESS,
  payload: contents,
})

export const getContentByProjectFail = error => ({
  type: GET_CONTENT_PROJECT_FAIL,
  payload: error,
})

/* STATS */

export const getContentStats = () => ({
  type: GET_CONTENT_STATS,
})

export const getContentStatsSuccess = project => ({
  type: GET_CONTENT_STATS_SUCCESS,
  payload: project,
})

export const getContentStatsFail = error => ({
  type: GET_CONTENT_STATS_FAIL,
  payload: error,
})

/* CONTENT STATE BY ID */

export const getContentStatsById = (projectId, contentId) => ({
  type: GET_CONTENT_STATS_BY_ID,
  payload: { projectId, contentId },
})

export const getContentByStatIdSuccess = project => ({
  type: GET_CONTENT_STATS_BY_ID_SUCCESS,
  payload: project,
})

export const getContentStatByIdFail = error => ({
  type: GET_CONTENT_STATS_BY_ID_FAIL,
  payload: error,
})

/* CONTENT BY ID */

export const getContentById = (projectId, contentId) => ({
  type: GET_CONTENT_ID,
  payload: { projectId, contentId },
})

export const getContentByIdSuccess = project => ({
  type: GET_CONTENT_ID_SUCCESS,
  payload: project,
})

export const getContentByIdFail = error => ({
  type: GET_CONTENT_ID_FAIL,
  payload: error,
})

/* UPDATE CONTENT BY ID */

export const updateContentById = (projectId, contentId, contentInfo) => ({
  type: UPDATE_CONTENT_ID,
  payload: { projectId, contentId, contentInfo },
})

export const updateContentByIdSuccess = project => ({
  type: UPDATE_CONTENT_ID_SUCCESS,
  payload: project,
})

export const updateContentByIdFail = error => ({
  type: UPDATE_CONTENT_ID_FAIL,
  payload: error,
})

/* Search CONTENT BY ID */

export const searchContent = query => ({
  type: SEARCH_CONTENT,
  payload: query,
})

export const searchContentSuccess = projects => ({
  type: SEARCH_CONTENT_SUCCESS,
  payload: projects,
})

export const searchContentdFail = error => ({
  type: SEARCH_CONTENT_FAIL,
  payload: error,
})

/* DELETE CONTENT BY ID */

export const deleteContentById = (projectId, contentId) => ({
  type: DELETE_CONTENT_ID,
  payload: { projectId, contentId },
})

export const deleteContentByIdSuccess = project => ({
  type: DELETE_CONTENT_ID_SUCCESS,
  payload: project,
})

export const deleteContentByIdFail = error => ({
  type: DELETE_CONTENT_ID_FAIL,
  payload: error,
})

export const addContentToProjectAction = (projectId, data) => ({
  type: ADD_CONTENTS_TO_PROJECT,
  payload: { projectId, data },
})

export const updateContentByIdAction = (projectId, contentId, data) => ({
  type: UPDATE_CONTENT_BY_ID,
  payload: { projectId, contentId, data },
})
