export const EDIT_PROFILE = "EDIT_PROFILE"
export const PROFILE_SUCCESS = "PROFILE_SUCCESS"
export const PROFILE_ERROR = "PROFILE_ERROR"
export const RESET_PROFILE_FLAG = "RESET_PROFILE_FLAG";

export const GET_USER_INFO = "GET_USER_INFO";
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
export const GET_USER_INFO_ERROR = "GET_USER_INFO_ERROR";

export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_ERROR = "UPDATE_USER_PROFILE_ERROR";


export const GET_USER_PHOTO= "GET_USER_PHOTO"
export const GET_USER_PHOTO_SUCCESS= "GET_USER_PHOTO_SUCCESS"
export const GET_USER_PHOTO_ERROR= "GET_USER_PHOTO_ERROR"


export const GET_USER_SHOWCASE = "GET_USER_SHOWCASE"
export const GET_USER_SHOWCASE_SUCCESS = "GET_USER_SHOWCASE_SUCCESS"
export const GET_USER_SHOWCASE_ERROR = "GET_USER_SHOWCASE_ERROR"

export const UPLOAD_USER_PHOTO = "UPLOAD_USER_PHOTO"
export const UPLOAD_USER_PHOTO_SUCCESS = "UPLOAD_USER_PHOTO_SUCCESS"
export const UPLOAD_USER_PHOTO_ERROR = "UPLOAD_USER_PHOTO_ERROR"
