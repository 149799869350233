import { takeEvery, put, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import { CREATE_MEDIUM_POST, GET_ACCESS_MEDIUM } from "./actionTypes"

import {
  getAccessMediumSuccess,
  getApiMediumError,
  createMediumPostSuccess,
  createMediumPostError,
} from "./actions"

//Include Both Helper File with needed methods
import { postAccessMedium, createMediumPost } from "../../helpers/backend_helper"
import { toast } from "react-toastify"

function* onAccessMedium({ payload: mediumApikey }) {
  try {
    const response = yield call(postAccessMedium, mediumApikey)
    yield put(getAccessMediumSuccess(response))
    toast.success("Well connected !", {
      position: toast.POSITION.TOP_RIGHT,
    })
  } catch (error) {
    yield put(getApiMediumError(error))
    toast.error("Invalid Key try again!", {
      position: toast.POSITION.TOP_RIGHT,
    })
  }
}

function* onCreatesMediumPost({ payload: content }) {
  try {
    const response = yield call(createMediumPost, content)
    yield put(createMediumPostSuccess(response))
    toast.success("Post Meduim well created !", {
      position: toast.POSITION.TOP_RIGHT,
    })
  } catch (error) {
    yield put(createMediumPostError(error))
    toast.error("Post Medium not created try again!", {
      position: toast.POSITION.TOP_RIGHT,
    })
  }
}

function* authMediumSaga() {
  yield takeEvery(GET_ACCESS_MEDIUM, onAccessMedium)
  yield takeEvery(CREATE_MEDIUM_POST, onCreatesMediumPost)
}

export default authMediumSaga
