import React, { useEffect, useMemo, useState, useRef } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { getUserInfo, signUpUser, getSubscriptionPlan } from "store/actions"
import Subscription from "components/PrincingPlans"

const Plans = props => {
  const dispatch = useDispatch()
  const { user, loading } = useSelector(state => state.user)

  const userRef = useRef(true)

  useEffect(() => {
    if (userRef.current) {
      dispatch(getUserInfo())
      dispatch(getSubscriptionPlan())
    }
  }, [])

  const { subscriptionPlan } = useSelector(state => state.subscription)

  console.log("subscriptionPlan", subscriptionPlan)
  const [planSubscribe, setPlanSubscribe] = useState("monthly")
  const handleChangePlan = e => {
    const checked = e.target.checked

    if (checked) {
      setPlanSubscribe("yearly")
    } else {
      setPlanSubscribe("monthly")
    }
  }

  const pricingOptions = useMemo(() => {
    if (!subscriptionPlan) {
      return []
    }
    const planOrder = ["standard", "essential", "business"]

    return Object.keys(subscriptionPlan)
      .map(key => {
        let icon = ""
        switch (key) {
          case "business":
            icon = "cycling"
            break
          case "essential":
            icon = "run"
            break
          case "standard":
            icon = "walking"
            break
          default:
            icon = ""
        }

        return {
          name: key,
          icon: icon,
          ...subscriptionPlan[key],
        }
      })
      .sort((a, b) => planOrder.indexOf(a.name) - planOrder.indexOf(b.name))
  }, [subscriptionPlan])

  const { subscription } = user

  return (
    <React.Fragment>
      <div>
        <h2 id="parent-modal-title">
          <p className="text-center">Select a plan to subscribe</p>
        </h2>
        <div className="tab-pane">
          <div className="row mt-5">
            <div className="col-sm-12">
              <div className="justify-content-center row">
                <div className="col-lg-6">
                  <div className="text-center mb-5">
                    {/*  <p className="text-muted">
                      To achieve this, it would be necessary to have uniform
                      grammar, pronunciation and more common words If several
                      languages coalesce
                    </p> */}
                  </div>
                  <div className="slideToggle">
                    <label className="form-switch">
                      <span className="beforeinput">MONTHLY</span>
                      <input
                        className="bg-primary"
                        type="checkbox"
                        id="js-contcheckbox"
                        onChange={e => handleChangePlan(e)}
                      />
                      <i></i>
                      <span className="afterinput">ANNUAL</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center border-primary">
                {pricingOptions &&
                  pricingOptions.map((plan, index) => (
                    <Subscription
                      key={index}
                      name={plan.name}
                      tagline={plan.tag_line}
                      features={plan.features}
                      prices={plan.prices}
                      icon={plan.icon}
                      type={planSubscribe}
                      currectSubscription={subscription}
                    />
                  ))}
              </div>
              <center>
                <p className="text-muted justify-content-center">
                  You can cancel your subscription any time by emailing contact@quillnow.com
                </p>
              </center>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Plans
