import { call, put, fork, takeEvery, all } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_PORTFOLIOS,
  GET_PORTFOLIO_DETAIL,
  ADD_NEW_PORTFOLIO,
  DELETE_PORTFOLIO,
  UPDATE_PORTFOLIO,
  GET_PORTFOLIO_IMAGE,
} from "./actionTypes"
import {
  getPortfoliosSuccess,
  getPortfoliosFail,
  getPortfolioDetailSuccess,
  getPortfolioDetailFail,
  addPortfolioFail,
  addPortfolioSuccess,
  updatePortfolioSuccess,
  updatePortfolioFail,
  deletePortfolioSuccess,
  deletePortfolioFail,
  getPortfolioImageSuccess,
  getPortfolioImageFail,
} from "./actions"

// Include Both Helper File with needed methods
import {
  getPortfolios,
  getPortfoliosDetails,
  addNewPortfolio,
  updatePortfolio,
  deletePortfolio,
  portfolioImage,
} from "helpers/backend_helper"
import { toast } from "react-toastify"

function* fetchPortfolios() {
  try {
    const response = yield call(getPortfolios)
    yield put(getPortfoliosSuccess(response))
  } catch (error) {
    yield put(getPortfoliosFail(error))
  }
}

function* fetchPortfolioDetail({ portfolioId }) {
  try {
    const response = yield call(getPortfoliosDetails, portfolioId)
    yield put(getPortfolioDetailSuccess(response))
  } catch (error) {
    yield put(getPortfolioDetailFail(error))
  }
}

function* fetchPortfolioImage({ payload: { id } }) {
  try {
    const response = yield call(portfolioImage, id)
    yield put(getPortfolioImageSuccess(response))
  } catch (error) {
    yield put(getPortfolioImageFail(error))
  }
}

function* onUpdatePortfolio({ payload: { id, portfolio } }) {
  try {
    const response = yield call(updatePortfolio, id, portfolio)
    if (!response) {
      throw new Error("Response is falsy")
    }
    yield put(updatePortfolioSuccess(response))
    toast.success("The Protfolio update was successfully", {
      position: toast.POSITION.TOP_CENTER,
    })
  } catch (error) {
    yield put(updatePortfolioFail(error))
    toast.error("Just retry again after a short while", {
      position: toast.POSITION.TOP_CENTER,
    })
  }
}

function* onDeletePortfolio({ payload: portfolio }) {
  try {
    const response = yield call(deletePortfolio, portfolio)
    yield put(deletePortfolioSuccess(response))
    toast.success("The Protfolio delete was successfully", {
      position: toast.POSITION.TOP_CENTER,
    })
  } catch (error) {
    yield put(deletePortfolioFail(error))
    toast.error("Just retry again after a short while", {
      position: toast.POSITION.TOP_CENTER,
    })
  }
}

function* onAddNewPortfolio({ payload: { portfolio, history } }) {
  try {
    const response = yield call(addNewPortfolio, portfolio)

    if (!response) {
      throw new Error("Response is falsy")
    }
    yield put(addPortfolioSuccess(response))
    toast.success("The Protfolio Add was successfully", {
      position: toast.POSITION.TOP_CENTER,
    })
    history.push("/portfolio")
    /* toast("The portfolio has been added", {
      position: toast.POSITION.BOTTOM_RIGHT,
    })
 */
  } catch (error) {
    yield put(addPortfolioFail(error))
    toast.error("Just retry again after a short while", {
      position: toast.POSITION.TOP_CENTER,
    })
  }
}

export function* watchProtfolio() {
  yield takeEvery(GET_PORTFOLIOS, fetchPortfolios)
  yield takeEvery(GET_PORTFOLIO_DETAIL, fetchPortfolioDetail)
  yield takeEvery(ADD_NEW_PORTFOLIO, onAddNewPortfolio)
  yield takeEvery(UPDATE_PORTFOLIO, onUpdatePortfolio)
  yield takeEvery(DELETE_PORTFOLIO, onDeletePortfolio)
  yield takeEvery(GET_PORTFOLIO_IMAGE, fetchPortfolioImage)
}

function* portfoliosSaga() {
  yield all([fork(watchProtfolio)])
}

export default portfoliosSaga
