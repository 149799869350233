import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_GENERATE_TEXT } from "./actionTypes"
import { getGenerateTexteSuccess, getGenerateTextFail } from "./actions"

//Include Both Helper File with needed methods
import { fetchGenerateText } from "helpers/backend_helper"

function* getGenerateText({ payload: content }) {
  try {
    const response = yield call(fetchGenerateText, content)
    yield put(getGenerateTexteSuccess(response))
  } catch (error) {
    yield put(getGenerateTextFail(error))
  }
}
function* generateTextSaga() {
  yield takeEvery(GET_GENERATE_TEXT, getGenerateText)
}

export default generateTextSaga
