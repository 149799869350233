import {
  REQUEST_FEEDBACK_SUCCESS,
  REQUEST_FEEDBACK_FAIL,
  GET_FEEDBACK_REQUEST_SUCCESS,
  GET_FEEDBACK_REQUEST_FAIL,
  GET_FEEDBACK_REQUEST_LIST,
  GET_FEEDBACK_REQUEST_LIST_SUCCESS,
  GET_FEEDBACK_REQUEST_LIST_FAIL,
  GET_MY_FEEDBACK_REQUEST_LIST,
  GET_MY_FEEDBACK_REQUEST_LIST_SUCCESS,
  GET_MY_FEEDBACK_REQUEST_LIST_FAIL,
  GET_MY_FEEDBACK_COMMENT_LIST,
  GET_MY_FEEDBACK_COMMENT_LIST_SUCCESS,
  GET_MY_FEEDBACK_COMMENT_LIST_FAIL,
  CLOSE_FEEDBACK_REQUEST,
  CLOSE_FEEDBACK_REQUEST_SUCCESS,
  CLOSE_FEEDBACK_REQUEST_FAIL,
  SEND_FEEDBACK,
  SEND_FEEDBACK_SUCCESS,
  SEND_FEEDBACK_FAIL
} from "./actionTypes"

const INIT_STATE = {
  requestFeedbackList: [],
  feedbackRequestsList: [],
  myFeedbackRequestsList: [],
  myFeedbackCommentsList: [],
  feedbackComments: {},
  loading: false,
  error: {},
}

const feedbackHub = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FEEDBACK_REQUEST_LIST:
      return {
        ...state,
        feedbackRequests: action.payload.items,
        loading: true,
      }

    case GET_FEEDBACK_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        feedbackRequests: action.payload.items,
        loading: false,
      }

    case GET_FEEDBACK_REQUEST_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_MY_FEEDBACK_REQUEST_LIST:
      return {
        ...state,
        myFeedbackRequestsList: action.payload.items,
        loading: true,
      }

    case GET_MY_FEEDBACK_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        myFeedbackRequestsList: action.payload.items,
        loading: false,
      }

    case GET_MY_FEEDBACK_REQUEST_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_MY_FEEDBACK_COMMENT_LIST:
      return {
        ...state,
        myFeedbackCommentsList: action.payload.items,
        loading: true,
      }

    case GET_MY_FEEDBACK_COMMENT_LIST_SUCCESS:
      return {
        ...state,
        myFeedbackCommentsList: action.payload.items,
        loading: false,
      }

    case GET_MY_FEEDBACK_COMMENT_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case REQUEST_FEEDBACK_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case REQUEST_FEEDBACK_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_FEEDBACK_REQUEST_SUCCESS:
      return {
        ...state,
        feedbackRequestsList: state.feedbackRequestsList.map(feedbackRequest =>
            feedbackRequest.id.toString() === action.payload.id.toString()? { feedbackRequest, ...action.payload } : feedbackRequest
        ),
        loading: false,
      }

    case GET_FEEDBACK_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CLOSE_FEEDBACK_REQUEST_SUCCESS:
      return {
        ...state,
        requestFeedbackList: state.requestFeedbackList.filter(
          requestFeedback => requestFeedback.id.toString() !== action.payload.id.toString()
        ),
        loading: true,
      }

    case CLOSE_FEEDBACK_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default feedbackHub
