import {
  GET_ACCESS_TWITTER_SUCCESS,
  GET_TWITTER_API_ERROR,
  GET_TWITTER_GET_ME_SUCCESS,
  GET_TWITTER_GET_ME_FAILED,
  GET_TWITTER_FOLLOWERS_COUNT_SUCCESS,
  GET_TWITTER_FOLLOWERS_COUNT_FAILED,
} from "./actionTypes"

const initialState = {
  redirectUrl: "",
  error: "",
  follower: 0,
  user: {},
  loading: false,
}

const twitter = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCESS_TWITTER_SUCCESS:
      return {
        ...state,
        redirectUrl: action.payload,
        loading: true,
      }
      break
    case GET_TWITTER_API_ERROR:
      return { ...state, error: action.payload, loading: false }
      break
      case GET_TWITTER_GET_ME_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: true,
      }
      break
    case GET_TWITTER_GET_ME_FAILED:
      return { ...state, error: action.payload, loading: false }
      break
      case GET_TWITTER_FOLLOWERS_COUNT_SUCCESS:
      return {
        ...state,
        follower: action.payload,
        loading: true,
      }
      break
    case GET_TWITTER_FOLLOWERS_COUNT_FAILED:
      return { ...state, error: action.payload, loading: false }
      break
    default:
      return state
  }
}

export default twitter
