import React, { useState, useEffect, Suspense } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import MetaTags from "react-meta-tags"
import {
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Container,
  ListGroup,
  ListGroupItem,
} from "reactstrap"
import IdeaCard from "../../components/IdeaCard"
import Breadcrumb from "components/Common/Breadcrumb2"
// actions
import { getIdeas } from "../../store/actions"
import { getLoggedInUser } from "../../helpers/backend_helper"
import mixpanel from "mixpanel-browser"
import NoDataFound from "components/NoDataFound"
import ReadMore from "components/ReadMore"
import RemoveIdea from "./RemoveIdea"
import { withSignup } from "hooks/withSignup"
import { Dropdown } from "react-bootstrap"
import {
  MdAnalytics,
  MdDeleteForever,
  MdEditNote,
  MdOutlineNoteAdd,
  MdMoreHoriz,
} from "react-icons/md"

const Idea = () => {
  const [show, setShow] = useState(false)
  const [removeIdeaModal, setRemoveIdeaModal] = useState(false)
  const [idea, setIdea] = useState()
  const dispatch = useDispatch()
  const { ideas, loading } = useSelector(state => state.ideas)

  const [allIdeas, setAllIdeas] = useState(null)
  const [data, setData] = useState("Enter your idea here...")
  const [editorLoaded, setEditorLoaded] = useState(false)

  const handleRemoveIdea = idea => {
    if (idea) {
      setIdea(idea)
    }
    setRemoveIdeaModal(true)
  }

  useEffect(() => {
    setEditorLoaded(true)
    const loggedInUser = getLoggedInUser()
    if (loggedInUser !== null) {
      mixpanel.track("idea_list", {
        event_properties: {
          user_id: loggedInUser.uid,
        },
      })
    } else {
      mixpanel.track("idea_list")
    }
  }, [])

  useEffect(() => {
    if (ideas) {
      //dispatch(getIdeas().then(u => setAllIdeas(u)))
      dispatch(getIdeas())
    }
  }, [ideas.length])

  const handleShow = () => {
    setShow(true)
  }

  const handleHide = () => {
    setShow(false)
  }

  const items = [
    { title: "Dashboard", link: "/dashboard" },
    {
      title: "Ideas",
      link: "/idea",
    },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Idea | QuillNow
          </title>
        </MetaTags>
        <Container fluid>
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18">Ideas</h4>
                <div className="page-title-right">
                  {/* <Breadcrumb breadcrumbItems={items} /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="mb-1 row">
              <div className="col-sm-8">
                <div className="text-sm-start">
                  <Link
                    className="btn-rounded mb-2 me-2 btn btn-primary"
                    to="/idea/create-new-idea"
                  >
                    <i className="dripicons-lightbulb"></i>
                    <span>Add Idea</span>
                  </Link>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div className="ideas-container">
                  <div className="projects-container mb-4">
                    <>
                      {ideas && ideas?.items && ideas?.items?.length > 0 ? (
                        <>
                          {ideas?.items.map((idea, index) => (
                            <div key={idea?.id}>
                              <div className="col-sm-6 col-xl-2 shadow">
                                <Card className="card-container" style={{ width: "18rem" }}>
                                  <CardBody>
                                    <CardTitle>
                                      <h4>{idea?.title}</h4>
                                    </CardTitle>
                                  </CardBody>
                                  <CardBody>
                                    <CardSubtitle>
                                      <ReadMore text={idea?.content} />
                                    </CardSubtitle>
                                  </CardBody>
                                  <CardBody className="content-card-link__idea">
                                    <ListGroup className="list-group-flush">
                                      <ListGroupItem>
                                        Last update
                                        <p className="text-muted">
                                          <div>{idea?.created_at}</div>
                                        </p>
                                      </ListGroupItem>
                                    </ListGroup>

                                    <Dropdown>
                                      <Dropdown.Toggle
                                        //variant="success"
                                        id="dropdown-basic"
                                        className="bg-transparent border-0"
                                        style={{ color: "#2a3042" }}
                                      >
                                        <MdMoreHoriz size={28} />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link to={`/idea/edit/${idea.id}`}>
                                            <MdEditNote size={28} />
                                            Edit
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to={`#`}
                                            onClick={() =>
                                              handleRemoveIdea(idea)
                                            }
                                          >
                                            <MdDeleteForever size={28} />
                                            Delete
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </CardBody>
                                </Card>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : (
                        <NoDataFound message="Start saving your ideas here" />
                      )}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <RemoveIdea
            modal={removeIdeaModal}
            setModal={setRemoveIdeaModal}
            idea={idea}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withSignup(Idea)
