import React, { useEffect, useState, useCallback } from "react"
import MetaTags from "react-meta-tags"
import { Container, Row, FormFeedback } from "reactstrap"
import PropTypes from "prop-types"
import { Link, useParams, withRouter } from "react-router-dom"
import {
  addContentToProjectAction,
  getProjects,
  updateContentByIdAction,
  getContentById,
  getContentByProject,
} from "store/actions"
import Select from "react-select"
import DatePicker from "react-datepicker"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import Breadcrumb from "components/Common/Breadcrumb2"
import Editor from "components/Editor"
import * as moment from "moment"
import { useHistory } from "react-router-dom"
import { getLoggedInUser } from "../../../../helpers/backend_helper"
import mixpanel from "mixpanel-browser"
import { withSignup } from "hooks/withSignup"

const schema = yup
  .object()
  .shape({
    title: yup.string().required(),
    //charCount: yup.number().required(),
  })
  .required()

const AddDocument = props => {
  const dispatch = useDispatch()
  let history = useHistory()
  const { mode, content, project } = useParams()
  const { contentProject, contents } = useSelector(state => state.projects)

  // Breadcrumb
  const items = [
    {
      title: "Projects",
      link:
        mode === "Add"
          ? `/project/documents/list/${project}`
          : `/project/documents/list/${content}`,
    },
    {
      title: mode === "add" ? "Add Content" : "Edit Content",
      link: "#",
    },
  ]
  useEffect(() => {
    dispatch(getContentById(content, project))
    const loggedInUser = getLoggedInUser()
    if (loggedInUser !== null) {
      mixpanel.track("add_doc", {
        event_properties: {
          user_id: loggedInUser.uid,
        },
      })
    } else {
      mixpanel.track("add_doc")
    }
  }, [])

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    register("editorContent", { required: true })
  }, [register])

  const onEditorStateChange = editorState => {
    setValue("editorContent", editorState)
  }

  const submitContentForm = useCallback(data => {
    const contentFormData = new FormData()

    contentFormData.append("title", data.title)
    contentFormData.append("content", data.editorContent)
    const additionalContentInfo = {
      history: props.history,
    }
    dispatch(addContentToProjectAction(project, contentFormData))
    reset({ title: "", editorContent: "" })
    dispatch(getContentByProject(project))
    history.push(`/project/documents/list/${project}`)
  }, [])
  /*  const submitContentForm = data => {
    const contentFormData = new FormData()

    contentFormData.append("title", data.title)
    contentFormData.append("content", data.editorContent)
    const additionalContentInfo = {
      history: props.history,
    }
    dispatch(addConentProject(project, contentFormData, additionalContentInfo))
    dispatch(getProjects())
    reset({ title: "", editorContent: "" })

    props.history.push(`/project/documents/list/${content}`)
  } */

  const submitUpdateContentForm = data => {
    console.log("Data", data)
    const additionalContentInfo = {
      history: props.history,
    }
    const contentFormData = new FormData()

    contentFormData.append("title", data.title)
    contentFormData.append("content", data.editorContent)
    dispatch(
      updateContentByIdAction(
        project,
        content,
        contentFormData
        //additionalContentInfo
      )
    )
    dispatch(getProjects())
    //the content in the editor just disappears
    //reset({ title: "", editorContent: "" })
  }
  const editorContent = watch("editorContent")

  useEffect(() => {
    if (mode === "edit") {
      // get content and set form fields
      //const fields = ["title"]
      //fields?.forEach(field => setValue(field, projectStats[field]))
      if (contentProject) {
        setValue("editorContent", contentProject["content"])
        setValue("title", contentProject["title"])
      }
    }
  }, [contentProject])
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Composer</title>
        </MetaTags>
        <Container fluid>
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18">Content</h4>
                <div className="page-title-right">
                  <Breadcrumb breadcrumbItems={items} />
                </div>
              </div>
            </div>
          </div>
          <Row className="justify-content-center mt-3">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="mb-4 card-title text-capitalize">
                    {mode === "add"
                      ? `Add Content`
                      : `Edit Content`}
                  </div>
                  <div data-repeater-list="outer-group" className="outer">
                    <div data-repeater-item="true" className="outer">
                      <div className="mb-4 row mb-3">
                        <label
                          htmlFor="title"
                          className="col-form-label col-lg-2 form-label"
                        >
                          Title
                        </label>
                        <div className="col-lg-10 mb-2">
                          <div>
                            <input
                              id="title"
                              name="title"
                              placeholder="Enter Title..."
                              type="text"
                              className={`form-control ${
                                errors.title?.message && "is-invalid"
                              }`}
                              {...register("title")}
                            />
                            {errors.title?.message && (
                              <div className="invalid-feedback text-capitalize">
                                {errors.title?.message}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="mb-4 row mb-3">
                        <label className="col-form-label col-lg-2 form-label text-capitalize">
                          {/*{type} Description*/}
                          Content
                        </label>
                        <div className="col-lg-10">
                          <Editor
                            value={editorContent || ""}
                            onChange={onEditorStateChange}
                          />
                          {errors.editorContent?.message && (
                            <div className="invalid-feedback text-capitalize">
                              {errors.editorContent?.message}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="buttons-composer">
                    <div>
                      {mode === "add" ? (
                        <button
                          className="btn btn-composer"
                          onClick={handleSubmit(submitContentForm)}
                        >
                          <i className="bx bx-list-plus bx-sm"></i>
                          <span>Save</span>
                        </button>
                      ) : (
                        <div>
                          <Link
                            to={`/project/documents/list/${project}`}
                            style={{ marginRight: "12px" }}
                          >
                            <button
                              //onClick={handleSubmit(submitUpdateContentForm)}
                              className="btn btn-composer"
                              style={{ backgroundColor: "#eee" }}
                            >
                              <i className="bx bx-arrow-back bx-sm"></i>
                              <span>Back</span>
                            </button>
                          </Link>
                          <button
                            className="btn btn-composer"
                            onClick={handleSubmit(submitUpdateContentForm)}
                          >
                            <i className="bx bx-list-plus bx-sm"></i>
                            <span>Save</span>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AddDocument.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}

export default withSignup(AddDocument)
