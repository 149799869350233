import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"
// Profile
import Profile from "../pages/Authentication/profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Home from "pages/Home/index"
import Composer from "pages/Composer"
import Calendar from "pages/Calendar"
import Feed from "pages/Feed"
import FeedbackHub from "pages/Feedback"
import Idea from "pages/Idea"
import Portfolio from "pages/Portfolio"
import Analyze from "pages/Analyze"
import Project from "pages/Composer/Project"
import Projects from "pages/Composer/Projects"
import Subscription from "pages/Subscription"
import AddPortfolio from "pages/Portfolio/AddPortfolio"
import AddIdea from "pages/Idea/AddIdea"
import Payment from "pages/Payment"
import AddDocument from "pages/Composer/Projects/AddDocument"
import ContentCharts from "pages/Analyze/Project/Document/Charts"
import ListDocuments from "pages/Composer/Projects/ListDocument"
import DocumentList from "pages/Analyze/Project"
import Success from "pages/Utility/Success"
import PublicProfile from "pages/Authentication/PublicProfile"
import StarterPage from "pages/Utility/StarterPage"
import Screenplay from "pages/Screenplay"
import ScreenplayPage from "pages/Screenplay/ScreenplayPage"
import ScreenPlayTable from "pages/Screenplay/ScreenplayTable"
import Plan from "pages/Plan"

const userInfo = JSON.parse(localStorage.getItem("userInfo"))?.subscription

const authProtectedRoutes = [
  { path: "/home", component: Home },

  // Composer
  { path: "/project/composer/document/:mode/:project_id", component: Composer },

  // Composer Edit Mode
  /*  { path: "/composer/:mode/:type/:id", component: Composer }, */

  {
    path: "/project/composer/project/:project_id/:content_id",
    component: Composer,
  },

  {
    path: "/project/composer/document/:mode/:project_id?/:content_id?",
    component: Composer,
  },
  // Project
  { path: "/project-entry/:id?", component: Project },

  //Projects
  { path: "/projects", component: Projects },

  // Add and Edit Content
  /* {
    path: "/project/content/:mode/:type/:project?/:content?",
    component: AddDocument,
  }, */

  // LIST Documents
  { path: "/project/documents/list/:project", component: ListDocuments },

  // LIST Documents for analyze
  {
    path: "/analyze/project/documents/list/:project",
    component: DocumentList,
  },

  // Analyze Content
  {
    path: "/analyze/project/document/:type/:project?/:content?",
    component: ContentCharts,
  },
  {
    path: "/charts/project/document/:type/:project?/:content?",
    component: ContentCharts,
  },

  // profile
  { path: "/settings", component: Profile },

  // Calendar
  { path: "/calendar", component: Calendar },

  // Feed
  { path: "/feed", component: Feed },

  // Feedback
  { path: "/hub", component: FeedbackHub },

  // Idea
  { path: "/idea", component: Idea },

  // Portfolio
  { path: "/portfolio", component: Portfolio },

  // Analyze
  { path: "/analyze", component: Analyze },

  // Subscription
  { path: "/subscription-plan", component: Subscription },

  // Add new portfolio entry
  { path: "/portfolio/create-new-portfolio-entry", component: AddPortfolio },
  { path: "/portfolio/:edit/:id", component: AddPortfolio },

  // Add new idea entry
  { path: "/idea/create-new-idea", component: AddIdea },
  { path: "/idea/:edit/:id", component: AddIdea },

  // Payment
  { path: "/settings/payment", component: Payment },

  // Payment Success
  { path: "/payment/success", component: Success },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  //{ path: "/", exact: true, component: () => <Redirect to="/home" /> },
  { path: "/", exact: true, component: () => <Redirect to="/projects" /> },

  { path: "/welcome", component: StarterPage },

  { path: "/screenplay", component: Screenplay },

  { path: "/screenplays", component: ScreenPlayTable },

  // Screenplay
  { path: "/screenplay/:screenplay_id", component: ScreenplayPage },

  { path: "/screenplay/:edit/:id", component: Screenplay },

  { path: "/plans", component: Plan },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
]
/* } else { */
// Subscription
/*  authProtectedRoutes = [
    { path: "/settings/subscription", component: Subscription },
    { path: "/", exact: true, component: () => <Redirect to="/settings/subscription" /> }
  ] */

/* } */

const subscriptionProtectedRoutes = [
  // Starter Page
  { path: "/welcome", component: StarterPage },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/profile/:showcaseId", component: PublicProfile },
]

export { publicRoutes, authProtectedRoutes, subscriptionProtectedRoutes }
