//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"
export const ADD_NEW_USER = "/add/user"
export const UPDATE_USER = "/update/user"
export const DELETE_USER = "/delete/user"
export const UPDATE_USER_PROFILE = "/api/user/profile"
export const GET_USER_PHOTO = "/api/user/photo"
export const POST_USER_PHOTO = "/api/user/photo"

// NEW FEATURES

export const DELETE_PORTFOLIO_ENTRY = "/api/portfolio/delete"
export const CREATE_NEW_PORTFOLIO_ENTRY = "/api/portfolio"
export const REINIT_SETUP_INTENT = "/api/payment/setup/reinit"
export const GET_USER_FEED = "/api/subscription"
export const LIST_PORTFOLIO_ENTRIES = "/api/portfolio/list"
export const GET_TOKEN = "/api/token"
export const SIGNUP = "/api/signup"
export const SOCIAL_SIGNUP = "/api/signup/social"
export const GET_CONTENT_SUMMARY = "/api/content/summary"

// IDEAS
export const API_BASE_IDEA = "/api/idea"
export const LIST_IDEA = API_BASE_IDEA + "/list"
export const DELETE_IDEA = API_BASE_IDEA + "/delete"


// Home Data
export const HOME = "/api/home"

// FEEDBACK REQUESTS
export const GET_FEEDBACK_REQUEST_BY_ID = "/api/feedback/request"
export const REQUEST_FEEDBACK = "/api/user/feedback"
export const CLOSE_FEEDBACK_REQUEST = "/api/feedback/request/close"
export const COMMENT_ON_FEEDBACK_REQUEST = "/api/feedback/comment"
export const GET_FEEDBACK_REQUEST_LIST = "/api/feedback/request/list"
export const GET_MY_FEEDBACK_REQUEST_LIST = "/api/feedback/request/self/list"
export const GET_MY_FEEDBACK_LIST = "/api/feedback/comment/self/list"

//PROJECTS
export const API_BASE_PROJECT = "/api/project"
export const GET_PROJECTS = "/api/project/list"
export const GET_PROJECT_SUMMARY = "/api/content/summary"
// SUBSCRIPTION
export const UPDATE_SUBSCRIPTION = "/api/subscription"
export const GET_SUBSCRIPTION_PLANS = "/api/subscription/plans"

// FEED
export const GET_USERS_FEED = "/api/feed/list"

/** PORTFOLIO */
export const API_BASE_PORTFOLIO = "/api/portfolio"
export const PORTFOLIO_LIST = API_BASE_PORTFOLIO + "/list"
export const DELETE_PORTFOLIO = API_BASE_PORTFOLIO + "/delete"
export const GET_PROTFOLIO_MAGE_API = API_BASE_PORTFOLIO + "/image"
export const GET_PROJECT_TYPES = API_BASE_PORTFOLIO + "/types"
export const GET_PROJECT_CATEGORIES = API_BASE_PORTFOLIO + "/categories"


/** CORRECTIONS */
const API_BASE_CONTENT = "/api/content";

export const GET_CORRECTIONS = API_BASE_CONTENT + "/corrections"

/** ENTITIES */
export const GET_ENTITIES = API_BASE_CONTENT + "/entities"

/** TOPICS */
export const GET_TOPICS = API_BASE_CONTENT + "/topics"

/** SCORE */
export const GET_SCORE = API_BASE_CONTENT + "/score"
export const GET_SCORE_BY_PARA = API_BASE_CONTENT + "/score/para"

/** TWITTER */
export const GET_TWITTER_AUTHORIZATION_URL = "/twitter/auth_url"
export const GET_TWITTER_GET_ME = "/twitter/get_me"
export const GET_FOLLOWER_COUNT = "/twitter/followers_count"

/** GOOGLE */
export const GET_GOOGLE_AUTHORIZATION_URL = "/google/auth_url"

/** CHECKOUT SESSION STRIPE */
export const CREATE_CHECKOUT_SESSION = "/api/subscription/checkout"

/** GET PARAPHRASE */
export const GET_PARAPHRASES = API_BASE_CONTENT + "/paraphrase"

/** GET GENERATE TEXT */
export const GET_GENERATE_TEXT = API_BASE_CONTENT + "/generate"

/** GET USER INFO */
export const GET_USER_INFO = "/api/user/info"

/**  MEDIUM */
export const CONNECT_MEDIUM = "/api/medium/connect"
export const CREATE_POST_MEDIUM = "/api/medium/post"


/** GET DOCUMENT STATS */
export const GET_DOCUMENT_STATS = "/api/stats/document/"

/** GET USER SHOWCASE */
export const GET_USER_SHOWCASE = "/api/showcase/data/"

/** SETUP INTENT */
export const GET_SETUP_INTENT = "/api/payment/setup/reinit"

/** SUBSCRIPTION PORTAL */
export const POST_SUBSCRIPTION_PORTAL = "/api/subscription/portal"

export const POST_SCREENPLAY = "/api/screenwriting/screenplay"
export const UPDATE_SCREENPLAY = "/api/screenwriting/screenplay"
export const GET_SCREENPLAY = "/api/screenwriting/screenplay"
export const LSIT_SCREENPLAYS = "/api/screenwriting/screenplay/list"
export const DELETE_SCREENPLAY = "/api/screenwriting/screenplay"

export const ADD_ACT_SCREENPLAY = "/api/screenwriting/screenplay/act"
export const REORDER_ACT_SCREENPLAY =
  "/api/screenwriting/screenplay/act/reorder"
export const DELETE_ACT_SCREENPLAY = "/api/screenwriting/screenplay/act"

export const ADD_SCENE_ACT = "/api/screenwriting/screenplay/act/scene"
export const REORDER_SCENE_ACT =
  "/api/screenwriting/screenplay/act/scene/reorder"
export const DELETE_SCENE_ACT = "/api/screenwriting/screenplay/act/scene"

export const GET_USER_SUBSCRIPTION_INFO = "/api/user/subscription_info"

const API_BASE_CONTENT_SCORE = API_BASE_CONTENT + "/score";
export const GET_EMOTION_SCORE = API_BASE_CONTENT_SCORE + "/emotion"
export const GET_BIAS_SCORE = API_BASE_CONTENT_SCORE + "/bias"
export const GET_COGNITIVE_BIAS_SCORE = API_BASE_CONTENT_SCORE + "/cognitive_bias"
export const GET_POLITICAL_BIAS_SCORE = API_BASE_CONTENT_SCORE + "/political_bias"
export const GET_FAKENESS_SCORE = API_BASE_CONTENT_SCORE + "/fakeness"
export const GET_HEADLINE_SCORE = API_BASE_CONTENT_SCORE + "/headline"


// Define the full URLs for each API endpoint
export const GET_EMPATHY_SCORE = `${API_BASE_CONTENT_SCORE}/empathy`;
export const GET_ENGAGEMENT_SCORE = `${API_BASE_CONTENT_SCORE}/engagement`;
export const GET_SENTIMENT_SCORE = `${API_BASE_CONTENT_SCORE}/sentiment`;

export const GET_HATE_SPEECH_SCORE = `${API_BASE_CONTENT_SCORE}/hate_speech`;
export const GET_RACISM_SEXISM_SCORE = `${API_BASE_CONTENT_SCORE}/racism_sexism`;
export const GET_TOXICITY_SCORE = `${API_BASE_CONTENT_SCORE}/toxicity`;
export const GET_PATRONIZE_SCORE = `${API_BASE_CONTENT_SCORE}/patronize`;

export const GET_SUBJECTIVE_BIAS_SCORE = `${API_BASE_CONTENT_SCORE}/subjective_bias`;
export const GET_GENDER_BIAS_SCORE = `${API_BASE_CONTENT_SCORE}/gender_bias`;
export const GET_REPORT_BIAS_SCORE = `${API_BASE_CONTENT}/report/bias`;
export const GET_COMPLEXITY_SCORE = `${API_BASE_CONTENT_SCORE}/complexity`;
export const GET_INFORMATIVENESS_SCORE = `${API_BASE_CONTENT_SCORE}/informativeness`;