import React, { useEffect, useState } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { deleteAct, getScreenplay } from "store/actions"

const RemoveActModal = ({ modal, setModal, screenplay, act }) => {
  const { selectedScreenplay, loading, error } = useSelector(
    state => state.screenplayReducer
  )

  const { acts } = useSelector(state => state.actReducer)

  const dispatch = useDispatch()

  const handleRemoveAct = () => {
    // Dispatch an action to add the act to the screenplay
    try {
      dispatch(deleteAct(act.id, screenplay?.id))
      setModal(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (acts) {
      dispatch(getScreenplay(screenplay?.id))
    }
  }, [acts, dispatch])

  return (
    <>
      <Modal isOpen={modal} toggle={() => setModal(false)}>
        <ModalHeader toggle={() => setModal(false)}>Confirm Delete</ModalHeader>
        <ModalBody>
          {`Are you sure you want to delete the act ${act?.title} and all of its scenes?`}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setModal(false)}>
            Cancel
          </Button>
          <Button color="danger" onClick={handleRemoveAct}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

RemoveActModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  screenplay: PropTypes.object.isRequired,
  act: PropTypes.object.isRequired,
}

export default RemoveActModal
