import React, { useState } from "react"
import PropTypes from "prop-types"

const ReadMore = ({ text, limit = 50 }) => {
  const [expanded, setExpanded] = useState(false)
  const toggleExpand = () => setExpanded(!expanded)

  const shortText = text.slice(0, limit) + (text.length > limit ? "..." : "")

  return (
    <>
      <p>{expanded ? text : shortText}</p>
      {text.length > limit && (
        <p
          onClick={toggleExpand}
          className="read-more-button"
        >
          {expanded ? "Read Less" : "Read More..."}
        </p>
      )}
    </>
  )
}

ReadMore.propTypes = {
  text: PropTypes.string.isRequired,
  limit: PropTypes.number,
}

export default ReadMore
