import React, { useState, useEffect, useMemo, useRef, useCallback } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import useComposer from "hooks/useComposer"
import {
  getContentStatsById,
  getContentById,
  updateContentByIdAction,
  scoreEmotion,
  scoreBias,
  scoreCognitiveBias,
  scorePoliticalBias,
  scoreFakeness,
  scoreHeadline,
  updateContentById,
  addConentProject,
  startCreateProject,
} from "store/actions"
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from "draft-js"
import { Button, CardTitle } from "reactstrap"
import CEditor from "pages/Composer/MyEditor/Editor"
import TabContentComponent from "components/Tabs/TabContentComponent"
import SidebarScore from "components/Score/SidebarScore"
import _ from "lodash"
import { useSelector } from "react-redux"
import {
  getAverageLexicalDiversityScore,
  getAverageReadabilityScore,
} from "utils"
import Project from "pages/Composer/Project/index--old"

const AddModeComposer = ({ project, content }) => {
  const dispatch = useDispatch()

  const { contentsProject, contentStatsProject, isLoading } = useSelector(
    state => state.projects
  )

  const composer = useComposer()
  const {
    sidebarOpen,
    documentTitle,
    editorState,
    hasContent,
    formDataRef,
    activeCard,
    collapse,
    loading,
    toggleSidebar,
    onEditorStateChange,
    handleTitleChange,
    setEditorState,
    setDocumentTitle,
    biasScoreAverage,
    cognitiveBiasScoreAverage,
    politicalBiasScoreAverage,
    fakenessScoreAverage,
    headlineScoreAverage,
    headlineUpdatedScore,
    emotionScore,
    fakenessScore,
    biasScore,
    cognitiveBiasScore,
    politicalBiasScore,
  } = composer

  useEffect(() => {
    dispatch(getContentById(content, project))
  }, [])

  useEffect(() => {
    if (formDataRef.current && hasContent) {
      const debounceTimeout = setTimeout(() => {
        dispatch(updateContentByIdAction(project, content, formDataRef.current))
        dispatch(getContentById(content, project))
        dispatch(getContentStatsById(content, project))
      }, 2000)

      return () => clearTimeout(debounceTimeout)
    }
  }, [dispatch, formDataRef.current, hasContent])

  const statsContentRender = useMemo(() => {
    console.log(
      "stats Content Render ran:" + contentStatsProject?.document_stats
    )
    return contentStatsProject?.document_stats
  }, [contentStatsProject])

  const attributesRender = useMemo(() => {
    console.log("attributes Render ran:" + contentStatsProject?.attributes)
    return contentStatsProject?.attributes
  }, [contentStatsProject])

  const lexicalRichnessScoreAverage =
    getAverageLexicalDiversityScore(statsContentRender)
  const readabilityScore = getAverageReadabilityScore(statsContentRender)

  const overAllReadability = {}

  const handleSave = useCallback(() => {
    const rawContent = convertToRaw(editorState.getCurrentContent())
    let htmlContent = ""

    rawContent.blocks.forEach(block => {
      htmlContent += "<p>" + block.text + "</p>"
    })

    htmlContent = htmlContent.substring(3, htmlContent.length - 4)

    const formData = new FormData()
    formData.append("content", htmlContent)
    formData.append("title", documentTitle)
    dispatch(startCreateProject(project, formData))
  }, [dispatch, editorState, documentTitle, project])

  const sidebarProps = {
    isLoading,
    loading,
    overAllReadability,
    readabilityScore,
    lexicalRichnessScoreAverage,
    statsContentRender,
    fakenessScoreAverage,
    fakenessScore,
    headlineScoreAverage,
    headlineUpdatedScore,
    biasScoreAverage,
    biasScore,
    cognitiveBiasScoreAverage,
    cognitiveBiasScore,
    politicalBiasScoreAverage,
    politicalBiasScore,
    emotionScore,
  }

  return (
    <React.Fragment>
      <div style={{ flex: 1 }}>
        <CardTitle style={{ position: "relative", textAlign: "start" }}>
          <input
            className="document-title"
            type="text"
            value={documentTitle}
            onChange={handleTitleChange}
            onFocus={e => (e.target.style.backgroundColor = "#f0f0f0")}
            onBlur={e => (e.target.style.backgroundColor = "#ffffff")}
            placeholder="Untitled document"
          />
          {!sidebarOpen && (
            <Button
              color="primary"
              onClick={toggleSidebar}
              style={{
                position: "absolute",
                right: 0,
                top: "1em",
                borderRadius: "30px",
              }}
            >
              START ASSISTANT
            </Button>
          )}
        </CardTitle>
        {editorState && (
          <CEditor
            onEditorStateChange={onEditorStateChange}
            editorState={editorState}
            hasContent={hasContent}
          />
        )}
        <TabContentComponent
          collapse={collapse}
          attributesRender={attributesRender}
          statsContentRender={statsContentRender}
          isLoading={isLoading}
          loading={loading}
          emotionData={statsContentRender}
        />
        <div className="buttons-composer">
          <div>
            <button
              className="btn btn-composer composer__project"
              onClick={handleSave}
              //disabled={!hasContent ? false : true}
            >
              <span style={{ fontSize: "20px" }}> Save</span>
            </button>
          </div>
        </div>
      </div>
      <SidebarScore
        activeCard={activeCard}
        sidebarOpen={sidebarOpen}
        toggleSidebar={toggleSidebar}
        {...sidebarProps}
      />
    </React.Fragment>
  )
}

AddModeComposer.propTypes = {
  project: PropTypes.string,
  content: PropTypes.string,
  history: PropTypes.object,
}

export default AddModeComposer
