import React from "react"
import PropTypes from "prop-types"
import { Form, FormGroup, Label, Input, Button, Row, Col } from "reactstrap"
import SceneEditor from "components/Scene/SceneEditor"
import { useState } from "react"

const ActEditor = ({ act }) => {
  const [actTitle, setActTitle] = useState(act.title)
  const [actTags, setActTags] = useState(act.tags)
  const [actDescription, setActDescription] = useState(act.description)
  const [scenes, setScenes] = useState(act.scenes)

  const handleAddScene = () => {
    setScenes([
      ...scenes,
      {
        id: scenes.length + 1,
        title: "",
        description: "",
        content: "",
        tags: [],
      },
    ])
  }

  return (
    <div>
      <Form>
        <FormGroup>
          <Label for={`actTitle-${act.id}`}>Act Title</Label>
          <Input
            type="text"
            name={`actTitle-${act.id}`}
            id={`actTitle-${act.id}`}
            placeholder="Enter the act title"
            value={actTitle}
            onChange={e => setActTitle(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for={`actTags-${act.id}`}>Act Tags</Label>
          <Input
            type="text"
            name={`actTags-${act.id}`}
            id={`actTags-${act.id}`}
            placeholder="Enter the act tags (comma separated)"
            value={actTags.join(", ")}
            onChange={e => setActTags(e.target.value.split(","))}
          />
        </FormGroup>
        <FormGroup>
          <Label for={`actDescription-${act.id}`}>Act Description</Label>
          <Input
            type="textarea"
            name={`actDescription-${act.id}`}
            id={`actDescription-${act.id}`}
            placeholder="Enter the act description"
            value={actDescription}
            onChange={e => setActDescription(e.target.value)}
          />
        </FormGroup>
      </Form>
      {/*  <Button color="primary" onClick={handleAddScene}>
          Add Scene
        </Button>
      
      <Row>
        {scenes.map(scene => (
          <Col sm="6" key={scene.id}>
            <SceneEditor scene={scene} />
          </Col>
        ))}
      </Row> */}
    </div>
  )
}

ActEditor.propTypes = {
  act: PropTypes.any,
}

export default ActEditor
