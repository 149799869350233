import React from "react"
import PropTypes from "prop-types"

const MainTabComponent = ({
  title = "",
  onMainItemClicked = () =>
    console.error("You passed no action to the component"),
  isActive = false,
}) => {
  return (
    <div
      className={
        isActive ? "tab-main-item" : "tab-main-item tab-item-main--inactive"
      }
      onClick={onMainItemClicked}
    >
      <p className="tab-item-main__title">{title}</p>
    </div>
  )
}

MainTabComponent.propTypes = {
  isActive: PropTypes.bool,
  onMainItemClicked: PropTypes.func,
  title: PropTypes.string,
}

export default MainTabComponent