import * as actionTypes from "./actionTypes"

export const signupRequest = (email) => ({
  type: actionTypes.SIGNUP_REQUEST,
  payload: email,
})

export const signupSuccess = (data) => ({
  type: actionTypes.SIGNUP_SUCCESS,
  payload: data,
})

export const signupFailure = (error) => ({
  type: actionTypes.SIGNUP_FAILURE,
  payload: error,
})
