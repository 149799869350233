import React from "react"
import { Container, Row, Col } from "reactstrap"
import SlackFeedback, { themes } from "react-slack-feedback"
import { useDispatch } from "react-redux"
import { requestNewFeedback } from "store/actions"

const Footer = () => {
  const dispatch = useDispatch()

  function sendFeedback(payload, success, error) {
    const { attachments } = payload

    const formData = new FormData()
    formData.append("feedback_type", attachments[0].title)
    formData.append("feedback", attachments[0].text)

    try {
      dispatch(requestNewFeedback(formData))
      success()
    } catch (err) {
      error(err)
    }
  }

  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
                {new Date().getFullYear()} © QuillNow Ltd.
              </div>
            </Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
                <SlackFeedback
                  channel="#prod-feedback"
                  // theme={themes.dark} // (optional) See src/themes/default for default theme
                  showChannel={false}
                  showIcon={false}
                  theme={themes.defaultTheme}
                  user="Slack Feedback" // The logged in user (default = "Unknown User")
                  onSubmit={(payload, success, error) =>
                    sendFeedback(payload, success, error)
                  }
                />
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
