import {
  GET_ACCESS_TWITTER,
  GET_ACCESS_TWITTER_SUCCESS,
  GET_TWITTER_API_ERROR,
  GET_TWITTER_GET_ME,
  GET_TWITTER_GET_ME_SUCCESS,
  GET_TWITTER_GET_ME_FAILED,
  GET_TWITTER_FOLLOWERS_COUNT,
  GET_TWITTER_FOLLOWERS_COUNT_SUCCESS,
  GET_TWITTER_FOLLOWERS_COUNT_FAILED,
} from "./actionTypes"

export const getAccessTwitter = () => {
  return {
    type: GET_ACCESS_TWITTER,
  }
}

export const getAccessTwitterSuccess = redirectUrl => {
  return {
    type: GET_ACCESS_TWITTER_SUCCESS,
    payload: redirectUrl,
  }
}

export const getApiTwitterError = error => {
  return {
    type: GET_TWITTER_API_ERROR,
    payload: error,
  }
}

export const getTwitterGetMe = () => {
  return {
    type: GET_TWITTER_GET_ME,
  }
}

export const getTwitterGetMeSuccess = redirectUrl => {
  return {
    type: GET_TWITTER_GET_ME_SUCCESS,
    payload: redirectUrl,
  }
}

export const getTwitterGetMeError = error => {
  return {
    type: GET_TWITTER_GET_ME_FAILED,
    payload: error,
  }
}


export const getTwitterFollowersCount = () => {
  return {
    type: GET_TWITTER_FOLLOWERS_COUNT,
  }
}

export const getTwitterFollowersCountSuccess = redirectUrl => {
  return {
    type: GET_TWITTER_FOLLOWERS_COUNT_SUCCESS,
    payload: redirectUrl,
  }
}

export const getTwitterFollowersCountError = error => {
  return {
    type: GET_TWITTER_FOLLOWERS_COUNT_FAILED,
    payload: error,
  }
}
