import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTable } from "react-table"

function ScreenplayList({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    })

  // Render the UI for your table
  return (
    <table className="screenplay-list" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, idx) => (
          <tr key={idx} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, idx) => (
              <th key={idx} {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr key={row.id} {...row.getRowProps()}>
              {row.cells.map((cell, idx) => {
                return <td key={idx} {...cell.getCellProps()}>{cell.render("Cell")}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

ScreenplayList.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.any,
}

export default ScreenplayList
