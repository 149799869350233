import * as types from "./actionTypes"

const initialState = {
  loading: false,
  error: null,
  emotionScore: null,
  biasScore: null,
  cognitiveBiasScore: null,
  politicalBiasScore: null,
  fakenessScore: null,
  headlineScore: null,
  empathyScore: null,
  engagementScore: null,
  sentimentScore: null,
  hateSpeechScore: null,
  racismSexismScore: null,
  toxicityScore: null,
  patronizeScore: null,
  subjectiveBiasScore: null,
  genderBiasScore: null,
  reportBiasScore: null,
  informativenessScore: null,
  complexityScore: null,
}

export default function scoresReducer(state = initialState, action) {
  switch (action.type) {
    case types.SCORE_EMOTION_REQUEST:
    case types.SCORE_BIAS_REQUEST:
    case types.SCORE_COGNITIVE_BIAS_REQUEST:
    case types.SCORE_POLITICAL_BIAS_REQUEST:
    case types.SCORE_FAKENESS_REQUEST:
    case types.SCORE_HEADLINE_REQUEST:
    case types.FETCH_EMPATHY:
    case types.FETCH_ENGAGEMENT:
    case types.FETCH_SENTIMENT:
    case types.FETCH_HATE_SPEECH:
    case types.FETCH_RACISM_SEXISM:
    case types.FETCH_TOXICITY:
    case types.FETCH_PATRONIZE:
    case types.FETCH_SUBJECTIVE_BIAS:
    case types.FETCH_GENDER_BIAS:
    case types.FETCH_REPORT_BIAS:
    case types.SCORE_INFORMATIVENESS_REQUEST:
    case types.SCORE_COMPLEXITY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case types.SCORE_EMOTION_SUCCESS:
      return {
        ...state,
        loading: false,
        emotionScore: action.payload,
      }
    case types.SCORE_BIAS_SUCCESS:
      return {
        ...state,
        loading: false,
        biasScore: action.payload,
      }
    case types.SCORE_COGNITIVE_BIAS_SUCCESS:
      return {
        ...state,
        loading: false,
        cognitiveBiasScore: action.payload,
      }
    case types.SCORE_POLITICAL_BIAS_SUCCESS:
      return {
        ...state,
        loading: false,
        politicalBiasScore: action.payload,
      }
    case types.SCORE_FAKENESS_SUCCESS:
      return {
        ...state,
        loading: false,
        fakenessScore: action.payload,
      }
    case types.SCORE_HEADLINE_SUCCESS:
      return {
        ...state,
        loading: false,
        headlineScore: action.payload,
      }
    case types.SCORE_INFORMATIVENESS_SUCCESS:
      return {
        ...state,
        loading: false,
        informativenessScore: action.payload,
      }

    case types.SCORE_COMPLEXITY_SUCCESS:
      return {
        ...state,
        loading: false,
        complexityScore: action.payload,
      }

    // Success Actions
    case types.FETCH_EMPATHY_SUCCESS:
      return {
        ...state,
        loading: false,
        empathyScore: action.payload,
      }
    case types.FETCH_ENGAGEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        engagementScore: action.payload,
      }
    case types.FETCH_SENTIMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        sentimentScore: action.payload,
      }
    case types.FETCH_HATE_SPEECH_SUCCESS:
      return {
        ...state,
        loading: false,
        hateSpeechScore: action.payload,
      }
    case types.FETCH_RACISM_SEXISM_SUCCESS:
      return {
        ...state,
        loading: false,
        racismSexismScore: action.payload,
      }
    case types.FETCH_TOXICITY_SUCCESS:
      return {
        ...state,
        loading: false,
        toxicityScore: action.payload,
      }
    case types.FETCH_PATRONIZE_SUCCESS:
      return {
        ...state,
        loading: false,
        patronizeScore: action.payload,
      }
    case types.FETCH_SUBJECTIVE_BIAS_SUCCESS:
      return {
        ...state,
        loading: false,
        subjectiveBiasScore: action.payload,
      }
    case types.FETCH_GENDER_BIAS_SUCCESS:
      return {
        ...state,
        loading: false,
        genderBiasScore: action.payload,
      }
    case types.FETCH_REPORT_BIAS_SUCCESS:
      return {
        ...state,
        loading: false,
        reportBiasScore: action.payload,
      }

    // FAILURE
    case types.SCORE_EMOTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case types.SCORE_BIAS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case types.SCORE_COGNITIVE_BIAS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case types.SCORE_POLITICAL_BIAS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case types.SCORE_FAKENESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case types.SCORE_HEADLINE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    // Failure Actions
    case types.FETCH_EMPATHY_FAILURE:
    case types.FETCH_ENGAGEMENT_FAILURE:
    case types.FETCH_SENTIMENT_FAILURE:
    case types.FETCH_HATE_SPEECH_FAILURE:
    case types.FETCH_RACISM_SEXISM_FAILURE:
    case types.FETCH_TOXICITY_FAILURE:
    case types.FETCH_PATRONIZE_FAILURE:
    case types.FETCH_SUBJECTIVE_BIAS_FAILURE:
    case types.FETCH_GENDER_BIAS_FAILURE:
    case types.FETCH_REPORT_BIAS_FAILURE:
    case types.SCORE_INFORMATIVENESS_FAILURE:
    case types.SCORE_COMPLEXITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}
