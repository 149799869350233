import { call, put, takeEvery } from "redux-saga/effects"

// Feedback Redux States
import {
  REQUEST_FEEDBACK,
  GET_FEEDBACK_REQUEST,
  GET_FEEDBACK_REQUEST_LIST,
  GET_MY_FEEDBACK_REQUEST_LIST,
  GET_MY_FEEDBACK_COMMENT_LIST,
  CLOSE_FEEDBACK_REQUEST,
  SEND_FEEDBACK,
} from "./actionTypes"
import {
  closeFeedbackRequestByIdFail,
  closeFeedbackRequestByIdSuccess,
  getFeedbackRequestByIdSuccess,
  getFeedbackRequestByIdFail,
  getFeedbackRequestListFail,
  getFeedbackRequestListSuccess,
  getMyFeedbackCommentListFail,
  getMyFeedbackCommentListSuccess,
  getMyFeedbackRequestListFail,
  getMyFeedbackRequestListSuccess,
  requestNewFeedbackSuccess,
  requestNewFeedbackFail,
  sendFeedbackSuccess,
  sendFeedbackFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  feedbackRequestList,
  myFeedbackRequestList,
  requestFeedback,
  closeFeedbackRequest,
  myFeedbackList,
  commentOnFeedbackRequest,
  feedbackRequestById,
} from "helpers/backend_helper"

function* fetchFeedbackRequestList() {
  try {
    const response = yield call(feedbackRequestList())
    yield put(getFeedbackRequestListSuccess(response))
  } catch (error) {
    yield put(getFeedbackRequestListFail(error))
  }
}

function* fetchMyFeedbackRequestList() {
  try {
    const response = yield call(myFeedbackRequestList())
    yield put(getMyFeedbackRequestListSuccess(response))
  } catch (error) {
    yield put(getMyFeedbackRequestListFail(error))
  }
}

function* fetchMyFeedbackList() {
  try {
    const response = yield call(myFeedbackList())
    yield put(getMyFeedbackCommentListSuccess(response))
  } catch (error) {
    yield put(getMyFeedbackCommentListFail(error))
  }
}

function* onRequestFeedback({ payload: feedbackRequest }) {
  console.log("feedbackRequest", feedbackRequest.get("feedback"))
  try {
    const response = yield call(requestFeedback, feedbackRequest)
    yield put(requestNewFeedbackSuccess(response))
  } catch (error) {
    yield put(requestNewFeedbackFail(error))
  }
}

function* onCloseFeedbackRequest({ feedbackRequestId }) {
  try {
    const response = yield call(closeFeedbackRequest(), feedbackRequestId)
    yield put(closeFeedbackRequestByIdSuccess(response))
  } catch (error) {
    yield put(closeFeedbackRequestByIdFail(error))
  }
}

function* fetchFeedbackRequestById({ feedbackRequestId }) {
  try {
    const response = yield call(feedbackRequestById(), feedbackRequestId)
    yield put(getFeedbackRequestByIdSuccess(response))
  } catch (error) {
    yield put(getFeedbackRequestByIdFail(error))
  }
}

function* onSendFeedback({ payload: feedbackComments }) {
  try {
    const response = yield call(commentOnFeedbackRequest(), feedbackComments)
    yield put(sendFeedbackSuccess(response))
  } catch (error) {
    yield put(sendFeedbackFail(error))
  }
}

function* feedbackHubSaga() {
  yield takeEvery(REQUEST_FEEDBACK, onRequestFeedback)
  yield takeEvery(CLOSE_FEEDBACK_REQUEST, onCloseFeedbackRequest)
  yield takeEvery(SEND_FEEDBACK, onSendFeedback)

  yield takeEvery(GET_FEEDBACK_REQUEST_LIST, fetchFeedbackRequestList)
  yield takeEvery(GET_FEEDBACK_REQUEST, fetchFeedbackRequestById)
  yield takeEvery(GET_MY_FEEDBACK_REQUEST_LIST, fetchMyFeedbackRequestList)
  yield takeEvery(GET_MY_FEEDBACK_COMMENT_LIST, fetchMyFeedbackList)
}

export default feedbackHubSaga
