import React from "react"
import Chart from "react-apexcharts"
import PropTypes from "prop-types"
import { useState } from "react"
import moment from "moment"
import { useEffect } from "react"
import noDataFound from "assets/images/no-data.png"

const CustomChart = ({ data, type }) => {
  const { writing_stats: stats } = data

  const [options, setOtptions] = useState([])
  const [series, setSeries] = useState([])

  useEffect(() => {
    stats?.series.map(data => {
      setOtptions(prev => [...prev, data.label])
      setSeries(prev => [...prev, data.value])
    })
  }, [])

  console.log(series, options)

  return (
    <>
      {series.length > 0 && options.length > 0 ? (
        <Chart
          series={[
            {
              name: "Characters",
              data: series,
            },
          ]}
          options={{
            chart: {
              height: 350,
              type: "line",
            },
            forecastDataPoints: {
              count: 7,
            },
            stroke: {
              width: 5,
              curve: "smooth",
            },
            xaxis: {
              type: "datetime",
              categories: options,
              tickAmount: 10,
              labels: {
                formatter: function (value, timestamp, opts) {
                  return opts.dateFormatter(new Date(value), "dd MMM")
                },
              },
            },
            title: {
              text: "Writing Forecast",
              align: "left",
              style: {
                fontSize: "16px",
                color: "#666",
              },
            },
            fill: {
              type: "gradient",
              gradient: {
                shade: "dark",
                gradientToColors: ["#FDD835"],
                shadeIntensity: 1,
                type: "horizontal",
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100, 100, 100],
              },
            },
            yaxis: {
              min: parseInt(Math.min(...series)),
              max:  parseInt(Math.max(...series)),
            },
          }}
          type={type}
          width="100%"
          height="200"
        />
      ) : (
        <div className="noData-chart">
          <img src={noDataFound} alt="" className="noData-chart" />
          <h1 className="text-center">No writing forecast data available</h1>
          <p className="text-center">Create new project to get started</p>
        </div>
      )}
    </>
  )
}
CustomChart.propTypes = {
  data: PropTypes.array,
  series: PropTypes.object,
  type: PropTypes.string,
}
export default CustomChart
