import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useForm } from "react-hook-form"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
} from "reactstrap"
import Input from "components/Input"
import { useDispatch } from "react-redux"
import { addScene, getScreenplay } from "store/actions"
import { useSelector } from "react-redux"

function AddSceneModal({ modal, setModal, screenplay, act }) {
  const { selectedScreenplay, loading, error } = useSelector(
    state => state.screenplayReducer
  )

  const { acts } = useSelector(state => state.actReducer)
  const { scene } = useSelector(state => state.sceneReducer)

  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()

  const onSubmit = data => {
    // Dispatch an action to add the act to the screenplay
    //sceneData, actId, screenplayId
    dispatch(addScene(data, act, screenplay?.id))
    setModal(false)
  }

  useEffect(() => {
    if (acts || scene?.scene_id) {
      dispatch(getScreenplay(screenplay?.id))
    }
  }, [acts, scene?.scene_id, dispatch])

  return (
    <Modal isOpen={modal} toggle={() => setModal(false)}>
      <ModalHeader toggle={() => setModal(false)}>Add Scene</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label for="title">Scene Title</Label>
            <Input
              type="text"
              id="title"
              {...register("scene_title", { required: true })}
            />
            {errors.title && <p>This field is required</p>}
          </FormGroup>

          <FormGroup>
            <Label for="description">Scene Description</Label>
            <Input
              type="textarea"
              id="description"
              {...register("scene_description")}
            />
          </FormGroup>
          <FormGroup>
            <Label for="content">Scene Content</Label>
            <Input
              type="textarea"
              id="content"
              {...register("scene_content")}
            />
          </FormGroup>
          <FormGroup>
            <Label for="tags">Tags</Label>
            <Input type="text" id="tags" {...register("scene_tags")} />
          </FormGroup>
          <Button color="primary" type="submit">
            Add Scene
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  )
}

AddSceneModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  screenplay: PropTypes.object.isRequired,
  act: PropTypes.string.isRequired,
}

export default AddSceneModal
