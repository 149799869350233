import React from "react"
import MetaTags from "react-meta-tags"
import { Card, Container } from "reactstrap"
import Table from "../../components/FeedbackRequestTable"
import MyTable from "../../components/MyFeedbackRequestTable"
import { withSignup } from "hooks/withSignup"
const FeedbackHub = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Feedback Hub | QuillNow</title>
        </MetaTags>
        <Container fluid>
          <h4>My Feedback Requests</h4>
          <div className="row mb-3 rounded border border-dark py-5 d-flex justify-content-center align-items-center">
            <Card className="p-4">
              { <MyTable /> }
            </Card>
          </div>

          <h4>Feedback Requests</h4>
          <div className="row mb-3 rounded border border-dark py-5 d-flex justify-content-center align-items-center">
            <Card className="p-4">
              { <Table /> }
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withSignup(FeedbackHub)
