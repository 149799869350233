import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumb from "components/Common/Breadcrumb2"
// useForm validation
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Select from "react-select"
// actions
import {
  addNewPortfolio,
  getProjectTypes,
  getProjectCategories,
  getPortfolios,
  getPortfolioDetail,
  updatePortfolio,
} from "../../../store/actions"
//redux
import { useDispatch, useSelector } from "react-redux"
import { getLoggedInUser } from "helpers/backend_helper"
import mixpanel from "mixpanel-browser"
import { withSignup } from "hooks/withSignup"
import { Link, useParams } from "react-router-dom"

const schema = yup
  .object()
  .shape({
    title: yup.string().required(),
    publication: yup.string().required(),
    category: yup.string().required(),
    source_url: yup.string().required(),
    type: yup.string().required(),
    description: yup.string().required(),
  })
  .required()

const AddPortfolio = props => {
  const { edit, id } = useParams()
  const dispatch = useDispatch()
  const { categories, types } = useSelector(state => state.projects)
  const { portfolioDetail } = useSelector(state => state.portfolios)

  const items = [
    { title: "Portfolio", link: "/portfolio" },
    {
      title: "Portfolio Entry",
      link: "/portfolio/create-new-portfolio-entry",
    },
  ]

  useEffect(() => {
    dispatch(getProjectTypes())
    dispatch(getProjectCategories())
    const loggedInUser = getLoggedInUser()
    if (loggedInUser !== null) {
      mixpanel.track("add_portfolio_entry", {
        event_properties: {
          user_id: loggedInUser.uid,
        },
      })
    } else {
      mixpanel.track("add_portfolio_entry")
    }
  }, [])

  useEffect(() => {
    if (id && edit) {
      dispatch(getPortfolioDetail(id))
    }
  }, [edit, id])

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const optionsCat = React.useMemo(() =>
    categories?.map(
      option => ({
        value: option,
        label: option,
      }),
      [categories]
    )
  )

  const optionsType = React.useMemo(() =>
    types?.items?.map(
      option => ({
        value: option,
        label: option,
      }),
      [types]
    )
  )

  const onSubmitForm = data => {
    var form_data = new FormData()

    for (var key in data) {
      form_data.append(key, data[key])
    }

    if (edit && id) {
      dispatch(updatePortfolio(id, form_data))
    } else {
      dispatch(addNewPortfolio(form_data, props.history))
    }
    dispatch(getPortfolios())
  }

  useEffect(() => {
    if (edit && portfolioDetail) {
      setValue("title", portfolioDetail.title)
      setValue("publication", portfolioDetail.publication)
      setValue("image_url", portfolioDetail.image_url)
      setValue("category", portfolioDetail.category)
      setValue("source_url", portfolioDetail.source_url)
      setValue("type", portfolioDetail.type)
      setValue("description", portfolioDetail.description)
      setValue("tags", portfolioDetail.tags)
    }
  }, [portfolioDetail, setValue, edit])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Portfolio | QuillNow
          </title>
        </MetaTags>
        <Container fluid>
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18">Portfolio Entry</h4>
                <div className="page-title-right">
                  <Breadcrumb breadcrumbItems={items} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit(onSubmitForm)}>
                    <div className="mb-3 row">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Title
                      </label>
                      <div className="col-md-10">
                        <input
                          type="text"
                          placeholder="Title"
                          name="title"
                          className={`form-control ${
                            errors.title?.message && "is-invalid"
                          }`}
                          {...register("title")}
                        />
                        {errors.title?.message && (
                          <div className="invalid-feedback text-capitalize">
                            {errors.title?.message}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label
                        htmlFor="example-url-input"
                        className="col-md-2 col-form-label"
                      >
                        Publication
                      </label>
                      <div className="col-md-10">
                        <input
                          placeholder="Publication"
                          name="publication"
                          type="text"
                          className={`form-control ${
                            errors.publication?.message && "is-invalid"
                          }`}
                          {...register("publication")}
                        />
                        {errors.publication?.message && (
                          <div className="invalid-feedback text-capitalize">
                            {errors.publication?.message}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label
                        htmlFor="image-url-input"
                        className="col-md-2 col-form-label"
                      >
                        Image URL
                      </label>
                      <div className="col-md-10">
                        <input
                          id="image-url-input"
                          placeholder="Image URL"
                          name="image_url"
                          type="url"
                          className={`form-control ${
                            errors.image_url?.message && "is-invalid"
                          }`}
                          {...register("image_url")}
                        />
                        {errors.image_url?.message && (
                          <div className="invalid-feedback text-capitalize">
                            {errors.image_url?.message}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="col-md-2 col-form-label">
                        category
                      </label>
                      <div className="col-md-10">
                        <Controller
                          control={control}
                          name="category"
                          render={({ field: { onChange, value, ref } }) => (
                            <Select
                              inputRef={ref}
                              value={(optionsCat || []).find(
                                c => c.value === value
                              )}
                              onChange={val => onChange(val.value)}
                              options={optionsCat}
                              className={
                                errors.category?.message && "is-invalid"
                              }
                            />
                          )}
                        />
                        {errors.category?.message && (
                          <div className="invalid-feedback text-capitalize">
                            {errors.category?.message}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label htmlFor="tags" className="col-md-2 col-form-label">
                        Tags
                      </label>
                      <div className="col-md-10">
                        <input
                          placeholder="Separate Tags with Commas"
                          name="tags"
                          type="text"
                          className={`form-control`}
                          {...register("tags")}
                        />
                        {/* {errors.tags?.message && (
                          <div className="invalid-feedback text-capitalize">
                            {errors.tags?.message}
                          </div>
                        )} */}
                      </div>
                      {/* <div className="col-md-10">
                        <TagsInput />
                      </div> */}
                    </div>
                    {/* Source URL */}
                    <div className="mb-3 row">
                      <label
                        htmlFor="example-url-input"
                        className="col-md-2 col-form-label"
                      >
                        Source URL
                      </label>
                      <div className="col-md-10">
                        <input
                          placeholder="Source URL"
                          name="source_url"
                          type="url"
                          className={`form-control ${
                            errors.source_url?.message && "is-invalid"
                          }`}
                          {...register("source_url")}
                        />
                        {errors.source_url?.message && (
                          <div className="invalid-feedback text-capitalize">
                            {errors.source_url?.message}
                          </div>
                        )}
                      </div>
                    </div>
                    {/* Project Type */}
                    <div className="mb-3 row">
                      <label className="col-md-2 col-form-label">Type</label>
                      <div className="col-md-10">
                        <Controller
                          control={control}
                          name="type"
                          render={({ field: { onChange, value, ref } }) => (
                            <Select
                              inputRef={ref}
                              value={(optionsType || []).find(
                                c => c.value === value
                              )}
                              onChange={val => onChange(val.value)}
                              options={optionsType}
                              className={errors.type?.message && "is-invalid"}
                            />
                          )}
                        />
                        {errors.type?.message && (
                          <div className="invalid-feedback text-capitalize">
                            {errors.type?.message}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="col-md-2 col-form-label">
                        Description
                      </label>
                      <div className="col-md-10">
                        <textarea
                          name="description"
                          label="Description"
                          rows="3"
                          className={`form-control ${
                            errors.description?.message && "is-invalid"
                          }`}
                          {...register("description")}
                        />
                        {errors.description?.message && (
                          <div className="invalid-feedback text-capitalize">
                            {errors.description?.message}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <div className="portfolio-button">
                        <Link to="/portfolio" style={{ marginRight: "12px" }}>
                          <button
                            className="btn btn-composer"
                            style={{ backgroundColor: "#eee" }}
                          >
                            <i className="bx bx-arrow-back bx-sm"></i>
                            <span>Back</span>
                          </button>
                        </Link>
                        <button type="submit" className="btn btn-portfolio">
                          <i className="bx bxs-book-open"></i>
                          <span>Save</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}
AddPortfolio.propTypes = {
  history: PropTypes.object,
}

export default withSignup(AddPortfolio)
