export const SCORE_EMOTION_REQUEST = "SCORE_EMOTION_REQUEST"
export const SCORE_EMOTION_SUCCESS = "SCORE_EMOTION_SUCCESS"
export const SCORE_EMOTION_FAILURE = "SCORE_EMOTION_FAILURE"

export const SCORE_BIAS_REQUEST = "SCORE_BIAS_REQUEST"
export const SCORE_BIAS_SUCCESS = "SCORE_BIAS_SUCCESS"
export const SCORE_BIAS_FAILURE = "SCORE_BIAS_FAILURE"

export const SCORE_COGNITIVE_BIAS_REQUEST = "SCORE_COGNITIVE_BIAS_REQUEST"
export const SCORE_COGNITIVE_BIAS_SUCCESS = "SCORE_COGNITIVE_BIAS_SUCCESS"
export const SCORE_COGNITIVE_BIAS_FAILURE = "SCORE_COGNITIVE_BIAS_FAILURE"

export const SCORE_POLITICAL_BIAS_REQUEST = "SCORE_POLITICAL_BIAS_REQUEST"
export const SCORE_POLITICAL_BIAS_SUCCESS = "SCORE_POLITICAL_BIAS_SUCCESS"
export const SCORE_POLITICAL_BIAS_FAILURE = "SCORE_POLITICAL_BIAS_FAILURE"

export const SCORE_FAKENESS_REQUEST = "SCORE_FAKENESS_REQUEST"
export const SCORE_FAKENESS_SUCCESS = "SCORE_FAKENESS_SUCCESS"
export const SCORE_FAKENESS_FAILURE = "SCORE_FAKENESS_FAILURE"

export const SCORE_HEADLINE_REQUEST = "SCORE_HEADLINE_REQUEST"
export const SCORE_HEADLINE_SUCCESS = "SCORE_HEADLINE_SUCCESS"
export const SCORE_HEADLINE_FAILURE = "SCORE_HEADLINE_FAILURE"

// tone/emotion
export const FETCH_EMPATHY = "FETCH_EMPATHY"
export const FETCH_EMPATHY_SUCCESS = "FETCH_EMPATHY_SUCCESS"
export const FETCH_EMPATHY_FAILURE = "FETCH_EMPATHY_FAILURE"

export const FETCH_ENGAGEMENT = "FETCH_ENGAGEMENT"
export const FETCH_ENGAGEMENT_SUCCESS = "FETCH_ENGAGEMENT_SUCCESS"
export const FETCH_ENGAGEMENT_FAILURE = "FETCH_ENGAGEMENT_FAILURE"

export const FETCH_SENTIMENT = "FETCH_SENTIMENT"
export const FETCH_SENTIMENT_SUCCESS = "FETCH_SENTIMENT_SUCCESS"
export const FETCH_SENTIMENT_FAILURE = "FETCH_SENTIMENT_FAILURE"

// offensive/abusive
export const FETCH_HATE_SPEECH = "FETCH_HATE_SPEECH"
export const FETCH_HATE_SPEECH_SUCCESS = "FETCH_HATE_SPEECH_SUCCESS"
export const FETCH_HATE_SPEECH_FAILURE = "FETCH_HATE_SPEECH_FAILURE"

export const FETCH_RACISM_SEXISM = "FETCH_RACISM_SEXISM"
export const FETCH_RACISM_SEXISM_SUCCESS = "FETCH_RACISM_SEXISM_SUCCESS"
export const FETCH_RACISM_SEXISM_FAILURE = "FETCH_RACISM_SEXISM_FAILURE"

export const FETCH_TOXICITY = "FETCH_TOXICITY"
export const FETCH_TOXICITY_SUCCESS = "FETCH_TOXICITY_SUCCESS"
export const FETCH_TOXICITY_FAILURE = "FETCH_TOXICITY_FAILURE"

export const FETCH_PATRONIZE = "FETCH_PATRONIZE"
export const FETCH_PATRONIZE_SUCCESS = "FETCH_PATRONIZE_SUCCESS"
export const FETCH_PATRONIZE_FAILURE = "FETCH_PATRONIZE_FAILURE"

// bias
export const FETCH_SUBJECTIVE_BIAS = "FETCH_SUBJECTIVE_BIAS"
export const FETCH_SUBJECTIVE_BIAS_SUCCESS = "FETCH_SUBJECTIVE_BIAS_SUCCESS"
export const FETCH_SUBJECTIVE_BIAS_FAILURE = "FETCH_SUBJECTIVE_BIAS_FAILURE"

export const FETCH_GENDER_BIAS = "FETCH_GENDER_BIAS"
export const FETCH_GENDER_BIAS_SUCCESS = "FETCH_GENDER_BIAS_SUCCESS"
export const FETCH_GENDER_BIAS_FAILURE = "FETCH_GENDER_BIAS_FAILURE"

export const FETCH_REPORT_BIAS = "FETCH_REPORT_BIAS"
export const FETCH_REPORT_BIAS_SUCCESS = "FETCH_REPORT_BIAS_SUCCESS"
export const FETCH_REPORT_BIAS_FAILURE = "FETCH_REPORT_BIAS_FAILURE"


export const SCORE_INFORMATIVENESS_REQUEST = "SCORE_INFORMATIVENESS_REQUEST"
export const SCORE_INFORMATIVENESS_SUCCESS = "SCORE_INFORMATIVENESS_SUCCESS"
export const SCORE_INFORMATIVENESS_FAILURE = "SCORE_INFORMATIVENESS_FAILURE"

export const SCORE_COMPLEXITY_REQUEST = "SCORE_COMPLEXITY_REQUEST"
export const SCORE_COMPLEXITY_SUCCESS = "SCORE_COMPLEXITY_SUCCESS"
export const SCORE_COMPLEXITY_FAILURE = "SCORE_COMPLEXITY_FAILURE"