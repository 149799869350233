import { put, takeLatest, call } from "redux-saga/effects"
import * as types from "./actionTypes"
import {
  getBiasScore,
  getCognitiveBiasScroe,
  getEmotionScore,
  getFakenessScore,
  getHeadLineScore,
  getPoliticalBiasScore,
  getEmpathyScore,
  getEngagementScore,
  getSentimentScore,
  getHateSpeechScore,
  getRacismSexismScore,
  getToxicityScore,
  getPatronizeScore,
  getSubjectiveBiasScore,
  getGenderBiasScore,
  getReportBiasScore,
  getInformativenessScore,
  getComplexityScore,
} from "helpers/backend_helper"

function* scoreEmotionSaga({ payload }) {
  const { content } = payload
  try {
    const response = yield call(getEmotionScore, content)
    yield put({ type: types.SCORE_EMOTION_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.SCORE_EMOTION_FAILURE, payload: error })
  }
}

function* scoreBiasSaga({ payload }) {
  const { content } = payload
  try {
    const response = yield call(getBiasScore, content)
    yield put({ type: types.SCORE_BIAS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.SCORE_BIAS_FAILURE, payload: error })
  }
}

function* scoreCognitiveBiasSaga({ payload }) {
  const { content } = payload
  try {
    const response = yield call(getCognitiveBiasScroe, content)
    yield put({
      type: types.SCORE_COGNITIVE_BIAS_SUCCESS,
      payload: response,
    })
  } catch (error) {
    yield put({ type: types.SCORE_COGNITIVE_BIAS_FAILURE, payload: error })
  }
}

function* scorePoliticalBiasSaga({ payload }) {
  const { content } = payload
  try {
    const response = yield call(getPoliticalBiasScore, content)
    yield put({
      type: types.SCORE_POLITICAL_BIAS_SUCCESS,
      payload: response,
    })
  } catch (error) {
    yield put({ type: types.SCORE_POLITICAL_BIAS_FAILURE, payload: error })
  }
}

function* scoreFakenessSaga({ payload }) {
  const { content } = payload
  try {
    const response = yield call(getFakenessScore, content)
    yield put({ type: types.SCORE_FAKENESS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.SCORE_FAKENESS_FAILURE, payload: error })
  }
}

function* scoreHeadlineSaga({ payload }) {
  const { content } = payload
  try {
    const response = yield call(getHeadLineScore, content)
    yield put({ type: types.SCORE_HEADLINE_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.SCORE_HEADLINE_FAILURE, payload: error })
  }
}

// tone/emotion
function* scoreEmpathySaga({ payload }) {
  const { content } = payload
  try {
    const response = yield call(getEmpathyScore, content)
    yield put({ type: types.FETCH_EMPATHY_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.FETCH_EMPATHY_FAILURE, payload: error })
  }
}

function* scoreEngagementSaga({ payload }) {
  const { content } = payload
  try {
    const response = yield call(getEngagementScore, content)
    yield put({ type: types.FETCH_ENGAGEMENT_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.FETCH_ENGAGEMENT_FAILURE, payload: error })
  }
}

function* scoreSentimentSaga({ payload }) {
  const { content } = payload
  try {
    const response = yield call(getSentimentScore, content)
    yield put({ type: types.FETCH_SENTIMENT_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.FETCH_SENTIMENT_FAILURE, payload: error })
  }
}

// offensive/abusive
function* scoreHateSpeechSaga({ payload }) {
  const { content } = payload
  try {
    const response = yield call(getHateSpeechScore, content)
    yield put({ type: types.FETCH_HATE_SPEECH_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.FETCH_HATE_SPEECH_FAILURE, payload: error })
  }
}

function* scoreRacismSexismSaga({ payload }) {
  const { content } = payload
  try {
    const response = yield call(getRacismSexismScore, content)
    yield put({ type: types.FETCH_RACISM_SEXISM_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.FETCH_RACISM_SEXISM_FAILURE, payload: error })
  }
}

function* scoreToxicitySaga({ payload }) {
  const { content } = payload
  try {
    const response = yield call(getToxicityScore, content)
    yield put({ type: types.FETCH_TOXICITY_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.FETCH_TOXICITY_FAILURE, payload: error })
  }
}

function* scorePatronizeSaga({ payload }) {
  const { content } = payload
  try {
    const response = yield call(getPatronizeScore, content)
    yield put({ type: types.FETCH_PATRONIZE_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.FETCH_PATRONIZE_FAILURE, payload: error })
  }
}

// bias
function* scoreSubjectiveBiasSaga({ payload }) {
  const { content } = payload
  try {
    const response = yield call(getSubjectiveBiasScore, content)
    yield put({ type: types.FETCH_SUBJECTIVE_BIAS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.FETCH_SUBJECTIVE_BIAS_FAILURE, payload: error })
  }
}

function* scoreGenderBiasSaga({ payload }) {
  const { content } = payload
  try {
    const response = yield call(getGenderBiasScore, content)
    yield put({ type: types.FETCH_GENDER_BIAS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.FETCH_GENDER_BIAS_FAILURE, payload: error })
  }
}

function* reportBiasSaga({ payload }) {
  const { content } = payload
  try {
    const response = yield call(getReportBiasScore, content)
    yield put({ type: types.FETCH_REPORT_BIAS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.FETCH_REPORT_BIAS_FAILURE, payload: error })
  }
}

function* scoreComplexitySaga({ payload }) {
  const { content } = payload
  try {
    const response = yield call(getComplexityScore, content)
    yield put({ type: types.SCORE_COMPLEXITY_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.SCORE_COMPLEXITY_FAILURE, payload: error })
  }
}

function* scoreInformativenessSaga({ payload }) {
  const { content } = payload
  try {
    const response = yield call(getInformativenessScore, content)
    yield put({ type: types.SCORE_INFORMATIVENESS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.SCORE_INFORMATIVENESS_FAILURE, payload: error })
  }
}

export default function* scoreSaga() {
  // tone/emotion
  yield takeLatest(types.FETCH_EMPATHY, scoreEmpathySaga)
  yield takeLatest(types.FETCH_ENGAGEMENT, scoreEngagementSaga)
  yield takeLatest(types.FETCH_SENTIMENT, scoreSentimentSaga)

  // offensive/abusive
  yield takeLatest(types.FETCH_HATE_SPEECH, scoreHateSpeechSaga)
  yield takeLatest(types.FETCH_RACISM_SEXISM, scoreRacismSexismSaga)
  yield takeLatest(types.FETCH_TOXICITY, scoreToxicitySaga)
  yield takeLatest(types.FETCH_PATRONIZE, scorePatronizeSaga)

  // bias
  yield takeLatest(types.FETCH_SUBJECTIVE_BIAS, scoreSubjectiveBiasSaga)
  yield takeLatest(types.FETCH_GENDER_BIAS, scoreGenderBiasSaga)
  yield takeLatest(types.FETCH_REPORT_BIAS, reportBiasSaga)
  yield takeLatest(types.SCORE_EMOTION_REQUEST, scoreEmotionSaga)
  yield takeLatest(types.SCORE_BIAS_REQUEST, scoreBiasSaga)
  yield takeLatest(types.SCORE_COGNITIVE_BIAS_REQUEST, scoreCognitiveBiasSaga)
  yield takeLatest(types.SCORE_POLITICAL_BIAS_REQUEST, scorePoliticalBiasSaga)
  yield takeLatest(types.SCORE_FAKENESS_REQUEST, scoreFakenessSaga)
  yield takeLatest(types.SCORE_HEADLINE_REQUEST, scoreHeadlineSaga)
  yield takeLatest(types.SCORE_COMPLEXITY_REQUEST, scoreComplexitySaga)
  yield takeLatest(
    types.SCORE_INFORMATIVENESS_REQUEST,
    scoreInformativenessSaga
  )
}
