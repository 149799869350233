 /**
  * Update Subscription
  */
 export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION"
 export const UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS"
 export const UPDATE_SUBSCRIPTION_FAIL = "UPDATE_SUBSCRIPTION_FAIL"

 /**
  * Get Subscription plans
  */

  export const GET_SUBSCRIPTION_PLAN = "GET_SUBSCRIPTION_PLAN"
  export const GET_SUBSCRIPTION_PLAN_SUCCESS = "GET_SUBSCRIPTION_PLAN_SUCCESS"
  export const GET_SUBSCRIPTION_PLAN_FAIL = "GET_SUBSCRIPTION_PLAN_FAIL"

  /** setup intent */
export const GET_SETUP_INTENT = "GET_SETUP_INTENT"
export const GET_SETUP_INTENT_SUCCESS = "GET_SETUP_INTENT_SUCCESS"
export const GET_SETUP_INTENT_FAIL = "GET_SETUP_INTENT_FAIL"

export const POST_SUBSCRIPTION_PORTAL = "POST_SUBSCRIPTION_PORTAL"
export const POST_SUBSCRIPTION_PORTAL_SUCCESS = "POST_SUBSCRIPTION_PORTAL_SUCCESS"
export const POST_SUBSCRIPTION_PORTAL_FAIL = "POST_SUBSCRIPTION_PORTAL_FAIL"
