export const GET_ACCESS_TWITTER = "GET_ACCESS_TWITTER"
export const GET_ACCESS_TWITTER_SUCCESS = "GET_ACCESS_TWITTER_SUCCESS"
export const GET_TWITTER_API_ERROR = "GET_TWITTER_API_ERROR"

export const GET_TWITTER_GET_ME = "GET_TWITTER_GET_ME"
export const GET_TWITTER_GET_ME_SUCCESS = "GET_TWITTER_GET_ME_SUCCESS"
export const GET_TWITTER_GET_ME_FAILED = "GET_TWITTER_GET_ME_FAILED"

export const GET_TWITTER_FOLLOWERS_COUNT = "GET_TWITTER_FOLLOWERS_COUNT"
export const GET_TWITTER_FOLLOWERS_COUNT_SUCCESS = "GET_TWITTER_FOLLOWERS_COUNT_SUCCESS"
export const GET_TWITTER_FOLLOWERS_COUNT_FAILED = "GET_TWITTER_FOLLOWERS_COUNT_FAILED"
