/* IDEAS */
export const GET_IDEAS = "GET_IDEAS"
export const GET_IDEAS_SUCCESS = "GET_IDEAS_SUCCESS"
export const GET_IDEAS_FAIL = "GET_IDEAS_FAIL"

/* IDEAS DETAIL*/
export const GET_IDEA_DETAIL = "GET_IDEA_DETAIL"
export const GET_IDEA_DETAIL_SUCCESS = "GET_IDEA_DETAIL_SUCCESS"
export const GET_IDEA_DETAIL_FAIL = "GET_IDEA_DETAIL_FAIL"

/**
 * add idea
 */
 export const ADD_NEW_IDEA = "ADD_NEW_IDEA"
 export const ADD_IDEA_SUCCESS = "ADD_IDEA_SUCCESS"
 export const ADD_IDEA_FAIL = "ADD_IDEA_FAIL"
 
 /**
  * Edit idea
  */
 export const UPDATE_IDEA = "UPDATE_IDEA"
 export const UPDATE_IDEA_SUCCESS = "UPDATE_IDEA_SUCCESS"
 export const UPDATE_IDEA_FAIL = "UPDATE_IDEA_FAIL"
 
 /**
  * Delete idea
  */
 export const DELETE_IDEA = "DELETE_IDEA"
 export const DELETE_IDEA_SUCCESS = "DELETE_IDEA_SUCCESS"
 export const DELETE_IDEA_FAIL = "DELETE_IDEA_FAIL"

