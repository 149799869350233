import React from "react"
import PropTypes from "prop-types"
import BiasScore from "./BiasScore"

const BiasProfile = ({
  biasData,
  cognitiveBiasData,
  subjectiveBiasData,
  politicalBiasData,
  genderBiasData,
  error,
}) => {
  return (
    <div className="bias-profile mt-4">
      <div className="bias-profile__section mb-2">
        <h5>Bias Score</h5>
        {!biasData || Object.keys(biasData).length === 0 || biasData.error ? (
          <p className="error-message">Score is not available currently.</p>
        ) : (
          biasData && <BiasScore data={biasData} biasType="bias" />
        )}
      </div>
      <hr />
      <div className="bias-profile__section mb-2">
        <h5>Cognitive Bias</h5>
        {!cognitiveBiasData ||
        Object.keys(cognitiveBiasData).length === 0 ||
        cognitiveBiasData.error ? (
          <p className="error-message">Score is not available currently.</p>
        ) : (
          cognitiveBiasData && (
            <BiasScore data={cognitiveBiasData} biasType="cognitive" />
          )
        )}
      </div>
      <hr />
      <div className="bias-profile__section mb-2">
        <h5>Subjective Bias</h5>
        {!subjectiveBiasData ||
        Object.keys(subjectiveBiasData).length === 0 ||
        subjectiveBiasData.error ? (
          <p className="error-message">Score is not available currently.</p>
        ) : (
          subjectiveBiasData && (
            <BiasScore data={subjectiveBiasData} biasType="subjective" />
          )
        )}
      </div>
      <hr />
      <div className="bias-profile__section mb-2">
        <h5>Political Bias</h5>
        {!politicalBiasData ||
        Object.keys(politicalBiasData).length === 0 ||
        politicalBiasData.error ? (
          <p className="error-message">Score is not available currently.</p>
        ) : (
          politicalBiasData && (
            <BiasScore data={politicalBiasData} biasType="political" />
          )
        )}
      </div>
      <hr />
      <div className="bias-profile__section mb-2">
        <h5>Gender Bias</h5>
        {!genderBiasData ||
        Object.keys(genderBiasData).length === 0 ||
        genderBiasData.error ? (
          <p className="error-message">Score is not available currently.</p>
        ) : (
          genderBiasData && (
            <BiasScore data={genderBiasData} biasType="gender" />
          )
        )}
      </div>
    </div>
  )
}

BiasProfile.propTypes = {
  biasData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  cognitiveBiasData: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  subjectiveBiasData: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  politicalBiasData: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  genderBiasData: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  error: PropTypes.any.isRequired,
}
export default BiasProfile
