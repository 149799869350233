import React, { useState } from "react"
import { Progress, Modal, ModalHeader, ModalBody, Tooltip } from "reactstrap"
import PropTypes from "prop-types"

const Informativeness = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState("")
  const [tooltipOpen, setTooltipOpen] = useState({})

  const openModal = content => {
    setModalContent(content)
    setIsModalOpen(true)
  }

  const toggleModal = () => setIsModalOpen(!isModalOpen)
  const toggleTooltip = key => {
    setTooltipOpen({ ...tooltipOpen, [key]: !tooltipOpen[key] })
  }

  return (
    <div>
      {Object.keys(data).map(key => (
        <div key={key}>
          <p style={{ fontWeight: "bold" }}>{data[key].label}</p>
          <div id={`progressTooltip-${key}`} style={{ marginBottom: "10px" }}>
            <Progress
              value={data[key].score * 100}
              max={100}
              color={
                data[key].score * 100 <= 20
                  ? "danger"
                  : data[key].score * 100 <= 50
                  ? "warning"
                  : "success"
              }
            >
              {data[key].score * 100}%
            </Progress>
          </div>
          <Tooltip
            placement="top"
            isOpen={tooltipOpen[key] || false}
            target={`progressTooltip-${key}`}
            toggle={() => toggleTooltip(key)}
          >
            {`Score: ${(data[key].score * 100).toFixed(2)}%`}
          </Tooltip>
          <a
            href="#!"
            style={{
              cursor: "pointer",
              color: "black",
            }}
            onClick={e => {
              e.preventDefault()
              openModal(data[key].description)
            }}
          >
            <span style={{ textDecoration: "underline" }}>See details</span>
          </a>
          <hr />
        </div>
      ))}
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Description</ModalHeader>
        <ModalBody>{modalContent}</ModalBody>
      </Modal>
    </div>
  )
}

Informativeness.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      score: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default Informativeness
