export const ADD_ACT = 'ADD_ACT';
export const ADD_ACT_SUCCESS = 'ADD_ACT_SUCCESS';
export const ADD_ACT_FAIL = 'ADD_ACT_FAIL';

export const REORDER_ACTS = 'REORDER_ACTS';
export const REORDER_ACTS_SUCCESS = 'REORDER_ACTS_SUCCESS';
export const REORDER_ACTS_FAIL = 'REORDER_ACTS_FAIL';

export const DELETE_ACT = 'DELETE_ACT';
export const DELETE_ACT_SUCCESS = 'DELETE_ACT_SUCCESS';
export const DELETE_ACT_FAIL = 'DELETE_ACT_FAIL';
