// ToneTab.js
import React from "react"
import PropTypes from "prop-types"
import TabItemComponent from "./tab-items"
import { ResponsiveContainer } from "recharts"
import OverallLexDiversityScoresChart from "components/Charts/overall/OverallLexDiversityScoresChart"
import ParagraphLexDiversityScoresChart from "components/Charts/paragraph/ParagraphLexDiversityScoresChart"
import LoadingBar from "components/Common/loading-bar"
import { Row, Col } from "reactstrap"

const LexDiversityTabComponent = ({
  active,
  setActive,
  dataLexDiversity = {},
  isLoading = false,
  tabItems,
}) => {
  const updatedTabItems = tabItems.slice(0, tabItems.length - 1)

  const {
    overall_stats: overallAnalytics,
    paragraph_stats: paragraphLevelAnalytics,
  } = dataLexDiversity
  //setActive(2)
  return (
    <>
      {/* Tabs */}
      <div className="tabs">
        {updatedTabItems.map(({ id, title }) => (
          <TabItemComponent
            key={title}
            title={title}
            onItemClicked={() => setActive(id)}
            isActive={active === id}
          />
        ))}
      </div>
      {isLoading ? (
        <LoadingBar />
      ) : (
        <div className="mt-4">
          {updatedTabItems.map(({ id }) => {
            if (active === id && id === 1) {
              return (
                <div
                  key={id}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <ResponsiveContainer width="100%" minHeight={400}>
                    <OverallLexDiversityScoresChart
                      overallLexDiversity={overallAnalytics}
                    />
                  </ResponsiveContainer>
                </div>
              )
            } else if (active === id && id === 2) {
              return (
                <div key={id}>
                  <Row>
                    <Col className="col-10" style={{ height: "80vh"}}>
                      <ParagraphLexDiversityScoresChart
                        paragrapLexDiversity={paragraphLevelAnalytics}
                      />
                    </Col>
                  </Row>
                </div>
              )
            } else {
              return null
            }
          })}
        </div>
      )}
    </>
  )
}

LexDiversityTabComponent.propTypes = {
  active: PropTypes.number,
  setActive: PropTypes.any,
  dataLexDiversity: PropTypes.any,
  isLoading: PropTypes.any,
  tabItems: PropTypes.array,
}
export default LexDiversityTabComponent
