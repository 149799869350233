import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_IDEAS,
  GET_IDEA_DETAIL,
  ADD_NEW_IDEA,
  DELETE_IDEA,
  UPDATE_IDEA,
} from "./actionTypes"
import {
  getIdeasSuccess,
  getIdeasFail,
  getIdeaDetailSuccess,
  getIdeaDetailFail,
  addIdeaFail,
  addIdeaSuccess,
  updateIdeaSuccess,
  updateIdeaFail,
  deleteIdeaSuccess,
  deleteIdeaFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  fetchIdeas,
  getIdeasDetails,
  addNewIdea,
  updateIdea,
  deleteIdea,
} from "helpers/backend_helper"
import { toast } from "react-toastify"
function* getIdeas() {
  try {
    const response = yield call(fetchIdeas)
    yield put(getIdeasSuccess(response))
  } catch (error) {
    yield put(getIdeasFail(error))
  }
}

function* fetchIdeaDetail({ payload: { id } }) {
  try {
    const response = yield call(getIdeasDetails, id)
    if (!response) {
      throw new Error("Response is falsy")
    }
    yield put(getIdeaDetailSuccess(response))
  } catch (error) {
    yield put(getIdeaDetailFail(error))
  }
}

function* onUpdateIdea({ payload: { idea, id, history } }) {
  try {
    const response = yield call(updateIdea, id, idea)
    if (!response) {
      throw new Error("Response is falsy")
    }
    yield put(updateIdeaSuccess(response))
    toast.success("The Idea update was successfully", {
      position: toast.POSITION.TOP_CENTER,
    })
    //history.push("/ideas")
  } catch (error) {
    yield put(updateIdeaFail(error))
    toast.error("Just retry again after a short while", {
      position: toast.POSITION.TOP_CENTER,
    })
  }
}

function* ondeleteIdea({ payload: { id } }) {
  try {
    const response = yield call(deleteIdea, id)
    if (!response) {
      throw new Error("Response is falsy")
    }
    yield put(deleteIdeaSuccess(response))
    toast.success("The idea delete was successfully", {
      position: toast.POSITION.TOP_CENTER,
    })
  } catch (error) {
    console.log(error) // Log the error
    yield put(deleteIdeaFail(error))
    toast.error("Just retry again after a short while", {
      position: toast.POSITION.TOP_CENTER,
    })
  }
}

function* onAddNewIdea({ payload: { idea, history } }) {
  try {
    const response = yield call(addNewIdea, idea)

    if (!response) {
      throw new Error("Response is falsy")
    }

    yield put(addIdeaSuccess(response))

    yield history.push("/idea")

    toast.success("The Idea create was successfully", {
      position: toast.POSITION.TOP_CENTER,
    })
  } catch (error) {
    yield put(addIdeaFail(error))
    toast.error("Just retry again after a short while", {
      position: toast.POSITION.TOP_CENTER,
    })
  }
}

function* ideasSaga() {
  yield takeEvery(GET_IDEAS, getIdeas)
  yield takeEvery(GET_IDEA_DETAIL, fetchIdeaDetail)
  yield takeEvery(ADD_NEW_IDEA, onAddNewIdea)
  yield takeEvery(UPDATE_IDEA, onUpdateIdea)
  yield takeEvery(DELETE_IDEA, ondeleteIdea)
}

export default ideasSaga
