import {Col, Row} from "reactstrap";
import React from "react";

const ProjectHeaderCard = () => {
    return (
        <Row>
            <Col className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="font-size-18">Projects</h4>
                </div>
            </Col>
        </Row>
    )
}

export default ProjectHeaderCard