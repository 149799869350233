import React from "react"
import PropTypes from "prop-types"
import {
  HAPAX_COLOR,
  TTR_COLOR,
  MSTTR_COLOR,
  MATTR_COLOR,
  MTLD_COLOR,
  HDD_COLOR,
  VOCD_COLOR,
} from "utils"
import {
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from "recharts"
import {Row, Col, Container, Card, CardBody, CardLink} from "reactstrap"

function ParagraphLexDiversityScoresChart({ paragrapLexDiversity = {} }) {
  if (!paragrapLexDiversity) return null

  if (!paragrapLexDiversity || !paragrapLexDiversity.lexical_diversity_scores)
    return null

  console.log("paragrapLexDiversity", paragrapLexDiversity)
  const data = paragrapLexDiversity?.lexical_diversity_scores?.map(
    (score, i) => ({
      name: `Paragraph ${paragrapLexDiversity.paragraph_index[i]}`,
      Hapax: score.hapax,
      TTR: score.ttr,
      MTLD: score.mtld,
      HDD: score.hdd,
      VOCD: score.vocd,
    })
  )

  if (!data || data.length === 0) return null

  console.log("data", data)
  return (
    <>
      <Container style={{ width: "100%", height: "400px"}}>
        <Row style={{ height: "90%" }}>
          <Col className="col-6" style={{ width: "50%", height: "100%" }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={data}
                margin={{
                  top: 15,
                  right: 10,
                  left: 10,
                  bottom: 15,
                }}
              >
                <defs>
                  <linearGradient id="hapax" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="5%"
                      stopColor={HAPAX_COLOR}
                      stopOpacity={0.8}
                    />
                    <stop
                      offset="95%"
                      stopColor={HAPAX_COLOR}
                      stopOpacity={0}
                    />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend verticalAlign="top" height={36} />
                <Bar
                  dataKey="Hapax"
                  stroke="url(#hapax)"
                  fillOpacity={1}
                  fill="url(#hapax)"
                  isAnimationActive={true}
                />
              </BarChart>
            </ResponsiveContainer>
          </Col>
          <Col className="col-6" style={{ width: "50%", height: "100%" }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={data}
                margin={{
                  top: 15,
                  right: 10,
                  left: 10,
                  bottom: 15,
                }}
              >
                <defs>
                  <linearGradient id="ttr" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={TTR_COLOR} stopOpacity={0.8} />
                    <stop offset="95%" stopColor={TTR_COLOR} stopOpacity={0} />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend verticalAlign="top" height={36} />
                <Bar
                  dataKey="TTR"
                  stroke="url(#ttr)"
                  fillOpacity={1}
                  fill="url(#ttr)"
                  isAnimationActive={true}
                />
              </BarChart>
            </ResponsiveContainer>
          </Col>
        </Row>
        <Row id="reading-ease-exp-row">
          <Col className="col-6" id="reading-ease-exp-card">
            <Card style={{float: "left", width: "100%"}}>
              <CardBody>
                <p>Hapax is a word or an expression that occurs only once within a context.</p>
                <CardLink href="https://en.wikipedia.org/wiki/Hapax_legomenon">
                  More info
                </CardLink>
              </CardBody>
            </Card>
          </Col>
          <Col className="col-6" id="foreign-reader-reading-ease-exp-card">
            <Card style={{float: "right", width: "100%", height: "100%" }}>
              <CardBody>
                <p>Type-token ratio (TTR) computed as the ratio of number of unique terms to the total number of words.
                  The type-token ratio (TTR) is a measure of vocabulary variation within a written text or a person’s speech.</p>
                <p>The type-token ratio is shown to be a helpful measure of lexical variety within a text.
                  The more types there are in comparison to the number of tokens, then the more varied is the vocabulary, i.e. it there is greater lexical variety.</p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

ParagraphLexDiversityScoresChart.propTypes = {
  paragrapLexDiversity: PropTypes.any,
}

export default ParagraphLexDiversityScoresChart
