import React from "react"
import { css } from "@emotion/react"
import { ScaleLoader } from "react-spinners"
import PropTypes from "prop-types"

const Spinner = ({ size = 35, color = "#36d7b7", loading = true }) => {
  const override = css`
    display: block;
    margin: 0 auto;
  `

  return (
    <ScaleLoader css={override} size={size} color={color} loading={loading} />
  )
}

Spinner.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  loading: PropTypes.bool,
}

export default Spinner
