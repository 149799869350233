import {Card, CardBody, Col, Row} from "reactstrap";
import NoDataFound from "components/NoDataFound";
import React from "react";

const NoProjectCard = () => {
    return (
        <Row>
            <Col className="col-lg">
                <Card style={{width: "100%", minWidth: "20rem"}} className="card-container">
                    <CardBody>
                        <NoDataFound message="Add a project to get started"/>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}
export default NoProjectCard