import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { Box, Modal } from "@mui/material"
import Button from "@mui/material/Button"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { getUserInfo, signUpUser } from "store/actions"
import Subscription from "components/PrincingPlans"

const StarterPage = props => {
  const dispatch = useDispatch()
  const { user, loading } = useSelector(state => state.user)

  useEffect(() => {
    dispatch(getUserInfo())
  }, [])

  const { subscriptionPlan } = useSelector(state => state.subscription)
  const [planSubscribe, setPlanSubscribe] = useState("monthly")
  const handleChangePlan = e => {
    const checked = e.target.checked

    if (checked) {
      setPlanSubscribe("yearly")
    } else {
      setPlanSubscribe("monthly")
    }
  }

  const pricingOptions = useMemo(() => {
    if (!subscriptionPlan) {
      return []
    }
    const planOrder = ["essential", "standard", "business"]

    return Object.keys(subscriptionPlan)
      .map(key => {
        let icon = ""
        switch (key) {
          case "business":
            icon = "cycling"
            break
          case "essential":
            icon = "walking"
            break
          case "standard":
            icon = "run"
            break
          default:
            icon = ""
        }

        return {
          name: key,
          icon: icon,
          ...subscriptionPlan[key],
        }
      })
      .sort((a, b) => planOrder.indexOf(a.name) - planOrder.indexOf(b.name))
  }, [subscriptionPlan])

  const handleSignup = () => {
    const signUpUserForm = new FormData()
    const email = JSON.parse(localStorage.getItem("emailUser"))
    signUpUserForm.append("email", email)
    signUpUserForm.append("plan", "business")
    signUpUserForm.append("billing_frequency", "monthly")
    signUpUserForm.append("is_trial_required", true)
    dispatch(signUpUser(signUpUserForm, props.history))
    dispatch(getUserInfo())
    dispatch()
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  }


  return (
    <React.Fragment>
      <Modal
        open={!user?.firebase_id ? true : false}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 500 }}>
          <h2 id="parent-modal-title">
            <p className="text-center">
              Congratulations on starting 14 day trial
            </p>
          </h2>
          <div className="tab-pane">
            <div className="row mt-5">
              <div className="col-sm-12">
                <div className="justify-content-center row">
                  <div className="col-lg-6">
                    <div className="text-center mb-5">
                      <h4>Update your Subscription plan</h4>
                    </div>
                    <div className="slideToggle">
                      <label className="form-switch">
                        <span className="beforeinput text-info">MONTHLY</span>
                        <input
                          className="bg-info"
                          type="checkbox"
                          id="js-contcheckbox"
                          onChange={e => handleChangePlan(e)}
                        />
                        <i></i>
                        <span className="afterinput">ANNUAL</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  {pricingOptions &&
                    pricingOptions.map((plan, index) => (
                      <Subscription
                        key={index}
                        name={plan.name}
                        tagline={plan.tag_line}
                        features={plan.features}
                        prices={plan.prices}
                        icon={plan.icon}
                        type={planSubscribe}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mt-4">
            <Button variant="contained" onClick={handleSignup}>
              Get Started
            </Button>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  )
}

StarterPage.propTypes = {
  history: PropTypes.object,
}

export default withRouter(StarterPage)
