import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom"
import { connect } from "react-redux"

// Import Routes all
import {
  authProtectedRoutes,
  publicRoutes,
  subscriptionProtectedRoutes,
} from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"

// Import toastify css
import "react-toastify/dist/ReactToastify.css"

// Import Editor
import "react-quill/dist/quill.snow.css"
import "quill/dist/quill.snow.css"

// Draft Editor css
import "draft-js/dist/Draft.css"

// Date Picker css
import "react-datepicker/dist/react-datepicker.css"

// Import ToastContainer
import { ToastContainer } from "react-toastify"

// Import Firebase Configuration file
import { initFirebaseBackend } from "./helpers/firebase_helper"

//import fakeBackend from "./helpers/AuthType/fakeBackend"

import axios, { AxiosResponse } from "axios"
import { toast } from "react-toastify"
import mixpanel from "mixpanel-browser"
import Profile from "pages/Authentication/profile"
import StarterPage from "pages/Utility/StarterPage"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { getUserInfo } from "store/actions"
import { useState } from "react"
// Activating fake backend
//fakeBackend

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
}

// init firebase backend
initFirebaseBackend(firebaseConfig)
mixpanel.init("fc6a29d4afb114b070818116ced72270", {
  debug: true,
  ignore_dnt: true,
})

const App = props => {
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.user)
  const [userInfo, setUserInfo] = useState()
  const userRef = useRef(true)
  useEffect(() => {
    if (userRef.current) {
      dispatch(getUserInfo())
      setUserInfo(JSON.parse(localStorage.getItem("userInfo"))?.subscription)
    }
  }, [userRef.current])

  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }
  const Layout = getLayout()

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}
          {/* {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))} */}
          {/*  {userInfo === undefined &&
            subscriptionProtectedRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))} */}
          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              //isTrial={user?.subscription ? true : false}
              exact
            />
          ))}
          <Route render={() => <Redirect to="/login" />} />
        </Switch>
      </Router>
      <ToastContainer />
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
