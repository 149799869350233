import React, { useEffect, useState } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { deleteScene, getScreenplay } from "store/actions"

const RemoveSceneModal = ({ modal, setModal, screenplay, act, scene }) => {
  const { selectedScreenplay, loading, error } = useSelector(
    state => state.screenplayReducer
  )

  const { acts } = useSelector(state => state.actReducer)
  const { scene: getScene } = useSelector(state => state.sceneReducer)

  const dispatch = useDispatch()

  const handleRemoveScene = () => {
    // Dispatch an action to add the act to the screenplay
    console.log("Remove Scene", getScene)
    try {
      dispatch(deleteScene(scene?.scene_id ,act?.id, screenplay?.id))
      setModal(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (acts || getScene?.scene_id) {
      dispatch(getScreenplay(screenplay?.id))
    }
  }, [acts, getScene?.scene_id, dispatch])

  return (
    <>
      <Modal isOpen={modal} toggle={() => setModal(false)}>
        <ModalHeader toggle={() => setModal(false)}>Confirm Delete</ModalHeader>
        <ModalBody>
          {`Are you sure you want to delete the  ${scene?.title} in the act ${act?.title} ?`}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setModal(false)}>
            Cancel
          </Button>
          <Button color="danger" onClick={handleRemoveScene}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

RemoveSceneModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  screenplay: PropTypes.object.isRequired,
  act: PropTypes.object.isRequired,
  scene: PropTypes.object.isRequired
}

export default RemoveSceneModal
