import {
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_FAIL,
  GET_SUBSCRIPTION_PLAN,
  GET_SUBSCRIPTION_PLAN_SUCCESS,
  GET_SUBSCRIPTION_PLAN_FAIL,
  GET_SETUP_INTENT,
  GET_SETUP_INTENT_SUCCESS,
  GET_SETUP_INTENT_FAIL,
  POST_SUBSCRIPTION_PORTAL,
  POST_SUBSCRIPTION_PORTAL_SUCCESS,
  POST_SUBSCRIPTION_PORTAL_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  message: {},
  plan: {},
  subscriptionPlan: null,
  loading: false,
  error: {},
  intentId: null,
  portal: null,
}

const subscription = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_SUBSCRIPTION:
      return {
        ...state,
        plan: action.payload,
        loading: true,
      }

    case UPDATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        message: action.payload,
        loading: false,
      }

    case UPDATE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    // subscription plan
    case GET_SUBSCRIPTION_PLAN:
      return {
        ...state,
        subscriptionPlan: action.payload,
        loading: true,
      }

    case GET_SUBSCRIPTION_PLAN_SUCCESS:
      return {
        ...state,
        subscriptionPlan: action.payload,
        loading: false,
      }

    case GET_SUBSCRIPTION_PLAN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    // subscription plan
    case GET_SETUP_INTENT:
      return {
        ...state,
        intentId: action.payload,
        loading: true,
      }

    case GET_SETUP_INTENT_SUCCESS:
      return {
        ...state,
        intentId: action.payload,
        loading: false,
      }

    case GET_SUBSCRIPTION_PLAN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    // subscription portal
    case POST_SUBSCRIPTION_PORTAL:
      return {
        ...state,
        portal: action.payload,
        loading: true,
      }

    case POST_SUBSCRIPTION_PORTAL_SUCCESS:
      return {
        ...state,
        portal: action.payload,
        loading: false,
      }

    case POST_SUBSCRIPTION_PORTAL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export default subscription
