/* PROJECTS */
export const GET_PROJECTS = "GET_PROJECTS"
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS"
export const GET_PROJECTS_FAIL = "GET_PROJECTS_FAIL"

/* PROJECTS DETAIL*/
export const GET_PROJECT_DETAIL = "GET_PROJECT_DETAIL"
export const GET_PROJECT_DETAIL_SUCCESS = "GET_PROJECT_DETAIL_SUCCESS"
export const GET_PROJECT_DETAIL_FAIL = "GET_PROJECT_DETAIL_FAIL"

/**
 * add user
 */
export const ADD_NEW_PROJECT = "ADD_NEW_PROJECT"
export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS"
export const ADD_PROJECT_FAIL = "ADD_PROJECT_FAIL"

/**
 * Edit user
 */
export const UPDATE_PROJECT = "UPDATE_PROJECT"
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS"
export const UPDATE_PROJECT_FAIL = "UPDATE_PROJECT_FAIL"

/**
 * Delete user
 */
export const DELETE_PROJECT = "DELETE_PROJECT"
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS"
export const DELETE_PROJECT_FAIL = "DELETE_PROJECT_FAIL"

/**
 * Project summary
 */
export const GET_PROJECT_SUMMARY = "GET_PROJECT_SUMMARY"
export const GET_PROJECT_SUMMARY_SUCCESS = "GET_PROJECT_SUMMARY_SUCCESS"
export const GET_PROJECT_SUMMARY_FAIL = "GET_PROJECT_SUMMARY_FAIL"

/**
 * Project Types
 */
export const GET_PROJECT_TYPES = "GET_PROJECT_TYPES"
export const GET_PROJECT_TYPES_SUCCESS = "GET_PROJECT_TYPES_SUCCESS"
export const GET_PROJECT_TYPES_FAIL = "GET_PROJECT_TYPES_FAIL"

/**
 * Project Categories
 */
export const GET_PROJECT_CATEGORIES = "GET_PROJECT_CATEGORIES"
export const GET_PROJECT_CATEGORIES_SUCESS = "GET_PROJECT_CATEGORIES_SUCESS"
export const GET_PROJECT_CATEGORIES_FAIL = "GET_PROJECT_CATEGORIES_FAIL"

/** Content project */
export const ADD_CONTENT_PROJECT = "ADD_CONTENT_PROJECT"
export const ADD_CONTENT_SUCCESS = "ADD_CONTENT_SUCCESS"
export const ADD_CONTENT_FAIL = "ADD_CONTENT_FAIL"

/** get content by project */
export const GET_CONTENT_PROJECT = "GET_CONTENT_PROJECT"
export const GET_CONTENT_PROJECT_SUCCESS = "GET_CONTENT_PROJECT_SUCCESS"
export const GET_CONTENT_PROJECT_FAIL = "GET_CONTENT_PROJECT_FAIL"

/** get content stats */
export const GET_CONTENT_STATS = "GET_CONTENT_STATS"
export const GET_CONTENT_STATS_SUCCESS = "GET_CONTENT_STATS_SUCCESS"
export const GET_CONTENT_STATS_FAIL = "GET_CONTENT_STATS_FAIL"

/** get content by id actions type */

export const GET_CONTENT_ID = "GET_CONTENT_ID"
export const GET_CONTENT_ID_SUCCESS = "GET_CONTENT_ID_SUCCESS"
export const GET_CONTENT_ID_FAIL = "GET_CONTENT_ID_FAIL"

/** update content by id actions type */

export const UPDATE_CONTENT_ID = "UPDATE_CONTENT_ID"
export const UPDATE_CONTENT_ID_SUCCESS = "UPDATE_CONTENT_ID_SUCCESS"
export const UPDATE_CONTENT_ID_FAIL = "UPDATE_CONTENT_ID_FAIL"

export const SEARCH_CONTENT = "SEARCH_CONTENT"
export const SEARCH_CONTENT_SUCCESS = "SEARCH_CONTENT_SUCCESS"
export const SEARCH_CONTENT_FAIL = "SEARCH_CONTENT_FAIL"

/** DELETE content by id actions type */

export const DELETE_CONTENT_ID = "DELETE_CONTENT_ID"
export const DELETE_CONTENT_ID_SUCCESS = "DELETE_CONTENT_ID_SUCCESS"
export const DELETE_CONTENT_ID_FAIL = "DELETE_CONTENT_ID_FAIL"

/** Start Project */

export const ADD_START_PROJECT = "ADD_START_PROJECT"
export const ADD_PROJECT_START_SUCCESS = "ADD_PROJECT_START_SUCCESS"
export const ADD_PROJECT_START_FAIL = "ADD_PROJECT_START_SUCCESS"

export const ADD_CONTENT_TO_PROJECT = "ADD_CONTENT_TO_PROJECT"
export const ADD_CONTENT_TO_SUCCESS = "ADD_CONTENT_TO_SUCCESS"
export const ADD_CONTENT_TO_FAIL = "ADD_CONTENT_TO_FAIL"

/** UPDATE  */
export const ADD_CONTENT_TO_PROJECTS = "ADD_CONTENT_TO_PROJECTS"
export const ADD_CONTENT_TO_SUCCESS_S = "ADD_CONTENT_TO_SUCCESS_S"
export const ADD_CONTENT_TO_FAIL_S = "ADD_CONTENT_TO_FAIL_S"

export const GET_CONTENT_STATS_BY_ID = "GET_CONTENT_STATE_ID"
export const GET_CONTENT_STATS_BY_ID_SUCCESS = "GET_CONTENT_STATE_ID_SUCCESS"
export const GET_CONTENT_STATS_BY_ID_FAIL = "GET_CONTENT_STATE_ID_FAIL"

export const ADD_CONTENTS_TO_PROJECT = "ADD_CONTENT_TO_PROJECT"
export const UPDATE_CONTENT_BY_ID = "UPDATE_CONTENT_BY_ID"


// CREATE PROJECT
export const START_CREATE_PROJECT = "START_CREATE_PROJECT"
export const START_CREATE_PROJECT_SUCCESS = "START_CREATE_PROJECT_SUCCESS"
export const START_CREATE_PROJECT_FAIL = "START_CREATE_PROJECT_FAIL"
