import CustomChart from "components/Charts/CustomChart"
import DashboardCard from "components/DashboardCard"
import React, { Component, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import { useDispatch, useSelector  } from "react-redux"
import { getTwitterGetMe, getTwitterFollowersCount } from "../../store/actions"
import { withSignup } from "hooks/withSignup"

const Dashboard = () => {

  const dispatch = useDispatch()
  const { user, follower } = useSelector(state => state.twitter)

  useEffect(() => {
    if(user && follower) {
      dispatch(getTwitterGetMe())
      dispatch(getTwitterFollowersCount())
    }
  }, [follower, user])

  const [series, setSeries] = useState([{
    name: 'Twitter',
    data: [44, 55, 41, 67, 22, 43, 34, 55, 41, 67, 22, 43]
  }, {
    name: 'Medium',
    data: [13, 23, 20, 8, 13, 27, 12, 23, 14, 15, 11, 27]
  }])
  const [options, setOptions] = useState({
    colors: ["#55acee", "#ecc5b9"],
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: true
      },
      zoom: {
        enabled: true
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0
        }
      }
    }],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10
      },
    },
    yaxis: {
      title: {
        text: 'Followers',
        style: {
          fontSize: "18px",
          color: "#000"
        },
      },
      
    },
    xaxis: {
      type: 'datetime',
      categories: ['01/01/2011 GMT', '01/02/2011 GMT', '01/03/2011 GMT', '01/04/2011 GMT',
        '01/05/2011 GMT', '01/06/2011 GMT', '01/07/2011 GMT', '01/08/2011 GMT', '01/09/2011 GMT', '01/10/2011 GMT', '01/11/2011 GMT', '01/12/2011 GMT'
      ],
    },
    legend: {
      position: 'right',
      offsetY: 40
    },
    fill: {
      opacity: 1
    }
  },)

  const [optionViewer, setOptionViewer] = useState({
    colors: ["#55acee", "#ecc5b9"],
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: true
      },
      zoom: {
        enabled: true
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0
        }
      }
    }],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10
      },
    },
    yaxis: {
      title: {
        text: 'Viewers',
        style: {
          fontSize: "18px",
          color: "#000"
        },
      },
      
    },
    xaxis: {
      type: 'datetime',
      categories: ['01/01/2011 GMT', '01/02/2011 GMT', '01/03/2011 GMT', '01/04/2011 GMT',
        '01/05/2011 GMT', '01/06/2011 GMT', '01/07/2011 GMT', '01/08/2011 GMT', '01/09/2011 GMT', '01/10/2011 GMT', '01/11/2011 GMT', '01/12/2011 GMT'
      ],
    },
    legend: {
      position: 'right',
      offsetY: 40
    },
    fill: {
      opacity: 1
    }
  })

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Dashboard | QuillNow</title>
          </MetaTags>
          <Container fluid>
            <h4>Dashboard</h4>
            <div className="row mb-3 rounded border border-dark py-5 d-flex justify-content-center align-items-center">
              <DashboardCard count="+200" description="Read this week" color="#7886e7"/>
              <DashboardCard count="2" description="Articles published this week" color="#ff7d85"/>
              <DashboardCard count="+7 %" description="Followers this week" color="#5d9370"/>
            </div>
            <div className="row mb-3 rounded border border-dark py-5 d-flex justify-content-center align-items-center">
              <CustomChart series={series} options={optionViewer} type="bar"/>
              <CustomChart series={series} options={options} type="bar"/>
            </div>
          </Container>
        </div>
      </React.Fragment>
    )
}

export default withSignup(Dashboard)
