import React from "react";
import PropTypes from "prop-types"
import useBlobUrl from "hooks/useBlobUrl";

const ImageBlob = ({ src, alt, ...rest }) => {
  const blobSrc = useBlobUrl(src);

  return <img src={blobSrc} alt={alt} {...rest} />;
};

ImageBlob.propTypes = {
    src: PropTypes.any,
    alt: PropTypes.string,
}

export default ImageBlob;