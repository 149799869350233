import { 
  ADD_ACT, 
  ADD_ACT_SUCCESS, 
  ADD_ACT_FAIL, 
  REORDER_ACTS, 
  REORDER_ACTS_SUCCESS, 
  REORDER_ACTS_FAIL, 
  DELETE_ACT, 
  DELETE_ACT_SUCCESS, 
  DELETE_ACT_FAIL 
} from './actionTypes';

export const addAct = (actData, screenplayId) => ({
  type: ADD_ACT,
  payload: { actData, screenplayId }
});

export const addActSuccess = (actData) => ({
  type: ADD_ACT_SUCCESS,
  payload: actData
});

export const addActFail = (error) => ({
  type: ADD_ACT_FAIL,
  payload: error
});

export const reorderActs = (actIds, screenplayId) => ({
  type: REORDER_ACTS,
  payload: { actIds, screenplayId }
});

export const reorderActsSuccess = (actIds) => ({
  type: REORDER_ACTS_SUCCESS,
  payload: actIds
});

export const reorderActsFail = (error) => ({
  type: REORDER_ACTS_FAIL,
  payload: error
});

export const deleteAct = (actId, screenplayId) => ({
  type: DELETE_ACT,
  payload: { actId, screenplayId }
});

export const deleteActSuccess = (actId) => ({
  type: DELETE_ACT_SUCCESS,
  payload: actId
});

export const deleteActFail = (error) => ({
  type: DELETE_ACT_FAIL,
  payload: error
});
