import {
  CREATE_CHECKOUT_SESSION,
  CREATE_CHECKOUT_SESSION_SUCCESS,
  CREATE_CHECKOUT_ERROR,
} from "./actionTypes"

const initialState = {
  session: {},
  error: "",
  loading: false,
}

const checkout = (state = initialState, action) => {
  switch (action.type) {

    case CREATE_CHECKOUT_SESSION_SUCCESS:
      return {
        ...state,
        session: action.payload,
        loading: true,
      }

    case CREATE_CHECKOUT_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default checkout
